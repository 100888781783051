<template>
    <div class="background-white p-3 loading-skeleton">
        <div class="container-fluid row p-lg-3">
            <div class="col-md-6 p-2" style="height:100px;">
                <table class="data-tabel " >
                    <tr>
                        <td class="td-nama-produk">
                                <div class="nama-produk">
                                    <figure class="img-profile">
                                        <!-- <img :src="image_produk" alt=""> -->
                                    </figure>
                                    <h6 class="px-2">Lorem ipsum dolor, sit amet consectetur </h6>
                                </div>
                            </td>
                    </tr>
                </table>
            </div>
            <div class="col-md-6 p-2" style="height:100px;">
                <table class="data-tabel " >
                    <tr>
                        <td class="td-nama-produk">
                                <div class="nama-produk">
                                    <figure class="img-profile">
                                        <!-- <img :src="image_produk" alt=""> -->
                                    </figure>
                                    <h6 class="px-2">Lorem ipsum dolor, sit amet consectetur </h6>
                                </div>
                            </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<style scoped>

.img-profile{
    width: 150px;
    height: 110px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.img-profile img{
    width: 100%;
}
.td-nama-produk{
    max-width: 200px !important;
}
.nama-produk{
    display: flex;
    margin: 0px;
    margin-right: 0px !important;
}
.nama-produk h6{
    vertical-align: inherit;
    margin: auto 0;
}
.console_log{
    border: 2px solid red !important;
}

.loading-skeleton h1,
.loading-skeleton h2,
.loading-skeleton h3,
.loading-skeleton h4,
.loading-skeleton h5,
.loading-skeleton h6,
.loading-skeleton p,
.loading-skeleton li,
.loading-skeleton .btn,
.loading-skeleton v-select,
.loading-skeleton label,
.loading-skeleton .image-user,
.loading-skeleton .datatable,
.loading-skeleton .form-control {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee;
  border-color: #eee;
}
.loading-skeleton h1::placeholder,
.loading-skeleton h2::placeholder,
.loading-skeleton h3::placeholder,
.loading-skeleton h4::placeholder,
.loading-skeleton h5::placeholder,
.loading-skeleton h6::placeholder,
.loading-skeleton p::placeholder,
.loading-skeleton li::placeholder, 
.loading-skeleton v-select::placeholder, 
.loading-skeleton .btn::placeholder,
.loading-skeleton .datatable::placeholder,
.loading-skeleton .image-user::placeholder,
.loading-skeleton label::placeholder,
.loading-skeleton .form-control::placeholder {
  color: transparent;
}

@keyframes loading-skeleton {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;
}
.loading-skeleton img {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
.loading-skeleton .datatable {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
</style>
