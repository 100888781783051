<template>
  <div class="nav-container p-0" :class="[sticky ? 'sticky-nav' : '']">
    <div class="nav-top">
      <div class="nav-top-container container">
        <div class="nav-top-item" :class="{ 'loading-skeleton' : loading }">
          <i class='bx bx-phone'></i>
          <span>{{setting.phone}}</span>
        </div>

        <div class="nav-top-item" :class="{ 'loading-skeleton' : loading }">
          <i class='bx bx-envelope mr-2'></i>
          <span>{{setting.email}}</span>
        </div>
      </div>
    </div>

    <div class="nav-bottom">
      <div class="nav-bottom-container container">
        <router-link to="/" class="nav-bottom-logo hide-mobile custom-link logo-container">
          <!-- <img :src="require(`../assets/logo-armed.png`)" alt="" class="logo"> -->
          <logo class="logo_nav"></logo>
        </router-link>
        <div @click="openSidebar" class="nav-bottom-icon hide-mobile">
          <i class='bx bx-menu'></i>
        </div>
        <div class="nav-bottom-search">
          <form @submit.prevent="searchProduct($event)" class="d-flex justify-content-between">
            <input type="text" v-model="search" placeholder="Cari produk.." class="nav-search-input">
            <button type="submit" class="bg-transparent d-inline">
              <i class='bx bx-search'></i>
            </button>
          </form>
        </div>

        <div class="nav-bottom-icon nav-bottom-cart shape-loading" v-if="loadingCart && $cookies.isKey('token')"></div>
        <div class="nav-bottom-icon nav-bottom-cart" v-else-if="(!loadingCart && $cookies.isKey('token')) || (!$cookies.isKey('token'))">
          
          <div v-if="dataCarts.length > 0 && $cookies.isKey('token')" class="circle-red circle-notif">{{dataCarts.length}}</div>
          <div class="dropdown">
            <button class="btn dropdown-toggle dropleft" @click="setBackgroundDropdown" type="button" id="navCartButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class='bx bx-cart'></i>
            </button>
            <div v-if="listCarts.length > 0" class="dropdown-menu" aria-labelledby="navCartButton" @click="hideBackgroundDropdown">
              <div class="d-flex justify-content-between align-items-center">
                <p class="container cart-dropdown-title m-0"><b>Keranjang Saya (<span>{{dataCarts.length}}</span>)</b></p>
                <button class="btn "  @click="hideBackgroundDropdown"><i class='bx bx-x'></i></button>
              </div>
              <div class="dropdown-divider"></div>
              <router-link :to="{ path: '/produk-detail', query: { id_produk: data.product_id }}" class="dropdown-item product-cart-item" v-for="(data, index) in listCarts" :key="index">
                
                <!-- Image Cart -->
                <div v-if="supportWebp && data.product.image.webp != null" :style="'background-image:url('+baseUrl+`/`+data.product.image.webp+')'" class="img-product-cart">
                </div>
                <div v-else-if="supportWebp && data.product.image.webp == null || !supportWebp" :style="'background-image:url('+baseUrl+`/`+data.product.image.image+')'" class="img-product-cart">
                </div>
                <div v-else class="img-product-cart d-flex align-items-center justify-content-center">
                    <i class='bx bxs-image' style="color:#212121" ></i>
                </div> 

                <!-- Detail Product -->
                <div class="product-cart-detail">
                  <p class="product-cart-name">{{data.product.name}}</p>
                  <p class="product-cart-total">{{data.qty}} x</p>
                </div>
                <!-- Price Product -->
                <div v-if="data.product.promo.length > 0">
                  <p class="price-now m-0">Rp. {{ new Intl.NumberFormat('id-ID').format(parseInt(data.product.promo[0].price_promo).toFixed()) }}</p>
                  <p class="price-before m-0">Rp. {{ new Intl.NumberFormat('id-ID').format(parseInt(data.product.price).toFixed())}}  </p>
                </div>
                <div v-else>
                  <p class="price-now m-0">Rp. {{ new Intl.NumberFormat('id-ID').format(parseInt(data.product.price).toFixed())}} </p>
                </div>
              </router-link>
              <div class="dropdown-divider"></div>

              <div class="btn-cart-container">
                <router-link to="/keranjang-belanja" class="btn button-cart"> 
                  <i class='bx bx-cart'></i>
                  Lihat Keranjang
                </router-link>
              </div>
            </div>
            <div v-else class="dropdown-menu" aria-labelledby="navCartButton" @click="hideBackgroundDropdown">
              <div class="d-flex justify-content-end">
                <button class="btn"  @click="hideBackgroundDropdown"><i class='bx bx-x'></i></button>
              </div>
                <div class="text-center py-2">
                  <img :src="require(`../assets/img/empty-illustration.svg`)" class="cart-illustration" alt="">
                  <p><b>Keranjang belanja kosong ..</b></p>
                </div>
            </div>
          </div>
          
        </div>
        <div v-if="!auth" class="nav-bottom-button">
          <router-link to="/login" class="btn btn-red btn-login">Masuk</router-link>
          <router-link to="/register" class="btn btn-registrasi">Registrasi</router-link>
        </div>
        <div v-else-if="auth" class="nav-bottom-user">
          <div class="">
            <router-link to="/profil/beranda" class="btn d-flex align-items-center" id="navUserButton" style="gap:.5rem">
              <div class="circle-red text-uppercase" v-if="user?.image == null">{{user?.username?.charAt(0)}}</div>
              <div class="circle-red text-uppercase" v-else>
                <img :src="baseUrl+'/'+user?.image" class="rounded-circle" alt="">
              </div>
              <div class="ml-2 hide-mobile">{{user?.username}}</div>
            </router-link>
            <!-- <div class="dropdown-menu" aria-labelledby="navUserButton"> -->
              <!-- <router-link to="/profil/beranda" class="dropdown-item hide-mobile" href="#">Beranda</router-link> -->
              <!-- <a class="dropdown-item" role="button" data-bs-toggle="modal" data-bs-target="#modalLogout">Keluar</a> -->
            <!-- </div> -->
          </div>
        </div>
        <div @click="openSidebar" class="nav-bottom-icon show-mobile">
          <i class='bx bx-menu'></i>
        </div>
      </div>
    </div>

    <div class="backgroundDropdown" @click="hideBackgroundDropdown"></div>

    <!-- <Modal
      :show="modal ? true : false "
      :header="false"
      :headerBorder="false"
      :title="false"
      :titleText="''"
      :footer="false"
      :footerBtnPosition="'center'"
      :footerBorder="false"
      :btnCancel="false"
      :btnSubmit="false"
    >
      <div class="d-flex align-items-center justify-content-center text-center">
        <div>
          <i class='bx bxs-error-circle' style="font-size:72px; color: #E40C0C;"></i>
          <p>Anda yakin ingin keluar?</p>
        </div>
      </div>
      <div class="text-center">
        <button class="btn btn-light py-2 mt-2"> <i class='bx bxs-minus-circle'></i> Batal</button>
        <button class="btn btn-red mt-2 px-4"><i class='bx bxs-check-square'></i> Keluar</button>
      </div>
    </Modal> -->

  </div>
    <!-- <div class="modal fade" id="modalLogout" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="d-flex align-items-center justify-content-center text-center">
              <div>
                <i class='bx bxs-error-circle' style="font-size:72px; color: #E40C0C;"></i>
                <p>Anda yakin ingin keluar?</p>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button type="button" class="btn btn-light py-2 mt-2" data-bs-dismiss="modal"><i class='bx bxs-minus-circle'></i> Batal</button>
            <button type="button" @click="logout()" class="btn btn-red"><i class='bx bxs-check-square'></i> Keluar</button>
          </div>
        </div>
      </div>
    </div> -->
</template>

<script>
// import axios from 'axios'
import axios from "axios";
import Logo from "@/components/Logo.vue"
// import Modal from '../components/Modal.vue'

export default {
  name: 'navbar-component',
  props: ['user','dataCarts','sticky','supportWebp'],
  data(){
      return{ 
        auth: false,
        cartsloaded: false,
        search: '',
        emptyCartIllustration: false,
        modal : false,
        listCarts: [],
        loading: false,
        setting: [],
        carts:[],
        baseUrl: process.env.VUE_APP_NAME_AXIOS,
        loadingCart:true
      }
  },
  components: {
    Logo
  },
  mounted(){
      this.loading = true
      this.$cookies.isKey('token') ? this.auth = true : this.auth = false
      
      axios.get(`setting`)
      .then((result) => {
          // console.log('setting : ', result.data.data)
          this.setting = result.data.data
          this.loading = false
      }).catch(() => {
          // console.log(err.response);
          this.loading = false
      })
  },
  methods: {
    // Modal Filter
    // setModal(condition){
    //   this.modal = condition
    // },
    // closeModal(){
    //   this.setModal(false)
    // },
    // submitModal(){
    //   this.setModal(false)
    //   this.logout()
    // },
    openSidebar(){
      document.querySelector('.sidebar').classList.add('active')
      if(!document.body.classList.contains('no-scroll')){
        document.body.classList.add('no-scroll')
      }
    },
    gotoProfil(){
      this.$router.push('/profil/beranda')
    },
    // logout(){
    //   // this.setModal('close')
    //   this.$cookies.remove('token')
    //   this.$cookies.remove('id_checkout')
    //   this.$cookies.remove('id_transaction')
    //   this.$router.push('/')
    //   this.auth = false
    //   window.location.reload()
    // },
    searchProduct(){
     this.search == '' ?  this.$router.push('/produk?page=1') : this.$router.push('/produk?search='+this.search)
     this.search = ''
    },
    setBackgroundDropdown(){
      let background = document.querySelector('.backgroundDropdown')
      if(!background.classList.contains('active')){
        background.classList.add('active')
      }
      if(!document.body.classList.contains('no-scroll')){
        document.body.classList.add('no-scroll')
      }
    },
    hideBackgroundDropdown(){
      let background = document.querySelector('.backgroundDropdown')
      background.classList.remove('active')
      if(document.body.classList.contains('no-scroll')){
          document.body.classList.remove('no-scroll')
      }
    },
  },
  // components: {
  //   Modal
  // },
  created(){ 
    if(this.$cookies.isKey('token')){
      this.auth = true
    }else{
      this.auth = false
    } 
  },
  watch: {
    dataCarts:{
      deep: true,
      handler: function(){
        this.loadingCart = false
        if(this.dataCarts.length > 0){
          
          // ambil 3 data terakhir
          let listCarts = this.dataCarts.slice(-3)
          // ganti urutan data dari yang terakhir
          listCarts.sort(function (a, b) {
            return b.id - a.id;
          });

          this.listCarts = listCarts
        }else{
          this.listCarts = []
        }
      }
    },
    user(value){
      if(!(value instanceof Promise) && value !== null && this.cartsloaded == false){
          // this.$store.dispatch('user')
          let me = this
          this.$store.dispatch('carts').then((res)=>{
            me.carts = res
            // console.log(me.$store.state.carts)
            me.cartsloaded = true
          })
          // console.log("cek kondisi")
          
        }
    },
    auth: {
      deep: true,
      handler: function(){
        this.$cookies.isKey('token') ? this.auth = true : this.auth = false
        // this.$state.dispatch('cart')
      }
    }
  },

}
</script>

<style scoped>
.nav-top {
  height: 2rem !important;
}
.nav-top-item {
  column-gap: 0.5rem !important;
}
.nav-container {
  position: fixed !important;
}
.backgroundDropdown{
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: black;
  opacity:.5;
  top:0;
  left:0;
  display: none;
}

.backgroundDropdown.active{
  display: block;
}


</style>


