import {createStore} from 'vuex'
import VueCookies from 'vue-cookies';
import axios from 'axios';
import moment from 'moment'

const state = {
    user: Promise.resolve(),
    menuchat:0,
    datapesan:null,
    address:[],
    gettoken:null,
    semuapesan:null,
    getpesan:null,
    dataupdate:null,
    id:null,
    products:null,
    categories:null,
    subcategories:null,
    carts:[],
    banners:null,
    settings: null
}

const store = createStore({
    state,
    getters: {
        authenticated(){
            if(VueCookies.isKey('token')){
                return true;
            }else{
                return false;
            }
        },
        user: (state) =>{
            return state.user
        },
        address: (state) =>{
            return state.address
        },
        datapesan:(state)=>{
            return state.datapesan
        },
        semuapesan:(state)=>{
            return state.semuapesan
        },
        getpesan:(state)=>{
            return state.semuapesan
        },
        gettoken:(state)=>{
            return state.gettoken
        },
        dataupdate:(state)=>{
            return state.dataupdate
        },
        id: (state) =>{
            return state.id
        },
        products: (state) =>{
            return state.products
        },

        categories: (state) =>{
            return state.categories
        },
        subcategories: (state) =>{
            return state.subcategories
        },
        carts: (state) =>{
            return state.carts
        },
        banners: (state) =>{
            return state.banners
        },
        settings: (state) => {
            return state.settings
        }

    },
    actions: {
        resetchat: ({ commit }, {datachat}) => commit('menuchat', datachat),
        async user(context){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + VueCookies.get('token')
            
            if(VueCookies.get('token')){
                await axios.get('user')
                .then(response => {
                    context.commit('user', response.data)
                })
                .catch(() => {
                    // if(error.response.data.message == "Unauthenticated."){
                    //     this.$cookies.remove('token')
                    //     this.$router.push('/login')
                    // }

                })
            }
        },
        async datapesan(context){
            await axios.get('usermanagement/chat/detail')
            .then(response =>{
                let pesandata = response.data.data
                let k = 0
                let cekdata = localStorage.getItem('datac')
                if(pesandata.length != cekdata){
                    pesandata.slice(cekdata, pesandata.length).forEach(element => {
                        if(element.is_user){
                            k = k + 1
                            context.commit('menuchat', k)
                            localStorage.setItem('datac', response.data.data.length)
                        }else{
                            k = 0
                            context.commit('menuchat', k)
                        }
                    });
                }
                
            }).catch(() => {
            })
        },
        async semuapesan(context){
            await axios.get('usermanagement/chat/detail')
            .then(response =>{
                context.commit('semuapesan',response.data.data) 
            }).catch(() => {
            })
        },
        async getpesan(context){
            let jmlh_data = state.semuapesan.length
            let datapesan = state.semuapesan[(jmlh_data-1)]
            if(state.dataupdate==null){
                let datatgl = moment(datapesan.created_at).format("YYYY-MM-DD HH:mm:ss ")
                let data = new FormData()
                data.append('id', state.user.id)
                data.append('date', datatgl)
                await axios.post('usermanagement/chat/ajax-request',data)
                .then((responce)=>{
                    let datapesan = state.semuapesan
                    if(responce.data.data.data.length > 0){
                        let k = 0
                        for(let i = 0;i<responce.data.data.data.length;i++){
                            datapesan.push(responce.data.data.data[k])
                            k++
                        }
                        context.commit('dataupdate',responce.data.data.dateUpdate)
                        context.commit('semuapesan',datapesan)
                    }
                }).catch(()=>{
                })
            }else{
                let datatgl = state.dataupdate
                let data = new FormData()
                data.append('id', state.user.id)
                data.append('date', datatgl)
                await axios.post('usermanagement/chat/ajax-request',data)
                .then((responce)=>{
                    context.commit('dataupdate',responce.data.data.dateUpdate)
                    let datapesan = state.semuapesan
                    if(responce.data.data.data.length > 0){
                        let k = 0
                        for(let i = 0;i<responce.data.data.data.length;i++){
                            datapesan.push(responce.data.data.data[k])
                            k++
                        }
                        context.commit('semuapesan',datapesan)
                    }
                }).catch(()=>{
                })
            }
            
        },
        async id(context){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + VueCookies.get('token')
            
            await axios.get('user')
            .then(response => {
                context.commit('id', response.data.id)
            })
            .catch(() => {
            })
        },
        async gettoken(context){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + VueCookies.get('token')
            
                context.commit('gettoken', VueCookies.get('token'))
        },


        products(context){
            axios.get('product')
            .then(response => {
                context.commit('products',response.data.data)
            })
            .catch(() =>{
            })
        },


        categories(context){
            axios.get('category')
            .then(response => {
                context.commit('categories',response.data.data)
            })
            .catch(() => {
            })
        },

        subcategories(context){
            axios.get('subcategory')
            .then(response => {
                context.commit('subcategories',response.data.data)
            })
            .catch(() => {
            })
        },

        carts(context){
                    axios.get('transaction/cart/list-cart/'+state.user.id)
                    .then(response => {
                        context.commit('carts',response.data.data)
                    })
                    .catch(() => {
                        context.commit('carts',[])

                    })
        },
        async address(context){
                    await axios.get('/customer/address/list/'+state.user.id)
                    .then(response => {
                        context.commit('address',response.data.data)
                    })
                    .catch(() => {
                        context.commit('address',[])
                    })
        },

        banners(context){
            axios.get('banner')
            .then(res =>{
                context.commit('banners', res.data.data)
            }).catch(() => {
            })
        },

        settings(context){
            axios.get('setting')
            .then(res =>{
                context.commit('setting', res.data.data)
            }).catch(() => {
            })
        }
    },
    mutations: {
        user(state, user){
            state.user = user
        },
        address(state, address){
            state.address = address
        },
        gettoken(state, gettoken){
            state.gettoken = gettoken
        },
        resetchat(state,menuchat){
            state.menuchat =menuchat
        },
        menuchat(state, menuchat){
            state.menuchat = menuchat
        },
        semuapesan(state, semuapesan){
            state.semuapesan = semuapesan
        },
        dataupdate(state, dataupdate){
            state.dataupdate = dataupdate
        },
        getpesan(state, semuapesan,dataupdate){
            state.semuapesan = semuapesan
            state.dataupdate = dataupdate
        },
        id(state, id){
            state.id = id
        },
        datapesan(state, datapesan){
            state.datapesan = datapesan
        },
        products(state, products){
            state.products = products
        },
        categories(state, categories){
            state.categories = categories
        },
        subcategories(state, subcategories){
            state.subcategories = subcategories
        },
        carts(state, carts){
            state.carts = carts
        },
        banners(state, banners){
            state.banners = banners
        },
        settings(state, settings){
            state.settings = settings
        }
    },
})

export default store