<template>
    <div class="border-data">
        <div class="background-white" >
            <div style="overflow-x:scroll;">
                <table>
                    <thead class="head-tabel">
                        <tr>
                            <th>Nama Produk</th>
                            <th style="min-width:100px;">Jumlah</th>
                            <th style="min-width:110px;">Harga</th>
                            <th style="min-width:110px;" v-if="price_nego != null">Nego Harga</th>
                            <th style="min-width:140px;">Sub Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="td-nama-produk">
                                <div class="nama-produk">
                                    <figure v-bind:style="{ backgroundImage: 'url(' + image_produk + ')' }" class="img-profile">
                                        <!-- <img :src="image_produk" alt=""> -->
                                    </figure>
                                    <div class="px-2 nama_produk">{{nama_produk}}</div>
                                </div>
                            </td>
                            <td>
                                <div>{{unit}} Unit</div>
                            </td>
                            <td style="max-width:80px;" class="text-right">
                                <div class="w-100">
                                    <div class="w-100">
                                        <p  class=" discount-harga">
                                            <span v-if="promo>0" class="p-1">{{promo}}%</span>
                                        </p>
                                    </div>
                                    <div v-if="promo > 0">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(harga_diskon).toFixed()) }}</div>
                                    <s v-if="promo > 0">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(harga).toFixed()) }}</s>
                                    <div v-if="promo== 0">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(harga).toFixed()) }}</div>
                                </div>
                            </td>
                            <td v-if="price_nego != null">
                                Rp. {{ new Intl.NumberFormat('id-ID').format(Number(price_nego).toFixed()) }}
                            </td>
                            <td>
                                <div>Rp. {{ subtotal_nego != null ? new Intl.NumberFormat('id-ID').format(Number(subtotal_nego).toFixed()) : new Intl.NumberFormat('id-ID').format(Number(subtotal).toFixed()) }}</div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4" v-if="price_nego == null"><b>Catatan :</b> <br>{{ catatandata }}</td>
                            <td colspan="5" v-else><b>Catatan</b> <br>{{ catatandata }}</td>
                        </tr>
                        <tr v-if="is_partial == true">
                            <td colspan="4" v-if="price_nego == null"><b>Pengiriman Partial</b> <br>
                                <div v-for="(shippingDetail, index) in shipping" :key="index">
                                    - Partial {{ index+1 }} : {{ shippingDetail?.qty }} Unit
                                </div>
                            </td>
                            <td colspan="5" v-else><b>Pengiriman Partial</b> <br>
                                <div v-for="(shippingDetail, index) in shipping" :key="index">
                                    - Partial {{ index+1 }} : {{ shippingDetail?.qty }} Unit
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="mt-3">
                <h5>Catatan</h5>
                <p></p>
            </div> -->
            <div class="w-100 mt-3" style="display: flow-root;">
                <!-- <a @click="openmodalharga()" class="btn btn-danger float-right" v-if="nego.length > 0">Ajukan Negosiasi Harga</a> -->
            </div>
        </div>

        <!-- <div v-if="modalharga" class="modal fade show" aria-modal="true" style="display: block; background-color: rgba(0,0,0,0.3);" tabindex="-1" role="dialog" id="modalchangepassword" >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body-top-keranjang">
                        <button type="button" @click="closemodalharga()" class="close btn" data-bs-dismiss="modal" aria-bs-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="container-fluid">
                        <div class="header-form px-1">
                            <h4>Pengajuan Harga</h4>
                        </div>
                        <form @submit.prevent="updateharga">
                            <div class="form-group mt-3">
                                <label for="satuan">Harga yang diajukan</label>
                                <input v-model="negoharga.hargadata" type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="form-control mt-1" id="nego_harga" aria-describedby="satuan" placeholder="Masukkan Harga Nego">
                            </div>
                            

                            <button type="submit" class="btn btn-danger mt-3 mb-3 float-right">Simpan</button>
                        </form>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>
<script>
export default {
    props:['id','dataindex','image_produk','nama_produk','unit','promo','harga','harga_diskon','subtotal','subtotal_nego','catatandata','nego', 'price_nego', 'is_partial', 'shipping'],
    name: 'DaftarProduct',
    data(){
        return {
            modalharga:false,
            negoharga:{
                dataindex:this.dataindex,
                id:this.id,
                old_harga:'',
                hargadata:''
            }
        }
    },
    mounted(){
    },

    methods: {
        openmodalharga(){
            this.modalharga =true
        },
        closemodalharga(){
            this.modalharga =false
        },
        updateharga(){
            if(this.promo > 0) {
                this.negoharga.old_harga = this.harga_diskon
                this.$emit('updatenegoharga', this.negoharga)
                this.modalharga =false
                this.negoharga.hargadata = ''
            }else{
                this.negoharga.old_harga = this.harga
                this.$emit('updatenegoharga', this.negoharga)
                this.modalharga =false
                this.negoharga.hargadata = ''
            }
        }
    }
}
</script>
<style scoped>
.form-group label{
    font-weight: 600;
    font-size: 1.3rem;
}
.header-form{
    border-bottom: solid 1px grey;
}
/* .border-data{
    box-shadow: 10px 10px 19px #f5f5f5, -10px -10px 19px #efefef;
    border-radius: 15px;
} */
/* .data-tabel, tr, td, th{
    border:0px !important;
} */
/* .head-tabel{
    border:0px !important;
    border-bottom:1px solid grey !important;
} */
.img-profile{
    width: 150px;
    height: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.img-profile img{
    width: 100%;
}
.td-nama-produk{
    max-width: 200px !important;
}
.nama-produk{
    display: flex;
    margin: 0px;
    margin-right: 0px !important;
}
.nama-produk h6{
    vertical-align: inherit;
    margin: auto 0;
}
.discount-harga{
    font-size: 12px;
    text-align: right;
    margin-bottom: 5px;
    /* max-width: 40px; */
}
.discount-harga span{
    background-color: #dc3545;
    color:#ffffff;
    border-radius: 5px;
    /* max-width: 40px; */
}
.ringkasan{
    display: flex;
    justify-content: space-between;
}
.total-ringkasan{
    border-top: solid 1px grey;
    display: flex;
    justify-content: space-between;
}
.nama_produk{
    font-size: 0.8rem;
}

@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
    .img-profile{
        width: 50px;
        height: 50px;
    }
    .nama_produk{
        font-size: 1rem;
    }
}

@media (min-width: 1023px) {
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>