<template>
    <div class="produk section">
        <div class="product-container container">
            <slot></slot>
        </div>
    </div>
</template>

<script>

export default {
    name: 'product-component',
    data(){
        return {
        }
    },

    methods: {
    }
}
</script>

