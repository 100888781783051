<template>
    <div class="background-white p-3">
        <div class="form-profile-header">
        </div>
        <div class="form-profile-body p-3">
            <div class="data-user">
                <p class="header-content">ID Pesanan</p>
                <p>{{datacomplain.transaction_detail?.transaction?.invoice}}</p>   
                <p class="mt-3 header-content">Produk</p>
                <div class="container-fluid row" style="padding: 0px !important;">
                    <div class="col-md-6">
                         <table class="data-tabel ">
                            <tr>
                                <td class="td-nama-produk">
                                        <div class="nama-produk">
                                            <figure v-bind:style="{ backgroundImage: 'url(' +$imgProductUrl+ datacomplain.transaction_detail?.image+ ')' }" class="img-profile">
                                                <!-- <img :src="image_produk" alt=""> -->
                                            </figure>
                                            <p class="px-2">{{datacomplain.transaction_detail?.name_product}}</p>
                                        </div>
                                    </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <p class="mt-3 header-content">Keterangan Komplain</p>
                <p>{{datacomplain.description_complaint}}</p>  
                <p class="mt-3 header-content">Tanggapan Admin</p>
                <p v-if="datacomplain.respon_admin">{{datacomplain.respon_admin}}</p>  
                <p v-else-if="!datacomplain.respon_admin">-</p>  
               <p class="mt-4 header-content">Bukti Komplain</p>
            </div>
            <div class="w-100 list-data-bukti mt-2" >
                <div class="card p-3 ml-2" v-for="(dataimg,index) in datacomplain.image" :key="index">
                    <div v-if="cekextensi(dataimg.image) == 'jpg' || cekextensi(dataimg.image) == 'png' || cekextensi(dataimg.image) == 'jpeg' || cekextensi(dataimg.image) == 'webp'">
                        <img :src="$imgProductUrl+dataimg.image" alt="" style="width:150px;">
                    </div>
                    <div v-else-if="cekextensi(dataimg.image) == 'mp4' || cekextensi(dataimg.image) == 'mpeg' || cekextensi(dataimg.image) == 'mov' || cekextensi(dataimg.image) == '3gp' || cekextensi(dataimg.image) == 'mkv' || cekextensi(dataimg.image) == 'avi'">
                        <video width="320" height="240" controls>
                            <source :src="$imgProductUrl+dataimg.image" :type="'video/'+cekextensi(dataimg.image)">
                        </video>
                    </div>
                </div>
            </div>
            <div class="w-100 mt-4 pt-4 pb-4">
                <router-link to="/profil/komplain" class="btn btn-secondary float-right mb-5">Kembali</router-link>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios'

export default {
    name: 'form-profil',
    data() {
        return {
        inputs: [],
        complain_id:'',
        datacomplain:[]
        };
    },

   components:{
    },
    created(){
        this.complain_id = this.$router.currentRoute._value.query.id_complain
            axios.get('/transaction/complaint/'+this.complain_id).then(res => {
                this.datacomplain = res.data.data
                //  this.loading=false
            })
            .catch(() =>{
                // this.loading=false
            })
    },
    methods: {
        cekextensi(value){
            return value.split('.').pop()
        },
        addInput(){
            this.inputs.push(this.inputs.length + 1)
        },
        
    }
}
</script>

<style scoped>
.header-content{
    font-weight: bold;
}
.list-data-bukti{
    display: flex;
}
.list-data-bukti i{
    text-align: center;
    font-size: 5rem;
}
.list-data-bukti p{
    margin-bottom: 0px !important;
}
.list-data-bukti .card{
    margin-left: 0.5rem !important;
}
.data-user h5{
    margin-bottom: 5px !important;
    color: #000;
    font-weight: 600;
}
.img-profile{
    width: 100px;
    height: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.img-profile img{
    width: 100%;
}
.td-nama-produk{
    max-width: 200px !important;
}
.nama-produk{
    display: flex;
    margin: 0px;
    margin-right: 0px !important;
}
.nama-produk h6{
    vertical-align: inherit;
    margin: auto 0;
}

</style>
