<template>
    <div>
        <skeleton-pembayaran v-if="loading"></skeleton-pembayaran>
        <div v-if="!loading">
            <div v-if="toLower(datatransaksi.status) !='shipped' && toLower(datatransaksi.status) !='pending' && toLower(datatransaksi.status) !='process' && toLower(datatransaksi.status) !='processed' && toLower(datatransaksi.status) !='cancel' && toLower(datatransaksi.status) !='confirm' || toLower(datatransaksi.status) !='partial delivery'">
                <div class="container-fluid pt-4">
                    <!-- <div class="form-group w-100 mt-4">
                        <label for="pembayaran">Pilih Metode Pembayaran</label>
                        <input id="pembayaran" type="text" name="metode-bayar" v-model="metode_pembarayan" placeholder="Pilih Metode Pembayaran" class="form-control" @click="openmodalbayar">
                        <div class="dropdown">
                            <button class="btn dropdown-toggle dropleft w-100 metode-pembayaran" type="button" id="navCartButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class='bx bx-chevron-down w-100' style="text-align:right;"></i>
                            </button>
                            <div class="dropdown-menu w-100" aria-labelledby="navCartButton">
                            <h6 class="container cart-dropdown-title py-2">Pilih Metode Pembayaran</h6>
                            <div @onclick="datapayment()" class="dropdown-divider"></div>
                            <div class="p-3">
                                <h6>Kartu Kredit</h6>
                                    <div  class="dropdown-item" style="vertical-align: middle;" @click.prevent="showModal('Kartu Kredit')" data-bs-toggle="modal" data-bs-target="#modalform">
                                        <div class="dropdown-content-left">
                                            <img :src="require(`../../assets/img/Produk1.png`)" alt="" class="img-product-cart">
                                            <p>XXXXXXXX</p>
                                        </div>
                                        <div class="dropdown-content-right">
                                            <i class='bx bx-chevron-right'></i>
                                        </div>
                                    </div>
                                <h6 class="mt-3">Transfer Virtual Account</h6>
                                <div class="dropdown-item" style="vertical-align: middle;" @click.prevent="showModal('Transfer Virtual Account')" data-bs-toggle="modal" data-bs-target="#modalform">
                                    <div class="dropdown-content-left">
                                        <img :src="require(`../../assets/img/Produk1.png`)" alt="" class="img-product-cart">
                                        <p>XXXXXXXX</p>
                                    </div>
                                    <div class="dropdown-content-right">
                                        <i class='bx bx-chevron-right'></i>
                                    </div>
                                </div>
                                <div class="dropdown-item" style="vertical-align: middle;" @click.prevent="showModal('Transfer Virtual Account')" data-bs-toggle="modal" data-bs-target="#modalform">
                                    <div class="dropdown-content-left">
                                        <img :src="require(`../../assets/img/Produk1.png`)" alt="" class="img-product-cart">
                                        <p>XXXXXXXX</p>
                                    </div>
                                    <div class="dropdown-content-right">
                                        <i class='bx bx-chevron-right'></i>
                                    </div>
                                </div>
                                <div class="dropdown-item" style="vertical-align: middle;" @click.prevent="showModal('Transfer Virtual Account')" data-bs-toggle="modal" data-bs-target="#modalform">
                                    <div class="dropdown-content-left">
                                        <img :src="require(`../../assets/img/Produk1.png`)" alt="" class="img-product-cart">
                                        <p>XXXXXXXX</p>
                                    </div>
                                    <div class="dropdown-content-right">
                                        <i class='bx bx-chevron-right'></i>
                                    </div>
                                </div>
                                <div class="dropdown-item" style="vertical-align: middle;" @click.prevent="showModal('Transfer Virtual Account')" data-bs-toggle="modal" data-bs-target="#modalform">
                                    <div class="dropdown-content-left">
                                        <img :src="require(`../../assets/img/Produk1.png`)" alt="" class="img-product-cart">
                                        <p>XXXXXXXX</p>
                                    </div>
                                    <div class="dropdown-content-right">
                                        <i class='bx bx-chevron-right'></i>
                                    </div>
                                </div>
                                <div class="dropdown-item" style="vertical-align: middle;" @click.prevent="showModal('Transfer Virtual Account')" data-bs-toggle="modal" data-bs-target="#modalform">
                                    <div class="dropdown-content-left">
                                        <img :src="require(`../../assets/img/Produk1.png`)" alt="" class="img-product-cart">
                                        <p>XXXXXXXX</p>
                                    </div>
                                    <div class="dropdown-content-right">
                                        <i class='bx bx-chevron-right'></i>
                                    </div>
                                </div>
                                <div class="dropdown-item" style="vertical-align: middle;" @click.prevent="showModal('Transfer Virtual Account')" data-bs-toggle="modal" data-bs-target="#modalform">
                                    <div class="dropdown-content-left">
                                        <img :src="require(`../../assets/img/Produk1.png`)" alt="" class="img-product-cart">
                                        <p>XXXXXXXX</p>
                                    </div>
                                    <div class="dropdown-content-right">
                                        <i class='bx bx-chevron-right'></i>
                                    </div>
                                </div>
                            </div>
                            <div class="dropdown-divider"></div>
                            <div class="btn-cart-container">
                                <a href="" class="btn btn-danger w-100"> 
                                Bayar Sekarang
                                </a>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-100 mt-3">
                        <button class="btn btn-danger float-right">Pilih</button>
                    </div> -->
                    <div class="mb-3" v-if="toLower(datatransaksi.status) =='document' || toLower(datatransaksi.status) =='payment_expired' && !datapayment">
                        <label for="exampleFormControlSelect1">Pilih Metode Pembayaran</label>
                        <select class="form-select" id="exampleFormControlSelect1" @change="onChange($event)" v-model="key">
                            <option selected>- Silahkan Pilih -</option>
                            <option value="BPD">QRIS / Virtual Account BPD Bali</option>
                            <option value="Doku">Lainnya</option>
                        </select>
                    </div>
                </div>
                <div v-if="datapayment" class="container-fluid pb-5">
                    <div class="row">
                        <div class="col-md-12">
                            <h5>Informasi Pembayaran</h5>
                            <div class="view-mobile">
                                <div class="w-100">
                                    <p><b>Layanan Pembayaran :</b></p>
                                    <p v-if="pembayaran.payment_service"> {{pembayaran.payment_service}}</p>
                                    <p v-else-if="!pembayaran.payment_service"> -</p>
                                </div>
                                <div class="w-100">
                                    <p><b>Tipe Pembayaran :</b></p>
                                    <p v-if="pembayaran.payment_type"> {{pembayaran.payment_type}}</p>
                                    <p v-else-if="!pembayaran.payment_type"> -</p>
                                </div>
                                <div class="w-100">
                                    <p><b>Nama Bank :</b></p>
                                    <p v-if="pembayaran.bank_name"> {{pembayaran.bank_name}}</p>
                                    <p v-else-if="!pembayaran.bank_name"> -</p>
                                </div>
                                <div class="w-100" v-if="pembayaran.payment_service == 'BPD'">
                                    <p><b>Atas Nama :</b></p>
                                    <p v-if="pembayaran.account_name"> {{pembayaran.account_name}}</p>
                                    <p v-else-if="!pembayaran.account_name"> -</p>
                                </div>
                                <div class="w-100">
                                    <p><b>No Rekening :</b></p>
                                    <p v-if="pembayaran.account_number"> {{pembayaran.account_number}}</p>
                                    <p v-else-if="!pembayaran.account_number"> -</p>
                                </div>
                                <div class="w-100">
                                    <p><b>Tanggal :</b></p>
                                    <p v-if="pembayaran.payment_date"> {{pembayaran.payment_date}}</p>
                                    <p v-else-if="!pembayaran.payment_date"> -</p>
                                </div>
                                <div class="w-100">
                                    <p><b>Jumlah :</b></p>
                                    <p v-if="pembayaran.nominal === '-'">: Rp.  {{pembayaran.nominal}}</p>
                                    <p v-else>: Rp.  {{ new Intl.NumberFormat('id-ID').format(Number(pembayaran?.nominal).toFixed()) }}</p>
                                </div>
                                <div class="w-100">
                                    <p><b>Status :</b></p>
                                    <p>{{pembayaran.status}}</p>
                                </div>
                                <div class="w-100" v-if="pembayaran.payment_service == 'BPD' && pembayaran.image">
                                    <p><b>Bukti Transfer :</b></p>
                                    <a :href="this.$imgProductUrl+pembayaran.image">Klik Disini</a>
                                </div>
                                <div class="w-100" v-if="pembayaran.notes">
                                    <p><b>Notes :</b></p>
                                    <p> {{pembayaran.notes}}</p>
                                </div>
                            </div>
                            <table class="table w-100 table-striped table-hover table-infoPayment view-web">
                                <tbody>
                                    <tr>
                                        <td>Layanan Pembayaran</td>
                                        <td v-if="pembayaran.payment_service">: {{pembayaran.payment_service}}</td>
                                        <td v-else-if="!pembayaran.payment_service">: -</td>
                                    </tr>
                                    <tr>
                                        <td>Tipe Pembayaran</td>
                                        <td v-if="pembayaran.payment_type">: {{pembayaran.payment_type}}</td>
                                        <td v-else-if="!pembayaran.payment_type">: -</td>
                                    </tr>
                                    <tr>
                                        <td>Nama Bank</td>
                                        <td v-if="pembayaran.bank_name">: {{pembayaran.bank_name}}</td>
                                        <td v-else-if="!pembayaran.bank_name">: -</td>
                                    </tr>
                                    <!-- <tr>
                                        <td>Cabang</td>
                                        <td>: Renon</td>
                                    </tr> -->
                                    <tr v-if="pembayaran.payment_service == 'BPD'">
                                        <td>Atas Nama</td>
                                        <td v-if="pembayaran.account_name">: {{pembayaran.account_name}}</td>
                                        <td v-else-if="!pembayaran.account_name">: -</td>
                                    </tr>
                                    <tr>
                                        <td>No Rekening</td>
                                        <td v-if="pembayaran.account_number">: {{pembayaran.account_number}}</td>
                                        <td v-else-if="!pembayaran.account_number">: -</td>
                                    </tr>
                                    <tr>
                                        <td>Tanggal</td>
                                        <td v-if="pembayaran.payment_date">: {{pembayaran.payment_date}}</td>
                                        <td v-else-if="!pembayaran.payment_date">: -</td>
                                    </tr>
                                    <tr>
                                        <td>Jumlah</td>
                                        <td v-if="pembayaran.nominal === '-'">: Rp.  {{pembayaran.nominal}}</td>
                                        <td v-else>: Rp.  {{ new Intl.NumberFormat('id-ID').format(Number(pembayaran?.nominal).toFixed()) }}</td>
                                    </tr>
                                    <tr>
                                        <td>Status</td>
                                        <td>: {{pembayaran.status}}</td>
                                    </tr>
                                    <tr v-if="pembayaran.payment_service == 'BPD' && pembayaran.image">
                                        <td>Bukti Transfer</td>
                                        <td>: <a :href="this.$imgProductUrl+pembayaran.image">Klik Disini</a></td>
                                    </tr>
                                    <tr v-if="pembayaran.notes">
                                        <td>Notes</td>
                                        <td>: {{pembayaran.notes}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="w-100 text-right" v-if="toLower(pembayaran.status) == 'pending' || toLower(datatransaksi.status) == 'pending_payment'">
                            <div v-if="toLower(pembayaran.payment_service) != 'bpd'">
                                <a class="btn btn-danger" :href="pembayaran.url_payment">Tata Cara Pembayaran Pembayaran</a>
                            </div>
                        </div>
                        <div class="w-100 text-right" v-else-if="toLower(pembayaran.status) == 'expired' || toLower(datatransaksi.status) == 'payment_expired'">
                            <div  v-if="toLower(pembayaran.payment_service) != 'bpd'">
                                <small class="error text-danger" style="margin-right: 0.75rem;">Anda telah melewati batas pembayaran, silahkan hubungi admin</small>
                                <router-link class="btn btn-danger" :to="{ path: '/profil/chat', query: { invoice: `mohon regenerate lagi metode pembayaran dengan invoice `+datatransaksi.invoice}}">chat admin</router-link>
                            </div>
                        </div>
                        <div class="col-md-12 text-right mt-3" v-if="pembayaran.payment_service == 'BPD'">
                            <div v-if="toLower(pembayaran.status) ==='reviewed' || toLower(pembayaran.status) === 'pending' || toLower(pembayaran.status) === 'failed' || toLower(pembayaran.status) == 'expired' || toLower(datatransaksi.status) == 'payment_expired'">
                                <button type="button" class="btn btn-armed" @click="bukamodal">
                                <i class='bx bx-upload' ></i> Upload Bukti Pembayaran
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3" v-if="pembayaran.payment_service === 'BPD'">
                        <div class="row" v-if="toLower(pembayaran.status) !='success'">
                            <div class="col-md-12">
                                <h5>Tata Cara Pembayaran</h5>
                            </div>
                            <div class="col-sm-12 col-md-6 text-center mb-2">
                                <div class="border border-2 p-4 rounded-15">
                                    <h6>Pembayaran Menggunakan QRIS</h6>
                                    <img class="barcode-bpdpayment" src="../../assets/qrbpd.png">
                                    <div class="text-left">
                                        Tata cara pembayaran :
                                        <ul class="list-method-bpdpayment">
                                            <li>Buka aplikasi mobile-banking yang anda miliki.</li>
                                            <li>Scan QR Code diatas ini.</li>
                                            <li>Input jumlah nominal yang ingin dibayarkan.</li>
                                            <!-- <li>Pilih bayar dan masukkan nama lengkap Anda.</li> -->
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 text-center mb-2">
                                <div class="border border-2 p-4 rounded-15">
                                    <h6>Pembayaran Menggunakan Virtual Account</h6>
                                    <div class="text-left">
                                        Tata cara pembayaran :
                                        <ul class="list-method-bpdpayment">
                                            <li>Buka aplikasi m-banking di smartphone.</li>
                                            <li>Masukkan username dan password / PIN  pada Aplikasi Mobile / Internet Banking</li>
                                            <li>Pilih menu <b>"Pembayaran"</b>.</li>
                                            <li>Pilih <b>"E-Commerce"</b>.</li>
                                            <li>Pilih Input nomor ID lalu Pilih Produk <b>"Armed"</b>.</li>
                                            <li>Masukkan nomor Id/ Tagihan <b>0000000161</b></li>
                                            <li>Masukkan PIN Untuk Melanjutkan Transaksi.</li>
                                            <li>Selesai.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <h3 v-if="datatransaksi.status=='confirmed'">informasi Pembayaran</h3>
                    <table v-if="datatransaksi.status=='confirmed'">
                        <tbody>
                            <tr>
                                <td>Metode Pembayran</td>
                                <td>: Manual(transfer Bank)</td>
                            </tr>
                            <tr>
                                <td>Nama Bank</td>
                                <td>: BCA</td>
                            </tr>
                            <tr>
                                <td>Cabang</td>
                                <td>: Renon</td>
                            </tr>
                            <tr>
                                <td>Atas Nama</td>
                                <td>: Bayu ArtaYoga</td>
                            </tr>
                            <tr>
                                <td>No Rekening</td>
                                <td>: 123456789</td>
                            </tr>
                            <tr>
                                <td>Tanggal</td>
                                <td>: 02 Januari 2022</td>
                            </tr>
                            <tr>
                                <td>Jumlah</td>
                                <td>: Rp.2.000.000</td>
                            </tr>
                            <tr>
                                <td>Bukti Transfer</td>
                                <td>: <a href="google.com">Klik Disini</a></td>
                            </tr>
                        </tbody>
                    </table> -->
                    <!-- <div v-if="modalbayar" class="modal fade show" aria-modal="true" style="display: block; background-color: rgba(0,0,0,0.3);" tabindex="-1" role="dialog" id="modalchangepassword" >
                        <div class="modal" tabindex="-1" role="dialog" id="modalchangepassword" >
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-body-top-keranjang">
                                    <button type="button" @click="closemodalbayar()" class="close btn" data-bs-dismiss="modal" aria-bs-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="container-fluid">
                                    <div class="header-form px-1">
                                        <h4>Metode Pembayaran</h4>
                                    </div>
                                    <div class="p-3">
                                        <h6>Kartu Kredit</h6>
                                            <div  class="dropdown-item" style="vertical-align: middle;" @click.prevent="setpayment('BPD')" data-bs-toggle="modal" data-bs-target="#modalform">
                                                <div class="dropdown-content-left">
                                                    <img :src="require(`../../assets/img/bpdbali.png`)" alt="" class="img-product-cart">
                                                    <p>Bank BPD Bali Virtual Account</p>
                                                </div>
                                                <div class="dropdown-content-right">
                                                    <i class='bx bx-chevron-right'></i>
                                                </div>
                                            </div>
                                        <h6 class="mt-3">Transfer Virtual Account</h6>
                                        <div class="dropdown-item" style="vertical-align: middle;" @click.prevent="setpayment('Doku')" data-bs-toggle="modal" data-bs-target="#modalform">
                                            <div class="dropdown-content-left">
                                                <img :src="require(`../../assets/img/doku.png`)" alt="" class="img-product-cart">
                                                <p>Doku</p>
                                            </div>
                                            <div class="dropdown-content-right">
                                                <i class='bx bx-chevron-right'></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dropdown-divider"></div>
                                    <div class="btn-cart-container">
                                        <a href="" class="btn btn-danger w-100"> 
                                        Bayar Sekarang
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- modal -->
                    <!-- <div class="modal" tabindex="-1" role="dialog" id="modalform" >
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-body-top-keranjang">
                                    <button type="button" class="close btn" data-bs-dismiss="modal" aria-bs-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="container-fluid">
                                    <div class="header-form px-1">
                                        <h3>{{modalTipe}}</h3>
                                    </div>
                                    <form>
                                        <div class="form-group mt-3">
                                            <label for="bank">Nama Bank</label>
                                            <input type="text" class="form-control mt-1" id="bank" aria-describedby="bank" placeholder="Enter Nama Bank">
                                        </div>
                                        <div class="form-group mt-3">
                                            <label for="cabang">Cabang</label>
                                            <input type="text" class="form-control mt-1" id="cabang" aria-describedby="cabang" placeholder="Enter Cabang">
                                        </div>
                                        <div class="form-group mt-3">
                                            <label for="nama">Atas Nama</label>
                                            <input type="text" class="form-control mt-1" id="nama" aria-describedby="nama" placeholder="Enter Atas Nama">
                                        </div>
                                        <div class="form-group mt-3">
                                            <label for="no_rekening">No rekening</label>
                                            <input type="text" class="form-control mt-1" id="no_rekening" aria-describedby="no_rekening" placeholder="Enter No rekening">
                                        </div>
                                        <div class="form-group mt-3">
                                            <label for="tanggal">Tanggal</label>
                                            <input type="date" class="form-control mt-1" id="tanggal" aria-describedby="tanggal" placeholder="Enter Tanggal">
                                        </div>
                                        <div class="form-group mt-3">
                                            <label for="jumlah">Jumlah</label>
                                            <input type="text" class="form-control mt-1" id="jumlah" aria-describedby="jumlah" placeholder="Enter Jumlah">
                                        </div>
                                        <div class="form-group mt-3">
                                            <label for="bukti">Bukti Transfer</label>
                                            <input type="file" class="form-control mt-1" id="bukti" aria-describedby="bukti" placeholder="Enter Bukti">
                                        </div>
                                        <div class="form-group mt-3">
                                            <label for="catatan">Catatan</label>
                                            <textarea class="form-control mt-2" id="catatan" rows="3">
                                            </textarea>
                                        </div>
                                        

                                        <button type="submit" class="btn btn-danger mt-3 mb-3 float-right">Simpan</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- modal -->
                </div>
            </div>
            <div v-else-if="toLower(datatransaksi.status) =='shipped' || toLower(datatransaksi.status) =='confirmed' || toLower(datatransaksi.status) =='pending' || toLower(datatransaksi.status) =='processed' || toLower(datatransaksi.status) =='cancel' || toLower(datatransaksi.status) =='process'">
                <div class="p-md-5">
                    <img src="../../assets/img/undraw_interview_re_e5jn.svg" class="img-none" alt="">
                </div>
                <p class="w-100 mt-3" style="text-align:center;">Anda belum bisa melakukan pembayaran</p>
            </div>
            <div v-else>
                <div class="p-md-5">
                    <img src="../../assets/img/undraw_interview_re_e5jn.svg" class="img-none" alt="">
                </div>
                <p class="w-100 mt-3" style="text-align:center;">Anda belum bisa melakukan pembayaran</p>
            </div>
            <div v-if="toLower(datatransaksi.status) =='partial delivery' || toLower(datatransaksi.status) =='confirmed' || toLower(datatransaksi.status) =='shipped' || toLower(datatransaksi.status) =='cancel' || toLower(datatransaksi.status) =='process'">
                <div class="p-md-5">
                    <img src="../../assets/img/undraw_interview_re_e5jn.svg" class="img-none" alt="">
                </div>
                <p class="w-100 mt-3" style="text-align:center;">Anda belum bisa melakukan pembayaran</p>
            </div>
            <div v-if="toLower(datatransaksi.status) =='pending'">
                <div class="p-md-5">
                    <img src="../../assets/img/undraw_interview_re_e5jn.svg" class="img-none" alt="">
                </div>
                <p class="w-100 mt-3" style="text-align:center;">Anda belum bisa melakukan pembayaran</p>
            </div>
        </div>
    </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Form Upload Bukti Pembayaran</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-group">
                            <label for="bank">Nama Bank</label>
                            <input type="text" class="form-control mt-1" id="bank" aria-describedby="bank" placeholder="Enter Nama Bank">
                        </div>
                        <div class="form-group mt-3">
                            <label for="cabang">Cabang</label>
                            <input type="text" class="form-control mt-1" id="cabang" aria-describedby="cabang" placeholder="Enter Cabang">
                        </div>
                        <div class="form-group mt-3">
                            <label for="nama">Atas Nama</label>
                            <input type="text" class="form-control mt-1" id="nama" aria-describedby="nama" placeholder="Enter Atas Nama">
                        </div>
                        <div class="form-group mt-3">
                            <label for="no_rekening">No rekening</label>
                            <input type="text" class="form-control mt-1" id="no_rekening" aria-describedby="no_rekening" placeholder="Enter No rekening">
                        </div>
                        <div class="form-group mt-3">
                            <label for="tanggal">Tanggal</label>
                            <input type="date" class="form-control mt-1" id="tanggal" aria-describedby="tanggal" placeholder="Enter Tanggal">
                        </div>
                        <div class="form-group mt-3">
                            <label for="jumlah">Jumlah</label>
                            <input type="text" class="form-control mt-1" id="jumlah" aria-describedby="jumlah" placeholder="Enter Jumlah">
                        </div>
                        <div class="form-group mt-3">
                            <label for="bukti">Bukti Transfer</label>
                            <input type="file" class="form-control mt-1" id="bukti" aria-describedby="bukti" placeholder="Enter Bukti">
                        </div>
                        <div class="form-group mt-3">
                            <label for="catatan">Catatan</label>
                            <textarea class="form-control mt-2" id="catatan" rows="3">
                            </textarea>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Batal</button>
                    <button type="button" class="btn btn-armed"><i class='bx bxs-save' ></i> Simpan</button>
                </div>
            </div>
        </div>
    </div>
    <div v-if="modalform" class="modal fade show" aria-modal="true" style="display: block; background-color: rgba(0,0,0,0.3);" tabindex="-1" role="dialog" id="modalchangepassword" >
            <!-- <div class="modal" tabindex="-1" role="dialog" id="modalchangepassword" > -->
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Form Upload Bukti Pembayaran</h5>
                    </div>
                    <div class="modal-body">
                        <div class="form-group mt-md-3">
                            <label for="atasnama">Atas Nama</label>
                            <input type="text" class="form-control mt-2" id="atasnama" :class="{ 'border-danger': v$.atasnama.$error }" v-model="atasnama" aria-describedby="diterima" placeholder="Atas Nama">
                            <div class="text-danger" v-if="v$.atasnama.$error">
                                <small v-if="v$.atasnama.required">Atas Nama Wajib Diisi</small>
                            </div>
                        </div>
                        <div class="form-group mt-md-3">
                            <label for="no_rekening">Nomor Rekening</label>
                            <input type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="form-control mt-2" id="no_rekening" :class="{ 'border-danger': v$.no_rekening.$error }" v-model="no_rekening" aria-describedby="diterima" placeholder="No Rekening">
                            <div class="text-danger" v-if="v$.no_rekening.$error">
                                <small v-if="v$.no_rekening.required">No Rekening Wajib Diisi</small>
                            </div>
                        </div>
                        <div class="form-group mt-md-3">
                            <label for="namabank">Nama Bank</label>
                            <input type="text" class="form-control mt-2" id="diterima" :class="{ 'border-danger': v$.namabank.$error }" v-model="namabank" aria-describedby="namabank" placeholder="Nama Bank">
                            <div class="text-danger" v-if="v$.namabank.$error">
                                <small v-if="v$.namabank.required">Nama Bank Wajib Diisi</small>
                            </div>
                        </div>
                        <div class="form-group mt-md-3">
                            <label for="input_file">Bukti Pembayaran</label><br>
                            <input type="file" accept=".jpg,.webp,image/*" id="input_file" @change="inputfile($event)" >
                        </div>
                        <small class="text-danger" v-if="checkimage">Anda belum Memasukkan Bukti Pembayaran</small>
                        <div class="modal-footer">
                            <div type="button" class="btn btn-secondary" @click="closemodal" data-bs-dismiss="modal">Batal</div>
                            <button @click="bayarbpd()" type="submit" class="btn btn-danger"><i class='bx bxs-save' ></i> Simpan</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- <div class="container-fluid py-3">
        <div class="row">
            <div class="col-md-12">
                <form>
                    <div class="mb-3">
                        <label for="exampleFormControlSelect1">Pilih Metode Pembayaran</label>
                        <select class="form-select" id="exampleFormControlSelect1">
                            <option selected>- Silahkan Pilih -</option>
                            <option>QRIS / Virtual Account BPD Bali</option>
                            <option>Lainnya</option>
                        </select>
                    </div>
                    <div class="text-right">
                        <button type="submit" class="btn btn-armed"><i class='bx bx-selection'></i> Pilih</button>
                    </div>
                </form>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <h5>Informasi Pembayaran</h5>
                <table class="table table-striped table-hover table-infoPayment">
                    <tbody>
                        <tr>
                            <td>Metode Pembayaran</td>
                            <td>: Manual(transfer Bank)</td>
                        </tr>
                        <tr>
                            <td>Nama Bank</td>
                            <td>: BCA</td>
                        </tr>
                        <tr>
                            <td>Cabang</td>
                            <td>: Renon</td>
                        </tr>
                        <tr>
                            <td>Atas Nama</td>
                            <td>: Bayu ArtaYoga</td>
                        </tr>
                        <tr>
                            <td>No Rekening</td>
                            <td>: 123456789</td>
                        </tr>
                        <tr>
                            <td>Tanggal</td>
                            <td>: 02 Januari 2022</td>
                        </tr>
                        <tr>
                            <td>Jumlah</td>
                            <td>: Rp.2.000.000</td>
                        </tr>
                        <tr>
                            <td>Bukti Transfer</td>
                            <td>: <a href="google.com">Klik Disini</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-12 text-right">
                <button type="button" class="btn btn-armed" data-bs-toggle="modal" data-bs-target="#exampleModal">
                   <i class='bx bx-upload' ></i> Upload Bukti Pembayaran
                </button>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-12">
                <h5>Tata Cara Pembayaran</h5>
            </div>
            <div class="col-sm-12 col-md-6 text-center mb-2">
                <div class="border border-2 p-4 rounded-15">
                    <h6>Pembayaran Menggunakan QRIS</h6>
                    <img class="barcode-bpdpayment" src="../../assets/sampleBarcode.jpeg">
                    <div class="text-left">
                        Tata cara pembayaran :
                        <ul class="list-method-bpdpayment">
                            <li>Buka aplikasi mobile-banking yang anda miliki.</li>
                            <li>Scan QR Code diatas ini.</li>
                            <li>Input jumlah nominal yang ingin dibayarkan.</li>
                            <li>Pilih bayar dan masukkan nama lengkap Anda.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-6 text-center mb-2">
                <div class="border border-2 p-4 rounded-15">
                    <h6>Pembayaran Menggunakan Virtual Account</h6>
                    <div class="text-left">
                        Tata cara pembayaran :
                        <ul class="list-method-bpdpayment">
                            <li>Buka aplikasi m-banking di smartphone.</li>
                            <li>Masukkan username dan password.</li>
                            <li>Pilih menu Transfer > Bank Lain Dalam Negeri.</li>
                            <li>Masukkan Kode Bank dan Nomor Virtual Account tujuan.</li>
                            <li>Masukkan nominal yang harus dibayarkan.</li>
                            <li>Layar akan menunjukkan konfirmasi. Apabila telah sesuai, pilih Lanjutkan / Kirim.</li>
                            <li>Masukkan PIN transaksi.</li>
                            <li>Selesai.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Form Upload Bukti Pembayaran</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-group">
                            <label for="bank">Nama Bank</label>
                            <input type="text" class="form-control mt-1" id="bank" aria-describedby="bank" placeholder="Enter Nama Bank">
                        </div>
                        <div class="form-group mt-3">
                            <label for="cabang">Cabang</label>
                            <input type="text" class="form-control mt-1" id="cabang" aria-describedby="cabang" placeholder="Enter Cabang">
                        </div>
                        <div class="form-group mt-3">
                            <label for="nama">Atas Nama</label>
                            <input type="text" class="form-control mt-1" id="nama" aria-describedby="nama" placeholder="Enter Atas Nama">
                        </div>
                        <div class="form-group mt-3">
                            <label for="no_rekening">No rekening</label>
                            <input type="text" class="form-control mt-1" id="no_rekening" aria-describedby="no_rekening" placeholder="Enter No rekening">
                        </div>
                        <div class="form-group mt-3">
                            <label for="tanggal">Tanggal</label>
                            <input type="date" class="form-control mt-1" id="tanggal" aria-describedby="tanggal" placeholder="Enter Tanggal">
                        </div>
                        <div class="form-group mt-3">
                            <label for="jumlah">Jumlah</label>
                            <input type="text" class="form-control mt-1" id="jumlah" aria-describedby="jumlah" placeholder="Enter Jumlah">
                        </div>
                        <div class="form-group mt-3">
                            <label for="bukti">Bukti Transfer</label>
                            <input type="file" class="form-control mt-1" id="bukti" aria-describedby="bukti" placeholder="Enter Bukti">
                        </div>
                        <div class="form-group mt-3">
                            <label for="catatan">Catatan</label>
                            <textarea class="form-control mt-2" id="catatan" rows="3">
                            </textarea>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Batal</button>
                    <button type="button" class="btn btn-armed"><i class='bx bxs-save' ></i> Simpan</button>
                </div>
                <p class="w-100" style="text-align:center;">Mohon Selesaikan Negosiasi Terlebih Dahulu</p>
            </div>
        </div>
    </div> -->

        <div v-if="loadingSubmit" class="loading-submit-animation-container" >
            <div class="loading-submit-animation-content">
                <div>
                    <div class="lds-facebook"><div></div><div></div><div></div></div>
                    <p>Mohon tunggu, data sedang diproses</p>
                </div>
            </div>
        </div>
        <Modal
        :show="modal.showModal"
        :header="modal.header"
        :headerBorder="modal.headerBorder"
        :title="modal.title"
        :titleText="modal.titleText"
        :footer="modal.footer"
        :footerBtnPosition="modal.footerBtnPosition"
        :footerBorder="modal.footerBorder"
        :btnCancel="modal.btnCancel"
        :btnSubmit="modal.btnSubmit"
        :btnSubmitText="modal.btnSubmitText"
        :btnCancelText="modal.btnCancelText"
        :btnSubmitWidth="modal.btnSubmitWidth"
        :btnCancelWidth="modal.btnCancelWidth"
        @closeModal="closeModal"
        @submitModal="submitModal"
        >

        <div v-if="modalTipe === 'success'" class="modal-body">
            <div  class="text-center">
            <i class='bx bxs-check-circle ' style="font-size:72px; color:var(--primary-color)"></i>
            <div class="notif-text">
                <h3>Selamat File Anda telah dikirim</h3>
            </div>
            </div>
        </div>
        <div v-else-if="modalTipe === 'errorimagesize'" class="d-flex align-items-center justify-content-center text-center mt-2">
            <div>
              <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
              <h5>Ooppsss</h5>
              <p>Ukuran format Gambar anda lebih dari 2mb</p>
            </div>
          </div>
          <div v-else-if="modalTipe === 'errorformat'" class="d-flex align-items-center justify-content-center text-center mt-2">
            <div>
              <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
              <h5>Ooppsss</h5>
              <p>Format File Image Hanya berupa(.jpg, .png, .jpeg, .webp, .pdf)</p>
            </div>
          </div>
        <div v-else-if="modalTipe === 'errorpay'" class="d-flex align-items-center justify-content-center text-center mt-2">
            <div>
            <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
            <h5>Ooppsss</h5>
            <p>{{massageadmin}}</p>
            </div>
        </div>
        <div v-else-if="modalTipe === 'errorbpd'" class="d-flex align-items-center justify-content-center text-center mt-2">
            <div>
            <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
            <h5>Ooppsss</h5>
            <p>{{massageerror}}</p>
            </div>
        </div>
        <div v-else-if="modalTipe === 'errorkosong'" class="d-flex align-items-center justify-content-center text-center mt-2">
            <div>
            <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
            <h5>Ooppsss</h5>
            <p>mohon untuk mengisi data terlebih dahulu</p>
            </div>
        </div>

    </Modal>
</template>

<script>
import axios from 'axios'
import Modal from '../Modal.vue'
import SkeletonPembayaran from '../../components/SkeletonUser/TransaksiUser/SkeletonPembayaran.vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  name: 'DetailPembayaran',
  data(){
    return{
        loading:true,
      metode_pembarayan: '',
      atasnama: '',
      no_rekening: '',
      namabank: '',
      modalbayar:false,
      modalform:false,
      datatransaksi:{},
      datapayment:false,
      filepembayaran:null,
      pembayaran:{
        payment_service:'-',
        bank_name:'-',
        account_name:'-',
        account_number:'-',
        payment_date:'-',
        nominal:'-',

      },
      transaksi_id:'',
      datasave : '',
      key: "",
      checkimage:false,
      massageerror:'',
      modalTipe:'',
      loadingSubmit:false,
      massageadmin:'',
        modal:{
            showModal: false,
            header:false,
            headerBorder: false,
            title: false,
            titleText: '',
            footer: false,
            footerBtnPosition:'',
            footerBorder: false,
            btnCancel: false,
            btnSubmit: false,
            btnSubmitText: '',
            btnCancelText: '',
            btnSubmitWidth: '',
            btnCancelWidth: '',
        },
    }
  },
    setup () {
        return { v$: useVuelidate() }
    },
  created(){
        this.transaksi_id = this.$router.currentRoute._value.query.id_transaksi
       axios.get('/transaction/'+this.transaksi_id).then(res => {
            this.datatransaksi = res.data.data.data
            this.loading=false
       })
       axios.get('/transaction/payment/detail/'+this.transaksi_id).then(res => {
            let data = res.data.data
            if(data){
                this.pembayaran = res.data.data
                this.datapayment = true
            }
            // this.loading=false
       })
      
      
  },
  validations() {
        return {
            atasnama: {
                required,
            },
            no_rekening: {
                required,
            },
            namabank: {
                required,
            },
        }
    },
  computed:{
    searchData: function () {
      return this.pembayaran.filter((data) => {
          data?.bank_name !== '_'
      })
    },
  },
  components: {
      SkeletonPembayaran,
      Modal

  },
  methods: {
     setModal(condition, tipe){
        this.resetDataModal()
        this.modal.showModal= condition
        condition == true ? this.modalTipe = tipe : ''
        if(tipe == 'success' && condition){
            this.modal.header=true
            this.modal.footer= true
            this.modal.footerBtnPosition='right'
            this.modal.btnSubmit= true
            this.modal.btnSubmitText= 'Oke'
            this.modal.btnSubmitWidth='auto'
        }else if(tipe == 'errorimagesize' && condition){
            this.modal.header=true
            this.modal.footer= true
            this.modal.footerBtnPosition='right'
            this.modal.btnSubmit= true
            this.modal.btnSubmitText= 'Oke'
            this.modal.btnSubmitWidth='auto'
        }else if(tipe == 'errorformat' && condition){
            this.modal.header=true
            this.modal.footer= true
            this.modal.footerBtnPosition='right'
            this.modal.btnSubmit= true
            this.modal.btnSubmitText= 'Oke'
            this.modal.btnSubmitWidth='auto'
        }else if(tipe == 'errorbpd' && condition){
            this.modal.header=true
            this.modal.footer= true
            this.modal.footerBtnPosition='right'
            this.modal.btnSubmit= true
            this.modal.btnSubmitText= 'Oke'
            this.modal.btnSubmitWidth='auto'
        }else if(tipe == 'errorpay' && condition){
            this.modal.header=true
            this.modal.footer= true
            this.modal.footerBtnPosition='right'
            this.modal.btnSubmit= true
            this.modal.btnSubmitText= 'Oke'
            this.modal.btnSubmitWidth='auto'
        }
    },
    resetDataModal(){
        this.modal.showModal = false
        this.modal.header = false,
        this.modal.headerBorder = false
        this.modal.title = false
        this.modal.titleText = '' 
        this.modal.footer = false
        this.modal.footerBtnPosition =''
        this.modal.footerBorder = false
        this.modal.btnCancel = false
        this.modal.btnSubmit = false
        this.modal.btnSubmitText = ''
        this.modal.btnCancelText = ''
        this.modal.btnSubmitWidth = ''
        this.modal.btnCancelWidth = ''
    },
    closeModal(){
        this.setModal(false, this.modalTipe)
        this.$store.dispatch('carts')
    },
    submitModal(){
      this.setModal(false, '' , this.modalTipe)
      this.messageErrorModal = ''
      if(this.modalTipe == 'notifSukses'){
        this.addNegox()
      }

    },
    bukamodal(){
        this.modalform=true
    },
    closemodal(){
        this.modalform=false
    },
    toLower(value){
      return value.toLowerCase()
    },
    dataoce(){
        return this.datasave = this.pembayaran?.bank_name.filter(todo => todo !== '_')
    },
    inputfile(event){
      let file = event.target.files[0];
      if (
      file["type"] === "image/jpeg" ||
    file["type"] === "image/jpg" ||
    file["type"] === "image/png" ||
    file["type"] === "image/webp" ||
    file["type"] === "application/pdf" 
      ) {
        if(file["size"] <= 2048000){
          this.filepembayaran = event.target.files[0]
        }else{
            this.setModal(true, 'errorimagesize')
          document.getElementById(`input_file`).value= null
        }
          
      } else {
         this.setModal(true, 'errorformat')
          document.getElementById(`input_file`).value= null
      }
    },
    bayarbpd(){
        this.v$.$touch()
        if(this.filepembayaran && !this.v$.$invalid){
            this.checkimage=false
            let transaksi_id = this.$router.currentRoute._value.query.id_transaksi
            let data = new FormData();
            data.append("id", transaksi_id);
            data.append("account_number", this.no_rekening);
            data.append("account_name", this.atasnama);
            data.append("bank_name", this.namabank);
            data.append("image", this.filepembayaran);
            this.modalform =false
            this.loadingSubmit =true
            axios.post(`/transaction/payment/proof-of-payment`, data)
            .then(() => {
                this.loadingSubmit =false
                this.setModal(true, 'success')
                this.loading=true
                this.v$.$reset()
                this.transaksi_id = this.$router.currentRoute._value.query.id_transaksi
                axios.get('/transaction/'+this.transaksi_id).then(res => {
                        this.datatransaksi = res.data.data.data
                        this.loading=false
                })
                axios.get('/transaction/payment/detail/'+this.transaksi_id).then(res => {
                        let data = res.data.data
                        if(data){
                            this.pembayaran = res.data.data
                            this.datapayment = true
                        }
                        // this.loading=false
                })
            }).catch((e) => {
                this.loadingSubmit =false
                this.massageerror = e.response.data.message
                this.setModal(true, 'errorbpd')
            });
        }else{
            this.checkimage=true
        }

    },
    onChange(event) {
        let transaksi_id = this.$router.currentRoute._value.query.id_transaksi
        let payment = event.target.value
        let data = new FormData();
        data.append("transaction_id", transaksi_id);
        data.append("payment_success_url", `${this.$apiSuccess}`);
        data.append("payment_service", payment)
        this.loadingSubmit = true
        axios.post(`/transaction/payment`, data)
        .then((response) => {
            this.loadingSubmit = false
            let datapayment = response.data.data
            this.metode_pembarayan = datapayment.payment_service
            if(datapayment.payment_service == 'Doku'){
                window.location.href = datapayment.url_payment
            } else {
                this.$router.push('/example/bpd-payment?id_transaksi='+transaksi_id)
            }
        }).catch((e) => {
            this.loadingSubmit = false
            this.massageadmin = e.response.data.message
            this.setModal(true, 'errorpay')
        });
    },
    openmodalbayar(){
        this.modalbayar =true
    },
    closemodalbayar(){
        this.modalbayar =false
    },
    setpayment(value){
        let transaksi_id = this.$router.currentRoute._value.query.id_transaksi
        let payment = value
        let data = new FormData();
        data.append("transaction_id", transaksi_id);
        data.append("payment_success_url", `${this.$apiUrl}profil/respon`);
        data.append("payment_service", payment)
        this.loadingSubmit = true
        axios.post(`/transaction/payment`, data)
        .then((response) => {
        this.loadingSubmit = false
        let datapayment = response.data.data
        this.metode_pembarayan = datapayment.payment_service
        if(datapayment.payment_service == 'Doku'){
            window.location.href = datapayment.url_payment
        }
        }).catch((e) => {
            this.loadingSubmit = false
            this.massageadmin = e.response.data.message
            this.setModal(true, 'errorpay')
        });
    }
  }
}
</script>
<style scoped>
    .btn-armed {
        background-color: #E40C0C;
        color: #FFFFFF;
    }
    .table-infoPayment td {
        border: none;
    }
    .img-none{
        width:100%;
        display:block; 
        margin:0 auto;
    }
    .view-mobile{
        display: block;
    }
    .view-web{
        display: none;
    }

@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
    .img-none{
        width:50%;
        display:block; 
        margin:0 auto;
    }
    .view-mobile{
        display: none;
    }
    .view-web{
        display: table;
    }
}

@media (min-width: 1023px) {
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>