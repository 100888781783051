<template>
  <div class="footbar show-mobile">
      <div class="footbar-container container">
          <router-link to="/" class="footbar-link">
              <i class='bx bxs-home'></i>
              <span class="text-xs">Beranda</span>
          </router-link>
          <router-link to="/profil/transaksi" class="footbar-link">
              <i class='bx bxs-wallet'></i>
              <span class="text-xs">Transaksi</span>
          </router-link>
          <router-link to="/profil/beranda" class="footbar-link">
              <i class='bx bxs-user'></i>
              <span class="text-xs">Pengguna</span>
          </router-link>
      </div>
  </div>
</template>

<script>
export default {
  name: 'footbar-component',
}
</script>
<style scoped>
.text-xs {
    font-size: 12px;
}

</style>

