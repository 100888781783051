import { createApp } from 'vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "bootstrap";
import App from './App.vue'
import '@/assets/style.css';
import router from './router' 
import "./axios";
import store from './vuex';
import VueCookies from 'vue-cookies';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import ApiImg from './Service/Apiimg'
import ApiDoc from './Service/ApiDocument'
import ApiImgProduct from './Service/Apiimgproduct'
import ApiUrl from './Service/ApiUrl'
import ApiSuccess from './Service/ApiSucces'
import vSelect from 'vue-select'

import 'vue-select/dist/vue-select.css';

const options = {
    confirmButtonColor: '#a71313',
    cancelButtonColor: '#ff7674',
  };
// const app = createApp(App);

// app.use(router);
// app.config.globalProperties.$http = axios; // Allow axios in all componenets this.$http.get
// app.mount('#app');

            // console.log(this.$router.currentRoute._value.href)

// createApp(App).use(router).mount('#app')

// const preload = async () => {
//   await store.dispatch('user') 
//   await store.dispatch('address') 

//   console.log(document.cookie)
//   console.log(store.state.gettoken)

  const app = createApp(App)
  app.use(router).use(VueSweetalert2, options).use(store).use(Cropper).use(VueCookies).mount('#app')
  app.config.globalProperties.$imgUrl = ApiImg
  app.config.globalProperties.$apiDoc = ApiDoc
  app.config.globalProperties.$imgProductUrl = ApiImgProduct
  app.config.globalProperties.$apiUrl = ApiUrl
  app.config.globalProperties.$apiSuccess = ApiSuccess
  app.component('v-select', vSelect)
  window.$ = window.jQuery = require('jquery');
  // }
  // preload();
// Note :
// untuk menggunakan component bootstrap, data-target diubah menjadi data-bs-target, atau data lainnya

