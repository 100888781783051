<template>
    <div>
        <skeleton-form v-if="loading ==true"></skeleton-form>
        <div v-if="loading ==false" class="container-fluid p-0">
            <div class="w-100 mt-3" style="height:60px;">
                <div class="float-right">
                    <button class="btn btn-outline-ubah" @click="bukamodalpass()">
                        <i class='bx bx-collapse-alt'></i> Ubah Kata Sandi
                    </button>
                </div>
            </div>
            <div class="w-100">
                <form @submit.prevent="editrofile">
                    <div class="form-profile-picture">
                        <div class="profile-piture-container">
                            <div  
                                class="profile-picture d-flex align-items-center justify-content-center"
                                v-bind:style="{ backgroundImage: 'url(' + imageuser + ')' }">
                                <div id="image_foto" class="w-100 img-dalam"></div>
                            </div>

                            <div class="input-group mb-3">
                                <input type="file" @input="handleUpload($event)" class="form-control" accept=".jpg,.jpeg,.png,.webp" id="inputGroupFile02">
                                <label class="input-group-text" @click="$refs.image.click(), resetimage()" for="inputGroupFile02">Upload</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-profile-info row">
                        <div class="form-group col-sm-12 col-md-6 mb-3">
                            <label class="form-label">Nama Instansi</label>
                            <input 
                                type="text" 
                                v-model="userdata.name" 
                                :class="{ 'border-danger': v$.userdata.name.$error }"
                                class="input w-100">
                            <div class="text-danger" v-if="v$.userdata.name.$error">
                                <small v-if="v$.userdata.name.required">Nama Instansi Wajib Diisi</small>
                            </div>
                        </div>
                        <div class="form-group col-sm-12 col-md-6 mb-3">
                            <label class="form-label">Email</label>
                            <input 
                                type="email" 
                                v-model="userdata.email"
                                @change="dataemailberubah"
                                :class="{ 'border-danger': v$.userdata.email.$error || dataemailsalah}"
                                class="input w-100">
                                <small
                                    class="error text-danger"
                                    v-for="(error, index) of v$.userdata.email.$errors"
                                    :key="index"
                                    >
                                    {{ error.$message }} <br>
                                </small>
                                <small class="error text-danger" v-if="dataemailsalah">Email Telah Terdaftar</small>
                        </div>
                        <div class="form-group col-sm-12 col-md-6 mb-3">
                            <label class="form-label">No Telepon</label>
                            <input 
                                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                type="text" 
                                v-model="userdata.phone" 
                                :class="{ 'border-danger': v$.userdata.phone.$error }"
                                class="input w-100">
                            <div class="text-danger" v-if="v$.userdata.phone.$error">
                                <small v-if="v$.userdata.phone.required">Nomor Telepon wajib diisi</small>
                            </div>
                        </div>
                        <div class="form-group col-sm-12 col-md-6 mb-3">
                            <label class="form-label">Tanggal Lahir</label>
                            <input 
                                type="date" 
                                v-model="userdata.birthdate"
                                :class="{ 'border-danger': v$.userdata.birthdate.$error }" 
                                class="input w-100">
                            <div class="text-danger" v-if="v$.userdata.birthdate.$error">
                                <small v-if="v$.userdata.birthdate.required">tanggal lahir Wajib Diisi</small>
                            </div>
                        </div>
                        <div class="form-group col-sm-12 col-md-6 mb-3">
                            <label class="form-label">Jenis Kelamin</label>
                            <fieldset class="form-group mb-5">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-check form-check-inline">
                                            <input v-model="userdata.gender" class="form-check-input" type="radio" name="gender" id="laki-laki" autocomplete="off" v-bind:value="true" checked>
                                            <label class="form-check-label" for="laki-laki">
                                                Laki-Laki
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input v-model="userdata.gender" class="form-check-input" type="radio" name="gender" id="perempuan" v-bind:value="false">
                                            <label class="form-check-label" for="perempuan">
                                                Perempuan
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        <div class="form-group col-sm-12 col-md-6 mb-3">
                            <label class="form-label">NIP</label>
                            <input 
                                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                type="text" 
                                v-model="userdata.nip"
                                :class="{ 'border-danger': v$.userdata.nip.$error }" 
                                class="input w-100">
                            <div class="text-danger" v-if="v$.userdata.nip.$error">
                                <small v-if="v$.userdata.nip.required">NIP Wajib Diisi</small>
                            </div>
                        </div>
                        <div class="form-group col-sm-12 col-md-6 mb-3">
                            <label class="form-label">Nama PP</label>
                            <input 
                                type="text" 
                                v-model="userdata.name_pp" 
                                :class="{ 'border-danger': v$.userdata.name_pp.$error }"
                                class="input w-100">
                            <div class="text-danger" v-if="v$.userdata.name_pp.$error">
                                <small v-if="v$.userdata.name_pp.required">Nama PP Wajib Diisi</small>
                            </div>
                        </div>
                        <div class="form-group col-sm-12 col-md-6 mb-3">
                            <label class="form-label">Nama PPK</label>
                            <input 
                                type="text" 
                                v-model="userdata.name_ppk" 
                                :class="{ 'border-danger': v$.userdata.name_ppk.$error }"
                                class="input w-100">
                            <div class="text-danger" v-if="v$.userdata.name_ppk.$error">
                                <small v-if="v$.userdata.name_ppk.required">Nama PPK Wajib Diisi</small>
                            </div>
                        </div>

                        <div class="col-12 d-flex justify-content-end">
                            <button type="submit" class="btn btn-red"><i class='bx bxs-save'></i> Simpan</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <!-- modal changepassword -->
            <div v-if="modalpassword" class="modal fade show" aria-modal="true" style="display: block; background-color: rgba(0,0,0,0.3);" tabindex="-1" role="dialog" id="modalchangepassword" >
            <!-- <div class="modal" tabindex="-1" role="dialog" id="modalchangepassword" > -->
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Form Ubah Kata Sandi</h5>
                    </div>
                    <form @submit.prevent="ubahpassword">
                        <div class="modal-body">
                            <div class="form-group col-12 mb-3">
                                <label class="form-label">Kata Sandi baru</label>
                                <div class="input-group mb-3" :class="{ 'border-danger': v$.datapassword.password.$error }">
                                    <input type="password" 
                                        v-model="datapassword.password"
                                        id="password"  
                                        class="form-control field-password input-grouppass" 
                                        placeholder="Kata Sandi Baru" 
                                        aria-label="Kata Sandi" >
                                    <div  v-if="!v$.datapassword.password.$error"  class="input-group-append">
                                        <i @click="togglePass" class='eye-icon input-group-text bx bx-hide btn-eye'></i>
                                    </div>
                                </div>
        
                                <small
                                class="error text-danger"
                                v-for="(error, index) of v$.datapassword.password.$errors"
                                :key="index"
                                >
                                {{ error.$message }}
                                </small>
                            </div>
                            <div class="form-group col-12 mb-3">
                                <label class="form-label">Konfirmasi Kata Sandi Baru</label>
                                <div class="input-group mb-3" :class="{ 'border-danger': v$.datapassword.confirmpassword.$error }">
                                    <input type="password" 
                                        v-model="datapassword.confirmpassword" 
                                        id="confirmpassword"  
                                        class="form-control field-confirmpassword input-grouppass"
                                        placeholder="Konfirmasi Kata Sandi Baru" 
                                        aria-label="Kata Sandi" >
                                    <div  v-if="!v$.datapassword.confirmpassword.$error" class="input-group-append">
                                        <i  @click="toggleconfirmPass" class='btn-eye eye-icon-konfir input-group-text bx bx-hide'></i>
                                    </div>
                                </div>
                                
                                <small
                                class="error text-danger"
                                v-for="(error, index) of v$.datapassword.confirmpassword.$errors"
                                :key="index"
                                >
                                {{ error.$message }} <br>
                                </small>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button @click="closemodalpass()" type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class='bx bx-window-close'></i> Batal</button>
                            <button type="submit" class="btn btn-armed"><i class='bx bxs-save'></i> Simpan</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- modal changepassword-->

        <!-- modal Cropimage -->
        <div v-if="modalcropper" class="modal fade show" id="staticBackdrop" data-bs-backdrop="static" style="display:block; background-color: rgba(0,0,0,0.3);" 
        data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-modal="true" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header" style="border-bottom:0px;">
                    <button type="button" @click="closemodal()" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                   <cropper
                    style="max-height: 400px !important"
                    :class="{
                        'image-exist': img,
                        'border-danger': image_validation_message,
                    }"
                    class="cropper rounded image-cropper-style"
                    :src="img"
                    :autoZoom="false"
                    :stencil-props="{
                        aspectRatio: 1 / 1,
                    }"
                    ref="cropper"
                    />
                    <div class="py-3 row">
                    <div class="col-6">
                        <button
                        class="btn btn-outline-danger w-100"
                       @click="closemodal()"
                        >
                        Tutup
                        </button>
                    </div>
                    <div class="col-6">
                        <button class="btn btn-danger w-100" @click="getCropped()">
                        Ok
                        </button>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        
        <!-- modal Cropimage -->
        <div v-if="loadingSubmit" class="loading-submit-animation-container" >
            <div class="loading-submit-animation-content">
                <div>
                    <div class="lds-facebook"><div></div><div></div><div></div></div>
                    <p>Mohon tunggu, data sedang diproses</p>
                </div>
            </div>
        </div>

        <Modal
          :show="modal.showModal"
          :header="modal.header"
          :headerBorder="modal.headerBorder"
          :title="modal.title"
          :titleText="modal.titleText"
          :footer="modal.footer"
          :footerBtnPosition="modal.footerBtnPosition"
          :footerBorder="modal.footerBorder"
          :btnCancel="modal.btnCancel"
          :btnSubmit="modal.btnSubmit"
          :btnSubmitText="modal.btnSubmitText"
          :btnCancelText="modal.btnCancelText"
          :btnSubmitWidth="modal.btnSubmitWidth"
          :btnCancelWidth="modal.btnCancelWidth"
          @closeModal="closeModal"
          @submitModal="submitModal"
        >
          <!-- Notif add cart -->
          <div v-if="modalTipe === 'editsuccess'">
            <div class="text-center mt-2 border-2 border-light border-bottom">
              <i class='bx bxs-check-circle ' style="font-size:115px; color:var(--primary-color)"></i>
              <div class="notif-text">
                <h5>Data anda telah berhasil diperbaharui</h5>
              </div>
            </div>
          </div>
          <div v-else-if="modalTipe === 'editpasswordsuccess'">
            <div class="text-center mt-2 border-2 border-light border-bottom">
              <i class='bx bxs-check-circle ' style="font-size:115px; color:var(--primary-color)"></i>
              <div class="notif-text">
                <h5>Data Kata Sandi anda telah berhasil diperbaharui</h5>
              </div>
            </div>
          </div>

          <!-- Info Negosiasi -->
          <div v-else-if="modalTipe === 'errorimagesize'" class="d-flex align-items-center justify-content-center text-center mt-2">
            <div>
              <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
              <h5>Ooppsss</h5>
              <p>Ukuran format Gambar anda lebih dari 2mb</p>
            </div>
          </div>
          <div v-else-if="modalTipe === 'errorformat'" class="d-flex align-items-center justify-content-center text-center mt-2">
            <div>
              <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
              <h5>Ooppsss</h5>
              <p>Format File Image Hanya berupa(.jpg, .png, .jpeg, .webp)</p>
            </div>
          </div>
          <div v-else-if="modalTipe === 'errorinput'" class="d-flex align-items-center justify-content-center text-center mt-2">
            <div>
              <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
              <h5>Ooppsss</h5>
              <p>{{massageadmin}}</p>
            </div>
          </div>
        </Modal>
</template>
<script>
import axios from 'axios'
import Modal from '../Modal.vue'
import Img_Default from '../../assets/img/img-default.png'
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import SkeletonForm from '../../components/SkeletonUser/SkeletonSuntingProfile.vue'
import useVuelidate from '@vuelidate/core'
import { required,email,minLength,helpers,sameAs } from '@vuelidate/validators'
export default {
    name: 'form-profil',
    components:{
        SkeletonForm,
        Cropper,
        Modal
    },
    setup () {
        return { v$: useVuelidate() }
    },
    data(){
        return{
            dataemailsalah:'',
            imagename:'',
            img:'',
            url:'',
            imageuser:'',
            loading:true,
            userdata:{
                id:'',
                name:'',
                image:'',
                email:'',
                phone:'',
                birthdate:'',
                gender:null,
                nip:'',
                name_pp:'',
                name_ppk:'',
            },
            datapassword:{
                    password:'',
                    confirmpassword:'',
            },
            modalcropper: false,
            modalpassword: false,
            modalTipe:'',
            loadingSubmit:false,
            massageadmin:'',
            modal:{
                showModal: false,
                header:false,
                headerBorder: false,
                title: false,
                titleText: '',
                footer: false,
                footerBtnPosition:'',
                footerBorder: false,
                btnCancel: false,
                btnSubmit: false,
                btnSubmitText: '',
                btnCancelText: '',
                btnSubmitWidth: '',
                btnCancelWidth: '',
            },
        }
    },
    validations() {
        return {
            datapassword:{
                password: {
                    required: helpers.withMessage('Kata Sandi wajib diisi', required),
                    minLength : helpers.withMessage(
                                ({
                                    $params,
                                }) => `Kata Sandi harus minimal diisi ${$params.min} Karakter`,
                                minLength(8)
                                ),
                },
                confirmpassword: {
                    required: helpers.withMessage('Konfirmasi Kata Sandi Kata Sandi wajib diisi', required),
                    minLength : helpers.withMessage(
                                ({
                                    $params,
                                }) => `konfirmasi Kata Sandi harus minimal diisi ${$params.min} karakter`,
                                minLength(8)
                                ),
                    sameAs: helpers.withMessage(`Konfirmasi Kata Sandi baru tidak sama dengan Kata Sandi baru
                     `,sameAs(this.datapassword.password))
                }
            },
            userdata: {
                name: {
                    required,
                },
                email: {
                    required: helpers.withMessage('Email wajib diisi', required),
                    email: helpers.withMessage('Format Email tidak sesuai', email),
                },
                phone: {
                    required,
                },
                birthdate: {
                    required,
                },
                nip: {
                    required,
                },
                name_pp: {
                    required,
                },
                name_ppk: {
                    required,
                },
            }
        }
    },
    created(){
        this.loading=true
            if(this.user){
                axios.get('/customer/data-customer/'+this.user.id).then(res => {
                    this.loading=false
                    this.userdata = res.data.data?.customer
                    if(this.userdata.image){
                        this.imageuser = this.$imgUrl+this.userdata.image
                    }else{
                        this.imageuser = Img_Default
                    }
                    this.userdata.id = this.user.id
                })
                .catch(() =>{
                })
            }
    
    },
    watch:{
        user(value){
            if(!(value instanceof Promise) && value !== null){

            axios.get('/customer/data-customer/'+this.user.id).then(res => {
                this.loading=false
                this.userdata = res.data.data?.customer
                if(this.userdata.image){
                    this.imageuser = this.$imgUrl+this.userdata.image
                }else{
                    this.imageuser = Img_Default
                }
                this.userdata.id = this.user.id
            })
            .catch(() =>{
            })
            
            }
        }
    },
    computed: {
        user: function() {
            return this.$store.state.user;
        },
    },
    methods: {
    dataemailberubah(){
        this.dataemailsalah=''
    },
    togglePass(){
        const input = document.querySelector(".field-password");
        const show = document.querySelector(".eye-icon");
        if (input.type === "password") {
            show.classList.remove("bx-hide");
            show.classList.add("bx-show");
            input.type = "text";
        } else {
            show.classList.remove("bx-show");
            show.classList.add("bx-hide");
            input.type = "password";
        }
        // if(document.querySelector('#'+id).type == 'password'){
        //     document.querySelector('#'+id).type = 'text'
        //     document.querySelector('#'+id+'~.icon-container .bx').classList.remove('bx-hide')
        //     document.querySelector('#'+id+'~.icon-container .bx').classList.add('bx-show')
        // }else {
        //     document.querySelector('#'+id).type = 'password'
        //     document.querySelector('#'+id+'~.icon-container .bx').classList.remove('bx-show')
        //     document.querySelector('#'+id+'~.icon-container .bx').classList.add('bx-hide')
        // }
    },
    setModal(condition, tipe){
        this.resetDataModal()
        this.modal.showModal= condition
        condition == true ? this.modalTipe = tipe : ''
        if(tipe == 'editsuccess' && condition){
            this.modal.header=true
            this.modal.footer= true
            this.modal.footerBtnPosition='right'
            this.modal.btnSubmit= true
            this.modal.btnSubmitText= 'Oke'
            this.modal.btnSubmitWidth='auto'
        }else if(tipe == 'editpasswordsuccess' && condition){
            this.modal.header=true
            this.modal.footer= true
            this.modal.footerBtnPosition='right'
            this.modal.btnSubmit= true
            this.modal.btnSubmitText= 'Oke'
            this.modal.btnSubmitWidth='auto'
        }else if(tipe == 'errorimagesize' && condition){
            this.modal.header=true
            this.modal.footer= true
            this.modal.footerBtnPosition='right'
            this.modal.btnSubmit= true
            this.modal.btnSubmitText= 'Oke'
            this.modal.btnSubmitWidth='auto'
        }else if(tipe == 'errorformat' && condition){
            this.modal.header=true
            this.modal.footer= true
            this.modal.footerBtnPosition='right'
            this.modal.btnSubmit= true
            this.modal.btnSubmitText= 'Oke'
            this.modal.btnSubmitWidth='auto'
        }else if(tipe == 'errorinput' && condition){
            this.modal.header=true
            this.modal.footer= true
            this.modal.footerBtnPosition='right'
            this.modal.btnSubmit= true
            this.modal.btnSubmitText= 'Oke'
            this.modal.btnSubmitWidth='auto'
        }
    },
    resetDataModal(){
        this.modal.showModal = false
        this.modal.header = false,
        this.modal.headerBorder = false
        this.modal.title = false
        this.modal.titleText = '' 
        this.modal.footer = false
        this.modal.footerBtnPosition =''
        this.modal.footerBorder = false
        this.modal.btnCancel = false
        this.modal.btnSubmit = false
        this.modal.btnSubmitText = ''
        this.modal.btnCancelText = ''
        this.modal.btnSubmitWidth = ''
        this.modal.btnCancelWidth = ''
    },
    closeModal(){
        this.setModal(false, this.modalTipe)
    },
    submitModal(){
        this.setModal(false, this.modalTipe)
    },
    toggleconfirmPass(){
        const input = document.querySelector(".field-confirmpassword");
        const show = document.querySelector(".eye-icon-konfir");
        if (input.type === "password") {
            show.classList.remove("bx-hide");
            show.classList.add("bx-show");
            input.type = "text";
        } else {
            show.classList.remove("bx-show");
            show.classList.add("bx-hide");
            input.type = "password";
        }
    },
    URLFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    resetimage() {
      document.getElementById("inputGroupFile02").value = "";
    },
    bukamodalpass(){
        this.modalpassword=true
    },
    closemodalpass(){
        this.modalpassword=false
    },
    closemodal(){
        this.modalcropper=false;
    },
    handleUpload(event) {
      if (event.target.value == "") {
        this.preview_image = this.previous_image;
      }

      let file = event.target.files[0];
      if (!file) {
        this.image_validation_message = null;
        this.img = null;
      } else {
        if (
          file["type"] === "image/jpeg" ||
          file["type"] === "image/jpg" ||
          file["type"] === "image/png"
        ) {
        this.modalcropper=true;
          this.image_validation_message = null;
          this.img = URL.createObjectURL(event.target.files[0]);
        } else {
            document.getElementById("inputGroupFile02").value = "";
            this.setModal(true, 'errorformat')
                this.resetimage()
          this.img = null;
        }
      }
    },
    getCropped() {
      const { canvas } = this.$refs.cropper.getResult();
      const dataimageurl = this.URLFile(
        canvas.toDataURL(),
        `${Date.now()}.jpg`
      );
      if (dataimageurl.size > 2000000) {
        this.modalcropper=false;
        document.getElementById("inputGroupFile02").value = "";
        this.setModal(true, 'errorimagesize')
      } else if (dataimageurl.size < 2000000) {
        this.imagename = dataimageurl;
        this.url = URL.createObjectURL(dataimageurl);
        document.getElementById("image_foto").style.backgroundImage = "url("+ this.url +")";
        this.modalcropper=false;
      }
    },
    editrofile() {
        this.v$.userdata.$touch()
        if (!this.v$.userdata.$invalid) {
               let data = new FormData();
              data.append("_method", "PUT");
              data.append("name", this.userdata.name);
              data.append("username", this.userdata.username);
              data.append("instance_name", this.userdata.instance_name);
              data.append("birthdate", this.userdata.birthdate);
              if(this.userdata.gender == true){
                data.append("gender", 1);
              }
              else if(this.userdata.gender == false){
                data.append("gender", 0);
              }
              if (this.imagename != "") {
                    data.append("image", this.imagename);
            }
              data.append("kldi", this.userdata.kldi);
              data.append("lspe", this.userdata.lspe);
              data.append("email", this.userdata.email);
              data.append("phone", this.userdata.phone);
              data.append("nip", this.userdata.nip);
              data.append("name_pp", this.userdata.name_pp);
              data.append("name_ppk", this.userdata.name_ppk);
                this.loadingSubmit = true
                axios
                  .post(`/customer/data-customer/update/`+this.userdata.id, data)
                  .then((response) => {
                    this.$store.dispatch('user')
                    this.loadingSubmit = false
                    if (response.data.status === "Success") {
                        this.setModal(true, 'editsuccess')
                    } else {
                      // validation.value = response.data.response
                    }
                  })
                  .catch((e) => {
                    this.loadingSubmit = false
                      if(e.response.data.data){
                        this.massageadmin = e.response.data.data.email[0]
                        this.setModal(true, 'errorinput')
                        //   this.dataemailsalah = e.response.data.data.email[0]
                      }else{
                        this.massageadmin = e.response.data.message
                        this.setModal(true, 'errorinput')
                      }
                      
                  });
        }
   
      
    },
    ubahpassword() {
        this.v$.datapassword.$touch()
        if (!this.v$.datapassword.$invalid) {
               let data = new FormData();
              data.append("_method", "PUT");
              data.append("name", this.userdata.name);
              data.append("username", this.userdata.username);
              data.append("instance_name", this.userdata.instance_name);
              data.append("birthdate", this.userdata.birthdate);
              if(this.userdata.gender == true){
                data.append("gender", 1);
              }
              else if(this.userdata.gender == false){
                data.append("gender", 0);
              }
              data.append("kldi", this.userdata.kldi);
              data.append("lspe", this.userdata.lspe);
              data.append("email", this.userdata.email);
              data.append("password", this.datapassword.password);
              data.append("password_confirmation", this.datapassword.confirmpassword);
              data.append("phone", this.userdata.phone);
              data.append("nip", this.userdata.nip);
              data.append("name_pp", this.userdata.name_pp);
              data.append("name_ppk", this.userdata.name_ppk);
                axios
                  .post(`/customer/data-customer/update/`+this.userdata.id, data)
                  .then((response) => {
                    this.$store.dispatch('user')
                    if (response.data.status === "Success") {
                        this.setModal(true, 'editpasswordsuccess')

                    } else {
                      // validation.value = response.data.response
                    }
                  })
                  .catch(() => {
                  });
        }
   
      
    }
    },
}
</script>

<style scoped>
.input-grouppass{
    border-right: 0px !important;
}
.btn-eye{
    background-color: #ffffff;
    border-left: 0px !important;
}
.profile-picture{
    overflow: hidden;
    background-size: cover;
}
.img-dalam{
    width: 100%;
    height: 100%;
    background-size: cover;
}
.profile-picture img{
    width: 100%;
}
.border-danger{
    border:1px solid red;
}
    .btn-armed {
        background-color: var(--primary-color);
        color: #FFFFFF;
    }
.btn-outline-ubah{
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
}
.btn-outline-ubah:active{
    background-color:var(--primary-color);
    color: #ffffff;
}
</style>
