<template>
    <div class="comment-wrapper">
        <div class="comment-header">
            <div class="comment-image">
                <img :src="baseUrl+'/'+customerImg" class="rounded-circle" alt="">
            </div>
            <div class="comment-right">
                <div class="comment-right-top">
                    <p class="font-bold m-0">{{name}}</p> 
                    <p class="font-small m-0">{{time}}</p>
                </div>
                <div class="star_reviews">
                    <div class="star_fill">
                        <span v-for="index in star" :key="index" >
                            <i class='bx bxs-star'></i>
                        </span>
                    </div>
                    <div class="star_outline">
                        <span v-for="str in 5" :key="str">
                            <i class='bx bx-star'></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <p>{{notes}}</p>
            <div class="image d-flex align-items-center">
                <figure v-for="img in images" :key="img.id" data-bs-toggle="modal" :data-bs-target="'#imageModal'+id">
                    <img :src="baseUrl+img.image" @click="showImg(img, id)" alt=""/>
                </figure>
            </div>
            <p class="comment-response" v-if="responNotes !== null">
                <span>Respon Penjual :</span> <br>
                {{responNotes}}
            </p>
        </div>
    </div>
    <div class="modal imageModal" :id="'imageModal'+id" tabindex="-1">
        <button type="button" class="btn btn__close" data-bs-dismiss="modal" aria-label="Close">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
            </svg>
        </button>
        <div class="modal-dialog modal-dialog-centered">
            <img :src="baseUrl+dataImg" class="shadow-lg" alt="">
        </div>
    </div>
    <!-- <vue-easy-lightbox
        :visible="visible"
        :imgs="images"
        :index="index"
        @hide="handleHide"
    ></vue-easy-lightbox> -->
</template>

<script>
// import VueEasyLightbox from 'vue-easy-lightbox'

export default {
    props:['id','customerImg','name','time','star','notes','images','responNotes'],
    name: 'ulasan-list-component',
    components: {
        // VueEasyLightbox,
    },
    data() {
        return{
            baseUrl: process.env.VUE_APP_NAME_AXIOS,
            visible: false,
            index: 0,
            dataImg: '',
            imgId: ''
        }
    },
    methods:{
        showImg(img, id) {
            this.imgId = id
            this.dataImg = img.image
        },
        handleHide() {
            this.visible = false
        }
    }
}
</script>

<style>
.imageModal{
    background: rgba(0, 0, 0, 0.6);
}
.btn__close{
    position: absolute;
    top: 20px;
    right: 20px;
    background: rgba(0, 0, 0, 0.4);
    padding: 10px;
    margin: 0;
    color: white !important;
}
</style>