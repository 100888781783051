<template>
    <skeleton-bpd v-if="loading"></skeleton-bpd>
    <div v-if="!loading">
        <div class="section section-modif mb-5">
            <div class="container">
                <div class="row row-bpdpayment">
                    <div class="d-flex justify-content-end">
                        <nav aria-label="breadcrumb" class="">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="../profil/beranda" class="custom-link">Beranda</a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a aria-current="page" class="router-link-active router-link-exact-active text-secondary">Metode Pembayaran BPD Bali</a>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div class="col-xs-12 col-sm-5 col-md-4 left-content-bpdpaymet">
                        <div class="border-bottom border-white pb-2">
                            <span class="title-bpdpayment">Nama Pembeli</span><br>
                            <span class="subtitle-bpdpayment"><b>{{ datauser.name }}</b></span>
                        </div>
                        <div class="border-bottom border-white pt-3 pb-2">
                            <span class="title-bpdpayment">Tanggal Pembelian</span><br>
                            <span class="subtitle-bpdpayment"><b>{{dateTime(datatransaksi.created_at)}}</b></span>
                        </div>
                        <div class="border-bottom border-white pt-3 pb-2">
                            <span class="title-bpdpayment">No Invoice</span><br>
                            <span class="subtitle-bpdpayment"><b>{{datatransaksi.invoice}}</b></span>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-7 col-md-8 right-content-bpdpaymet p-3">
                        <div class="row">
                            <div class="col-sm-12 col-md-2 text-center">
                                <img class="logo-bpdpayment" src="../../assets/bpdLogo.png">
                            </div>
                            <div class="col-sm-12 col-md-10 title-logo-bpdpayment">
                                <b>Bank Pembangunan Daerah Bali</b>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <hr class="border border-2 border-secondary">
                            </div>
                            <div class="col-md-12 text-center">
                                <b>Metode Pembayaran :</b> Transfer Bank Pembangunan Daerah Bali<br>
                                <b>Lakukan Pembayaran Sebelum :</b> {{dateandtime(pembayaran.expired_date)}} WITA
                            </div>
                            <div class="col-md-12 text-center my-3">
                                <div>Total Yang Harus Dibayar :</div>
                                <div class="title-price-bpdpayment"><b>Rp. {{ new Intl.NumberFormat('id-ID').format(Number(datatransaksi?.grand_total).toFixed())  }}</b></div>
                            </div>
                        </div>
                        <div class="w-100">
                            <button type="button" class="mt-3 btn btn-danger btn-upload" @click="bukamodal">
                                <i class='bx bx-upload' ></i> Upload Bukti Pembayaran
                            </button>
                        </div>
                        <div class="row mt-3">
                            <div class="col-sm-12 col-md-6 text-center mb-2">
                                <div class="border border-2 p-4 rounded-15">
                                    <h6>Pembayaran Menggunakan QRIS</h6>
                                    <img class="barcode-bpdpayment" src="../../assets/qrbpd.png">
                                    <div class="text-left">
                                        Tata cara pembayaran :
                                        <ul class="list-method-bpdpayment">
                                            <li>Buka aplikasi mobile-banking yang anda miliki.</li>
                                            <li>Scan QR Code diatas ini.</li>
                                            <li>Input jumlah nominal yang ingin dibayarkan.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 text-center mb-2">
                                <div class="border border-2 p-4 rounded-15">
                                    <h6>Pembayaran Menggunakan Virtual Account</h6>
                                    <div class="text-left">
                                        Tata cara pembayaran :
                                        <ul class="list-method-bpdpayment">
                                            <li>Buka aplikasi m-banking di smartphone.</li>
                                            <li>Masukkan username dan password / PIN  pada Aplikasi Mobile / Internet Banking</li>
                                            <li>Pilih menu <b>"Pembayaran"</b>.</li>
                                            <li>Pilih <b>"E-Commerce"</b>.</li>
                                            <li>Pilih Input nomor ID lalu Pilih Produk <b>"Armed"</b>.</li>
                                            <li>Masukkan nomor Id/ Tagihan <b>0000000161</b></li>
                                            <li>Masukkan PIN Untuk Melanjutkan Transaksi.</li>
                                            <li>Selesai.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12 text-right">
                                <router-link :to="{ path: '/profil/detailtransaksi', query: { id_transaksi: transaksi_id }}" class="btn btn-back-bpdpayment">
                                <i class='bx bx-arrow-back' ></i> Kembali</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="modalform" class="modal fade show" aria-modal="true" style="display: block; background-color: rgba(0,0,0,0.3);" tabindex="-1" role="dialog" id="modalchangepassword" >
                <!-- <div class="modal" tabindex="-1" role="dialog" id="modalchangepassword" > -->
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Form Upload Bukti Pembayaran</h5>
                        </div>
                        <div class="modal-body">
                            <div class="form-group mt-md-3">
                                <label for="atasnama">Atas Nama</label>
                                <input type="text" class="form-control mt-2" id="atasnama" :class="{ 'border-danger': v$.atasnama.$error }" v-model="atasnama" aria-describedby="diterima" placeholder="Atas Nama">
                                <div class="text-danger" v-if="v$.atasnama.$error">
                                    <small v-if="v$.atasnama.required">Atas Nama Wajib Diisi</small>
                                </div>
                            </div>
                            <div class="form-group mt-md-3">
                                <label for="no_rekening">Nomor Rekening</label>
                                <input type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="form-control mt-2" id="no_rekening" :class="{ 'border-danger': v$.no_rekening.$error }" v-model="no_rekening" aria-describedby="diterima" placeholder="No Rekening">
                                <div class="text-danger" v-if="v$.no_rekening.$error">
                                    <small v-if="v$.no_rekening.required">No Rekening Wajib Diisi</small>
                                </div>
                            </div>
                            <div class="form-group mt-md-3">
                                <label for="namabank">Nama Bank</label>
                                <input type="text" class="form-control mt-2" id="diterima" :class="{ 'border-danger': v$.namabank.$error }" v-model="namabank" aria-describedby="namabank" placeholder="Nama Bank">
                                <div class="text-danger" v-if="v$.namabank.$error">
                                    <small v-if="v$.namabank.required">Nama Bank Wajib Diisi</small>
                                </div>
                            </div>
                            <div class="form-group mt-md-3">
                                <label for="input_file">Bukti Pembayaran</label><br>
                                <input type="file" accept=".jpg,.webp,image/*" id="input_file" @change="inputfile($event)" >
                            </div>
                            <small class="text-danger" v-if="checkimage">Anda belum Memasukkan Bukti Pembayaran</small>
                            <div class="modal-footer">
                                <div type="button" class="btn btn-secondary" @click="closemodal" data-bs-dismiss="modal">Batal</div>
                                <button @click="bayarbpd()" type="submit" class="btn btn-danger"><i class='bx bxs-save' ></i> Simpan</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div v-if="loadingSubmit" class="loading-submit-animation-container" >
                <div class="loading-submit-animation-content">
                    <div>
                        <div class="lds-facebook"><div></div><div></div><div></div></div>
                        <p>Mohon tunggu, data sedang diproses</p>
                    </div>
                </div>
            </div>
            <Modal
            :show="modal.showModal"
            :header="modal.header"
            :headerBorder="modal.headerBorder"
            :title="modal.title"
            :titleText="modal.titleText"
            :footer="modal.footer"
            :footerBtnPosition="modal.footerBtnPosition"
            :footerBorder="modal.footerBorder"
            :btnCancel="modal.btnCancel"
            :btnSubmit="modal.btnSubmit"
            :btnSubmitText="modal.btnSubmitText"
            :btnCancelText="modal.btnCancelText"
            :btnSubmitWidth="modal.btnSubmitWidth"
            :btnCancelWidth="modal.btnCancelWidth"
            @closeModal="closeModal"
            @submitModal="submitModal"
            >

            <div v-if="modalTipe === 'success'" class="modal-body">
                <div  class="text-center">
                <i class='bx bxs-check-circle ' style="font-size:72px; color:var(--primary-color)"></i>
                <div class="notif-text">
                    <h3>Selamat File Anda telah dikirim</h3>
                </div>
                <div class="text-right mt-2">
                    <div class="text-right mt-2">
                        <button @click="dataoke" class="btn btn-red">Oke</button>
                    </div>
                    
                </div>
                
                </div>
            </div>
            <div v-else-if="modalTipe === 'errorimagesize'" class="d-flex align-items-center justify-content-center text-center mt-2">
                <div>
                <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
                <h5>Ooppsss</h5>
                <p>Ukuran format Gambar anda lebih dari 2mb</p>
                </div>
            </div>
            <div v-else-if="modalTipe === 'errorformat'" class="d-flex align-items-center justify-content-center text-center mt-2">
                <div>
                <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
                <h5>Ooppsss</h5>
                <p>Format File Image Hanya berupa(.jpg, .png, .jpeg, .webp, .pdf)</p>
                </div>
            </div>
            <div v-else-if="modalTipe === 'errorbpd'" class="d-flex align-items-center justify-content-center text-center mt-2">
                <div>
                <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
                <h5>Ooppsss</h5>
                <p>{{massageerror}}</p>
                </div>
            </div>
            <div v-else-if="modalTipe === 'errorkosong'" class="d-flex align-items-center justify-content-center text-center mt-2">
                <div>
                <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
                <h5>Ooppsss</h5>
                <p>mohon untuk mengisi data terlebih dahulu</p>
                </div>
            </div>

        </Modal>
    </div>
</template>
<script>
import axios from 'axios'
import Modal from '../Modal.vue'
import moment from 'moment'
import SkeletonBpd from '../SkeletonUser/Example/BpdPayment.vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
    data() {
        return {
            loading:true,
            dataid:'detail_pesanan',
            transaksi_id: '',
            atasnama: '',
            no_rekening: '',
            namabank: '',
            datatransaksi:{},
            datauser:{},
            pembayaran:{},
            dataAddress: '',
            filepembayaran:null,
            modalTipe:'',
            modalform:false,
            loadingSubmit:false,
            massageadmin:'',
            modal:{
                showModal: false,
                header:false,
                headerBorder: false,
                title: false,
                titleText: '',
                footer: false,
                footerBtnPosition:'',
                footerBorder: false,
                btnCancel: false,
                btnSubmit: false,
                btnSubmitText: '',
                btnCancelText: '',
                btnSubmitWidth: '',
                btnCancelWidth: '',
            },
        };
    },
    components:{
      Modal,
      SkeletonBpd
    },
    setup () {
        return { v$: useVuelidate() }
    },
    validations() {
        return {
            atasnama: {
                required,
            },
            no_rekening: {
                required,
            },
            namabank: {
                required,
            },
        }
    },
    created(){

        this.transaksi_id = this.$router.currentRoute._value.query.id_transaksi
        axios.get('/transaction/'+this.transaksi_id).then(res => {
            this.datatransaksi = res.data.data.data
            if(this.user){
                this.datauser = this.user
                //  this.loading=false
            }
        })
        .catch(() =>{
            // this.loading=false
        })
        axios.get('/transaction/payment/detail/'+this.transaksi_id).then(res => {
            let data = res.data.data
            if(data){
                this.pembayaran = res.data.data
                this.datapayment = true
            }
            this.loading=false
       })
        
    },
    computed:{
        user: function() {
                return this.$store.state.user;
            },
    },
    watch:{
        user(value){
            if(!(value instanceof Promise) && value !== null){
             this.datauser = value
                 this.loading=false
            }
        }
    },
    methods: {
        dateTime(value) {
            return moment(value).format("DD MMMM YYYY");
        },
        dateandtime(value){
            return moment(value).format("DD MMMM YYYY hh:mm:ss");
        },
        bukamodal(){
        this.modalform=true
        },
        closemodal(){
            this.modalform=false
        },
        setModal(condition, tipe){
            this.resetDataModal()
            this.modal.showModal= condition
            condition == true ? this.modalTipe = tipe : ''
            if(tipe == 'success' && condition){
                this.modal.header=true
            }else if(tipe == 'errorimagesize' && condition){
                this.modal.header=true
                this.modal.footer= true
                this.modal.footerBtnPosition='right'
                this.modal.btnSubmit= true
                this.modal.btnSubmitText= 'Oke'
                this.modal.btnSubmitWidth='auto'
            }else if(tipe == 'errorformat' && condition){
                this.modal.header=true
                this.modal.footer= true
                this.modal.footerBtnPosition='right'
                this.modal.btnSubmit= true
                this.modal.btnSubmitText= 'Oke'
                this.modal.btnSubmitWidth='auto'
            }else if(tipe == 'errorbpd' && condition){
                this.modal.header=true
                this.modal.footer= true
                this.modal.footerBtnPosition='right'
                this.modal.btnSubmit= true
                this.modal.btnSubmitText= 'Oke'
                this.modal.btnSubmitWidth='auto'
            }
        },
        resetDataModal(){
            this.modal.showModal = false
            this.modal.header = false,
            this.modal.headerBorder = false
            this.modal.title = false
            this.modal.titleText = '' 
            this.modal.footer = false
            this.modal.footerBtnPosition =''
            this.modal.footerBorder = false
            this.modal.btnCancel = false
            this.modal.btnSubmit = false
            this.modal.btnSubmitText = ''
            this.modal.btnCancelText = ''
            this.modal.btnSubmitWidth = ''
            this.modal.btnCancelWidth = ''
        },
        closeModal(){
            this.setModal(false, this.modalTipe)
        },
        dataoke(){
            this.setModal(false, this.modalTipe)
            this.$router.push('/profil/detailtransaksi?id_transaksi='+this.transaksi_id)
        },
        submitModal(){
        this.setModal(false, '' , this.modalTipe)
        this.messageErrorModal = ''
        if(this.modalTipe == 'notifSukses'){
            this.addNegox()
        }

        },
        inputfile(event){
            let file = event.target.files[0];
            if (
            file["type"] === "image/jpeg" ||
            file["type"] === "image/jpg" ||
            file["type"] === "image/png" ||
            file["type"] === "image/webp" ||
            file["type"] === "application/pdf"
            ) {
                if(file["size"] <= 2048000){
                this.filepembayaran = event.target.files[0]
                }else{
                    this.setModal(true, 'errorimagesize')
                document.getElementById(`input_file`).value= null
                }
                
            } else {
                this.setModal(true, 'errorformat')
                document.getElementById(`input_file`).value= null
            }
        },
        bayarbpd(){
        this.v$.$touch()
        if(this.filepembayaran && !this.v$.$invalid){
            this.checkimage=false
            let transaksi_id = this.$router.currentRoute._value.query.id_transaksi
            let data = new FormData();
            data.append("id", transaksi_id);
            data.append("account_number", this.no_rekening);
            data.append("account_name", this.atasnama);
            data.append("bank_name", this.namabank);
            data.append("image", this.filepembayaran);
            this.modalform =false
            this.loadingSubmit =true
            axios.post(`/transaction/payment/proof-of-payment`, data)
            .then(() => {
                this.loadingSubmit =false
                this.setModal(true, 'success')
                this.v$.$reset()
            }).catch((e) => {
                this.loadingSubmit =false
                this.massageerror = e.response.data.message
                this.setModal(true, 'errorbpd')
            });
        }else{
            this.checkimage=true
        }

    },
    }
}
</script>

<style>
    .btn-upload{
        display: block;
        margin: 0 auto;
    }
    .left-content-bpdpaymet {
        background-color: var(--primary-color);
        border-radius: 10px 0px 0px 10px;
        color: #ffffff;
        padding: 30px;
    }
    .title-bpdpayment {
        font-size: 12px;
    }
    .subtitle-bpdpayment {
        font-size: 24px;
    }
    .right-content-bpdpaymet {
        background-color: #ffffff;
        border-radius: 0px 10px 10px 0px;
    }
    .logo-bpdpayment {
        width: 60px;
        height: auto;
    }
    .title-logo-bpdpayment {
        font-size: 26px;
        margin-top: 10px;
        margin-left: -20px;
    }
    .border-2 {
        border-width:2px !important;
    }
    .rounded-15 {
        border-radius: 15px;
    }
    .title-price-bpdpayment {
        font-size: 40px;
        color: var(--primary-color);
        margin-top: -10px;
    }
    .barcode-bpdpayment {
        width: 200px;
        height: auto;
    }
    .list-method-bpdpayment {
        list-style-type: decimal; 
        margin-left:15px;
    }
    .btn-back-bpdpayment {
        background-color: var(--primary-color);
        color: #FFFFFF;
    }
    @media (max-width: 1200px) { 
        .row-bpdpayment {
            margin: 0px 3px;
        }
        .subtitle-bpdpayment {
            font-size: 20px;
        }
    }
    @media (max-width: 992px) { 
        .title-logo-bpdpayment {
            font-size: 24px;
            margin-top: 10px;
        }
        .subtitle-bpdpayment {
            font-size: 16px;
        }
    }
    @media (max-width: 768px) { 
        .title-logo-bpdpayment {
            margin-top: 0px;
            margin-left: 0px;
            text-align: center;
        }
        .left-content-bpdpaymet {
            padding: 20px;
        }
        .subtitle-bpdpayment {
            font-size: 18px;
        }
    }
    @media (max-width: 576px) { 
        .left-content-bpdpaymet {
            border-radius: 10px 10px 0px 0px;
        }
        .right-content-bpdpaymet {
            border-radius: 0px 0px 10px 10px;
        }
    }
</style>