<template>
    <div>
        <form class="mt-3" @submit.prevent="simpanAlamat()">
            <div class="form-profile-info row">
                <input type="hidden" name="" v-model="customer_id">
                <div class="form-group col-sm-12 col-md-6 mb-3">
                    <label class="form-label">Nama Penerima <span class="text-danger">*</span></label>
                    <input type="text" class=" form-control input w-100" id="receipt_name" v-model="receipt_name"
                    :class="[v$.receipt_name.$error ? 'is-invalid': '']">
                    <span  v-if="v$.receipt_name.$error" class="text-danger font-small"> {{ v$.receipt_name.$errors[0].$message }}</span>
                </div>
                <div class="form-group col-sm-12 col-md-6 mb-3">
                    <label class="form-label">No Telepon <span class="text-danger">*</span></label>
                    <input type="text" id="receipt_phone_number" class="form-control input w-100" v-model="receipt_phone_number"
                    :class="[v$.receipt_phone_number.$error ? 'is-invalid': '']">
                    <span  v-if="v$.receipt_phone_number.$error" class="text-danger font-small"> {{ v$.receipt_phone_number.$errors[0].$message }}</span>
                </div>
                <div class="form-group col-12 mb-3">
                    <label class="form-label">Alamat <span class="text-danger">*</span></label>
                    <textarea class="form-control input w-100" id="address" v-model="address"
                    :class="[v$.address.$error ? 'is-invalid': '']"></textarea>
                    <span  v-if="v$.address.$error" class="text-danger font-small"> {{ v$.address.$errors[0].$message }}</span>

                </div>
                <div class="form-group col-12 col-md-6 mb-3">
                    <label class="form-label">Satker <span class="text-danger">*</span></label>
                    <input type="text" class="form-control input w-100" id="name" v-model="name"
                    :class="[v$.name.$error ? 'is-invalid': '']">
                    <span  v-if="v$.name.$error" class="text-danger font-small"> {{ v$.name.$errors[0].$message }}</span>
                </div>
                <div class="form-group col-sm-12 col-md-6 mb-3">
                    <label for="province_id">Provinsi <span class="text-danger">*</span></label>
                    <select id="province_id" class="form-control mt-1 input" v-model="province_id" @change="getWilayah('district',$event.target.value)"
                    :class="[v$.province_id.$error ? 'is-invalid': '']">
                        <option value="" disabled>Pilih Provinsi</option>
                        <option :value="provinsi.province_id" v-for="provinsi in provinces" :key="provinsi.province_id">{{provinsi.province_name}}</option>
                    </select>
                    <span  v-if="v$.province_id.$error" class="text-danger font-small"> {{ v$.province_id.$errors[0].$message }}</span>
                </div>
                <div class="form-group col-sm-12 col-md-6 mb-3">
                    <label for="district_id">Kabupaten <span class="text-danger">*</span></label>
                    <select id="district_id" class="form-control mt-1 input" v-model="district_id" @change="getWilayah('sub-district',$event.target.value)"
                    :class="[v$.district_id.$error ? 'is-invalid': '']">
                        <option value="" disabled>Pilih Kabupaten</option>
                        <option :value="district.city_id" v-for="district in districts" :key="district.city_id">{{district.city_name}}</option>
                    </select>
                    <span  v-if="v$.district_id.$error" class="text-danger font-small"> {{ v$.district_id.$errors[0].$message }}</span>
                </div>

                <div class="form-group col-sm-12 col-md-6 mb-3">
                    <label for="sub_district_id">Kecamatan <span class="text-danger">*</span></label>
                    <select id="sub_district_id" class="form-control mt-1 input" v-model="sub_district_id"
                    :class="[v$.sub_district_id.$error ? 'is-invalid': '']">
                        <option value="" disabled>Pilih Kecamatan</option>
                        <option :value="sub_district.subdistrict_id" v-for="sub_district in sub_districts" :key="sub_district.sub_districtid">{{sub_district.subdistrict_name}}</option>
                    </select>
                    <span  v-if="v$.sub_district_id.$error" class="text-danger font-small"> {{ v$.sub_district_id.$errors[0].$message }}</span>
                </div>
                <div class="col-12 text-right">
                    <router-link to="/profil/alamat" class="btn btn-warning">
                        <i class='bx bx-left-arrow-alt'></i> Kembali
                    </router-link>
                    <button type="submit" class="btn btn-red" style="margin-left:5px;"><i class='bx bxs-save'></i> Simpan</button>
                </div>
            </div>
        </form>

        <div v-if="loadingSubmit" class="loading-submit-animation-container" >
            <div class="loading-submit-animation-content">
                <div>
                    <div class="lds-facebook"><div></div><div></div><div></div></div>
                    <p>Mohon tunggu, data sedang diproses</p>
                </div>
            </div>
        </div>

        <div class="custom-toast" :class="toastShowed" >
            <div class="custom-toast-container">
                <div class="custom-toast-content">
                    <i class='bx bxs-check-circle mx-2'></i>
                    <p class="font-small m-0">Data berhasil tersimpan</p>
                </div>
                <a class="text-secondary" @click="setToast('hide-after')">
                    <i class='bx bx-x' ></i>
                </a>
            </div>
        </div>


    </div>
</template>

<script>
import axios from 'axios'
import useVuelidate from '@vuelidate/core'
import { required, helpers, numeric} from '@vuelidate/validators'

export default {
    name: 'FormUserAddress',
    setup () {
        return { v$: useVuelidate() }
    },
    data(){
        return {
            'customer_id': this.$store.state.user?.id,
            'receipt_name': '',
            'address': '',
            'name': '',
            'province_id': '',
            'district_id': '',
            'sub_district_id': '',
            'receipt_phone_number': '',
            'provinces':"",
            'districts':"",
            'sub_districts':"",
            'address_id':'',
            'loadingSubmit':false,
            'toastShowed': 'hide-before'
        }
    },
    validations() {
        return {
            receipt_name: {
                required: helpers.withMessage("Nama penerima tidak boleh kosong", required), 
            },
            address: {
                required: helpers.withMessage("Alamat tidak boleh kosong", required), 
            },
            name: {
                required: helpers.withMessage("Satuan kerja tidak boleh kosong", required), 
            },
            province_id: {
                required: helpers.withMessage("Silahkan pilih provinsi", required), 
            },
            district_id: {
                required: helpers.withMessage("Silahkan pilih kabupaten", required), 
            },
            sub_district_id: {
                required: helpers.withMessage("Silahkan pilih kecamatan", required), 
            },
            receipt_phone_number: {
                required: helpers.withMessage("No telepon tidak boleh kosong", required), 
                numeric: helpers.withMessage("Pastikan data yang diinput berupa angka", numeric)
            },
        }
    },
    components : {
    },
    created(){
    },
    methods: {
        getWilayah(wilayah, value){
            wilayah = 'customer/' +wilayah
            let cek_wilayah = wilayah;
            value ? wilayah += '/'+value : wilayah;
            axios.get(wilayah)
                .then(response => {
                    if(cek_wilayah == 'customer/province'){
                        this.provinces = response.data.data
                    }else if(cek_wilayah == 'customer/district'){
                        this.districts = response.data.data
                    }else if(cek_wilayah == 'customer/sub-district'){
                        this.sub_districts = response.data.data
                    }
                })
                .catch(() => {
                    // console.error("There was an error!", error);
                }); 
        },
        kembali(){
            this.$emit('kembali')
        },
        async simpanAlamat(){
            this.v$.$touch()
            if (!this.v$.$invalid) {
                let form = new FormData();
                form.append('customer_id', this.customer_id)
                form.append('receipt_name', this.receipt_name)
                form.append('address', this.address)
                form.append('name', this.name)
                form.append('province_id', this.province_id)
                form.append('district_id', this.district_id)
                form.append('sub_district_id', this.sub_district_id)
                form.append('receipt_phone_number', this.receipt_phone_number)
                this.loadingSubmit = true
                axios.post(`/customer/address/create`, form)
                .then(() => {
                    this.loadingSubmit = false
                    this.$store.dispatch('address').then(()=>{
                        this.$router.push('/profil/alamat')
                        this.loadingSubmit =  false
                        this.setToast()
                    })

                })
                .catch((e) => {
                    this.loadingSubmit = false
                    this.massageadmin = e.response.data.message
                    this.$swal("Ooops", e.response.data.message, "error", {
                        button: "OK"})
                    
                });

            }
            
        },
        resetForm(){
            this.receipt_name = "";
            this.receipt_phone_number ="";
            this.address = "";
            this.name = "";
            this.province_id = "";
            this.district_id =  "";
            this.sub_district_id =  "";
        },
        
        setToast(param = null){
            if(param == null){
                this.toastShowed = 'show'
                setTimeout(() => this.toastShowed = 'hide-after', 5000)
            }else{
                this.toastShowed = param
            }
        }
    },
    mounted(){
        this.getWilayah('province');
    },
    computed: {
    },
    watch: {
    }
    
}
</script>

<style>

</style>
