<template>
  <div class="home">

    <Banner
    :dataCategories="dataCategories"
    :banners="banners"
    ></Banner>
    
    <Product v-if="productTerlaris.length > 0">
      <div class="product-header mb-0">
        <h3 class="title">Produk Terlaris</h3>
        <router-link to="produk?sort=terlaris" class="product-link custom-link">
          Lihat semua <i class='bx bx-right-arrow-alt' ></i>
        </router-link>
      </div>

      <div class="bg-white">
        <swiper
        :slidesPerView="1"
        :centeredSlides="false"
        :slidesPerGroupSkip="1"
        :grabCursor="true"
        :keyboard="{
            enabled: true,
        }"
        :breakpoints="{
          '325': {
            slidesPerView: 2,
            slidesPerGroup: 1,
            },
            '530': {
            slidesPerView: 4,
            slidesPerGroup: 1,
            },
            '848': {
            slidesPerView: 5,
            slidesPerGroup: 1,
            },
            '992': {
            slidesPerView: 6,
            slidesPerGroup: 1,
            },
        }"
        :navigation="true"
        :modules="modules"
        class="product-home"
        >
        <swiper-slide class="product-list-home shadow-sm rounded-3 p-2"
            v-for="(terlaris, index) in productTerlaris" 
            :key="index" 
        >
          <product-list 
            :id="terlaris.id"
            :img="terlaris.image"
            :title="terlaris.name"
            :desc="terlaris.descriptions"
            :price="terlaris.price"
            :promo="terlaris.promo"
            :rating="terlaris.star_review"
            :status="terlaris.status"
            >
            </product-list>
          </swiper-slide>
          </swiper>
        </div>
    </Product>
    <BannerBottom
    :banners="banners"
    ></BannerBottom>
    <Product v-if="productPromo.length > 0">
      <div class="product-header mb-0">
        <h3 class="title">Produk Promo</h3>
        <router-link to="/produk/promo?page=1" class="product-link custom-link">
          Lihat semua <i class='bx bx-right-arrow-alt' ></i>
        </router-link>
      </div>

      <div class="bg-white">
        <swiper
        :slidesPerView="1"
        :centeredSlides="false"
        :slidesPerGroupSkip="1"
        :grabCursor="true"
        :keyboard="{
            enabled: true,
        }"
        :breakpoints="{
          '325': {
            slidesPerView: 2,
            slidesPerGroup: 1,
            },
            '400': {
            slidesPerView: 2,
            slidesPerGroup: 1,
            },
            '727': {
            slidesPerView: 3,
            slidesPerGroup: 1,
            },
            '848': {
            slidesPerView: 4,
            slidesPerGroup: 1,
            },
            '992': {
            slidesPerView: 6,
            slidesPerGroup: 1,
            },
        }"
        :navigation="true"
        :modules="modules"
        class="product-home"
        >
          <swiper-slide class="product-list-home shadow-sm rounded-3 p-2"
              v-for="(promo, index) in productPromo" 
              :key="promo" 
          >
          <product-list 
            :index="index"
            :id="promo.id"
            :img="promo.image"
            :title="promo.name"
            :desc="promo.descriptions"
            :price="promo.price"
            :promo="promo.promo"
            :rating="promo.star_review"
            :status="promo.status"
            >
            </product-list>
          </swiper-slide>
          </swiper>
      </div>
    </Product>
    <!-- Sudah Login -->
    <Product v-if="recProduct.length > 0">
      <div class="product-header mb-0">
        <h3 class="title">Produk Rekomendasi</h3>
        <router-link to="/produk?page=1" class="product-link custom-link">
          Lihat semua <i class='bx bx-right-arrow-alt' ></i>
        </router-link>
      </div>

      <div class="bg-white">
        <swiper
        :slidesPerView="1"
        :centeredSlides="false"
        :slidesPerGroupSkip="1"
        :grabCursor="true"
        :keyboard="{
            enabled: true,
        }"
        :breakpoints="{
          '325': {
            slidesPerView: 2,
            slidesPerGroup: 1,
            },
            '400': {
            slidesPerView: 2,
            slidesPerGroup: 1,
            },
            '700': {
            slidesPerView: 3,
            slidesPerGroup: 1,
            },
            '848': {
            slidesPerView: 4,
            slidesPerGroup: 1,
            },
            '992': {
            slidesPerView: 6,
            slidesPerGroup: 1,
            },
        }"
        :navigation="true"
        :modules="modules"
        class="product-home"
        >
        <swiper-slide v-for="(rekomendasi, index) in recProduct" 
          :key="index" class="product-list-home shadow-sm rounded-3 p-2">
          <product-list 
          :id="rekomendasi.id"
          :img="rekomendasi.image"
          :title="rekomendasi.name"
          :desc="rekomendasi.descriptions"
          :price="rekomendasi.price"
          :promo="rekomendasi.promo"
          :rating="rekomendasi.star_review"
          :status="rekomendasi.status"
          >
          </product-list>
        </swiper-slide>
      </swiper>
        </div>

        <!-- di komen karena di slider -->
        <!--<div class="button-muat-container">
           <button v-if="showMoreRekomendasi" class="btn btn-muat" @click="setProductRekomendasi('more')">Muat lebih banyak</button> 

        </div>-->
    </Product>
    <!-- Belum Login -->
    <!-- <Product v-if="rekomendasiNoAuth.length > 0 && !$cookies.isKey('token')">
      <div class="product-header mb-0">
        <h3 class="title">Produk Rekomendasi</h3>
        <router-link to="/produk/rekomendasi" class="product-link custom-link">
          Lihat semua <i class='bx bx-right-arrow-alt' ></i>
        </router-link>
      </div>

      <div class="bg-white">
        <swiper
        :slidesPerView="1"
        :centeredSlides="false"
        :slidesPerGroupSkip="1"
        :grabCursor="true"
        :keyboard="{
            enabled: true,
        }"
        :breakpoints="{
          '325': {
            slidesPerView: 2,
            slidesPerGroup: 1,
            },
            '400': {
            slidesPerView: 2,
            slidesPerGroup: 1,
            },
            '700': {
            slidesPerView: 3,
            slidesPerGroup: 1,
            },
            '848': {
            slidesPerView: 4,
            slidesPerGroup: 1,
            },
            '992': {
            slidesPerView: 6,
            slidesPerGroup: 1,
            },
        }"
        :navigation="true"
        :modules="modules"
        class="product-home"
        >
        <swiper-slide v-for="(rkm, index) in rekomendasiNoAuth" 
          :key="index" class="product-list-home shadow-sm rounded-3 p-2">
          <product-list 
          :id="rkm.id"
          :img="rkm.image"
          :title="rkm.name"
          :desc="rkm.descriptions"
          :price="rkm.price"
          :promo="rkm.promo"
          :rating="rkm.star_review"
          :status="rkm.status"
          >
          </product-list>
        </swiper-slide>
      </swiper>
        </div>
    </Product> -->
  </div>
</template>

<script>
import axios from 'axios'
import VueCookies from 'vue-cookies';

import Banner from '../components/Banner.vue'
import Product from '../components/Product.vue'
import ProductList from '../components/ProductList.vue'
import BannerBottom from '../components/BannerBottom.vue'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import "swiper/css";

import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Keyboard, Scrollbar, Navigation, Pagination } from "swiper";

export default {
  name: 'App',
  props:['dataCategories','dataSubCategories','dataAllProducts','banners'],
  setup() {
      return {
        modules: [Keyboard, Scrollbar, Navigation, Pagination],
      };
  },
  data(){
    return{
      dataProducts:{},
      productTerlaris:[],
      productPromo: [],
      recProduct: [],
      promo: false,
      user: '',
      loadRecProduct: false,
      productRekomendasi: [],
      rekomendasiNoAuth: [],
      fromSliceRekomendasi:0,
      totalSliceRekomendasi:10,
      toSliceRekomendasi:10,
      showMoreRekomendasi: true,
      baseUrl: process.env.VUE_APP_NAME_AXIOS
    }
  },
  components: {
    Banner,
    Product,
    ProductList,
    BannerBottom,
    Swiper,
    SwiperSlide,
  },
  methods: {
    setProductTerlaris(){
      let data_products = this.dataAllProducts
      let products_terlaris = data_products.sort(function(a, b) {
          return b.total_sold - a.total_sold;
      });
      products_terlaris = products_terlaris.slice(0, 6)
      this.productTerlaris = products_terlaris
      // console.log('terlaris: ', this.productTerlaris)
    },
    setProductRekomendasi(param = null){
      // let data_products = this.recProduct
      let data_products = this.dataAllProducts
      if(param == null){
        //dikomen karena data product di scrol kesamping
        // this.productRekomendasi = data_products.slice(0,VueCookies.get('sampaiTampilRekomendasi'))
        this.productRekomendasi = data_products.slice(0,6)
        // console.log('Rek Prod', this.productRekomendasi)
      }else{
        
        let fromSliceRekomendasi = parseInt(VueCookies.get('sampaiTampilRekomendasi')) > 0 ? parseInt(VueCookies.get('sampaiTampilRekomendasi')) : 0
        let toSliceRekomendasi = 0
        if((parseInt(data_products.length) - parseInt(VueCookies.get('sampaiTampilRekomendasi'))) >= parseInt(VueCookies.get('jumlahTampilRekomendasi'))){
          toSliceRekomendasi = parseInt(VueCookies.get('sampaiTampilRekomendasi')) + parseInt(VueCookies.get('jumlahTampilRekomendasi'))
        }else{
          toSliceRekomendasi = parseInt(VueCookies.get('sampaiTampilRekomendasi')) + (parseInt(data_products.length) - parseInt(VueCookies.get('sampaiTampilRekomendasi')))
        }

        this.$cookies.set('dariTampilRekomendasi', fromSliceRekomendasi);
        this.$cookies.set('sampaiTampilRekomendasi', toSliceRekomendasi);

        let data_baru = data_products.slice(parseInt(VueCookies.get('dariTampilRekomendasi')), parseInt(VueCookies.get('sampaiTampilRekomendasi')))
        data_baru.forEach(data => {
          this.productRekomendasi.push(data)
        });
      }

      if(this.productRekomendasi.length == this.dataAllProducts.length){
        this.showMoreRekomendasi = false
      }
    },
    async getProductPromo(){
      this.loadRecProduct = true
      this.promo = true
      let formdata = new FormData()
      let url = 'product/filter'
      let method = 'post'

      this.promo ? formdata.append('promo', this.promo) : this.promo = false
      try {
        const {data} = await axios({
            method: method,
            url: url,
            data: formdata
        });

        this.productPromo = data.data.products.slice(0, 6)
      } catch (error) {
        console.log(error)
      }
      // axios.get('product/promo')
      // .then(res => {
      //   this.productPromo = res.data.data
      //   if(this.productPromo.length > 0){
      //     this.productPromo = this.productPromo.slice(0, 6)
      //   }
      //   // console.log('Promo', this.productPromo);
      // })
      // .catch(() => {
      //   // console.log(err)
      // })
    },
    getRekomendasiNotAuth(){
      axios.get(`product/recomendation`)
      .then(res => {
        this.rekomendasiNoAuth = res.data.data
        if(this.rekomendasiNoAuth.length > 0){
          this.rekomendasiNoAuth = this.rekomendasiNoAuth.slice(0, 6)
        }
        // console.log('rekomend: ', res.data.data);
      }).catch(() => {
        // console.log(err.response);
      })
    },
    getRekomendasi(){
      
      axios.get(`product?limit=6`)
      // axios.get(`product/customer/recomendation`)
      .then(res => {
        
        this.recProduct = res.data.data
        this.recProduct = this.recProduct.slice(0, 6)
        // if(this.newPRecomendation){
        //   this.newPRecomendation = this.newPRecomendation.slice(0, 6)
          // console.log('rek prod: ', this.recProduct);
        // }
        setTimeout(() => {
          this.loadRecProduct = false
        },2000)
          
      }).catch(() => {
        this.loadRecProduct = true
        // console.log(err.response);
      })
    },
    getUser(){
      axios.get(`user`)
      .then(res => {
        // console.log('user: ', res);
          this.user = res.data.data
      }).catch(() => {
        // console.log(err.response);
        // if (err.response.data.message == 'Unauthenticated.'){
        //   this.$cookies.remove('token')
        //   // this.$router.push('/')
        // }
      })
    },
    autoLogout(){
      axios.interceptors.response.use( (response) => {
            // Return a successful response back to the calling service
            return response;
        }, (error) => {
            // console.log('response error', error.response)
            if (error.response.status == 401) {
              if (error.response.data.message == "Unauthenticated.") {
                  // Hapus token di cookie
                  this.$cookies.remove('token')
                  this.$store.dispatch('cart')
                  // Redirect Home atau login
                  this.$router.push('/login')
              }
          }

          return Promise.reject(error.response);
        })
    }
  },
  mounted(){
    this.$store.dispatch('products')
    if(this.$cookies.get('token')){
      this.getUser()
    }
    this.autoLogout()
    this.getProductPromo()
    // this.getRekomendasiNotAuth()
    this.getRekomendasi()
    // this.setProductTerlaris()
    // console.log('user: ', this.user());
    if(this.dataAllProducts != ''){
      this.setProductTerlaris()
      this.setProductRekomendasi()
      // console.log('terlaris: ', this.productTerlaris)
      // this.getRekomendasi()
    }

  },
  created(){

  },
  watch: {
    dataAllProducts:{
      deep: true,
      handler: function(){
        // this.getUser()
        // this.autoLogout()
        this.dataAllProducts
        // this.setProductTerlaris()
        if(this.dataAllProducts != ''){
          this.setProductTerlaris()
          this.setProductRekomendasi()
          this.getRekomendasi()
        }
      }
    },
    // user:{
    //   deep: true,
    //   handler: function(){
    //     this.getUser()
    //     // this.autoLogout()
    //   }
    // },
  }
}
</script>
<style scoped>
.product-list-home {
  border: 1px solid #FFF;
}
</style>

