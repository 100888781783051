<template>
    <div>
        <div class="container-custom">
            <div class="data-left">
                <img :src="baseUrl+'storage/'+image" alt="">
            </div>
            <div class="data-right">
                <div class="isi-data-right">
                    <router-link to="/" class="d-block text-center font-bold custom-link logo-container auth-logo-container" style="font-size:24px;">
                        <!-- <img :src="require(`../../assets/logo-armed.png`)" alt="" class="logo"> -->
                        <logo></logo>
                    </router-link>

                    <h1 class="mt-3">Lupa Kata Sandi?</h1>
                    <small>Silahkan masukkan alamat email Anda yang sudah terdaftar di Kantorqu.</small>
                    <form @submit.prevent="lupaPassword" class="mt-xl-5">
                        <div class="form-group mt-4">
                            <label for="email">Email Anda</label>
                            <input type="text" class="form-control mt-2 radius" id="email" placeholder="Email" v-model="email"
                            :class="[v$.email.$error ? 'is-invalid' : '']"
                            >
                            <span v-if="v$.email.$error " class="text-danger font-small">{{v$.email.$errors[0].$message }}</span>

                        </div>
                        <div v-if="pesan_sukses != ''" class="alert alert-success alert-dismissible fade show mt-4"  role="alert">
                            <strong>Berhasil ! </strong> 
                            <span>{{pesan_sukses}}</span>
                        </div>

                        <div v-if="errorMessage != ''" class="alert alert-warning alert-dismissible fade show mt-4"  role="alert">
                            <strong>Peringatan ! </strong> 
                            <span>{{errorMessage}}</span>
                        </div>
                        <div class="form-group mt-4 btnlink">
                            <button type="submit" class="btn btn-red mt-md-3 btn-radius">Reset Kata Sandi</button>
                            <router-link to="/login" class="btn-data-login text-red mt-md-3">Kembali Ke Menu Login</router-link>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div v-if="loadingSubmit" class="loading-submit-animation-container" >
            <div class="loading-submit-animation-content">
                <div>
                    <div class="lds-facebook"><div></div><div></div><div></div></div>
                    <p>Mohon tunggu, data sedang diproses</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import useVuelidate from '@vuelidate/core'
import { required, email, helpers   } from '@vuelidate/validators'
import Logo from '@/components/Logo.vue'

export default {
  name: 'forgot-password-component',
  props:['banners'],
    setup () {
        return { v$: useVuelidate() }
    },
    components: {
        Logo
    },
    validations() {
        return {
            email: {
                required: helpers.withMessage("Email tidak boleh kosong", required),
                email: helpers.withMessage("Format email salah", email)
            }
        }
    },
  data(){
      return{
        'email' : '',
        'errorMessage':'',
        'pesan_sukses':'',
        loadingSubmit: false,
        image: {},
        baseUrl: process.env.VUE_APP_NAME_AXIOS
      }
  },
  mounted(){
    this.setImage()
  },
  methods: {
    lupaPassword(){
        this.v$.$validate()

        let data = new FormData()
        let url_reset_pass = window.location.origin + '/reset-password' 
        data.append('email', this.email)
        this.pesan_sukses = ''
        this.errorMessage = ''

        if ( !this.v$.$error) {
            this.loadingSubmit =  true
            axios.post('customer/forget-password?redirect_url='+url_reset_pass, data)
            .then(() => {
                // console.log(response)
               
                this.pesan_sukses = 'Silahkan periksa email Anda'
                // this.resetForm()
                this.loadingSubmit =  false
            })
            .catch(error => {
                let val = Object.values(error.response.data)
                let key = Object.keys(error.response.data)
                for(let j = 0; j < key.length; j++){
                    if(key[j] == 'message'){
                        if(val[j] != ''){
                            this.errorMessage = val[j] 
                        }
                    }
                }
                this.loadingSubmit =  false
                let data_error = Object.entries(error.response.data.data)
                for(let i = 0; i < data_error.length; i++){
                    this.errors[data_error[i][0]] = data_error[i][1][0];
                }
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }); 
        }
    },
    resetForm(){
        this.email = "";
    },
    setImage(){
        if(this.banners != null){
            for(let i = 0; i < Object.keys(this.banners).length; i++){
                if(this.banners[i].position === 'Forget Password Page'){
                    this.image = this.banners[i].image
                    // console.log(this.baseUrl+this.loginImage);
                }
            }
            this.loadingMainBanner = false
        }
    },
  },
  watch:{
        banners:{
            deep: true,
            handler: function(){
                this.setImage()
                //           :autoplay='{
                // "delay": 2500,
                // "disableOnInteraction": false
                // }'
            }
        },
    }
}
</script>

<style scoped>
.container-custom{
    display: flex;
    flex-direction: column;
}
.data-left{
    height: 100vh;
    position: relative;
    display: none;
}
.data-left img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.data-left::after{
    content:'';
    width: 100%;
    min-height: 100vh;
    height: 100%;
    /* background-image: url("../../assets/img/abby-anaday-Z3fXPuxa15k-unsplash.webp"); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    position: fixed;
    top:0;
    left: 0;
}
.data-right{
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:70px 20px;
    position: relative;
    background: white;
}

.data-left,
.data-right{
    width: 100%;
}

.isi-data-right small{
    color: var(--color-light);
}
.radius{
    border-radius: 25px;
    padding: 0.7rem 1.5rem
}
.btn-radius{
    border-radius: 25px;
    /* padding-left: 15px; */
    padding: 0.7rem 1.5rem
}
.btnlink{
    display: flex;
}
.btn-data-login{
    padding: 0.7rem 1.5rem;
    text-decoration:none;
}
.btn-data-login:hover{
    color: black;
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
    .container-custom{
        flex-direction: row;
    }
    .data-left,
    .data-right{
        width: 50%;
    }

    .data-left{
        display: block;
    }
}

@media (min-width: 1023px) {
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>