<template>
    <div class="">
            <!-- <pre>{{filteredData}}</pre> -->
            <!-- <pre>{{curentpage}}</pre> -->
            <div class="header-table">
                <div class="header-table-left">
                  <label for="select_leght">
                    <span>Show </span>
                    <select
                      name="entries_data"
                      id="entries_data"
                      class="entries_select border rounded p-1"
                      v-model="jmlh_value"
                      v-on:change="entries_data(1), btn_paginate()"
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>

                    <span> entries</span>
                  </label>
                </div>
                <div class="header-table-right">
                  <div class="input-group search-group mb-3">
                    <input
                      v-model="search"
                      type="text"
                      class="form-control"
                      placeholder="Search"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                    <div class="input-group-append tombol-search">
                      <span class="input-group-text h-100" id="basic-addon2"
                        ><i class="bx bx-search"></i
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            <div class="data-table">
              <table>
                <tr>
                  <th>No</th>
                  <th>ID Pembelian</th>
                  <th>Tanggal</th>
                  <th>Status</th>
                  <th>Aksi</th>
                </tr>
                <tbody v-if="loading === true">
                    <tr>
                        <td colspan="4">
                            <div class="d-flex justify-content-center">
                                <div class="lds-facebook"><div></div><div></div><div></div></div>
                            </div>
                        </td>
                    </tr> 
                </tbody>
                <tbody v-else-if="filteredData.length <= 0">
                    <tr>
                        <td colspan="5" style="text-align: center;">Data tidak ditemukan</td>
                    </tr> 
                </tbody>
                <tbody v-else-if="filteredData.length > 0">
                  <tr v-for="(datasub, index) in filteredData" :key="datasub.id">
                    <td class="">
                      {{ index + 1 + jmlh_value * curentpage }}
                    </td>
                    <td class="">
                      {{ datasub.invoice }}
                    </td>
                    <td>
                      <h6>{{ dateTime(datasub.created_at) }}</h6>
                    </td>
                    <td>
                      <h6 class="badge bg-warning" v-if="toLowerCase(datasub.status) == 'pending_payment'">Pending Payment</h6>
                      <h6 class="badge bg-warning" v-else-if="toLowerCase(datasub.status) == 'payment_expired'">Payment expired</h6>
                      <h6 class="badge bg-secondary text-white" v-else-if="toLowerCase(datasub.status) == 'partial delivery'">Partial Delivery</h6>
                      <h6 class="badge bg-primary text-white" v-else-if="toLowerCase(datasub.status) == 'payment_process'">Payment Prosess</h6>
                      <h6 class="badge bg-danger text-white" v-else-if="toLowerCase(datasub.status) == 'payment_failed'">Payment Failed</h6>
                      <h6 class="badge" v-else 
                      :class="{ 'bg-warning': toLowerCase(datasub.status) == 'pending',
                                'bg-success text-white': toLowerCase(datasub.status) == 'paid' || toLowerCase(datasub.status) == 'confirmed',
                                'bg-primary text-white': toLowerCase(datasub.status) == 'process',
                                'bg-kuning text-white': toLowerCase(datasub.status) == 'document',
                                'bg-secondary text-white': toLowerCase(datasub.status) == 'shipped' ,
                                'bg-danger text-white': toLowerCase(datasub.status) == 'cancel' }">{{datasub.status }}</h6>
                    </td>
                    <td>
                      <div class="d-flex">
                        <router-link class="btn btn-sm btn-red mx-2" :to="{ path: '/profil/detailtransaksi', query: { id_transaksi: datasub.id }}"><i class='bx bx-detail'></i> Detail</router-link>
                        <!-- <a class="btn btn-sm btn-light" @click="showModal(datasub.id)" data-bs-toggle="modal" data-bs-target="#modalhapus">Hapus</a> -->
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style="display-flex">
              <button class="btn btn-outline-secondary p-1 px-2 rounded-0" @click="btnpage(-1)">
                <i class="bx bx-chevron-left"></i>
              </button>
              <button v-if="(curentpage+1) ==initPagination && (curentpage-1) > 0" class="btn btn-outline-secondary p-1 px-2 rounded-0" @click="btnpage(-2)">
                {{curentpage-1}}
              </button>
              <button v-if="curentpage >0" class="btn btn-outline-secondary p-1 px-2 rounded-0" @click="btnpage(-1)">
                {{curentpage}}
              </button>
              <button 
                class="btn btn-secondary p-1 px-3 rounded-0"
              >
                {{ curentpage+1 }}
              </button>
              <button v-if="initPagination>1 && (curentpage+1) < initPagination" class="btn btn-outline-secondary p-1 px-2 rounded-0" @click="btnpage(+1)">
                {{curentpage+2}}
              </button>
              <button class="btn btn-outline-secondary p-1 px-2 rounded-0" @click="btnpage(+1)">
                <i class="bx bx-chevron-right"></i>
              </button>
            </div>
      </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
// import DashboardUser from '../../components/DashboardUser.vue'
// import FormProfile from '../../components/FormProfile.vue'

export default {
  name: "profile-data-component",
  data() {
    return {
      loading:true,
      isisearch: "",
      datalist: [],
      jmlh_value: 5,
      curentpage: 0,
      listpage: null,
      selectedData: [],
      search: "",
      key: "",
      datapage: 0,
    };
  },
  watch: {
    search(value) {
      this.isisearch = value;
      this.curentpage = 0
    },
    user(){
      axios.get('/transaction/list-transaction/'+this.$store.state.user.id).then(res => {
          this.datalist = res.data.data
          this.loading=false
      })
      .catch(() =>{
        // this.loading=false
      })
    }

  },
  computed: {
    user: function() {
        return this.$store.state.user;
    },
    initPagination() {
      return Math.ceil(this.searchData.length / this.jmlh_value);
    },
    searchData: function () {
      return this.datalist
      .filter((data) => {
        return (
          data.invoice.toLowerCase().includes(this.search.toLowerCase()) ||
          data.status.toLowerCase().includes(this.search.toLowerCase()) ||
          data.created_at.toLowerCase().includes(this.search.toLowerCase())
        );
      })
    },
    Datasort: function(){
      return this.searchData.slice().sort( ( a, b) => {
            return new Date(b.created_at)- new Date(a.created_at);
        });
    },
    filteredData: function () {
      let pageStart = this.curentpage * this.jmlh_value;
      let pageEnd = this.jmlh_value * (this.curentpage * 1 + 1);

      return this.Datasort.filter((item, index) => {
        return index >= pageStart && index < pageEnd;
      });
    },
  },
  created(){
          if(this.$store.state.user){
            axios.get('/transaction/list-transaction/'+this.$store.state.user.id).then(res => {
               this.datalist = res.data.data
               this.loading=false
            })
            .catch(() =>{
              // this.loading=false
            })
          }
  },
  mounted() {
      
  },
  methods: {
    toLowerCase(value){
      return value.toLowerCase()
    },
    dateTime(value) {
        return moment(value).format("DD MMMM YYYY");
    },
    sendBroker(databroker) {
      this.selectedData = databroker;
    },
    async entries_data(value) {
      this.curentpage = value - 1;
    },
    btnpage(value) {
        let datapage = this.initPagination
            
        if(this.curentpage <= 0 && value == -1){
            this.curentpage = 0
        } else if (this.curentpage <= 0 && value == -2) {
            this.curentpage = 0
        } else if (this.curentpage >= 0 && this.curentpage < (datapage - 1)) {
            this.curentpage += value  
        } else if (this.curentpage >= 0 && this.curentpage <= datapage - 1 && value == -1) {
            this.curentpage += value  
        } else if (this.curentpage >= 0 && this.curentpage <= datapage - 1 && value == -2) {
            this.curentpage += value  
        }  

    },
    firstData(data) {
      this.datalist = [];
      for (let i = 0; i < 10; i++) {
        if (data.length == i) {
          break;
        }
        let dataval = data[i];
        this.datalist.push(dataval);
      }

    },
  },
};
</script>

<style scoped>
.bg-kuning{
  background-color: #F7D716;
  color: #fff !important;
}
.search-group {
  border: solid 1px #ced4da;
  background-color: #fff;
  border-radius: 20px;
  max-width: 15rem;
}
.search-group input {
  background-color: transparent;
  border: 0px;
}
.tombol-search span {
  background-color: transparent;
  border: 0px;
}
.header-table{
  display: grid;
}
.header-table-left{
  margin-bottom: 1rem;
}
.content-wrapper .content {
  padding: 0 0.5rem;
}
.head_subs{
    display: flex;
}
.collapse .card{
  background-color: transparent !important;
  box-shadow: none !important;
}
.collapsing .card{
  background-color: transparent !important;
  box-shadow: none !important;
}
.data-table{
  overflow-x: scroll;
}
.table-data{
    overflow: auto;
}
.table > tbody > tr > td {
  vertical-align: center !important;
  background-color: transparent !important;
  --bs-table-accent-bg: transparent !important;
  vertical-align: center;
  line-height: 50px;
  justify-content: center;
  padding: 15px 15px;
}
.table > tbody > tr > td h6{
    padding-top: 10px;
}
.table > tbody > tr > td h5{
    padding-top: 5px;
}
.panjang-td-table figure{
  width: 70px;
  height: 70px;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  margin: 0px;
}
.panjang-td-table img{
  height: 100%;
}
.dalam-td{
    padding-left: 20px;
}
.dalam-td p{
    margin-bottom: 0px;
    color: var(--color-light);
}
.status{
  display: inline-block;
  padding: 2px 3px;
  border-radius: 2px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
  .content-wrapper .content {
    padding: 0 0.5rem;
  }
  .nav-subcription {
    display: flex;
    justify-content: space-between;
  }
  .header-subcription{
  display: flex;
  justify-content: space-between;
  }
  .user-investasi::before {
    content: " ";
    position: absolute;
    width: 150px;
    right: 10%;
    top: 40px;
    height: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    /* background-image: url("../assets/image/team.svg"); */
  }
  .data-subcription-user{
  padding-left: 20px;
  }
  .paginate_button{
      padding: 2px !important;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button{
      padding: 2px !important;
  }
  .header-table{
    justify-content: space-between;
    display: flex;
  }
  .header-table-left{
    margin-bottom: 0rem;
  }
}

@media (min-width: 1023px) {
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>
