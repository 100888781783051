<template>
    <div>
        <skeleton-pesanan v-if="loading"></skeleton-pesanan>
        <div v-if="!loading">
            <div class="container-fluid px-0 px-md-2 mt-4">
                <div v-if="toLowerCase(datatransaksi.status) == 'confirmed' && this.datanego.length <= 0" class="alert alert-warning alert-dismissible fade show mt-4"  role="alert">
                    <strong>Pemberitahuan ! </strong> 
                    <span> Silahkan scroll kebawah untuk konfirmasi pesanan anda</span>
                </div>
                <span><b>Tanggal</b> : {{dateTime(datatransaksi.created_at)}}</span><br>
                <span><b>ID Pesanan</b> : {{datatransaksi.invoice}}</span><br>
                <span><b>Status</b> : <span class="badge text-light" :class="{ 'bg-warning': toLowerCase(datatransaksi.status) == 'pending' || toLowerCase(datatransaksi.status) == 'pending_payment' || toLowerCase(datatransaksi.status) == 'payment_expired',
                        'bg-success': toLowerCase(datatransaksi.status) == 'paid' || toLowerCase(datatransaksi.status) == 'confirmed',
                        'bg-kuning': toLowerCase(datatransaksi.status) == 'document',
                        'bg-primary': toLowerCase(datatransaksi.status) == 'process' || toLowerCase(datatransaksi.status) == 'payment_process' ,
                        'bg-secondary': toLowerCase(datatransaksi.status) == 'shipped' || toLowerCase(datatransaksi.status) == 'partial delivery',
                        'bg-danger': toLowerCase(datatransaksi.status) == 'cancel' || toLowerCase(datatransaksi.status) == 'payment_failed'
                        }">{{datatransaksi.status}}</span></span>
                <div class="confirmation-order-info-container web-view">
                    <div class="confirmation-order-user border border-4 border-light rounded-lg px-2 py-2 px-md-4 py-md-3">
                        <div class="">
                            <h5>Informasi User</h5>
                            <hr>
                            <table class="table-no-border informationTable">
                                <tr>
                                    <td><b>Nama Instansi</b></td>
                                    <td>: {{user?.instance_name}}</td>
                                </tr>
                                <tr>
                                    <td><b>Email</b></td>
                                    <td>: {{user?.email}}</td>
                                </tr>
                                <tr>
                                    <td><b>No Telepon</b></td>
                                    <td>: {{user?.phone}}</td>
                                </tr>
                                <tr>
                                    <td><b>Alamat</b></td>
                                    <td>: {{datatransaksi?.customer_address?.address}}, {{datatransaksi?.customer_address?.sub_district}}, {{datatransaksi?.customer_address?.district}}, {{datatransaksi?.customer_address?.province}} {{datatransaksi?.customer_address?.postal_code}}</td>
                                    
                                </tr>
                                <tr>
                                    <td><b>NIP</b></td>
                                    <td>: {{user?.nip}}</td>
                                </tr>
                                <tr>
                                    <td><b>Nama PP</b></td>
                                    <td>: {{user?.name_pp}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="confirmation-order-ekspedisi border border-4 border-light rounded-lg px-2 py-2 px-md-4 py-md-3">
                        <div class="">
                            <h5>Ekspedisi Pengiriman</h5>
                            <hr>
                            <table class="table-no-border informationTable">
                                <tr>
                                    <td><b>Ekspedisi</b></td>
                                    <td>: {{ dataaddress.shipping_courier }}</td>
                                </tr>
                                <tr>
                                    <td><b>Layanan</b></td>
                                    <td>: {{dataaddress?.shipping_service}}</td>
                                </tr>
                                <tr>
                                    <td><b>Nama Penerima</b></td>
                                    <td>: {{dataaddress?.name_receipt}}</td>
                                </tr>
                                <tr>
                                    <td><b>Nomor Penerima</b></td>
                                    <td>: {{dataaddress?.phone_receipt}}</td>
                                </tr>
                                <tr>
                                    <td><b>Alamat Pengiriman</b></td>
                                    <td style="overflow-x:auto;">:
                                    {{dataaddress.address}},{{dataaddress.sub_district}},{{dataaddress.district}}
                                    {{dataaddress.province}},{{dataaddress.postal_code}}</td>
                                </tr>
                            </table>
                            <!-- <p>Ekspedisi: <b>{{dataaddress.shipping_courier}}</b></p>
                            <p>Alamat Pengirim:
                                <br>
                                <b>
                                    {{dataaddress.name_receipt}} <br/>
                                    {{dataaddress.address}},{{dataaddress.sub_district}},{{dataaddress.district}}<br/>
                                    {{dataaddress.province}},{{dataaddress.postal_code}}
                                </b>
                            </p> -->
                            <div class="transaksi-footer-bottom">
                                <!-- <router-link to="/oke" class="btn btn-danger float-right">Nego Ongkir</router-link> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="confirmation-order-info-container mobile-view">
                    <div class="confirmation-order-user border border-4 border-light rounded-lg px-3 py-3 px-md-4 py-md-3">
                        <div class="">
                            <h5>Informasi User</h5>
                            <hr>
                            <div class="table-no-border informationTable">
                                <p>
                                    <b>Nama Instansi :</b><br>
                                    {{user?.instance_name}}
                                </p>
                                <p>
                                    <b>Email :</b><br>
                                    {{user?.email}}
                                </p>
                                <p>
                                    <b>No Telepon :</b> <br>
                                    {{user?.phone}}
                                </p>
                                <p>
                                    <b>Alamat :</b><br>
                                    {{datatransaksi?.customer_address?.address}}, {{datatransaksi?.customer_address?.sub_district}}, {{datatransaksi?.customer_address?.district}}, {{datatransaksi?.customer_address?.province}} {{datatransaksi?.customer_address?.postal_code}}
                                </p>
                                <p>
                                    <b>NIP :</b><br>
                                    {{user?.nip}}
                                </p>
                                <p>
                                    <b>Nama PP :</b><br>
                                    {{user?.name_pp}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="confirmation-order-ekspedisi mt-3 border border-4 border-light rounded-lg px-3 py-3 px-md-4 py-md-3">
                        <div class="">
                            <h5>Ekspedisi Pengiriman</h5>
                            <hr>
                            <div class="table-no-border informationTable">
                                <p>
                                    <b>Ekspedisi :</b><br>
                                    {{ dataaddress.shipping_courier }}
                                </p>
                                <p>
                                    <b>Layanan :</b><br>
                                    {{dataaddress?.shipping_service}}
                                </p>
                                <p>
                                    <b>Nama Penerima :</b><br>
                                    {{dataaddress?.name_receipt}}
                                </p>
                                <p>
                                    <b>Nomor Penerima :</b><br>
                                    {{dataaddress?.phone_receipt}}
                                </p>
                                <div class="w-100">
                                    <p>
                                        <b>Alamat Pengiriman :</b><br>
                                        {{dataaddress.address}} ,{{dataaddress.sub_district}}, {{dataaddress.district}}
                                        {{dataaddress.province}},{{dataaddress.postal_code}}
                                    </p>
                                </div>
                            </div>
                            <!-- <p>Ekspedisi: <b>{{dataaddress.shipping_courier}}</b></p>
                            <p>Alamat Pengirim:
                                <br>
                                <b>
                                    {{dataaddress.name_receipt}} <br/>
                                    {{dataaddress.address}},{{dataaddress.sub_district}},{{dataaddress.district}}<br/>
                                    {{dataaddress.province}},{{dataaddress.postal_code}}
                                </b>
                            </p> -->
                            <div class="transaksi-footer-bottom">
                                <!-- <router-link to="/oke" class="btn btn-danger float-right">Nego Ongkir</router-link> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid mt-3" >
                <div class="background-white">
                    <div v-for="(produk, index) in datatransaksi.transaction_detail" 
                    :key="index">
                        <daftar-produk @updatenegoharga="updatenegoharga"
                        :nama_produk="produk?.name_product"
                        :dataindex="index"
                        :id="produk?.id"
                        :image_produk="$imgProductUrl+produk?.image"
                        :unit="produk?.qty"
                        :harga="produk?.price"
                        :harga_diskon="produk?.price_after_discount"
                        :subtotal="produk?.subtotal"
                        :subtotal_nego="produk?.subtotal_nego"
                        :promo="produk?.discount"
                        :catatandata="produk?.notes"
                        :price_nego="produk?.price_nego"
                        :is_partial="produk?.is_partial"
                        :shipping="produk?.shipping"></daftar-produk>
                    </div>
                    <!-- <div class="mt-3 p-3 data-ongkir" style="overflow-x:scroll;">
                        <h5 class="mt-3">Pengiriman Partial</h5>
                        <div v-for="(partialongkir,dataindex) in produk.shipping" :key="dataindex">
                            <table class="mt-2 data-tabel" >
                                <thead>
                                    <tr style="border:0px !important;">
                                        <th style="min-width:80px;">jumlah</th>
                                        <th style="min-width:130px;">Harga Ongkir</th>
                                        <th style="min-width:180px;" colspan="2">Nego Harga Ongkir</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style="border:0px !important;" >
                                        <td>{{datatransaksi.total_qty}} Unit</td>
                                        <td>Rp. {{datatransaksi.shipping_cost}}</td>
                                        <td>
                                            <input class="form-control" type="text" v-model="negoongkir" name="nego-harga">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="w-100 mt-3" style="display: flow-root;">
                                <a @click="negoongkirdata()" class="btn btn-danger float-right" v-if="datatransaksi.is_nego">Ajukan Negosiasi Harga Ongkir</a>
                            </div>
                        </div>
                    </div> -->
                </div>
                <h5 class="mt-2">Ringkasan Belanja</h5>
                    <div class="mt-3 border border-2 border-light rounded-lg">
                        <div class="ringkasan-belanja-container data-total-harga">
                            <table class="table-data-belanja border-0">
                                <tr>
                                    <th class="border-0 p-1" >Total Harga ({{datatransaksi?.total_qty}} Barang) </th>
                                    <td class="border-0 p-1" >Rp. {{ datatransaksi?.total_price_nego != null ? new Intl.NumberFormat('id-ID').format(Number(datatransaksi?.total_price_nego).toFixed()) : new Intl.NumberFormat('id-ID').format(Number(datatransaksi?.total_price).toFixed()) }}</td>
                                </tr>
                                <tr>
                                    <th class="border-0 p-1">Total Ongkir</th>
                                    <td class="border-0 p-1" v-if="datatransaksi.shipping_cost_nego">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(datatransaksi?.shipping_cost_nego).toFixed()) }}</td>
                                    <td class="border-0 p-1" v-else-if="!datatransaksi.shipping_cost_nego">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(datatransaksi?.shipping_cost).toFixed()) }}</td>
                                </tr>
                                <tr>
                                    <th class="border-0 p-1">Total PPN</th>
                                    <td class="border-0 p-1">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(datatransaksi?.total_ppn).toFixed())  }}</td>
                                </tr>
                                <tr class="total-belanja view-table">
                                    <th class="border-0 p-1 tothar" >Total Harga</th>
                                    <th class="border-0 p-1 harga-total" >Rp. {{ new Intl.NumberFormat('id-ID').format(Number(datatransaksi?.grand_total).toFixed())  }}</th>
                                </tr>
                            </table>
                            <div class="view-div mt-3">
                                <h6><b>Total Harga</b></h6>
                                <h6><b>Rp. {{ new Intl.NumberFormat('id-ID').format(Number(datatransaksi?.grand_total).toFixed())  }}</b></h6>
                            </div>
                        </div>
                    <!-- <div class="ringkasan w-100">
                        <p v-if="datatransaksi.is_nego">Total Nego Harga({{datatransaksi?.total_qty}} Barang)</p>
                        <p v-if="!datatransaksi.is_nego">Total Harga({{datatransaksi?.total_qty}} Barang)</p>
                        <p><b>Rp. {{datatransaksi?.total_price}}</b></p>
                    </div>
                    <div class="ringkasan w-100">
                        <p v-if="datatransaksi.is_nego">Total Nego Ongkir</p>
                        <p v-if="datatransaksi.is_nego"><b>Rp. {{datatransaksi?.shipping_cost_nego}}</b></p>
                        <p v-if="!datatransaksi.is_nego">Total Ongkir</p>
                        <p v-if="!datatransaksi.is_nego"><b>Rp. {{datatransaksi?.shipping_cost}}</b></p>
                    </div>
                    <div class="ringkasan w-100">
                        <p>Total PPN</p>
                        <p><b>Rp. 30.000</b></p>
                    </div>
                    <div class="total-ringkasan w-100">
                        <h3 v-if="datatransaksi.is_nego">Total Nego Tagihan</h3>
                        <h3 v-if="!datatransaksi.is_nego">Total Tagihan</h3>
                        <h3>Rp. {{datatransaksi?.grand_total}}</h3>
                    </div> -->
                </div>
                <!-- <div v-if="datatransaksi.is_nego"> -->
                    <!-- <div class="form-group">
                        <label for="status">Status</label>
                        <input type="text" class="form-control mt-2" id="status" aria-describedby="status" placeholder="Status" :value="'Nego Tahap '+datatransaksi.nego_step" disabled>
                    </div>
                    <div class="form-group mt-3">
                        <label for="respon">Respon Admin</label>
                        <textarea class="form-control mt-2" id="respon" rows="3" disabled>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                        </textarea>
                    </div>
                    <div class="form-group mt-3">
                        <label for="respon">Catatan</label>
                        <textarea v-model="catatan" class="form-control mt-2" id="respon" rows="3">
                        </textarea>
                    </div> -->
                    <div class="w-100 mt-4 pb-3" v-if="toLowerCase(datatransaksi.status) == 'confirmed' && this.datanego.length <= 0">
                        <!-- <div class="float-right">
                            <button class="btn btn-danger mx-1" @click.prevent="tipeModal()" data-bs-toggle="modal" data-bs-target="#modalform">
                                Lanjutkan Transaksi
                            </button>
                            <button class="btn btn-danger mx-1">
                                Setuju Negoisasi
                            </button>
                            <button class="btn btn-danger mx-1" @click="ajukanNego">
                                Lanjutkan Negosiasi
                            </button>
                        </div> -->
                        <button class="btn btn-red mx-2 float-right" type="button" v-on:click="openmodalform()">
                            <i class='bx bxs-checkbox-checked'></i> Konfirmasi Transaksi
                        </button>
                    </div>
                <!-- </div> -->
            </div>
            







            <!-- modal -->
            <div v-if="modalform" class="modal fade show" aria-modal="true" style="display: block; background-color: rgba(0,0,0,0.3);" tabindex="-1" role="dialog" id="modalchangepassword" >
                <!-- <div class="modal" tabindex="-1" role="dialog" id="modalchangepassword" > -->
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Konfirmasi Transaksi</h5>
                        </div>
                        <div class="modal-body form-nego-data">
                            <div class="form-group mt-md-3">
                                <!-- <label for="satuan_kerja">Nama Satuan Kerja</label> -->
                                <input type="text" class="form-control mt-2 mt-md-4" id="satuan_kerja" :class="{ 'border-danger': v$.satuan_kerja.$error }" v-model="satuan_kerja" aria-describedby="diterima" placeholder="Nama Satuan Kerja">
                                <div class="text-danger" v-if="v$.satuan_kerja.$error">
                                    <small v-if="v$.satuan_kerja.required">Nama Satuan Kerja Wajib Diisi</small>
                                </div>
                            </div>
                            <div class="form-group mt-md-3">
                                <!-- <label for="lembaga">Nama Kementrian/Lembaga/Pemerintahan</label> -->
                                <input type="text" class="form-control mt-2 mt-md-4" id="lembaga" :class="{ 'border-danger': v$.lembaga.$error }" v-model="lembaga" aria-describedby="diterima" placeholder="Nama Kementerian/Lembaga/Pemerintahan">
                                <div class="text-danger" v-if="v$.lembaga.$error">
                                    <small v-if="v$.lembaga.required">Nama Kementerian/Lembaga/Pemerintahan Wajib Diisi</small>
                                </div>
                            </div>
                            <div class="form-group mt-md-3">
                                <!-- <label for="paket_kerja">Nama Paket Pekerjaan</label> -->
                                <input type="text" class="form-control mt-2 mt-md-4" id="paket_kerja" :class="{ 'border-danger': v$.paket_kerja.$error }" v-model="paket_kerja" aria-describedby="diterima" placeholder="Nama Paket Pekerjaan">
                                <div class="text-danger" v-if="v$.paket_kerja.$error">
                                    <small v-if="v$.paket_kerja.required">Nama Paket Pekerjaan Wajib Diisi</small>
                                </div>
                            </div>
                            <div class="form-group mt-md-3">
                                <!-- <label for="tahun_anggaran">Tahun Anggaran</label> -->
                                <input type="tel" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="form-control mt-2 mt-md-4" id="tahun_anggaran" :class="{ 'border-danger': v$.tahun_anggaran.$error }" v-model="tahun_anggaran" aria-describedby="diterima" placeholder="Tahun Anggaran">
                                <div class="text-danger" v-if="v$.tahun_anggaran.$error">
                                    <small v-if="v$.tahun_anggaran.required">Tahun Anggaran Wajib Diisi</small>
                                </div>
                            </div>
                            <div class="form-group mt-md-3">
                                <!-- <label for="sumber_dana">Sumber Dana</label> -->
                                <input type="text" class="form-control mt-2 mt-md-4" id="sumber_dana" :class="{ 'border-danger': v$.sumber_dana.$error }" v-model="sumber_dana" aria-describedby="sumber_dana" placeholder="Sumber Dana">
                                <div class="text-danger" v-if="v$.sumber_dana.$error">
                                    <small v-if="v$.sumber_dana.required">Sumber Dana Wajib Diisi</small>
                                </div>
                            </div>
                            <div class="form-group mt-md-3">
                                <!-- <label for="kode_rekening">Kode Rekening Anggaran</label> -->
                                <input type="tel" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="form-control mt-2 mt-md-4" id="kode_rekening" :class="{ 'border-danger': v$.kode_rekening.$error }" v-model="kode_rekening" aria-describedby="kode_rekening" placeholder="Kode Rekening Anggaran">
                                <div class="text-danger" v-if="v$.kode_rekening.$error">
                                    <small v-if="v$.kode_rekening.required">Kode Rekening Anggaran Wajib Diisi</small>
                                </div>
                            </div>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text mt-2 mt-md-4" id="basic-addon1">Rp.</span>
                                </div>
                                <input type="tel" maxlength="9" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="form-control mt-2 mt-md-4" id="pagu_anggaran" :class="{ 'border-danger': v$.pagu_anggaran.$error }" v-model="pagu_anggaran" aria-describedby="pagu_anggaran" placeholder="Pagu Anggaran">
                            </div>
                            <div class="text-danger" v-if="v$.pagu_anggaran.$error">
                                <small v-if="v$.pagu_anggaran.required">Pagu Anggaran Wajib Diisi</small>
                            </div>
                            <!-- <div class="form-group mt-md-3"> -->
                                <!-- <label for="pagu_anggaran">Pagu Anggaran</label> -->
                                <!-- <input type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="form-control mt-2 mt-md-4" id="pagu_anggaran" :class="{ 'border-danger': v$.pagu_anggaran.$error }" v-model="pagu_anggaran" aria-describedby="pagu_anggaran" placeholder="Pagu Anggaran">
                                <div class="text-danger" v-if="v$.pagu_anggaran.$error">
                                    <small v-if="v$.pagu_anggaran.required">Pagu Anggaran Wajib Diisi</small>
                                </div>
                            </div> -->
                            <div class="container-fluid row option-form">
                                <div class="col-md-6 col-12">
                                    <select class="form-select mt-3 mt-md-4" v-model="dataatas" aria-label="Default select example">
                                        <option value="ppk" selected>PPK</option>
                                        <option value="pengguna_anggaran">Pengguna Anggaran</option>
                                        <option value="kuasa_pengguna_anggaran">Kuasa Pengguna Anggaran</option>
                                    </select>
                                </div>
                                <div class="col-md-6 col-12" v-if="dataatas == 'ppk'">
                                    <div class="form-group mt-md-3">
                                        <!-- <label for="nama_ppk">Nama PPK</label> -->
                                        <input type="text" class="form-control mt-2" id="nama_ppk" v-model="nama_ppk" aria-describedby="nama_ppk" placeholder="Nama PPK">
                                        <!-- <div class="text-danger" v-if="v$.nama_ppk.$error">
                                            <small v-if="v$.nama_ppk.required">Nama PPK Wajib Diisi</small>
                                        </div> -->
                                    </div>
                                    <div class="form-group mt-md-3">
                                        <!-- <label for="nip_ppk">NIP PPK</label> -->
                                        <input type="text" class="form-control mt-2" id="nip_ppk" onkeypress="return event.charCode >= 48 && event.charCode <= 57" v-model="nip_ppk" aria-describedby="nip_ppk" placeholder="NIP PPK">
                                        <!-- <div class="text-danger" v-if="v$.nip_ppk.$error">
                                            <small v-if="v$.nip_ppk.required">NIP PPK Wajib Diisi</small>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="col-md-6 col-12" v-if="dataatas == 'pengguna_anggaran'">
                                    <div class="form-group mt-md-3">
                                        <!-- <label for="nama_pa">Nama Pengguna Anggaran</label> -->
                                        <input type="text" class="form-control mt-2" id="nama_pa" v-model="nama_pa" aria-describedby="nama_pa" placeholder="Nama Pengguna Anggaran">
                                        <!-- <div class="text-danger" v-if="v$.nama_pa.$error">
                                            <small v-if="v$.nama_pa.required">Nama Pengguna Anggaran Wajib Diisi</small>
                                        </div> -->
                                    </div>
                                    <div class="form-group mt-md-3">
                                        <!-- <label for="nip_pa">NIP Pengguna Anggaran</label> -->
                                        <input type="tel" class="form-control mt-2" onkeypress="return event.charCode >= 48 && event.charCode <= 57" id="nip_pa" v-model="nip_pa" aria-describedby="nip_pa" placeholder="NIP Pengguna Anggaran">
                                        <!-- <div class="text-danger" v-if="v$.nip_pa.$error">
                                            <small v-if="v$.nip_pa.required">NIP Pengguna Anggaran Wajib Diisi</small>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="col-md-6 col-12" v-if="dataatas == 'kuasa_pengguna_anggaran'">
                                    <div class="form-group mt-md-3">
                                        <!-- <label for="nama_kpa">Nama Kuasa Pengguna Anggaran</label> -->
                                        <input type="text" class="form-control mt-2" id="nama_kpa" v-model="nama_kpa" aria-describedby="nama_kpa" placeholder="Nama Kuasa Pengguna Anggaran">
                                        <!-- <div class="text-danger" v-if="v$.nama_kpa.$error">
                                            <small v-if="v$.nama_kpa.required">Nama Kuasa Pengguna Anggaran Wajib Diisi</small>
                                        </div> -->
                                    </div>
                                    <div class="form-group mt-md-3">
                                        <!-- <label for="nip_kpa">NIP Kuasa Pengguna Anggaran</label> -->
                                        <input type="tel" class="form-control mt-2" onkeypress="return event.charCode >= 48 && event.charCode <= 57" id="nip_kpa"  v-model="nip_kpa" aria-describedby="nip_kpa" placeholder="NIP Kuasa Pengguna Anggaran">
                                        <!-- <div class="text-danger" v-if="v$.nip_kpa.$error">
                                            <small v-if="v$.nip_kpa.required">NIP Kuasa Pengguna Anggaran Wajib Diisi</small>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="container-fluid row option-form">
                                <div class="col-md-6 col-12">
                                    <select class="form-select mt-3 mt-md-4" v-model="databawah" aria-label="Default select example">
                                        <option value="bendahara_umum" @click="databawah" selected>Bendahara Umum</option>
                                        <option value="bendahara_pengeluaran" @click="databawah" >Bendahara Pengeluaran</option>
                                        <option value="bendahara_pembantu"  @click="databawah" >Bendahara pembantu</option>
                                    </select>
                                </div>
                                <div class="col-md-6 col-12" v-if="databawah == 'bendahara_umum'">
                                    <div class="form-group mt-md-3">
                                        <!-- <label for="nama_bu">Nama Bendahara Umum</label> -->
                                        <input type="text" class="form-control mt-2" id="nama_bu" v-model="nama_bu" aria-describedby="nama_bu" placeholder="Nama Bendahara Umum">
                                        <!-- <div class="text-danger" v-if="v$.nama_bu.$error">
                                            <small v-if="v$.nama_bu.required">Nama Bendahara Umum Wajib Diisi</small>
                                        </div> -->
                                    </div>
                                    <div class="form-group mt-md-3">
                                        <!-- <label for="nip_bu">NIP Bendahara Umum</label> -->
                                        <input type="tel" class="form-control mt-2" onkeypress="return event.charCode >= 48 && event.charCode <= 57" id="nip_bu" v-model="nip_bu" aria-describedby="nip_bu" placeholder="NIP Bendahara Umum">
                                        <!-- <div class="text-danger" v-if="v$.nip_bu.$error">
                                            <small v-if="v$.nip_bu.required">NIP Bendahara Umum Wajib Diisi</small>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="col-md-6 col-12" v-if="databawah == 'bendahara_pengeluaran'">
                                    <div class="form-group mt-md-3">
                                        <!-- <label for="nama_bpn">Nama Bendahara Pengeluaran</label> -->
                                        <input type="text" class="form-control mt-2" id="nama_bpn" v-model="nama_bpn" aria-describedby="nama_bpn" placeholder="Nama Bendahara Pengeluaran">
                                        <!-- <div class="text-danger" v-if="v$.nama_bpn.$error">
                                            <small v-if="v$.nama_bpn.required">Nama Bendahara Pengeluaran Wajib Diisi</small>
                                        </div> -->
                                    </div>
                                    <div class="form-group mt-md-3">
                                        <!-- <label for="nip_bpn">NIP Bendahara Pengeluaran</label> -->
                                        <input type="tel" class="form-control mt-2" onkeypress="return event.charCode >= 48 && event.charCode <= 57" id="nip_bpn" v-model="nip_bpn" aria-describedby="nip_bpn" placeholder="NIP Bendahara Pengeluaran">
                                        <!-- <div class="text-danger" v-if="v$.nip_bpn.$error">
                                            <small v-if="v$.nip_bpn.required">NIP Bendahara Pengeluaran Wajib Diisi</small>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="col-md-6 col-12" v-if="databawah == 'bendahara_pembantu'">
                                    <div class="form-group mt-md-3">
                                        <!-- <label for="nama_bpu">Nama Bendahara Pembantu</label> -->
                                        <input type="text" class="form-control mt-2" id="nama_bpu" v-model="nama_bpu" aria-describedby="nama_bpu" placeholder="Nama Bendahara Pembantu">
                                        <!-- <div class="text-danger" v-if="v$.nama_bpu.$error">
                                            <small v-if="v$.nama_bpu.required">Nama Bendahara Pembantu Wajib Diisi</small>
                                        </div> -->
                                    </div>
                                    <div class="form-group mt-md-35">
                                        <!-- <label for="nip_bpu">NIP Bendahara Pembantu</label> -->
                                        <input type="tel" class="form-control mt-2" onkeypress="return event.charCode >= 48 && event.charCode <= 57" id="nip_bpu"  v-model="nip_bpu" aria-describedby="nip_bpu" placeholder="NIP Bendahara Pembantu">
                                        <!-- <div class="text-danger" v-if="v$.nip_bpu.$error">
                                            <small v-if="v$.nip_bpu.required">NIP Bendahara Pembantu Wajib Diisi</small>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            
                            <div class="modal-footer">
                                <div type="button" class="btn btn-secondary" @click="closemodalform" data-bs-dismiss="modal">Batal</div>
                                <button type="submit" @click="konfirmasi_transaksi" class="btn btn-danger"><i class='bx bxs-save' ></i> Simpan</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- modal -->
            <!-- modal nego harga ongkir-->
                <div v-if="modalnegoongkir" class="modal fade show" aria-modal="true" style="display: block; background-color: rgba(0,0,0,0.3);" tabindex="-1" role="dialog" id="modalchangepassword" >
                <!-- <div class="modal" tabindex="-1" role="dialog" id="modalchangepassword" > -->
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-body-top-keranjang">
                            <button type="button" @click="closemodalongkir()" class="close btn" data-bs-dismiss="modal" aria-bs-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="container-fluid">
                            <div class="header-form px-1">
                                <h4>Pengajuan Harga Ongkir</h4>
                            </div>
                            <form @submit.prevent="updatehargaongkir">
                                <div class="form-group mt-3">
                                    <label for="satuan">Harga yang diajukan</label>
                                    <input v-model="pengajuanongkir" type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="form-control mt-1" id="nego_harga" aria-describedby="satuan" placeholder="Masukkan Harga Nego">
                                </div>
                                

                                <button type="submit" class="btn btn-danger mt-3 mb-3 float-right">Simpan</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- modal nego harga ongkir-->
            
            <div v-if="loadingSubmit" class="loading-submit-animation-container" >
                <div class="loading-submit-animation-content">
                    <div>
                        <div class="lds-facebook"><div></div><div></div><div></div></div>
                        <p>Mohon tunggu, data sedang diproses</p>
                    </div>
                </div>
            </div>
            <Modal
            :show="modal.showModal"
            :header="modal.header"
            :headerBorder="modal.headerBorder"
            :title="modal.title"
            :titleText="modal.titleText"
            :footer="modal.footer"
            :footerBtnPosition="modal.footerBtnPosition"
            :footerBorder="modal.footerBorder"
            :btnCancel="modal.btnCancel"
            :btnSubmit="modal.btnSubmit"
            :btnSubmitText="modal.btnSubmitText"
            :btnCancelText="modal.btnCancelText"
            :btnSubmitWidth="modal.btnSubmitWidth"
            :btnCancelWidth="modal.btnCancelWidth"
            @closeModal="closeModal"
            @submitModal="submitModal"
            >

            <div v-if="modalTipe === 'confirmnego'">
                <div class="text-center mt-2 border-2 border-light border-bottom">
                <i class='bx bxs-check-circle ' style="font-size:115px; color:var(--primary-color)"></i>
                <div class="notif-text">
                    <h5>Data anda telah berhasil disimpan</h5>
                </div>
                </div>
            </div>
            <div v-else-if="modalTipe === 'errorconfirmnego'" class="d-flex align-items-center justify-content-center text-center mt-2">
                <div>
                <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
                <h5>Ooppsss</h5>
                <p>{{ErrorMessage}}</p>
                </div>
            </div>
            <div v-else-if="modalTipe === 'errorkosong'" class="d-flex align-items-center justify-content-center text-center mt-2">
                <div>
                <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
                <h5>Ooppsss</h5>
                <p>mohon untuk mengisi data terlebih dahulu</p>
                </div>
            </div>

        </Modal>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import Modal from '../Modal.vue'
// import Img_Default from '../../assets/img/img-default.png'
import DaftarProduk from '../TransaksiUser/DaftarProduk.vue'
import SkeletonPesanan from '../../components/SkeletonUser/TransaksiUser/Detailpesanan.vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  name: 'DetailPesanan',
  data(){
    return{
        loading:true,
        pengajuanongkir:'',
        modalnegoongkir:false,
        imageuser:'',
        dataaddress:{},
        datanego:[],
        datatransaksi:{
            negokoke:'',
            nego_step:'',
            shipping_cost:'',
            shipping_cost_nego:'',
            total_price:'',
            total_qty:''
        },
        transaction_detail:{},
        negoongkir:'',
        address:'',
        users:[],
        transaksi_id:'',
        modalTipe: '',
        negoterakhir:[],
        transaction_detail_id:[],
        old_price:[],
        new_price:[],
        old_shipping_price:'',
        new_shipping_price:'',
        dataatas: 'ppk',
        databawah: 'bendahara_umum',
        satuan_kerja:'',
        lembaga:'',
        paket_kerja:'',
        tahun_anggaran:'',
        sumber_dana:'',
        kode_rekening:'',
        pagu_anggaran:'',
        nama_ppk:'',
        nip_ppk:'',
        nama_pa:'',
        nip_pa:'',
        nama_kpa:'',
        nip_kpa:'',
        nama_bu:'',
        nip_bu:'',
        nama_bpn:'',
        nip_bpn:'',
        nama_bpu:'',
        nip_bpu:'',
        modalform:false,
        ErrorMessage:'',
        loadingSubmit:false,
        modal:{
            showModal: false,
            header:false,
            headerBorder: false,
            title: false,
            titleText: '',
            footer: false,
            footerBtnPosition:'',
            footerBorder: false,
            btnCancel: false,
            btnSubmit: false,
            btnSubmitText: '',
            btnCancelText: '',
            btnSubmitWidth: '',
            btnCancelWidth: '',
        },
        // catatan:''
    }
  },
  setup () {
     return { v$: useVuelidate() }
  },
  validations() {
        return {
            satuan_kerja: {
                required,
            },
            lembaga: {
                required,
            },
            paket_kerja: {
                required,
            },
            tahun_anggaran: {
                required,
            },
            sumber_dana: {
                required,
            },
            kode_rekening: {
                required,
            },                      
            pagu_anggaran: {
                required,
            },                  
        }
    },
  components: {
      DaftarProduk,
      SkeletonPesanan,
      Modal
  },
  created(){
    this.setModal(false, this.modalTipe)
    this.modalform =false
      this.loading=true
      this.transaksi_id = this.$router.currentRoute._value.query.id_transaksi
        axios.get('/transaction/'+this.transaksi_id).then(res => {
            this.dataaddress = res.data.data.address
            this.datatransaksi = res.data.data.data
            this.negoterakhir = res.data.data.negotiations[res.data.data.negotiations.length-1]
            this.datanego = res.data.data.negotiations
            this.transaction_detail = this.datatransaksi.transaction_detail
            this.old_shipping_price = this.datatransaksi.shipping_cost
             this.loading=false
        })
        .catch(() =>{
            // this.loading=false
        })
  },
   watch:{
        user(value){
            if(this.$store.state.address > 0){
                this.listaddress = this.$store.state.address[0].customer_address
                this.loading=false
            }
            else if(!(value instanceof Promise) && value !== null && this.$store.state.address == null){
                // this.address = 'null'
            // this.$store.dispatch('user')
            this.$store.dispatch('address').then(()=>{
                // console.log(this.$store.state.address[0])
                this.listaddress = this.$store.state.address[0].customer_address
                this.loading=false
            })
            // console.log("cek kondisi")
            
            }
        },
        databawah(){
            this.nama_bu=''
        this.nip_bu=''
        this.nama_bpn=''
        this.nip_bpn=''
        this.nama_bpu=''
        this.nip_bpu=''
        },
        dataatas(){
            this.nama_ppk = ''
            this.nip_ppk = ''
            this.nama_pav= ''
            this.nama_kpa=''
            this.nip_kpa=''
        },
        pagu_anggaran(value){
            if(value == 0){
                this.pagu_anggaran
            }else{
                this.pagu_anggaran= parseInt(value)
            }
        }
    },
  mounted(){
    this.setModal(false, this.modalTipe)
    this.modalform =false
  },
  computed:{
    user: function() {
            return this.$store.state.user;
        },
    addressdata: function() {
            return this.$store.state.address;
        },
  },
  methods: {
    toLowerCase(value){
      return value.toLowerCase()
    },
    negoongkirdata(){
        this.modalnegoongkir =true
    },
    closemodalongkir(){
        this.modalnegoongkir =false
    },
    updatehargaongkir(){
        this.datatransaksi.shipping_cost = this.pengajuanongkir
        this.new_shipping_price = this.pengajuanongkir
        this.modalnegoongkir =false
        this.pengajuanongkir= ''
    },
    updatenegoharga(value){
        this.transaction_detail[value.dataindex].price = value.hargadata
        this.transaction_detail_id = [...this.transaction_detail_id,value.id]
        this.old_price = [...this.old_price,value.old_harga]
        this.new_price = [...this.new_price,value.hargadata]
    },
    dateTime(value) {
        return moment(value).format("DD MMMM YYYY");
    },
    
    setModal(condition, tipe){
        this.resetDataModal()
        this.modal.showModal= condition
        condition == true ? this.modalTipe = tipe : ''
        if(tipe == 'confirmnego' && condition){
            this.modal.header=true
            this.modal.footer= true
            this.modal.footerBtnPosition='right'
            this.modal.btnSubmit= true
            this.modal.btnSubmitText= 'Oke'
            this.modal.btnSubmitWidth='auto'
        }else if(tipe == 'errorconfirmnego' && condition){
            this.modal.header=true
            this.modal.footer= true
            this.modal.footerBtnPosition='right'
            this.modal.btnSubmit= true
            this.modal.btnSubmitText= 'Oke'
            this.modal.btnSubmitWidth='auto'
        }else if(tipe == 'errorkosong' && condition){
            this.modal.header=true
            this.modal.footer= true
            this.modal.footerBtnPosition='right'
            this.modal.btnSubmit= true
            this.modal.btnSubmitText= 'Oke'
            this.modal.btnSubmitWidth='auto'
        }
    },
    resetDataModal(){
        this.modal.showModal = false
        this.modal.header = false,
        this.modal.headerBorder = false
        this.modal.title = false
        this.modal.titleText = '' 
        this.modal.footer = false
        this.modal.footerBtnPosition =''
        this.modal.footerBorder = false
        this.modal.btnCancel = false
        this.modal.btnSubmit = false
        this.modal.btnSubmitText = ''
        this.modal.btnCancelText = ''
        this.modal.btnSubmitWidth = ''
        this.modal.btnCancelWidth = ''
    },
    closeModal(){
        this.setModal(false, this.modalTipe)
        this.modalTipe=''
        this.ErrorMessage =''
    },
    submitModal(){
      this.setModal(false, '' , this.modalTipe)
      this.messageErrorModal = ''
      this.modalTipe=''
      this.ErrorMessage =''

    },
    openmodalform(){
        this.modalform =true
    },
    closemodalform(){
        this.modalform =false
    },
    konfirmasi_transaksi(){
        this.v$.$touch()
        if (!this.v$.$invalid) {
               let data = new FormData();
              data.append("transaction_id", this.transaksi_id);
              data.append("satuan_kerja", this.satuan_kerja);
              data.append("lembaga", this.lembaga);
              data.append("paket_kerja", this.paket_kerja);
              data.append("tahun_anggaran", this.tahun_anggaran);
              data.append("sumber_dana", this.sumber_dana);
              data.append("kode_rekening", this.kode_rekening);
              data.append("pagu_anggaran", this.pagu_anggaran);
              if(this.dataatas == 'ppk'){
                data.append("tipe_pemerintah", 'ppk');
                data.append("nama_pemerintah", this.nama_ppk);
                data.append("nip_pemerintah", this.nip_ppk);
              }else if(this.dataatas == 'pengguna_anggaran'){
                data.append("tipe_pemerintah", 'pengguna angaran');
                data.append("nama_pemerintah", this.nama_pa);
                data.append("nip_pemerintah", this.nip_pa);
              }else if(this.dataatas == 'kuasa_pengguna_anggaran'){
                data.append("tipe_pemerintah", 'kuasa pengguna angaran');
                data.append("nama_pemerintah", this.nama_kpa);
                data.append("nip_pemerintah", this.nip_kpa);
              }
              if(this.databawah == 'bendahara_umum'){
                data.append("tipe_bendahara", 'bendahara umum');
                data.append("nama_bendahara", this.nama_bu);
                data.append("nip_bendahara", this.nip_bu);
              }else if(this.databawah == 'bendahara_pengeluaran'){
                data.append("tipe_bendahara", 'bendahara pengeluaran');
                data.append("nama_bendahara", this.nama_bpn);
                data.append("nip_bendahara", this.nip_bpn);
              }else if(this.databawah == 'bendahara_pembantu'){
                data.append("tipe_bendahara", 'bendahara pembantu');
                data.append("nama_bendahara", this.nama_bpu);
                data.append("nip_bendahara", this.nip_bpu);
              }
                this.loadingSubmit = true
                axios.post(`/transaction/store-spk`, data).then(() => {
                    this.transaksi_id = this.$router.currentRoute._value.query.id_transaksi
                    axios.get('/transaction/'+this.transaksi_id).then(res => {
                        this.dataaddress = res.data.data.address
                        this.datatransaksi = res.data.data.data
                        this.negoterakhir = res.data.data.negotiations[res.data.data.negotiations.length-1]
                        this.datanego = res.data.data.negotiations
                        this.transaction_detail = this.datatransaksi.transaction_detail
                        this.old_shipping_price = this.datatransaksi.shipping_cost
                        this.loadingSubmit = false
                         this.modalform =false
                        this.setModal(true, 'confirmnego')
                        //  this.loading=false
                    }).catch(() =>{
                        // this.loading=false
                    })
                })
                .catch((e) => {
                this.loadingSubmit = false
                // this.modalform =false
                this.setModal(true, 'errorconfirmnego')
                if(e.response.status === 201 || e.response.status === 'Success'){
                    this.setModal(true, 'confirmnego')
                }
                else if(e.response.status === 400){
                    if(e.response.data?.data?.nama_pemerintah){
                        this.ErrorMessage = 'Mohon Lengkapi data anda terlebih dahulu'
                    }else if(e.response.data?.data?.nip_pemerintah){
                        this.ErrorMessage = 'Mohon Lengkapi data anda terlebih dahulu'
                    }else if(e.response.data?.data?.nama_bendahara){
                        this.ErrorMessage = 'Mohon Lengkapi data anda terlebih dahulu'
                    }else if(e.response.data?.data?.nip_bendahara){
                        this.ErrorMessage = 'Mohon Lengkapi data anda terlebih dahulu'
                    }else if(e.response.data?.massage){
                        this.ErrorMessage = e.response.data.massage
                    }
                }
                });
        }
    },
  }
}
</script>
<style scoped>
.bg-kuning{
  background-color: #F7D716;
  color: #fff !important;
}
.informationTable td {
    padding: 2px;
}
/* .data-tabel, tr, td, th{
    border:0px !important;
} */
.data-ongkir{
    box-shadow: 10px 10px 19px #f5f5f5, -10px -10px 19px #efefef;
    border-radius: 15px;
}
.transaksi-footer-bottom{
    display: block;
    position: initial;
    bottom: 1.5rem;
    right: 2rem;
}
.img-profile{
    max-height: 150px !important;
    max-width: 150px !important;
}
.img-profile img{
    width: 100%;
}
.td-nama-produk{
    max-width: 200px !important;
}
.nama-produk{
    display: flex;
    margin: 0px;
    margin-right: 0px !important;
}
.discount-harga{
    text-align: right;
    margin-bottom: 5px;
    /* max-width: 40px; */
}
.discount-harga span{
    background-color: #dc3545;
    color:#ffffff;
    border-radius: 5px;
    /* max-width: 40px; */
}
.ringkasan{
    display: flex;
    justify-content: space-between;
}
.total-ringkasan{
    border-top: solid 1px grey;
    display: flex;
    justify-content: space-between;
}
.form-group label{
    font-weight: 600;
    font-size: 1.3rem;
}
.header-form{
    border-bottom: solid 1px grey;
}
.web-view{
    display: none;
}
.mobile-view{
    display: block;
}
.ringkasan-belanja-container{
    padding: 0.75rem;
    text-align: right;
    background-color: var(--white-color);
    display: flex;
    justify-content: end;
    flex-direction: column;
    font-size: 0.8rem;
}
.total-ringkasan-belanja th{
    border-top: 1px solid var(--primary-color);
    font-size: 0.8rem;
}
.tothar{
    max-width: 70px;
}
.view-table{
    display: none;
}
.view-div{
    display: block;
    
}
.total-belanja{
    width: 100%;
}
.harga-total{
    text-align: right;
}
.data-total-harga{
    display: flow-root !important;
}
.table-data-belanja{
    width: 100%;
    float: right;
}
.table-data-belanja th{
    text-align: left;
}
.harga-total{
    text-align: left;
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
    .transaksi-footer-bottom{
        position: absolute;
    }
    .ringkasan-belanja-container{
        padding: 1.75rem;
        text-align: right;
        background-color: var(--white-color);
        display: flex;
        justify-content: end;
        flex-direction: column;
        font-size: 1rem;
    }
    .total-ringkasan-belanja th{
        border-top: 1px solid var(--primary-color);
        font-size: 1.25rem;
    }
    .tothar{
        max-width: 150px;
    }
    .view-table{
        display: table-footer-group;
        border-top: 1px solid #dee2e6 !important;
    }
    .view-div{
        display: none;
    }
    .harga-total{
        text-align: right !important;
    }
    .table-data-belanja{
        width: 70%;
    }
    
}

@media (min-width: 1023px) {
    .web-view{
        display: flex;
    }
    .mobile-view{
        display: none ;
    }
    .table-data-belanja{
        width: 50%;
    }
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>