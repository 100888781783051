<template>
    <!-- <div class="border-data">
        <div class="background-white" >
            <div style="overflow-x:scroll;">
                <table>
                    <thead>
                        <tr>
                            <th>Nama Produk</th>
                            <th style="min-width:100px;">Jumlah</th>
                            <th style="min-width:110px;">Harga Awal</th>
                            <th style="min-width:140px;">Harga Nego</th>
                        </tr>
                    </thead>
                    <tbody> -->
                        <tr>
                            <td class="td-nama-produk">
                                <div class="nama-produk">
                                    <span class="px-2">{{nama_produk}}</span>
                                </div>
                            </td>
                            <td style="max-width:80px;">
                                <div class="w-100">
                                    <span>Rp. {{ new Intl.NumberFormat('id-ID').format(Number(harga_awal).toFixed()) }}</span>
                                </div>
                            </td>
                            <td>
                                <span>Rp. {{ new Intl.NumberFormat('id-ID').format(Number(harga_nego).toFixed()) }}</span>
                            </td>
                        </tr>
                        <!-- <tr>
                            <td colspan="4" style="vertical-align:baseline;">
                                <p style="margin-bottom:0px;"><b>Catatan Negosiasi </b></p>
                                <p>{{note}}</p>
                            </td>
                        </tr> -->
                    <!-- </tbody>
                </table>
            </div>
        </div>

    </div> -->
</template>
<script>
export default {
    props:['dataindex','nama_produk','unit','harga_awal','harga_nego','note','shipping_cost_negotiation'],
    name: 'DaftarProduct',
    data(){
        return {
            modalharga:false,
            negoharga:{
                dataindex:this.dataindex,
                hargadata:''
            }
        }
    },

    methods: {
    }
}
</script>
<style scoped>
.form-group label{
    font-weight: 600;
    font-size: 1.3rem;
}
.header-form{
    border-bottom: solid 1px grey;
}
/* .data-tabel, tr, td, th{
    border:0px !important;
}
.head-tabel{
    border:0px !important;
    border-bottom:1px solid grey !important;
} */
.img-profile{
    width: 150px;
    height: 110px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.img-profile img{
    width: 100%;
}
.td-nama-produk{
    max-width: 200px !important;
}
.nama-produk{
    display: flex;
    margin: 0px;
    margin-right: 0px !important;
}
.nama-produk h6{
    vertical-align: inherit;
    margin: auto 0;
}
.discount-harga{
    text-align: right;
    margin-bottom: 5px;
    /* max-width: 40px; */
}
.discount-harga span{
    background-color: #dc3545;
    color:#ffffff;
    border-radius: 5px;
    /* max-width: 40px; */
}
.ringkasan{
    display: flex;
    justify-content: space-between;
}
.total-ringkasan{
    border-top: solid 1px grey;
    display: flex;
    justify-content: space-between;
}

@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
    /* .img-profile{
        width: auto;
    } */
}

@media (min-width: 1023px) {
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>