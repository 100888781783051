<template>
    <div class="about section section-modif">
        <div class="container">
            <h1 class="text-center">Tentang Kami</h1>

            <div class="row" v-if="loading">
                <div class="col-md-8 mx-auto" :class="{ 'loading-skeleton' : loading }">
                    <p>loading..</p>
                    <p>loading..</p>
                    <p>loading..</p>
                </div>
            </div>

            <div class="row">
                <div class="col-md-8 mx-auto">
                    <p class="text" v-html="about.about ? about.about : '-'">
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'faq-view',
    data(){
        return{
            about: [],
            loading: false
        }
    },
    mounted(){
        this.loading = true
        axios.get(`setting`)
        .then((result) => {
            // console.log('about : ', result.data.data.about)
            this.about = result.data.data
            this.loading = false
        }).catch(() => {
            // console.log(err.response);
            this.loading = false
        })
    }
}
</script>

<style scoped>

</style>