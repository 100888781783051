<template>
    <div class="loading-skeleton">
        <div> 
        <form >
            <div class="data1" >
                <div class="container-fluid pt-4">
                        <div class="row">
                            <div class="form-group col-12">
                                <label for="namaproduk">Nama Produk</label>
                                <input type="text" disabled class="form-control mt-2"  id="namaproduk" aria-describedby="namaproduk" placeholder="Nama Produk">
                            </div>
                            <div class="form-group col-md-6 mt-md-3">
                                <label for="resi">No Resi</label>
                                <input type="text" disabled class="form-control mt-2"  id="resi" aria-describedby="resi" placeholder="No Resi">
                            </div>
                            <div class="form-group col-md-6 mt-md-3">
                                <label for="Ekspedisi">Ekspedisi</label>
                                <input type="text" disabled class="form-control mt-2"  id="Ekspedisi" aria-describedby="Ekspedisi" placeholder="Ekspedisi">
                            </div>
                            <div class="form-group col-md-6 mt-md-3">
                                <label for="dikirim">Tanggal Dikirim</label>
                                <input type="date" disabled class="form-control mt-2"  id="dikirim" aria-describedby="dikirim" placeholder="Ekspedisi">
                            </div>
                            <div class="form-group col-md-6 mt-md-3">
                                <label for="diterima">Tanggal Diterima</label>
                                <input type="date" class="form-control mt-2" id="diterima"  aria-describedby="diterima" placeholder="Ekspedisi">
                                
                            </div>
                            <div class="form-group col-md-6 mt-md-3">
                                <label for="unitdikirim">Unit Dikirim</label>
                                <input type="text" disabled class="form-control mt-2"  id="unitdikirim" aria-describedby="unitdikirim" placeholder="Unit Dikirim">
                            </div>
                            <div class="form-group col-md-6 mt-md-3">
                                <label for="unitditerima">Unit Diterima</label>
                                <input type="text" class="form-control mt-2" id="unitditerima"  aria-describedby="unitditerima" placeholder="Unit Diterima">
                                
                            </div>
                            <div class="form-group col-12 mt-md-3">
                                <label for="penerima">Nama Penerima</label>
                                <input type="text" class="form-control mt-2" id="penerima" aria-describedby="penerima" placeholder="Nama Penerima">
                                
                            </div>
                        </div>
                </div>
                <div class="mt-5">
                    <table>
                        <tr>
                            <th><p>No</p></th>
                            <th><p>Nama File</p></th>
                            <th><p>Download Template File</p></th>
                            <th><p>Upload</p></th>
                            <th><p>File</p></th>
                        </tr>
                        <tbody>
                            <tr>
                                <td class="">
                                <p>no</p>
                                </td>
                                <td class="">
                                   <p> Nama File</p>
                                </td>
                                <td>
                                    <p>Download Template</p>
                                </td>
                                <td>
                                <p>Upload File</p>
                                </td>
                                <td>
                                    <p>Download Template</p>
                                </td>
                            </tr>
                            <tr>
                                <td class="">
                                <p>no</p>
                                </td>
                                <td class="">
                                   <p> Nama File</p>
                                </td>
                                <td>
                                    <p>Download Template</p>
                                </td>
                                <td>
                                <p>Upload File</p>
                                </td>
                                <td>
                                    <p>Download Template</p>
                                </td>
                            </tr>
                            <tr>
                                <td class="">
                                <p>no</p>
                                </td>
                                <td class="">
                                   <p> Nama File</p>
                                </td>
                                <td>
                                    <p>Download Template</p>
                                </td>
                                <td>
                                <p>Upload File</p>
                                </td>
                                <td>
                                    <p>Download Template</p>
                                </td>
                            </tr>
                            <tr>
                                <td class="">
                                <p>no</p>
                                </td>
                                <td class="">
                                   <p> Nama File</p>
                                </td>
                                <td>
                                    <p>Download Template</p>
                                </td>
                                <td>
                                <p>Upload File</p>
                                </td>
                                <td>
                                    <p>Download Template</p>
                                </td>
                            </tr>
                            <tr>
                                <td class="">
                                <p>no</p>
                                </td>
                                <td class="">
                                   <p> Nama File</p>
                                </td>
                                <td>
                                    <p>Download Template</p>
                                </td>
                                <td>
                                <p>Upload File</p>
                                </td>
                                <td>
                                    <p>Download Template</p>
                                </td>
                            </tr>
                            <tr>
                                <td class="">
                                <p>no</p>
                                </td>
                                <td class="">
                                   <p> Nama File</p>
                                </td>
                                <td>
                                    <p>Download Template</p>
                                </td>
                                <td>
                                <p>Upload File</p>
                                </td>
                                <td>
                                    <p>Download Template</p>
                                </td>
                            </tr>
                            <tr>
                                <td class="">
                                <p>no</p>
                                </td>
                                <td class="">
                                   <p> Nama File</p>
                                </td>
                                <td>
                                    <p>Download Template</p>
                                </td>
                                <td>
                                <p>Upload File</p>
                                </td>
                                <td>
                                    <p>Download Template</p>
                                </td>
                            </tr>
                            <tr>
                                <td class="">
                                <p>no</p>
                                </td>
                                <td class="">
                                   <p> Nama File</p>
                                </td>
                                <td>
                                    <p>Download Template</p>
                                </td>
                                <td>
                                <p>Upload File</p>
                                </td>
                                <td>
                                    <p>Download Template</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="container-fluid pb-3">
                    <div class="float-right">
                        <button type="submit" class="btn btn-danger">Simpan</button>
                    </div>
                </div>
            </div>
        </form>
        </div>
     </div>
</template>
<style scoped>

.loading-skeleton h1,
.loading-skeleton h2,
.loading-skeleton h3,
.loading-skeleton h4,
.loading-skeleton h5,
.loading-skeleton h6,
.loading-skeleton p,
.loading-skeleton li,
.loading-skeleton .btn,
.loading-skeleton label,
.loading-skeleton .image-user,
.loading-skeleton .datatable,
.loading-skeleton .form-control {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee;
  border-color: #eee;
}
.loading-skeleton h1::placeholder,
.loading-skeleton h2::placeholder,
.loading-skeleton h3::placeholder,
.loading-skeleton h4::placeholder,
.loading-skeleton h5::placeholder,
.loading-skeleton h6::placeholder,
.loading-skeleton p::placeholder,
.loading-skeleton li::placeholder,
.loading-skeleton .btn::placeholder,
.loading-skeleton .datatable::placeholder,
.loading-skeleton .image-user::placeholder,
.loading-skeleton label::placeholder,
.loading-skeleton .form-control::placeholder {
  color: transparent;
}

@keyframes loading-skeleton {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;
}
.loading-skeleton img {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
.loading-skeleton .datatable {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
</style>