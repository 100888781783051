<template>
    <div class="container-fluid py-3 loading-skeleton">
        <div class="row">
            <div class="col-md-12">
                <ul class="timeline">
                    <li class="timeline-item">
                        <div class="loading-skeleton">
                            <h5>Pembuatan Order</h5>
                            <p> adajdajdnajdbjabdjad WITA</p>
                            <h6>Total Pembayaran</h6>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit</p>

                        </div>
                    </li>
                    <li class="timeline-item mt-3">
                        <div class="loading-skeleton">
                            <h5>Pembuatan Order</h5>
                            <p> adajdajdnajdbjabdjad WITA</p>
                            <h6>Total Pembayaran</h6>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit</p>

                        </div>
                    </li>
                    <li class="timeline-item mt-3">
                        <div class="loading-skeleton">
                            <h5>Pembuatan Order</h5>
                            <p> adajdajdnajdbjabdjad WITA</p>
                            <h6>Total Pembayaran</h6>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit</p>

                        </div>
                    </li>
                    <li class="timeline-item mt-3">
                        <div class="loading-skeleton">
                            <h5>Pembuatan Order</h5>
                            <p> adajdajdnajdbjabdjad WITA</p>
                            <h6>Total Pembayaran</h6>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit</p>

                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<style scoped>

.loading-skeleton h1,
.loading-skeleton h2,
.loading-skeleton h3,
.loading-skeleton h4,
.loading-skeleton h5,
.loading-skeleton h6,
.loading-skeleton p,
.loading-skeleton li,
.loading-skeleton .btn,
.loading-skeleton label,
.loading-skeleton .image-user,
.loading-skeleton .datatable,
.loading-skeleton .form-control {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee;
  border-color: #eee;
}
.loading-skeleton h1::placeholder,
.loading-skeleton h2::placeholder,
.loading-skeleton h3::placeholder,
.loading-skeleton h4::placeholder,
.loading-skeleton h5::placeholder,
.loading-skeleton h6::placeholder,
.loading-skeleton p::placeholder,
.loading-skeleton li::placeholder,
.loading-skeleton .btn::placeholder,
.loading-skeleton .datatable::placeholder,
.loading-skeleton .image-user::placeholder,
.loading-skeleton label::placeholder,
.loading-skeleton .form-control::placeholder {
  color: transparent;
}

@keyframes loading-skeleton {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;
}
.loading-skeleton img {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
.loading-skeleton .datatable {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
    .timeline {
        border-left: 1px solid hsl(0, 0%, 90%);
        position: relative;
        list-style: none;
    }
    .timeline .timeline-item {
        position: relative;
        padding: 15px;
    }
    .timeline .timeline-item:after {
        position: absolute;
        display: block;
        top: 22px;
    }
    .timeline .timeline-item:after {
        background-color: hsl(0, 0%, 90%);
        left: -6px;
        border-radius: 50%;
        height: 11px;
        width: 11px;
        content: "";
    }
</style>