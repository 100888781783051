<template>
    <skeletonpay v-if="loading"></skeletonpay>
    <div v-else-if="!loading">
        <div class="p-md-4" v-if="toLowerCase(datatransaksi?.status) == 'pending_payment' || toLowerCase(datatransaksi?.status) == 'pending'">
            <data-pending
            :name="datauser.name"
            :tanggal_beli="dateTime(datatransaksi.created_at)"
            :invoice="datatransaksi.transaction.invoice"
            :id="datatransaksi.transaction.id"
            :nominal="datatransaksi.nominal"
            :payment="datatransaksi.payment_service"></data-pending>
            <!-- <div class="p-md-5">
                <img src="../../assets/img/undraw_mobile_pay_re_sjb8.svg" style="width:50%;display:block; margin:0 auto;" alt="">
            </div>
            <p class="w-100" style="text-align:center;">Mohon Untuk segera Melakukan Pembayaran,<br> agar bisa lanjut ke proses selanjutnya</p>
            <router-link to="/profil/beranda" class="btn btn-danger" style="margin:0 auto; display:block; width:200px;">Kembali Ke Beranda</router-link> -->
        </div>
        <div class="p-md-4" v-if="toLowerCase(datatransaksi?.status) == 'paid' || toLowerCase(datatransaksi?.status) == 'success'">
            <data-doku :name="datauser.name"
            :tanggal_beli="dateTime(datatransaksi.created_at)"
            :invoice="datatransaksi.transaction.invoice"
            :id="datatransaksi.transaction.id"
            :payment="datatransaksi.payment_service"
            :datatransaksi="datatransaksi"></data-doku>
            





            <!-- <div class="p-md-5">
                <img src="../../assets/img/undraw_winners_re_wr1l.svg" style="width:50%;display:block; margin:0 auto;" alt="">
            </div>
            <h3 class="w-100" style="text-align:center;">Selamat</h3>
            <p class="w-100" style="text-align:center;">Pesanan anda akan segera di proses dan dikirim</p>
            <router-link to="/profil/beranda" class="btn btn-danger" style="margin:0 auto; display:block; width:200px;">Kembali Ke Beranda</router-link> -->
        </div>
        <div class="p-md-4" v-if="toLowerCase(datatransaksi?.status) == 'payment_expired' || toLowerCase(datatransaksi?.status) == 'expired'">
            <div class="p-md-5">
                <img src="../../assets/img/undraw_mobile_pay_re_sjb8.svg" style="width:50%;display:block; margin:0 auto;" alt="">
            </div>
            <h3 class="w-100" style="text-align:center;">Oooops</h3>
            <p class="w-100" style="text-align:center;">Pembayaran anda sudah Expired, silahkan lakukan permohonan Pembayaran kembali</p>
            <router-link to="/profil/beranda" class="btn btn-danger" style="margin:0 auto; display:block; width:200px;">Kembali Ke Beranda</router-link>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
// import DataBPD from '../Example/BpdPayment.vue'
import skeletonpay from '../SkeletonUser/Example/DokuPayment.vue'
import DataPending from '../Example/DokuPaymentPending.vue'
import DataDoku from '../Example/DokuPaymentSuccess.vue'
export default {
    data(){
        return{
            loading:true,
            transaksi_id:{},
            dataid:{},
            datatransaksi:[],
            datauser:{}
        }
    },
    components:{
        // DataBPD,
        DataPending,
        DataDoku,
        skeletonpay
    },
    created() {
        this.dataid = this.$router.currentRoute._value.query.id
        axios.get('/transaction/payment/status?id='+this.dataid).then(res => {
            this.datatransaksi = res.data.data
            if(this.user){
                this.datauser = this.user
            }
             this.loading=false
        })
  },
    computed:{
        user: function() {
                return this.$store.state.user;
            },
    },
  watch:{
        user(value){
            if(!(value instanceof Promise) && value !== null){
             this.datauser = value
                 this.loading=false
            }
        }
    },
  methods:{
    dateTime(value) {
        return moment(value).format("DD MMMM YYYY");
    },
    toLowerCase(value){
      return value.toLowerCase()
    },
  }
}
</script>
<style scoped>
    /* .view-mobile{
        display: block;
    }
    .view-web{
        display: none;
    } */

@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
    /* .view-mobile{
        display: none;
    }
    .view-web{
        display: table;
    } */
}

@media (min-width: 1023px) {
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>