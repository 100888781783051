<template>
    <div class="banner-bottom section my-2">
        <div class="banner-bottom-container container">
            <!-- <a :href="urlBanner1.substring(0,4) === 'http' ? 'https://' : ''+urlBanner1" v-if="urlBanner1 !== '' || urlBanner1 !== null" target="_blank">
                <img v-if="banner1 !== ''" :src="banner1" alt="" class="banner-bottom-img banner-bottom-img-1">
            </a> -->
            <img v-if="banner1 !== ''" :src="banner1" alt="" class="banner-bottom-img banner-bottom-img-1" @click="bannerOne()">
            <img v-if="banner2 !== ''" :src="banner2" alt="" class="banner-bottom-img banner-bottom-img-2" @click="bannerTwo()">
            <img v-if="banner3 !== ''" :src="banner3" alt="" class="banner-bottom-img banner-bottom-img-3" @click="bannerThree()">
        </div>


    </div>
</template>

<script>

export default {
    name: 'banner-bottom-component',
    props:['banners'],
    data(){
        return {
            baseUrl: process.env.VUE_APP_NAME_AXIOS,
            banner1:'',
            urlBanner1: '',
            banner2:'',
            urlBanner2: '',
            banner3:'',
            urlBanner3: '',
        }
    },
    methods: {
        setBanner(){
            
            if(Object.keys(this.banners).length > 0){
                for(let i = 0; i < Object.keys(this.banners).length; i++){
                    if(this.banners[i].position == 'Banner 1'){
                        this.urlBanner1 = this.banners[i].url
                        this.banner1 = this.baseUrl + '/storage/'+ this.banners[i].image
                    }
    
                    if(this.banners[i].position == 'Banner 2'){
                        this.urlBanner2 = this.banners[i].url
                        this.banner2 = this.baseUrl + '/storage/'+ this.banners[i].image
                    }
    
                    if(this.banners[i].position == 'Banner 3'){
                        this.urlBanner3 = this.banners[i].url
                        this.banner3 = this.baseUrl + '/storage/'+ this.banners[i].image
                    }
                }
            }            
        },
        bannerOne(){
            if(this.urlBanner1 !== ''){
                window.open((this.urlBanner1.substring(0,4) !== 'http' ? 'https://' : '')+this.urlBanner1, '_blank');
            }
        },
        bannerTwo(){
            if(this.urlBanner2 !== ''){
                window.open((this.urlBanner2.substring(0,4) !== 'http' ? 'https://' : '')+this.urlBanner2, '_blank');
            }
        },
        bannerThree(){
            if(this.urlBanner3 !== ''){
                window.open((this.urlBanner3.substring(0,4) !== 'http' ? 'https://' : '')+this.urlBanner3, '_blank');
            }
        },
    
    },
    mounted(){
        if(this.banners != null){
            this.setBanner()
        }
        
    },
    watch:{
        banners:{
            deep: true,
            handler: function(){
                if(this.banners != 'null'){
                    this.setBanner()
                    this.bannerOne
                    this.bannerTwo
                    this.bannerThree
                    // console.log('Url Banner 1:', (this.urlBanner1.substring(0,4) !== 'http' ? 'https://' : 'bukan')+this.urlBanner1);
                    // console.log('Url Banner 2:', this.urlBanner2.substring(0,4) !== 'http' ? 'https://' : 'bukan'+this.urlBanner2);
                    // console.log('Url Banner 3:', this.urlBanner3.substring(0,4) !== 'http' ? 'https://' : 'bukan'+this.urlBanner1);
                }
            }
        }
    }
}
</script>

