<template>
    <div id="paginationContainer" class="mb-5">

        <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
                <li v-if="(page - 1) != 0 "  class="page-item d-flex align-items-center">
                    <router-link class="page-link pagination-previous" tabindex="-1" :to="'/produk'+promoUrl === true ? '/promo' : ''+'?page='+(parseInt(page) - 1)+''+url" ><i class='bx bx-chevron-left'></i> </router-link>
                </li>
                <li v-else class="page-item d-flex align-items-center disabled">
                    <a class="page-link pagination-previous" tabindex="-1">
                        <i class='bx bx-chevron-left'></i>
                    </a>
                </li>
                
                <li v-if="page == 1" class="page-item d-flex align-items-center disabled">
                    <a class="page-link pagination-previous" tabindex="-1">
                        <i class='bx bx-chevrons-left'></i>
                    </a>
                </li>
                <li v-else-if="page > 1" class="page-item d-flex align-items-center ">
                    <router-link :to="'/produk'+promoUrl === true ? '/promo' : ''+'?page=1'+url" class="page-link pagination-previous" tabindex="-1">
                        <i class='bx bx-chevrons-left'></i>
                    </router-link>
                </li>

                <li v-for="link in pagination.linkPagination" :key="link" class="page-item " :class="{'active' : link === pagination.currentPage}" >
                    <router-link :to="'/produk'+promoUrl === true ? '/promo' : ''+'?page='+link+''+url" class=" pagination-link page-link">{{link}}</router-link>
                </li>
                <li v-if="page == lastPage" class="page-item d-flex align-items-center disabled">
                    <a class="page-link pagination-previous" tabindex="-1">
                        <i class='bx bx-chevrons-right'></i>
                    </a>
                </li>
                <li v-else-if="page < lastPage" class="page-item d-flex align-items-center ">
                    <router-link :to="'/produk'+promoUrl === true ? '/promo' : ''+'?page='+lastPage+''+url" class="page-link pagination-previous" tabindex="-1">
                        <i class='bx bx-chevrons-right'></i>
                    </router-link>
                </li>

                <li v-if="page < lastPage " class="page-item d-flex align-items-center">
                    <router-link :to="'/produk'+promoUrl === true ? '/promo' : ''+'?page='+(parseInt(page) + 1)+''+url" class=" pagination-link page-link"><i class='bx bx-chevron-right'></i></router-link>
                </li>
                <li v-else class="page-item d-flex align-items-center disabled">
                    <a class="page-link pagination-next">
                        <i class='bx bx-chevron-right'></i>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</template>
<script>
    export default {
        name: 'pagination-component',
        props:['pagination','page','lastPage'],
        data(){
            return{
                url:'',
                serachQuery: this.$router.currentRoute._value.query.search,
                promoUrl: Object.keys(this.$router.currentRoute._value.params)[0] === 'promo'
            }
        },
        methods:{
            setUrl(){   
                let p_subkategori = this.$router.currentRoute._value.query.sub_kategori ? 'sub_kategori='+this.$router.currentRoute._value.query.sub_kategori : ''
                let p_kategori = this.$router.currentRoute._value.query.kategori ? 'kategori='+this.$router.currentRoute._value.query.kategori : ''
                let p_search = this.$router.currentRoute._value.query.search ? 'search='+this.$router.currentRoute._value.query.search : ''
                let p_minprice = this.$router.currentRoute._value.query.min_price ? 'price_min='+this.$router.currentRoute._value.query.min_price : ''
                let p_maxprice = this.$router.currentRoute._value.query.max_price ? 'price_max='+this.$router.currentRoute._value.query.max_price : ''
                let p_rating = this.$router.currentRoute._value.query.rating ? 'rating='+this.$router.currentRoute._value.query.rating: ''
                let p_sort = this.$router.currentRoute._value.query.sort ? 'sort='+this.$router.currentRoute._value.query.sort : ''

                let array_filter = []
                array_filter.push(p_subkategori)
                array_filter.push(p_kategori)
                array_filter.push(p_search)
                array_filter.push(p_minprice)
                array_filter.push(p_maxprice)
                array_filter.push(p_rating)
                array_filter.push(p_sort)

                
                let url = ''
                for(let i = 0; i < array_filter.length; i ++){
                    if(array_filter[i].substring(array_filter[i].indexOf('=') + 1) != ''){
                        url += '&'+array_filter[i]
                    }
                }

                this.url = url
            }
        },
        created(){
            this.setUrl()
        },
        watch:{
            '$route.query': {
                handler: function() {
                    this.setUrl()
                },
                deep: true,
                immediate: true
            }
        }
    }
</script>