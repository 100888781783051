<template>
    <!-- <div class="border-data">
        <div class="background-white" >
            <div style="overflow-x:scroll;">
                <form>
                    <table>
                        <thead>
                            <tr>
                                <th>Nama Produk</th>
                                <th style="min-width:100px;">Jumlah</th>
                                <th style="min-width:110px;">Harga Nego (Admin)</th>
                                <th style="min-width:160px;">Harga Nego (Customer)</th>
                            </tr>
                        </thead>
                        <tbody> -->
                            <tr v-if="harga.length > 0">
                                <td class="td-nama-produk">
                                    <div class="nama-produk">
                                        <h6 class="px-2">{{nama_produk}}</h6>
                                        <input type="hidden" name="transaction_detail_id[]" :id="'idTransactionDetail'+ dataindexplusone" :value="idTransactionDetail">
                                    </div>
                                </td>
                                <td style="max-width:80px;">
                                    <div class="w-100">
                                        <div v-for="(hargaXxx, index2) in harga" :key="index2">
                                            <span v-if="index2 === harga.length - 1">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(hargaXxx?.new_price).toFixed()) }}</span>
                                            <input type="hidden" v-if="index2 === harga.length - 1" name="old_price[]" :id="'idOldPrice'+ dataindexplusone" :value="parseInt(hargaXxx?.new_price)">
                                        </div>
                                    </div>
                                </td>
                                <td>
                                   <input v-model="negoharga.hargadata" @change="updateharga" type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="form-control mt-1" id="nego_harga" aria-describedby="satuan" placeholder="Masukkan Harga Nego">
                                </td>
                            </tr>
                        <!-- </tbody>
                    </table>
                    <button type="submit" class="btn btn-danger mt-3 mb-3 float-right">Simpan</button>
                </form>
            </div>
            <div class="w-100 mt-3" style="display: flow-root;">
                <a @click="openmodalharga()" class="btn btn-danger float-right" v-if="nego.length > 0">Ajukan Negosiasi Harga</a>
            </div>
        </div> -->

        <!-- <div v-if="modalharga" class="modal fade show" aria-modal="true" style="display: block; background-color: rgba(0,0,0,0.3);" tabindex="-1" role="dialog" id="modalchangepassword" >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body-top-keranjang">
                        <button type="button" @click="closemodalharga()" class="close btn" data-bs-dismiss="modal" aria-bs-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="container-fluid">
                        <div class="header-form px-1">
                            <h4>Pengajuan Harga</h4>
                        </div>
                        <form @submit.prevent="updateharga">
                            <div class="form-group mt-3">
                                <label for="satuan">Harga yang diajukan</label>
                                <input v-model="negoharga.hargadata" type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="form-control mt-1" id="nego_harga" aria-describedby="satuan" placeholder="Masukkan Harga Nego">
                            </div>
                            

                            <button type="submit" class="btn btn-danger mt-3 mb-3 float-right">Simpan</button>
                        </form>
                    </div>
                </div>
            </div>
        </div> -->
    <!-- </div> -->
</template>
<script>
export default {
    props:['id','dataindex','nama_produk','unit','harga','datanegosiasi', 'dataindexplusone', 'idTransactionDetail'],
    name: 'DaftarProduct',
    data(){
        return {
            modalharga:false,
            negoharga:{
                dataindex:this.dataindex,
                id:this.id,
                old_harga:'',
                hargadata:''
            },
            old_price:[],
        }
    },
    mounted(){
    },

    methods: {
        openmodalharga(){
            this.modalharga =true
        },
        closemodalharga(){
            this.modalharga =false
        },
        updateharga(){
                let harga = this.harga[this.harga.length-1].new_price
                this.negoharga.old_harga = parseInt(harga)
                this.$emit('updatenegoharga', this.negoharga)
        }
    }
}
</script>
<style scoped>
.form-group label{
    font-weight: 600;
    font-size: 1.3rem;
}
/* .header-form{
    border-bottom: solid 1px grey;
}
.data-tabel, tr, td, th{
    border:0px !important;
}
.head-tabel{
    border:0px !important;
    border-bottom:1px solid grey !important;
} */
.td-nama-produk{
    max-width: 200px !important;
}
.nama-produk{
    display: flex;
    margin: 0px;
    margin-right: 0px !important;
}
.nama-produk h6{
    vertical-align: inherit;
    margin: auto 0;
}
.discount-harga{
    text-align: right;
    margin-bottom: 5px;
    /* max-width: 40px; */
}
.discount-harga span{
    background-color: #dc3545;
    color:#ffffff;
    border-radius: 5px;
    /* max-width: 40px; */
}
.ringkasan{
    display: flex;
    justify-content: space-between;
}
.total-ringkasan{
    border-top: solid 1px grey;
    display: flex;
    justify-content: space-between;
}

@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
    /* .img-profile{
        width: auto;
    } */
}

@media (min-width: 1023px) {
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>