<template>
    <div class="sidebar">
        <div @click="closeSidebar" class="sidebar-background"></div>
        <div class="sidebar-container">
            <div class="d-flex justify-content-between align-items-center sidebar-header">
                <div class="sidebar-logo-container">
                    <router-link to="/" class=" custom-link logo-container" @click="closeSidebar">
                        <!-- <img :src="require(`../assets/logo-armed.png`)" alt="" class="logo side_ogo"> -->
                        <logo></logo>
                    </router-link>
                </div>
                <div class="sidebar-close-container">
                    <a @click="closeSidebar" class="sidebar-close-icon">
                        <i class='bx bx-x'></i>
                    </a>
                </div>
            </div>
            <h3 class="title mb-2">Kategori Produk</h3>
            <ul class="accordion-custom sidebar-accordion border-0 p-0">
                <li v-for="(kategori, index) in dataCategories" :key="index"  >
                    <a v-if="kategori.subcategory.length > 0" class="accordion-custom-btn" @click="openAccordion($event)">
                        <div class="d-flex align-items-center">
                            <i class='bx bx-dots-vertical text-secondary'></i>
                            <span class="mx-2">{{kategori.name}}</span> 
                        </div>
                        <span class="icon"><i class='bx bx-chevron-down'></i></span>  
                    </a>
                    <router-link v-else-if="kategori.subcategory.length == 0" :to="'/produk?kategori='+kategori.id" class=" accordion-custom-btn" :id="'kategori-'+kategori.id" @click="closeSidebar" >
                        <div class="d-flex align-items-center">
                            <i class='bx bx-dots-vertical text-secondary'></i>
                            <span class="mx-2" >{{kategori.name}}</span> 
                        </div>
                    </router-link>
                    <ul class="accordion-custom-content">
                        <li class="subkategori-item "  v-for="(subcategory, number) in kategori.subcategory" :key="number">
                            <router-link :to="'/produk?kategori='+subcategory.category_id+'&sub_kategori='+subcategory.id" class="btn btn-link btn-sidebar-submenu d-flex align-items-center" @click="closeSidebar">
                                <i class='bx bx-chevrons-right'></i>
                                <span class="" > {{subcategory.name}}</span> 
                            </router-link>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Logo from '@/components/Logo.vue';
export default {
    name: 'sidebar-component',
    components: {
        Logo
    },
    props:['dataCategories','dataSubCategories'],
    methods: {
        closeSidebar(){
            document.querySelector('.sidebar').classList.remove('active')
            if(document.body.classList.contains('no-scroll')){
                document.body.classList.remove('no-scroll')
            }
        },
        openAccordion(event){
            const accordion = document.querySelectorAll('.accordion-custom-btn');

            if(event.currentTarget.classList.contains("active")){
                event.currentTarget.classList.remove("active")
            }else{
                accordion.forEach(n => {
                    n.classList.remove('active')
                });
                event.currentTarget.classList.add("active")
            }

        },
    },

    mounted(){
    }
}
</script>

<style>

</style>
