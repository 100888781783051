<template>
    <div class="section section-modif mb-5 loading-skeleton">
        <div class="container">
            <div class="row row-bpdpayment">
                <div class="d-flex justify-content-end">
                    <nav aria-label="breadcrumb" class="">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a href="../profil/beranda" class="custom-link">Beranda</a>
                            </li>
                            <li class="breadcrumb-item">
                                <a aria-current="page" class="router-link-active router-link-exact-active text-secondary">Metode Pembayaran BPD Bali</a>
                            </li>
                        </ol>
                    </nav>
                </div>
                <div class="col-xs-12 col-sm-5 col-md-4 left-content-bpdpaymet">
                    <div class="border-bottom border-white pb-2">
                        <span class="title-bpdpayment">Nama Pembeli</span><br>
                        <span class="subtitle-bpdpayment"><b>Nama Pembeli</b></span>
                    </div>
                    <div class="border-bottom border-white pt-3 pb-2">
                        <span class="title-bpdpayment">Tanggal Pembelian</span><br>
                        <span class="subtitle-bpdpayment"><b>Tanggal Pembelian</b></span>
                    </div>
                    <div class="border-bottom border-white pt-3 pb-2">
                        <span class="title-bpdpayment">No Invoice</span><br>
                        <span class="subtitle-bpdpayment"><b>No Invoice</b></span>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-7 col-md-8 right-content-bpdpaymet p-3">
                    <div class="row">
                        <div class="col-sm-12 col-md-2 text-center">
                            <img class="logo-bpdpayment" src="../../../assets/bpdLogo.png">
                        </div>
                        <div class="col-sm-12 col-md-10 title-logo-bpdpayment">
                            <b>Bank Pembangunan Daerah Bali</b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <hr class="border border-2 border-secondary">
                        </div>
                        <div class="col-md-12 text-center">
                            <b>Metode Pembayaran :</b> Transfer Bank Pembangunan Daerah Bali<br>
                            <b>Lakukan Pembayaran Sebelum :</b> Lakukan Pembayaran Sebelum : WITA
                        </div>
                        <div class="col-md-12 text-center my-3">
                            <div>Total Yang Harus Dibayar :</div>
                            <div class="title-price-bpdpayment"><b>Rp. Total Yang Harus Dibayar</b></div>
                        </div>
                    </div>
                    <div class="w-100">
                        <button type="button" class="mt-3 btn btn-danger btn-upload">
                            <i class='bx bx-upload' ></i> Upload Bukti Pembayaran
                        </button>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-12 col-md-6 text-center mb-2">
                            <div class="border border-2 p-4 rounded-15">
                                <h6>Pembayaran Menggunakan QRIS</h6>
                                <img class="barcode-bpdpayment" src="../../../assets/sampleBarcode.jpeg">
                                <div class="text-left">
                                    Tata cara pembayaran :
                                    <ul class="list-method-bpdpayment">
                                        <li>Buka aplikasi mobile-banking yang anda miliki.</li>
                                        <li>Scan QR Code diatas ini.</li>
                                        <li>Input jumlah nominal yang ingin dibayarkan.</li>
                                        <li>Pilih bayar dan masukkan nama lengkap Anda.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 text-center mb-2">
                            <div class="border border-2 p-4 rounded-15">
                                <h6>Pembayaran Menggunakan Virtual Account</h6>
                                <div class="text-left">
                                    Tata cara pembayaran :
                                    <ul class="list-method-bpdpayment">
                                        <li>Buka aplikasi m-banking di smartphone.</li>
                                        <li>Masukkan username dan password.</li>
                                        <li>Pilih menu Transfer > Bank Lain Dalam Negeri.</li>
                                        <li>Masukkan Kode Bank dan Nomor Virtual Account tujuan.</li>
                                        <li>Masukkan nominal yang harus dibayarkan.</li>
                                        <li>Layar akan menunjukkan konfirmasi. Apabila telah sesuai, pilih Lanjutkan / Kirim.</li>
                                        <li>Masukkan PIN transaksi.</li>
                                        <li>Selesai.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-12 text-right">
                            <button>
                            <i class='bx bx-arrow-back' ></i> Kembali</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.loading-skeleton h1,
.loading-skeleton h2,
.loading-skeleton h3,
.loading-skeleton h4,
.loading-skeleton h5,
.loading-skeleton h6,
.loading-skeleton p,
.loading-skeleton li,
.loading-skeleton .btn,
.loading-skeleton label,
.loading-skeleton .image-user,
.loading-skeleton .datatable,
.loading-skeleton .form-control {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee;
  border-color: #eee;
}
.loading-skeleton h1::placeholder,
.loading-skeleton h2::placeholder,
.loading-skeleton h3::placeholder,
.loading-skeleton h4::placeholder,
.loading-skeleton h5::placeholder,
.loading-skeleton h6::placeholder,
.loading-skeleton p::placeholder,
.loading-skeleton li::placeholder,
.loading-skeleton .btn::placeholder,
.loading-skeleton .datatable::placeholder,
.loading-skeleton .image-user::placeholder,
.loading-skeleton label::placeholder,
.loading-skeleton .form-control::placeholder {
  color: transparent;
}

@keyframes loading-skeleton {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;
}
.loading-skeleton img {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
.loading-skeleton .datatable {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
    .btn-upload{
        display: block;
        margin: 0 auto;
    }
    .left-content-bpdpaymet {
        background-color: #e40c0c;
        border-radius: 10px 0px 0px 10px;
        color: #ffffff;
        padding: 30px;
    }
    .title-bpdpayment {
        font-size: 12px;
    }
    .subtitle-bpdpayment {
        font-size: 24px;
    }
    .right-content-bpdpaymet {
        background-color: #ffffff;
        border-radius: 0px 10px 10px 0px;
    }
    .logo-bpdpayment {
        width: 60px;
        height: auto;
    }
    .title-logo-bpdpayment {
        font-size: 26px;
        margin-top: 10px;
        margin-left: -20px;
    }
    .border-2 {
        border-width:2px !important;
    }
    .rounded-15 {
        border-radius: 15px;
    }
    .title-price-bpdpayment {
        font-size: 40px;
        color: #e40c0c;
        margin-top: -10px;
    }
    .barcode-bpdpayment {
        width: 200px;
        height: auto;
    }
    .list-method-bpdpayment {
        list-style-type: decimal; 
        margin-left:15px;
    }
    .btn-back-bpdpayment {
        background-color: #e40c0c;
        color: #FFFFFF;
    }
    @media (max-width: 1200px) { 
        .row-bpdpayment {
            margin: 0px 3px;
        }
        .subtitle-bpdpayment {
            font-size: 20px;
        }
    }
    @media (max-width: 992px) { 
        .title-logo-bpdpayment {
            font-size: 24px;
            margin-top: 10px;
        }
        .subtitle-bpdpayment {
            font-size: 16px;
        }
    }
    @media (max-width: 768px) { 
        .title-logo-bpdpayment {
            margin-top: 0px;
            margin-left: 0px;
            text-align: center;
        }
        .left-content-bpdpaymet {
            padding: 20px;
        }
        .subtitle-bpdpayment {
            font-size: 18px;
        }
    }
    @media (max-width: 576px) { 
        .left-content-bpdpaymet {
            border-radius: 10px 10px 0px 0px;
        }
        .right-content-bpdpaymet {
            border-radius: 0px 0px 10px 10px;
        }
    }
</style>