<template>
    <skeleton-timeline v-if="loading"></skeleton-timeline>
    <div v-if="!loading" class="container-fluid py-3">
        <div class="row">
            <div class="col-md-12">
                <ul class="timeline">
                    <li class="timeline-item" v-for="(timeline, index) in datatimeline" :key="index">
                        <div v-if="toLower(timeline?.section_change)== 'make order'">
                            <p class="fw-bold" v-if="toLower(timeline.name_section_change) == 'make order'"><b>Pembuatan Order</b></p>
                            <p class="text-muted mb-2 fw-bold"> {{dateTime(timeline.created_at)}} WITA</p>
                            <h6>Total Pembayaran</h6>
                            <p>{{timeline.new_value}}</p>

                        </div>
                        <div v-else-if="toLower(timeline?.section_change) == 'price'">
                            <p class="fw-bold" v-if="toLower(timeline.name_section_change) == 'change price'"><b>Permintaan Nego tahap {{timeline.section_nego}}</b></p>
                            <p class="text-muted mb-2 fw-bold"> {{dateTime(timeline.created_at)}} WITA</p>
                            <div class="text-muted w-100" style="overflow-x:scroll;">
                                <table class="table table-striped table-hover table-timeline" >
                                    <thead>
                                        <tr>
                                            <td>
                                                Nama Produk
                                            </td>
                                            <td>Harga</td>
                                            <td>Nego Harga</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{timeline.transaction_detail.name_product}}</td>
                                            <td>{{timeline.old_value}}</td>
                                            <td>{{timeline.new_value}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div v-else-if="toLower(timeline?.section_change) == 'shipping cost'">
                            <p class="fw-bold" v-if="toLower(timeline.name_section_change) == 'change shipping cost'"><b>Permintaan Nego Ongkir tahap {{timeline.section_nego}}</b></p>
                            <p class="text-muted mb-2 fw-bold"> {{dateTime(timeline.created_at)}} WITA</p>
                            <h6>Nego Ongkir</h6>
                            <div class="text-muted w-100" style="overflow-x:scroll;">
                                <table class="table table-striped table-hover table-timeline" >
                                    <thead>
                                        <tr>
                                            <td>Harga</td>
                                            <td>Nego Harga</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{timeline.old_value}}</td>
                                            <td>{{timeline.new_value}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div v-else-if="toLower(timeline?.section_change) == 'status'">
                            <p class="fw-bold" v-if="toLower(timeline.name_section_change) == 'status' || toLower(timeline.name_section_change) == 'update status payment'"><b>Perubahan Status</b></p>
                            <p class="text-muted mb-2 fw-bold"> {{dateTime(timeline.created_at)}} WITA</p>
                            <h6>Status</h6>
                            <p>{{timeline.old_value}} <i class='bx bx-right-arrow-alt'></i> {{timeline.new_value}}</p>

                        </div>
                        <div v-else-if="toLower(timeline?.section_change) == 'request payment'">
                            <p class="fw-bold" v-if="toLower(timeline.name_section_change) == 'request payment'"><b>Permintaan Pembayaran</b></p>
                            <p class="text-muted mb-2 fw-bold"> {{dateTime(timeline.created_at)}} WITA</p>
                            <h6>Total Pembayaran</h6>
                            <p>{{timeline.new_value}}</p>

                        </div>
                        <div v-else-if="toLower(timeline?.section_change) == 'payment'">
                            <p class="fw-bold" v-if="toLower(timeline.name_section_change) == 'update status payment'"><b>Pembayaran</b></p>
                            <p class="text-muted mb-2 fw-bold"> {{dateTime(timeline.created_at)}} WITA</p>
                            <h6>Total Pembayaran</h6>
                            <p>{{timeline.new_value}}</p>

                        </div>
                        <div v-else-if="toLower(timeline?.section_change) == 'upload payment'">
                            <p class="fw-bold" v-if="toLower(timeline.name_section_change) == 'upload proof of payment'"><b>Mengirim Bukti Pembayaran</b></p>
                            <p class="text-muted mb-2 fw-bold"> {{dateTime(timeline.created_at)}} WITA</p>
                            <h6>Total Pembayaran</h6>
                            <p>{{timeline.new_value}}</p>

                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import SkeletonTimeline from '../../components/SkeletonUser/TransaksiUser/SkeletonLog.vue'
export default {
    setup() {
        
    },
    data(){
        return{
            loading:true,
            datatimeline:{}
        }
    },
    components:{
        SkeletonTimeline
    },
    created(){
        let transaksi_id = this.$router.currentRoute._value.query.id_transaksi
        axios.get('/transaction/log/'+transaksi_id).then(res => {
            this.loading = false
            this.datatimeline = res.data.data.log
        })
        .catch(() =>{
            this.loading = false
        })
    },
    methods:{
        dateTime(value) {
            return moment(value).format("DD MMMM YYYY hh:mm:ss");
        },
        toLower(value){
            return value.toLowerCase()
        },
    }
}
</script>
<style scoped>
    .timeline {
        border-left: 1px solid hsl(0, 0%, 90%);
        position: relative;
        list-style: none;
        max-height: 400px;
        overflow-y: scroll;

    }
    .timeline .timeline-item {
        position: relative;
        padding: 15px;
    }
    .timeline .timeline-item:after {
        position: absolute;
        display: block;
        top: 22px;
    }
    .timeline .timeline-item:after {
        background-color: hsl(0, 0%, 90%);
        left: -6px;
        border-radius: 50%;
        height: 11px;
        width: 11px;
        content: "";
    }
    .timeline-item h5{
        font-size: 0.9rem;
    }
    .timeline-item h6, .timeline-item p{
        font-size: 0.8rem;
    }
    .table-timeline th, .table-timeline td{
        font-size: 0.8rem;
        min-width:140px;
    }
    @media (min-width: 319px) {
    }

    @media (min-width: 374px) {
    }

    @media (min-width: 424px) {
    }

    @media (min-width: 767px) {
        .timeline-item h5{
            font-size: 1.25rem;
        }
        .timeline-item h6, .timeline-item p{
            font-size: 1rem;
        }
        .table-timeline th, .table-timeline td{
            font-size: 1rem;
            min-width:150px;
        }
    }

    @media (min-width: 1023px) {
    }

    @media (min-width: 1439px) {
    }

    @media (min-width: 2000px) {
    }
</style>