<template>
    <div>
        <div class="card p-3">
            <div style="display:flex;">
                <div style="display:flex;vertical-align: baseline; ">
                    <firgure class="figure_produk">
                        <img :src="$imgProductUrl+image_product" alt="">
                    </firgure>
                    <div class="px-1 py-2 py-md-4 py-xl-5 px-md-3">
                        <h6 class="text-ulasan">{{name_product}}</h6>
                    </div>
                </div>
                <!-- <div class="py-5">
                    <h1 style="display:flex;"><i class='bx bxs-star align-middle'  style="color:yellow;"></i> 4.0</h1>
                </div> -->
            </div>
            <div>
                <h6 class="mt-3">Penilaian</h6>
                <h3 v-for="(n,index) in star" :key="index" class="bx bxs-star bintang"></h3>
            </div>
            <div v-if="notes">
                <div>
                    <h6>Komentar</h6>
                    <p class="text-ulasan">{{notes}}</p>
                </div>
            </div>
            <div v-if="respon_notes">
                <div>
                    <h6>Komentar Admin</h6>
                    <p class="text-ulasan">{{respon_notes}}</p>
                </div>
            </div>
            <div class="w-100 list-data-bukti mt-2" >
                <div class="card p-3 ml-2" v-for="(dataimg,index) in image" :key="index">
                    <div v-if="cekextensi(dataimg.image) == 'jpg' || cekextensi(dataimg.image) == 'png' || cekextensi(dataimg.image) == 'jpeg' || cekextensi(dataimg.image) == 'webp'">
                        <img :src="$imgProductUrl+dataimg.image" alt="" style="width:150px;">
                    </div>
                    <div v-else-if="cekextensi(dataimg.image) == 'mp4' || cekextensi(dataimg.image) == 'mpeg' || cekextensi(dataimg.image) == 'mov' || cekextensi(dataimg.image) == '3gp' || cekextensi(dataimg.image) == 'mkv' || cekextensi(dataimg.image) == 'avi'">
                        <video width="320" height="240" controls>
                            <source :src="$imgProductUrl+dataimg.image" :type="'video/'+cekextensi(dataimg.image)">
                        </video>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:['image_productdata','name_productdata','stardata','notesdata','respon_notesdata','imagedata'],
    data(){
        return{
            image_product:'',
            name_product:'',
            star:'',
            notes:'',
            respon_notes:'',
            image:{},

        }
    },
    mounted(){
        this.image_product = this.image_productdata
        this.name_product = this.name_productdata
        this.star = this.stardata
        this.notes = this.notesdata
        this.respon_notes = this.respon_notesdata
        this.image = this.imagedata
    },
    methods:{
        cekextensi(value){
            return value.split('.').pop()
        },
        addInput(){
            this.inputs.push(this.inputs.length + 1)
        }
    }
}
</script>

<style scoped>

.comment-star {
    position: relative;
    width: 400px;
    padding: 0px;
    border-radius: 5px;
    display: flex;
    text-align: left !important;
    /* align-items: right; */
}

.star-widget input {
    display: none;
}

.comment-star label {
    font-size: 1.5rem;
    color: #444;
    padding: 5px;
    float: right;
    transition: all 0.2s ease;
}

.header-content{
    color: rgba(0, 0, 0, 0.4);
}
.list-data-bukti{
    display: flex;
}
.list-data-bukti i{
    text-align: center;
    font-size: 5rem;
}
.list-data-bukti p{
    margin-bottom: 0px !important;
}
.list-data-bukti .card{
    margin-left: 0.5rem !important;
}
.data-user h5{
    margin-bottom: 5px !important;
    color: #000;
    font-weight: 600;
}
.img-profile{
    width: 100px;
    height: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.img-profile img{
    width: 100%;
}

input:not(:checked)~label:hover,
input:not(:checked)~label:hover~label {
    color: #fd4;
}

input:checked~label {
    color: #fd4;
}

input#rate-5:checked~label {
    color: #fe7;
}
.bintang{
    color: #fd4;
    font-size: 2rem;
}
.text-ulasan{
    font-size: 0.8rem;
}
.figure_produk{
    max-width: 80px;
    max-height: 80px;
}
.nilai_web{
    display: none;
}
.nilai_mobile{
    display: block;
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
  .bintang{
    font-size: 3rem;
    }
    .text-ulasan{
        font-size: 1rem;
    }
    .figure_produk{
        max-width: 150px;
        max-height: 150px;
    }
    .nilai_web{
    display: block;
    }
    .nilai_mobile{
        display: none;
    }
}

@media (min-width: 1023px) {
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}

</style>
