<template>
    <div>
        <skeleton-nego v-if="loading"></skeleton-nego>
        <div v-if="!loading">
            <!-- <div class="background-white p-md-3" v-for="(produk, index) in transaction_detail" 
                :key="index" >
                <daftar-produk
                :nama_produk="produk?.name_product"
                :dataindex="index"
                :image_produk="$imgProductUrl+produk?.image"
                :unit="produk?.qty"
                :harga="produk?.price"
                :harga_diskon="produk?.price_after_discount"
                :subtotal="produk?.subtotal"
                :promo="produk?.discount"
                :catatan="produk?.notes"></daftar-produk>
                <div class="mt-3 p-3 data-ongkir" style="overflow-x:scroll;">
                    <h5 class="mt-3">Pengiriman Partial</h5>
                    <div v-for="(partialongkir,dataindex) in produk.shipping" :key="dataindex">
                        <table class="mt-2 data-tabel" >
                            <thead>
                                <tr style="border:0px !important;">
                                    <th style="min-width:80px;">jumlah</th>
                                    <th style="min-width:130px;">Harga Ongkir</th>
                                    <th style="min-width:180px;" colspan="2">Nego Harga Ongkir</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="border:0px !important;" >
                                    <td>{{partialongkir.qty}} Unit</td>
                                    <td>Rp. {{partialongkir.shipping_cost}}</td>
                                    <td>
                                        <input class="form-control" type="text" v-model="negoongkir" name="nego-harga">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> -->
            <!-- <div class="w-100 p-4 data-status">
                <h4>Status Negosiasi</h4>
                <p class="disetujui"><i class='bx bxs-check-circle'></i> Disetujui Pada</p>
                <p class="pl-3">02 Mei 2022</p>
            </div> -->
            <div class="mt-2 w-100 p-2" v-for="datanego in datanegosiasi" :key="datanego.id">
                <div class="w-100 bg-secondary text-white" style="text-align:center;">Batch {{datanego.nego_step}}</div>
                <div style="overflow-x:scroll;">
                    <table>
                        <thead>
                            <tr>
                                <th>Nama Produk</th>
                                <th style="min-width:110px;">Harga Awal<small>(unit)</small></th>
                                <th style="min-width:140px;">Harga Nego<small>(unit)</small></th>
                            </tr>
                        </thead>
                        <tbody>
                            <data-nego
                            v-for="(databarang,index) in datanego.product_negotiation" :key="index"
                            :dataindex="index"
                            :nama_produk="databarang.name_product"
                            :harga_awal="databarang.old_price"
                            :harga_nego="databarang.new_price"
                            :note="databarang.note"
                            :shipping_cost_negotiation="datanego.shipping_cost_negotiation"></data-nego>
                            <tr v-if="datanego.shipping_cost_negotiation?.old_price || datanego.shipping_cost_negotiation?.new_price">
                                <td style="vertical-align:baseline;">
                                    <p style="margin-bottom:0px;"><b>Nego Ongkir </b></p>
                                </td>
                                <td v-if="datanego.shipping_cost_negotiation?.old_price">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(datanego.shipping_cost_negotiation?.old_price).toFixed()) }}</td>
                                <td v-if="datanego.shipping_cost_negotiation?.new_price">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(datanego.shipping_cost_negotiation?.new_price).toFixed()) }}</td>
                            </tr>
                            <tr v-for="(databarang,index) in datanego.product_negotiation.slice(0,1)" :key="index">
                                <td colspan="4" style="vertical-align:baseline;">
                                    <p style="margin-bottom:0px;"><b>Catatan Negosiasi </b></p>
                                    <p v-if="databarang.note">{{databarang.note}}</p>
                                    <p v-else>Tidak ada catatan</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="mt-2 w-100 p-2" id="idFormNego" v-if="is_admin == true && datatransaksi?.status == 'Pending'">
                <div class="w-100 bg-secondary text-white" style="text-align:center;">Batch {{datanegosiasi.length+1}}</div>
                <div style="overflow-x:scroll;">
                    <div>
                        <form>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Nama Produk</th>
                                        <th style="min-width:110px;">Harga Nego (Admin)</th>
                                        <th style="min-width:160px;">Harga Nego (Customer)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <nego-produk v-for="(produk, index) in datatransaksi.transaction_detail" 
                                    :key="index" @updatenegoharga="updatenegoharga"
                                        :nama_produk="produk?.name_product"
                                        :dataindex="index"
                                        :dataindexplusone="index + 1"
                                        :id="produk?.id"
                                        :harga="produk?.price_negotiation"
                                        :datanegosiasi="datanegosiasi"
                                        :idTransactionDetail="produk?.id">
                                    </nego-produk>
                                    <tr>
                                        <td v-if="datanegosiasi[datanegosiasi.length -1]?.shipping_cost_negotiation?.new_price">
                                            <b>Nego Ongkir (Admin)</b>
                                        </td>
                                        <td v-if="datanegosiasi[datanegosiasi.length -1]?.shipping_cost_negotiation?.new_price">
                                            <div v-for="(datanegosiasiXxx, index) in datanegosiasi" :key="index">
                                                <span v-if="index === datanegosiasi.length - 1">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(datanegosiasiXxx?.shipping_cost_negotiation?.new_price).toFixed()) }}</span>
                                                <input type="hidden" name="old_shipping_price" id="idOldShippingPrice" :value="parseInt(datanegosiasiXxx?.shipping_cost_negotiation?.new_price)" v-if="index === datanegosiasi.length - 1">
                                            </div>
                                        </td>
                                        <td v-if="datanegosiasi[datanegosiasi.length -1]?.shipping_cost_negotiation?.new_price">
                                            <input type="text" v-model="pengajuanongkir" class="form-control mt-1" name="new_shipping_price" aria-describedby="satuan" id="idNewShippingPrice" placeholder="Masukkan Harga Nego">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                            <div class="form-group">
                                                <div><b>Catatan</b></div>
                                                <textarea class="form-control mt-2" v-model="catatan" name="notes" id="idNotes" rows="3"></textarea>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="mt-3 text-right">
                                <button class="btn btn-danger" type="button" v-on:click="ajukanNego()">
                                    <i class='bx bxs-color' ></i> Lanjutkan Negosiasi
                                </button>
                                <button class="btn btn-red mx-2" type="button" v-on:click="openmodalform()">
                                    <i class='bx bxs-checkbox-checked'></i> Setujui Negosiasi
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- <div class="p-3" style="overflow-x:scroll;">
                    <h5 class="mt-3">Nego Pengiriman</h5>
                    <form @submit.prevent="updatehargaongkir">
                        <table class="mt-2 data-tabel" >
                            <thead>
                                <tr style="border:0px !important;">
                                    <th style="min-width:80px;">jumlah</th>
                                    <th style="min-width:130px;">Harga Ongkir</th>
                                    <th style="min-width:130px;">Nego Ongkir</th>
                                    <th style="min-width:180px;" colspan="2">Nego Harga Ongkir</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="border:0px !important;" >
                                    <td>{{datatransaksi.total_qty}} Unit</td>
                                    <td>Rp. {{datatransaksi.shipping_cost}}</td>
                                    <td>
                                        <input v-model="pengajuanongkir" type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="form-control mt-1" id="nego_harga" aria-describedby="satuan" placeholder="Masukkan Harga Nego">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="w-100 mt-3" style="display: flow-root;">
                            <button type="submit" class="btn btn-danger mt-3 mb-3 float-right">Simpan</button>
                        </div>
                    </form>
                </div> -->
                <!-- <div class="form-group p-3">
                    <label for="respon">Catatan</label>
                    <textarea v-model="catatan" class="form-control mt-2" id="respon" rows="3">
                    </textarea>
                </div> -->
                <!-- <button class="btn btn-danger mx-1" @click="ajukanNego">
                    Lanjutkan Negosiasi
                </button> -->
            </div>
            <div class="w-100" style="display: flow-root;" v-if="toLowerCase(datatransaksi.status) == 'confirmed'">
                <button class="btn btn-red mx-2 float-right" type="button" v-on:click="openmodalform()">
                    <i class='bx bxs-checkbox-checked'></i> Setujui Negosiasi
                </button>
            </div>
            <div v-if="modalform" class="modal fade show" aria-modal="true" style="display: block; background-color: rgba(0,0,0,0.3);" tabindex="-1" role="dialog" id="modalchangepassword" >
                <!-- <div class="modal" tabindex="-1" role="dialog" id="modalchangepassword" > -->
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Setujui Negosiasi</h5>
                        </div>
                        <div class="modal-body form-nego-data">
                            <div class="form-group mt-md-3">
                                <!-- <label for="satuan_kerja">Nama Satuan Kerja</label> -->
                                <input type="text" class="form-control mt-2 mt-md-4" id="satuan_kerja" :class="{ 'border-danger': v$.satuan_kerja.$error }" v-model="satuan_kerja" aria-describedby="diterima" placeholder="Nama Satuan Kerja">
                                <div class="text-danger" v-if="v$.satuan_kerja.$error">
                                    <small v-if="v$.satuan_kerja.required">Nama Satuan Kerja Wajib Diisi</small>
                                </div>
                            </div>
                            <div class="form-group mt-md-3">
                                <!-- <label for="lembaga">Nama Kementrian/Lembaga/Pemerintahan</label> -->
                                <input type="text" class="form-control mt-2 mt-md-4" id="lembaga" :class="{ 'border-danger': v$.lembaga.$error }" v-model="lembaga" aria-describedby="diterima" placeholder="Nama Kementerian/Lembaga/Pemerintahan">
                                <div class="text-danger" v-if="v$.lembaga.$error">
                                    <small v-if="v$.lembaga.required">Nama Kementerian/Lembaga/Pemerintahan Wajib Diisi</small>
                                </div>
                            </div>
                            <div class="form-group mt-md-3">
                                <!-- <label for="paket_kerja">Nama Paket Pekerjaan</label> -->
                                <input type="text" class="form-control mt-2 mt-md-4" id="paket_kerja" :class="{ 'border-danger': v$.paket_kerja.$error }" v-model="paket_kerja" aria-describedby="diterima" placeholder="Nama Paket Pekerjaan">
                                <div class="text-danger" v-if="v$.paket_kerja.$error">
                                    <small v-if="v$.paket_kerja.required">Nama Paket Pekerjaan Wajib Diisi</small>
                                </div>
                            </div>
                            <div class="form-group mt-md-3">
                                <!-- <label for="tahun_anggaran">Tahun Anggaran</label> -->
                                <input type="tel" maxlength="9" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="form-control mt-2 mt-md-4" id="tahun_anggaran" :class="{ 'border-danger': v$.tahun_anggaran.$error }" v-model="tahun_anggaran" aria-describedby="diterima" placeholder="Tahun Anggaran">
                                <div class="text-danger" v-if="v$.tahun_anggaran.$error">
                                    <small v-if="v$.tahun_anggaran.required">Tahun Anggaran Wajib Diisi</small>
                                </div>
                            </div>
                            <div class="form-group mt-md-3">
                                <!-- <label for="sumber_dana">Sumber Dana</label> -->
                                <input type="text" class="form-control mt-2 mt-md-4" id="sumber_dana" :class="{ 'border-danger': v$.sumber_dana.$error }" v-model="sumber_dana" aria-describedby="sumber_dana" placeholder="Sumber Dana">
                                <div class="text-danger" v-if="v$.sumber_dana.$error">
                                    <small v-if="v$.sumber_dana.required">Sumber Dana Wajib Diisi</small>
                                </div>
                            </div>
                            <div class="form-group mt-md-3">
                                <!-- <label for="kode_rekening">Kode Rekening Anggaran</label> -->
                                <input type="tel" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="form-control mt-2 mt-md-4" id="kode_rekening" :class="{ 'border-danger': v$.kode_rekening.$error }" v-model="kode_rekening" aria-describedby="kode_rekening" placeholder="Kode Rekening Anggaran">
                                <div class="text-danger" v-if="v$.kode_rekening.$error">
                                    <small v-if="v$.kode_rekening.required">Kode Rekening Anggaran Wajib Diisi</small>
                                </div>
                            </div>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text mt-2 mt-md-4" id="basic-addon1">Rp.</span>
                                </div>
                                <input type="tel" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="form-control mt-2 mt-md-4" id="pagu_anggaran" :class="{ 'border-danger': v$.pagu_anggaran.$error }" v-model="pagu_anggaran" aria-describedby="pagu_anggaran" placeholder="Pagu Anggaran">
                            </div>
                            <div class="text-danger" v-if="v$.pagu_anggaran.$error">
                                <small v-if="v$.pagu_anggaran.required">Pagu Anggaran Wajib Diisi</small>
                            </div>
                            <!-- <div class="form-group mt-md-3"> -->
                                <!-- <label for="pagu_anggaran">Pagu Anggaran</label> -->
                                <!-- <input type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="form-control mt-2 mt-md-4" id="pagu_anggaran" :class="{ 'border-danger': v$.pagu_anggaran.$error }" v-model="pagu_anggaran" aria-describedby="pagu_anggaran" placeholder="Pagu Anggaran">
                                <div class="text-danger" v-if="v$.pagu_anggaran.$error">
                                    <small v-if="v$.pagu_anggaran.required">Pagu Anggaran Wajib Diisi</small>
                                </div>
                            </div> -->
                            <div class="container-fluid row option-form">
                                <div class="col-md-6 col-12">
                                    <select class="form-select mt-3 mt-md-4" v-model="dataatas" aria-label="Default select example">
                                        <option value="ppk" selected>PPK</option>
                                        <option value="pengguna_anggaran">Pengguna Anggaran</option>
                                        <option value="kuasa_pengguna_anggaran">Kuasa Pengguna Anggaran</option>
                                    </select>
                                </div>
                                <div class="col-md-6 col-12" v-if="dataatas == 'ppk'">
                                    <div class="form-group mt-md-3">
                                        <!-- <label for="nama_ppk">Nama PPK</label> -->
                                        <input type="text" class="form-control mt-2" id="nama_ppk" v-model="nama_ppk" aria-describedby="nama_ppk" placeholder="Nama PPK">
                                        <!-- <div class="text-danger" v-if="v$.nama_ppk.$error">
                                            <small v-if="v$.nama_ppk.required">Nama PPK Wajib Diisi</small>
                                        </div> -->
                                    </div>
                                    <div class="form-group mt-md-3">
                                        <!-- <label for="nip_ppk">NIP PPK</label> -->
                                        <input type="tel" class="form-control mt-2" id="nip_ppk" onkeypress="return event.charCode >= 48 && event.charCode <= 57" v-model="nip_ppk" aria-describedby="nip_ppk" placeholder="NIP PPK">
                                        <!-- <div class="text-danger" v-if="v$.nip_ppk.$error">
                                            <small v-if="v$.nip_ppk.required">NIP PPK Wajib Diisi</small>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="col-md-6 col-12" v-if="dataatas == 'pengguna_anggaran'">
                                    <div class="form-group mt-md-3">
                                        <!-- <label for="nama_pa">Nama Pengguna Anggaran</label> -->
                                        <input type="text" class="form-control mt-2" id="nama_pa" v-model="nama_pa" aria-describedby="nama_pa" placeholder="Nama Pengguna Anggaran">
                                        <!-- <div class="text-danger" v-if="v$.nama_pa.$error">
                                            <small v-if="v$.nama_pa.required">Nama Pengguna Anggaran Wajib Diisi</small>
                                        </div> -->
                                    </div>
                                    <div class="form-group mt-md-3">
                                        <!-- <label for="nip_pa">NIP Pengguna Anggaran</label> -->
                                        <input type="tel" class="form-control mt-2" onkeypress="return event.charCode >= 48 && event.charCode <= 57" id="nip_pa" v-model="nip_pa" aria-describedby="nip_pa" placeholder="NIP Pengguna Anggaran">
                                        <!-- <div class="text-danger" v-if="v$.nip_pa.$error">
                                            <small v-if="v$.nip_pa.required">NIP Pengguna Anggaran Wajib Diisi</small>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="col-md-6 col-12" v-if="dataatas == 'kuasa_pengguna_anggaran'">
                                    <div class="form-group mt-md-3">
                                        <!-- <label for="nama_kpa">Nama Kuasa Pengguna Anggaran</label> -->
                                        <input type="text" class="form-control mt-2" id="nama_kpa" v-model="nama_kpa" aria-describedby="nama_kpa" placeholder="Nama Kuasa Pengguna Anggaran">
                                        <!-- <div class="text-danger" v-if="v$.nama_kpa.$error">
                                            <small v-if="v$.nama_kpa.required">Nama Kuasa Pengguna Anggaran Wajib Diisi</small>
                                        </div> -->
                                    </div>
                                    <div class="form-group mt-md-3">
                                        <!-- <label for="nip_kpa">NIP Kuasa Pengguna Anggaran</label> -->
                                        <input type="tel" class="form-control mt-2" onkeypress="return event.charCode >= 48 && event.charCode <= 57" id="nip_kpa"  v-model="nip_kpa" aria-describedby="nip_kpa" placeholder="NIP Kuasa Pengguna Anggaran">
                                        <!-- <div class="text-danger" v-if="v$.nip_kpa.$error">
                                            <small v-if="v$.nip_kpa.required">NIP Kuasa Pengguna Anggaran Wajib Diisi</small>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="container-fluid row option-form">
                                <div class="col-md-6 col-12">
                                    <select class="form-select mt-3 mt-md-4" v-model="databawah" aria-label="Default select example">
                                        <option value="bendahara_umum" @click="databawah" selected>Bendahara Umum</option>
                                        <option value="bendahara_pengeluaran" @click="databawah" >Bendahara Pengeluaran</option>
                                        <option value="bendahara_pembantu"  @click="databawah" >Bendahara pembantu</option>
                                    </select>
                                </div>
                                <div class="col-md-6 col-12" v-if="databawah == 'bendahara_umum'">
                                    <div class="form-group mt-md-3">
                                        <!-- <label for="nama_bu">Nama Bendahara Umum</label> -->
                                        <input type="text" class="form-control mt-2" id="nama_bu" v-model="nama_bu" aria-describedby="nama_bu" placeholder="Nama Bendahara Umum">
                                        <!-- <div class="text-danger" v-if="v$.nama_bu.$error">
                                            <small v-if="v$.nama_bu.required">Nama Bendahara Umum Wajib Diisi</small>
                                        </div> -->
                                    </div>
                                    <div class="form-group mt-md-3">
                                        <!-- <label for="nip_bu">NIP Bendahara Umum</label> -->
                                        <input type="tel" class="form-control mt-2" onkeypress="return event.charCode >= 48 && event.charCode <= 57" id="nip_bu" v-model="nip_bu" aria-describedby="nip_bu" placeholder="NIP Bendahara Umum">
                                        <!-- <div class="text-danger" v-if="v$.nip_bu.$error">
                                            <small v-if="v$.nip_bu.required">NIP Bendahara Umum Wajib Diisi</small>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="col-md-6 col-12" v-if="databawah == 'bendahara_pengeluaran'">
                                    <div class="form-group mt-md-3">
                                        <!-- <label for="nama_bpn">Nama Bendahara Pengeluaran</label> -->
                                        <input type="text" class="form-control mt-2" id="nama_bpn" v-model="nama_bpn" aria-describedby="nama_bpn" placeholder="Nama Bendahara Pengeluaran">
                                        <!-- <div class="text-danger" v-if="v$.nama_bpn.$error">
                                            <small v-if="v$.nama_bpn.required">Nama Bendahara Pengeluaran Wajib Diisi</small>
                                        </div> -->
                                    </div>
                                    <div class="form-group mt-md-3">
                                        <!-- <label for="nip_bpn">NIP Bendahara Pengeluaran</label> -->
                                        <input type="tel" class="form-control mt-2" onkeypress="return event.charCode >= 48 && event.charCode <= 57" id="nip_bpn" v-model="nip_bpn" aria-describedby="nip_bpn" placeholder="NIP Bendahara Pengeluaran">
                                        <!-- <div class="text-danger" v-if="v$.nip_bpn.$error">
                                            <small v-if="v$.nip_bpn.required">NIP Bendahara Pengeluaran Wajib Diisi</small>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="col-md-6 col-12" v-if="databawah == 'bendahara_pembantu'">
                                    <div class="form-group mt-md-3">
                                        <!-- <label for="nama_bpu">Nama Bendahara Pembantu</label> -->
                                        <input type="text" class="form-control mt-2" id="nama_bpu" v-model="nama_bpu" aria-describedby="nama_bpu" placeholder="Nama Bendahara Pembantu">
                                        <!-- <div class="text-danger" v-if="v$.nama_bpu.$error">
                                            <small v-if="v$.nama_bpu.required">Nama Bendahara Pembantu Wajib Diisi</small>
                                        </div> -->
                                    </div>
                                    <div class="form-group mt-md-35">
                                        <!-- <label for="nip_bpu">NIP Bendahara Pembantu</label> -->
                                        <input type="tel" class="form-control mt-2" onkeypress="return event.charCode >= 48 && event.charCode <= 57" id="nip_bpu"  v-model="nip_bpu" aria-describedby="nip_bpu" placeholder="NIP Bendahara Pembantu">
                                        <!-- <div class="text-danger" v-if="v$.nip_bpu.$error">
                                            <small v-if="v$.nip_bpu.required">NIP Bendahara Pembantu Wajib Diisi</small>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            
                            <div class="modal-footer">
                                <div type="button" class="btn btn-secondary" @click="closemodalform" data-bs-dismiss="modal">Batal</div>
                                <button type="submit" @click="updatenegosiasi" class="btn btn-danger"><i class='bx bxs-save' ></i> Simpan</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="loadingSubmit" class="loading-submit-animation-container" >
                <div class="loading-submit-animation-content">
                    <div>
                        <div class="lds-facebook"><div></div><div></div><div></div></div>
                        <p>Mohon tunggu, data sedang diproses</p>
                    </div>
                </div>
            </div>
            <Modal
            :show="modal.showModal"
            :header="modal.header"
            :headerBorder="modal.headerBorder"
            :title="modal.title"
            :titleText="modal.titleText"
            :footer="modal.footer"
            :footerBtnPosition="modal.footerBtnPosition"
            :footerBorder="modal.footerBorder"
            :btnCancel="modal.btnCancel"
            :btnSubmit="modal.btnSubmit"
            :btnSubmitText="modal.btnSubmitText"
            :btnCancelText="modal.btnCancelText"
            :btnSubmitWidth="modal.btnSubmitWidth"
            :btnCancelWidth="modal.btnCancelWidth"
            @closeModal="closeModal"
            @submitModal="submitModal"
            >

            <div v-if="modalTipe === 'ajukannego'" class="modal-body">
                <div  class="text-center">
                <i class='bx bxs-check-circle ' style="font-size:72px; color:var(--primary-color)"></i>
                <div class="notif-text">
                    <h3>Selamat Permohonan Nego Anda Berhasil diajukan, mohon menunggu proses selanjutnya</h3>
                </div>
                </div>
            </div>
            <div v-else-if="modalTipe === 'confirmnego'">
                <div class="text-center mt-2 border-2 border-light border-bottom">
                <i class='bx bxs-check-circle ' style="font-size:115px; color:var(--primary-color)"></i>
                <div class="notif-text">
                    <h5>Data anda telah berhasil disimpan</h5>
                </div>
                </div>
            </div>
            <div v-else-if="modalTipe === 'errorconfirmnego'" class="d-flex align-items-center justify-content-center text-center mt-2">
                <div>
                <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
                <h5>Ooppsss</h5>
                <p>{{checkoutErrorMessage}}</p>
                </div>
            </div>
            <div v-else-if="modalTipe === 'errorajukannego'" class="d-flex align-items-center justify-content-center text-center mt-2">
                <div>
                <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
                <h5>Ooppsss</h5>
                <p>{{massageadmin}}</p>
                </div>
            </div>
            <div v-else-if="modalTipe === 'errorkosong'" class="d-flex align-items-center justify-content-center text-center mt-2">
                <div>
                <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
                <h5>Ooppsss</h5>
                <p>mohon untuk mengisi data terlebih dahulu</p>
                </div>
            </div>

        </Modal>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import Modal from '../Modal.vue'
// import DaftarProduk from '../TransaksiUser/DataProduk.vue'
import NegoProduk from '../TransaksiUser/NegoProduk.vue'
import DataNego from '../TransaksiUser/DataNego.vue'
import SkeletonNego from '../../components/SkeletonUser/TransaksiUser/SkeletonNego.vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default {
  name: 'DetailNegosiasi',
  data(){
    return{
        loading:true,
        datanegosiasi:{},
        datatransaksi:{
            nego_step:'',
            shipping_cost:'',
            shipping_cost_nego:'',
            total_price:'',
            total_qty:''
        },
        transaction_detail:{},
        pengajuanongkir:'',
        catatan:'',
        transaction_detail_id:[],
        old_price:[],
        new_price:[],
        old_shipping_price:'',
        new_shipping_price:'',
        transaksi_id:'',
        is_admin:null,
        product_nego:{},
        oldPriceVal: '',
        newPriceVal: '',
        transactionDetailVal: '',
        oldShippingPrice: '',
        newShippingPrice: '',
        notes: '',
        dataatas: 'ppk',
        databawah: 'bendahara_umum',
        satuan_kerja:'',
        lembaga:'',
        paket_kerja:'',
        tahun_anggaran:'',
        sumber_dana:'',
        kode_rekening:'',
        pagu_anggaran:'',
        nama_ppk:'',
        nip_ppk:'',
        nama_pa:'',
        nip_pa:'',
        nama_kpa:'',
        nip_kpa:'',
        nama_bu:'',
        nip_bu:'',
        nama_bpn:'',
        nip_bpn:'',
        nama_bpu:'',
        nip_bpu:'',
        modalform:false,
        modalTipe:'',
        loadingSubmit:false,
        massageadmin:'',
        modal:{
            showModal: false,
            header:false,
            headerBorder: false,
            title: false,
            titleText: '',
            footer: false,
            footerBtnPosition:'',
            footerBorder: false,
            btnCancel: false,
            btnSubmit: false,
            btnSubmitText: '',
            btnCancelText: '',
            btnSubmitWidth: '',
            btnCancelWidth: '',
        },
    }
  },
  components: {
    //   DaftarProduk,
      DataNego,
      NegoProduk,
      SkeletonNego,
      Modal
  },
  setup () {
     return { v$: useVuelidate() }
  },
  validations() {
        return {
            satuan_kerja: {
                required,
            },
            lembaga: {
                required,
            },
            paket_kerja: {
                required,
            },
            tahun_anggaran: {
                required,
            },
            sumber_dana: {
                required,
            },
            kode_rekening: {
                required,
            },                      
            pagu_anggaran: {
                required,
            },                  
        }
    },
  created(){
      this.transaksi_id = this.$router.currentRoute._value.query.id_transaksi
        axios.get('/transaction/'+this.transaksi_id).then(res => {
            this.datatransaksi = res.data.data.data
            this.datanegosiasi = res.data.data.negotiations
            this.transaction_detail = this.datatransaksi.transaction_detail
            let data = this.datanegosiasi.length
            this.is_admin = this.datanegosiasi[data-1].is_admin
            this.loading=false
            this.product_nego = this.datanegosiasi[data-1].product_negotiation
            this.product_nego.forEach((value,index)=>{
                this.transaction_detail_id[index]=value.transaction_detail_id
                this.old_price[index]=parseInt(value.new_price)
                this.new_price[index]=parseInt(value.new_price)
            })
            this.new_shipping_price = parseInt(this.datanegosiasi[data-1].shipping_cost_negotiation.new_price)
            this.old_shipping_price = parseInt(this.datanegosiasi[data-1].shipping_cost_negotiation.new_price)
        })
        .catch(() =>{
            this.loading=false
        })
  },
  mounted(){
  },
  watch:{
    pengajuanongkir(value){
        this.updatehargaongkir(value)
    },
    databawah(){
        this.nama_bu=''
       this.nip_bu=''
       this.nama_bpn=''
       this.nip_bpn=''
       this.nama_bpu=''
       this.nip_bpu=''
    },
    dataatas(){
        this.nama_ppk = ''
        this.nip_ppk = ''
        this.nama_pav= ''
        this.nama_kpa=''
        this.nip_kpa=''
    },
    pagu_anggaran(value){
        if(value == 0){
            this.pagu_anggaran
        }else{
            this.pagu_anggaran= parseInt(value)
        }
    }
  },
  methods: {
    refreshdata(){
        
    },
    toLowerCase(value){
      return value.toLowerCase()
    },
    setModal(condition, tipe){
        this.resetDataModal()
        this.modal.showModal= condition
        condition == true ? this.modalTipe = tipe : ''
        if(tipe == 'ajukannego' && condition){
            this.modal.header=true
            this.modal.footer= true
            this.modal.footerBtnPosition='right'
            this.modal.btnSubmit= true
            this.modal.btnSubmitText= 'Oke'
            this.modal.btnSubmitWidth='auto'
        }else if(tipe == 'confirmnego' && condition){
            this.modal.header=true
            this.modal.footer= true
            this.modal.footerBtnPosition='right'
            this.modal.btnSubmit= true
            this.modal.btnSubmitText= 'Oke'
            this.modal.btnSubmitWidth='auto'
        }else if(tipe == 'errorconfirmnego' && condition){
            this.modal.header=true
            this.modal.footer= true
            this.modal.footerBtnPosition='right'
            this.modal.btnSubmit= true
            this.modal.btnSubmitText= 'Oke'
            this.modal.btnSubmitWidth='auto'
        }else if(tipe == 'errorajukannego' && condition){
            this.modal.header=true
            this.modal.footer= true
            this.modal.footerBtnPosition='right'
            this.modal.btnSubmit= true
            this.modal.btnSubmitText= 'Oke'
            this.modal.btnSubmitWidth='auto'
        }else if(tipe == 'errorkosong' && condition){
            this.modal.header=true
            this.modal.footer= true
            this.modal.footerBtnPosition='right'
            this.modal.btnSubmit= true
            this.modal.btnSubmitText= 'Oke'
            this.modal.btnSubmitWidth='auto'
        }
    },
    resetDataModal(){
        this.modal.showModal = false
        this.modal.header = false,
        this.modal.headerBorder = false
        this.modal.title = false
        this.modal.titleText = '' 
        this.modal.footer = false
        this.modal.footerBtnPosition =''
        this.modal.footerBorder = false
        this.modal.btnCancel = false
        this.modal.btnSubmit = false
        this.modal.btnSubmitText = ''
        this.modal.btnCancelText = ''
        this.modal.btnSubmitWidth = ''
        this.modal.btnCancelWidth = ''
    },
    closeModal(){
        this.setModal(false, this.modalTipe)
        this.modalTipe=''
        this.checkoutErrorMessage=''
    },
    submitModal(){
      this.setModal(false, '' , this.modalTipe)
      this.messageErrorModal = ''
    this.modalTipe=''
    this.checkoutErrorMessage=''
    },
    openmodalform(){
        this.modalform =true
    },
    closemodalform(){
        this.modalform =false
    },
    confirmNego(){
        this.transaksi_id = this.$router.currentRoute._value.query.id_transaksi
        let data = new FormData();
        data.append("_method", "PUT");
        data.append('status', 'Process')
        this.loadingSubmit = true
        axios.post('admin-transaction/update/'+ this.transaksi_id, data)
        .then(() => {
        this.loadingSubmit = false
            document.getElementById('idFormNego').style.display = "none";
            this.setModal(true, 'confirmnego')
        }).catch(err =>{
        this.loadingSubmit = false
        this.setModal(true, 'errorconfirmnego')
            this.checkoutErrorMessage = err.response.data.message
        })
    },
    selectatas(value){
        this.dataatas = value
    },
    selectbawah(value){
       
        this.databawah = value
    },
    dateTime(value) {
        return moment(value).format("DD MMMM YYYY");
    },
    updatenegosiasi(){
        this.v$.$touch()
        if (!this.v$.$invalid) {
               let data = new FormData();
              data.append("transaction_id", this.transaksi_id);
              data.append("satuan_kerja", this.satuan_kerja);
              data.append("lembaga", this.lembaga);
              data.append("paket_kerja", this.paket_kerja);
              data.append("tahun_anggaran", this.tahun_anggaran);
              data.append("sumber_dana", this.sumber_dana);
              data.append("kode_rekening", this.kode_rekening);
              data.append("pagu_anggaran", this.pagu_anggaran);
              if(this.dataatas == 'ppk'){
                data.append("tipe_pemerintah", 'ppk');
                data.append("nama_pemerintah", this.nama_ppk);
                data.append("nip_pemerintah", this.nip_ppk);
              }else if(this.dataatas == 'pengguna_anggaran'){
                data.append("tipe_pemerintah", 'pengguna angaran');
                data.append("nama_pemerintah", this.nama_pa);
                data.append("nip_pemerintah", this.nip_pa);
              }else if(this.dataatas == 'kuasa_pengguna_anggaran'){
                data.append("tipe_pemerintah", 'kuasa pengguna angaran');
                data.append("nama_pemerintah", this.nama_kpa);
                data.append("nip_pemerintah", this.nip_kpa);
              }
              if(this.databawah == 'bendahara_umum'){
                data.append("tipe_bendahara", 'bendahara umum');
                data.append("nama_bendahara", this.nama_bu);
                data.append("nip_bendahara", this.nip_bu);
              }else if(this.databawah == 'bendahara_pengeluaran'){
                data.append("tipe_bendahara", 'bendahara pengeluaran');
                data.append("nama_bendahara", this.nama_bpn);
                data.append("nip_bendahara", this.nip_bpn);
              }else if(this.databawah == 'bendahara_pembantu'){
                data.append("tipe_bendahara", 'bendahara pembantu');
                data.append("nama_bendahara", this.nama_bpu);
                data.append("nip_bendahara", this.nip_bpu);
              }
                this.loadingSubmit = true
                axios.post(`/transaction/store-spk`, data).then(() => {
                    this.setModal(true, 'confirmnego')
                    this.modalform =false
                    this.loadingSubmit = false
                    this.transaksi_id = this.$router.currentRoute._value.query.id_transaksi
                    axios.get('/transaction/'+this.transaksi_id).then(res => {
                        this.setModal(true, 'confirmnego')
                        this.datatransaksi = res.data.data.data
                        this.datanegosiasi = res.data.data.negotiations
                        this.transaction_detail = this.datatransaksi.transaction_detail
                        let data = this.datanegosiasi.length
                        this.is_admin = this.datanegosiasi[data-1].is_admin
                    })
                  })
                  .catch((e) => {
                    this.loadingSubmit = false
                    // this.modalform =false
                    this.setModal(true, 'errorconfirmnego')
                        if(e.response.status === 201 || e.response.status === 'Success'){
                            this.setModal(true, 'confirmnego')
                        }
                        else if(e.response.status === 400){
                            if(e.response.data?.data?.nama_pemerintah){
                                this.checkoutErrorMessage = 'Mohon Lengkapi data anda terlebih dahulu'
                            }else if(e.response.data?.data?.nip_pemerintah){
                                this.checkoutErrorMessage = 'Mohon Lengkapi data anda terlebih dahulu'
                            }else if(e.response.data?.data?.nama_bendahara){
                                this.checkoutErrorMessage = 'Mohon Lengkapi data anda terlebih dahulu'
                            }else if(e.response.data?.data?.nip_bendahara){
                                this.checkoutErrorMessage = 'Mohon Lengkapi data anda terlebih dahulu'
                            }else if(e.response.data?.massage){
                                this.checkoutErrorMessage = e.response.data.massage
                            }
                        }
                  });
        }
    },
    updatehargaongkir(value){
        this.new_shipping_price = value
        this.old_shipping_price = parseInt(this.datatransaksi.shipping_cost)
    },
    updatenegoharga(value){
        let dataid = this.transaction_detail_id.findIndex((id)=> id===value.id)
            this.transaction_detail[value.dataindex].price = parseInt(value.hargadata)
        if(dataid >=0){
            this.transaction_detail_id[dataid]=value.id
            this.old_price[dataid]=parseInt(value.old_harga)
            this.new_price[dataid]=parseInt(value.hargadata)

        }else{
            this.transaction_detail_id = [...this.transaction_detail_id,value.id]
            this.old_price = [...this.old_price,parseInt(value.old_harga)]
            this.new_price = [...this.new_price,parseInt(value.hargadata)]
        }
    },
    ajukanNego(){
        if(this.new_shipping_price || this.new_price.length > 0){
            let data = []
            if(this.old_shipping_price && this.transaction_detail_id.length < 0){
                
                data = {
                transaction_id: this.transaksi_id,
                old_shipping_price: this.old_shipping_price,
                new_shipping_price: this.new_shipping_price,
                notes: this.catatan,
                }
            }else if(this.transaction_detail_id.length > 0 && !this.old_shipping_price){
                data = {
                    
                transaction_detail_id: [...this.transaction_detail_id],
                old_price: [...this.old_price],
                new_price: [...this.new_price],
                notes: this.catatan,
                }
            }else if(this.transaction_detail_id.length > 0 && this.old_shipping_price){
                data = {
                transaction_id: this.transaksi_id,
                old_shipping_price: this.old_shipping_price,
                new_shipping_price: this.new_shipping_price,
                transaction_detail_id: [...this.transaction_detail_id],
                old_price: [...this.old_price],
                new_price: [...this.new_price],
                notes: this.catatan,
                }
            }else if(this.old_shipping_price){
                
                data = {
                transaction_id: this.transaksi_id,
                old_shipping_price: this.old_shipping_price,
                new_shipping_price: this.new_shipping_price,
                notes: this.catatan,
                }
            }
            this.loadingSubmit = true
            axios.post(`/transaction/store-negotiation/`+this.transaksi_id, data)
            .then(() => {
                this.setModal(true, 'ajukannego')
                axios.get('/transaction/'+this.transaksi_id).then(res => {
                    this.datatransaksi = res.data.data.data
                    this.datanegosiasi = res.data.data.negotiations
                    this.transaction_detail = this.datatransaksi.transaction_detail
                    let data = this.datanegosiasi.length
                    this.is_admin = this.datanegosiasi[data-1].is_admin
                    this.loadingSubmit = false
                })
                .catch(() =>{
                    this.loadingSubmit = false
                })
                this.transaction_detail_id = []
                this.old_price = []
                this.new_price = []
                this.catatan =''

            })
            .catch((e) => {
                this.loadingSubmit = false
                this.setModal(true, 'errorajukannego')
                this.massageadmin = e.response.data.message
                
            });
        }else{
            this.loadingSubmit = false
            this.setModal(true, 'errorkosong')
        }
        
        }
  }
}
</script>
<style scoped>
/* .data-tabel, tr, td, th{
    border:0px !important;
} */
.data-ongkir{
    box-shadow: 10px 10px 19px #f5f5f5, -10px -10px 19px #efefef;
    border-radius: 15px;
}
.transaksi-footer-bottom{
    display: block;
    position: initial;
    bottom: 1.5rem;
    right: 2rem;
}
.img-profile{
    max-height: 150px !important;
    max-width: 150px !important;
}
.img-profile img{
    width: 100%;
}
.td-nama-produk{
    max-width: 200px !important;
}
.nama-produk{
    display: flex;
    margin: 0px;
    margin-right: 0px !important;
}
.discount-harga{
    text-align: right;
    margin-bottom: 5px;
    /* max-width: 40px; */
}
.discount-harga span{
    background-color: #dc3545;
    color:#ffffff;
    border-radius: 5px;
    /* max-width: 40px; */
}
.ringkasan{
    display: flex;
    justify-content: space-between;
}
.total-ringkasan{
    border-top: solid 1px grey;
    display: flex;
    justify-content: space-between;
}
.form-group label{
    font-weight: 600;
    font-size: 1.3rem;
}
.header-form{
    border-bottom: solid 1px grey;
}
.data-status{
    box-shadow: 10px 10px 19px #f5f5f5, -10px -10px 19px #efefef;
    border-radius: 15px;
}
.disetujui{
    color:#27ae60;
    margin-bottom: 0px !important;
}
.form-nego-data label{
    font-weight: 400 !important;
    font-size:0.8rem !important;
}
.option-form{
    padding:0px !important;
    margin:0px !important;
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
    .transaksi-footer-bottom{
        position: absolute;
    }
    .form-nego-data label{
        font-weight: 400 !important;
        font-size:1rem !important;
    }
}

@media (min-width: 1023px) {
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>