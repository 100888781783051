<template>
    <div class="section">
        <div class="container">
            <div class="row row-dokupayment">
                <div class="d-flex justify-content-end">
                    <!-- <nav aria-label="breadcrumb" class="">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a href="../profil/beranda" class="custom-link">Beranda</a>
                            </li>
                            <li class="breadcrumb-item">
                                <a aria-current="page" class="router-link-active router-link-exact-active text-secondary">Metode Pembayaran BCA Virtual Account</a>
                            </li>
                        </ol>
                    </nav> -->
                </div>
                <div class="col-xs-12 col-sm-5 col-md-4 left-content-bpdpaymet">
                    <div class="border-bottom border-white pb-2">
                        <span class="title-dokupayment">Nama Pembeli</span><br>
                        <span class="subtitle-dokupayment"><b>{{name}}</b></span>
                    </div>
                    <div class="border-bottom border-white pt-3 pb-2">
                        <span class="title-dokupayment">Tanggal Pembelian</span><br>
                        <span class="subtitle-dokupayment"><b>{{tanggal_beli}}</b></span>
                    </div>
                    <div class="border-bottom border-white pt-3 pb-2">
                        <span class="title-dokupayment">No Invoice</span><br>
                        <span class="subtitle-dokupayment"><b>{{invoice}}</b></span>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-7 col-md-8 right-content-bpdpaymet p-3">
                    <div class="row">
                        <div class="col-sm-12 col-md-2 pt-3 text-center">
                            <img class="logo-dokupayment" src="../../assets/img/doku.png">
                        </div>
                        <div class="col-sm-12 col-md-10 title-logo-dokupayment">
                            <b>{{payment}}</b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <hr class="border border-2 border-secondary">
                        </div>
                        <div class="col-md-12 text-center mb-3">
                            <b>Metode Pembayaran :</b> {{payment}}<br>
                        </div>
                        <div class="col-md-12 text-center">
                            <div class="alert alert-success d-flex flex-row" role="alert">
                                <div>
                                    <img class="logo-checked-dokupayment" src="../../assets/checked.png">
                                </div>
                                <div class="text-left p-4">
                                    Terima kasih atas pembayaran yang telah dilakukan, kami akan segera proses pesanan anda
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="view-mobile">
                            <div class="w-100">
                                <p><b>Layanan Pembayaran :</b></p>
                                <p v-if="datatransaksi.payment_service"> {{datatransaksi.payment_service}}</p>
                                <p v-else-if="!datatransaksi.payment_service"> -</p>
                            </div>
                            <div class="w-100">
                                <p><b>Tipe Pembayaran :</b></p>
                                <p v-if="datatransaksi.payment_type"> {{datatransaksi.payment_type}}</p>
                                <p v-else-if="!datatransaksi.payment_type"> -</p>
                            </div>
                            <div class="w-100">
                                <p><b>Nama Bank :</b></p>
                                <p v-if="datatransaksi.bank_name"> {{datatransaksi.bank_name}}</p>
                                <p v-else-if="!datatransaksi.bank_name"> -</p>
                            </div>
                            <div class="w-100" v-if="datatransaksi.payment_service == 'BPD'">
                                <p><b>Atas Nama :</b></p>
                                <p v-if="datatransaksi.account_name"> {{datatransaksi.account_name}}</p>
                                <p v-else-if="!datatransaksi.account_name"> -</p>
                            </div>
                            <div class="w-100">
                                <p><b>No Rekening :</b></p>
                                <p v-if="datatransaksi.account_number"> {{datatransaksi.account_number}}</p>
                                <p v-else-if="!datatransaksi.account_number"> -</p>
                            </div>
                            <div class="w-100">
                                <p><b>Tanggal :</b></p>
                                <p v-if="datatransaksi.payment_date"> {{datatransaksi.payment_date}}</p>
                                <p v-else-if="!datatransaksi.payment_date"> -</p>
                            </div>
                            <div class="w-100">
                                <p><b>Jumlah :</b></p>
                                <!-- <p> Rp.  {{datatransaksi.nominal}}</p> -->
                                <p> Rp.  {{ new Intl.NumberFormat('id-ID').format(Number(datatransaksi?.nominal).toFixed()) }}</p>
                            </div>
                            <div class="w-100">
                                <p><b>Status :</b></p>
                                <p>{{datatransaksi.status}}</p>
                            </div>
                            <div class="w-100" v-if="datatransaksi.payment_service == 'BPD' && datatransaksi.image">
                                <p><b>Bukti Transfer :</b></p>
                                <a :href="this.$imgProductUrl+datatransaksi.image">Klik Disini</a>
                            </div>
                            <div class="w-100" v-if="datatransaksi.notes">
                                <p><b>Notes :</b></p>
                                <p> {{datatransaksi.notes}}</p>
                            </div>
                        </div>
                        <table class="table w-100 table-striped table-hover table-infoPayment view-web">
                            <tbody>
                                <tr>
                                    <td>Layanan Pembayaran</td>
                                    <td v-if="datatransaksi.payment_service">: {{datatransaksi.payment_service}}</td>
                                    <td v-else-if="!datatransaksi.payment_service">: -</td>
                                </tr>
                                <tr>
                                    <td>Tipe Pembayaran</td>
                                    <td v-if="datatransaksi.payment_type">: {{datatransaksi.payment_type}}</td>
                                    <td v-else-if="!datatransaksi.payment_type">: -</td>
                                </tr>
                                <tr>
                                    <td>Nama Bank</td>
                                    <td v-if="datatransaksi.bank_name">: {{datatransaksi.bank_name}}</td>
                                    <td v-else-if="!datatransaksi.bank_name">: -</td>
                                </tr>
                                <!-- <tr>
                                    <td>Cabang</td>
                                    <td>: Renon</td>
                                </tr> -->
                                <tr v-if="datatransaksi.payment_service == 'BPD'">
                                    <td>Atas Nama</td>
                                    <td v-if="datatransaksi.account_name">: {{datatransaksi.account_name}}</td>
                                    <td v-else-if="!datatransaksi.account_name">: -</td>
                                </tr>
                                <tr>
                                    <td>No Rekening</td>
                                    <td v-if="datatransaksi.account_number">: {{datatransaksi.account_number}}</td>
                                    <td v-else-if="!datatransaksi.account_number">: -</td>
                                </tr>
                                <tr>
                                    <td>Tanggal</td>
                                    <td v-if="datatransaksi.payment_date">: {{datatransaksi.payment_date}}</td>
                                    <td v-else-if="!datatransaksi.payment_date">: -</td>
                                </tr>
                                <tr>
                                    <td>Jumlah</td>
                                    <!-- <td>: Rp.  {{datatransaksi.nominal}}</td> -->
                                    <td>: Rp.  {{ new Intl.NumberFormat('id-ID').format(Number(datatransaksi?.nominal).toFixed()) }}</td>
                                </tr>
                                <tr>
                                    <td>Status</td>
                                    <td>: {{datatransaksi.status}}</td>
                                </tr>
                                <tr v-if="datatransaksi.payment_service == 'BPD' && datatransaksi.image">
                                    <td>Bukti Transfer</td>
                                    <td>: <a :href="this.$imgProductUrl+datatransaksi.image">Klik Disini</a></td>
                                </tr>
                                <tr v-if="datatransaksi.notes">
                                    <td>Notes</td>
                                    <td>: {{datatransaksi.notes}}</td>
                                </tr>
                            </tbody>
                        </table>
                    <div class="row mt-3">
                        <div class="col-md-12 text-right">
                             <router-link :to="'/profil/detailtransaksi?id_transaksi='+id" type="button" class="btn btn-back-dokupayment"><i class='bx bx-arrow-back' ></i> Kembali</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
     props:['datatransaksi','id','name','invoice','tanggal_beli','payment'],
    setup() {
        
    },
}
</script>
<style>
    .left-content-bpdpaymet {
        background-color: var(--primary-color);
        border-radius: 10px 0px 0px 10px;
        color: #ffffff;
        padding: 30px;
    }
    .title-dokupayment {
        font-size: 12px;
    }
    .subtitle-dokupayment {
        font-size: 24px;
    }
    .right-content-bpdpaymet {
        background-color: #ffffff;
        border-radius: 0px 10px 10px 0px;
    }
    .logo-dokupayment {
        width: 35px;
        height: auto;
    }
    .logo-checked-dokupayment {
        width: 100px;
    }
    .title-logo-dokupayment {
        font-size: 26px;
        margin-top: 10px;
        margin-left: -20px;
    }
    .border-2 {
        border-width:2px !important;
    }
    .rounded-15 {
        border-radius: 15px;
    }
    .title-price-dokupayment {
        font-size: 40px;
        color: var(--primary-color);
        margin-top: -10px;
    }
    .barcode-dokupayment {
        width: 200px;
        height: auto;
    }
    .list-method-dokupayment {
        list-style-type: decimal; 
        margin-left:15px;
    }
    .btn-back-dokupayment {
        background-color: var(--primary-color);
        color: #FFFFFF;
    }
    .view-mobile{
        display: block;
    }
    .view-web{
        display: none;
    }
    @media (max-width: 1200px) { 
        .row-dokupayment {
            margin: 0px 3px;
        }
        .subtitle-dokupayment {
            font-size: 20px;
        }
    }
    @media (max-width: 992px) { 
        .title-logo-dokupayment {
            font-size: 24px;
            margin-top: 10px;
        }
        .subtitle-dokupayment {
            font-size: 16px;
        }
    }
    @media (max-width: 768px) { 
        .title-logo-dokupayment {
            margin-top: 0px;
            margin-left: 0px;
            text-align: center;
        }
        .left-content-bpdpaymet {
            padding: 20px;
        }
        .subtitle-dokupayment {
            font-size: 18px;
        }
    }
    @media (max-width: 576px) { 
        .left-content-bpdpaymet {
            border-radius: 10px 10px 0px 0px;
        }
        .right-content-bpdpaymet {
            border-radius: 0px 0px 10px 10px;
        }
    }
    @media (min-width: 767px) {
    .img-none{
        width:50%;
        display:block; 
        margin:0 auto;
    }
    .view-mobile{
        display: none;
    }
    .view-web{
        display: table;
    }
}
</style>