<template>
    <div class="container-fluid mt-3 p-0">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
            <a class="nav-link" 
            @click.prevent="tabclick('detail_pesanan')" 
            :class="{
                'active': dataid=='detail_pesanan',
            }"
            id="detail_pesanan" 
            data-toggle="tab"
            role="tab" 
            aria-controls="detail_pesanan" 
            aria-selected="true">Detail Pesanan</a>
        </li>
        <li class="nav-item" v-if="datanego != ''">
            <a class="nav-link" 
            @click.prevent="tabclick('negodetail')" 
            :class="{
                'active': dataid=='negodetail',
            }"
            id="negodetail" 
            data-toggle="tab"
            role="tab" 
            aria-controls="negodetail" 
            aria-selected="true">Negosiasi</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" 
            @click.prevent="tabclick('penerimaan')"
            :class="{
                'active': dataid=='penerimaan',
            }"  
            id="penerimaan" 
            data-toggle="tab"
            role="tab" 
            aria-controls="penerimaan" 
            aria-selected="false">Penerimaan</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" 
            @click.prevent="tabclick('Pembayaran')" 
            :class="{
                'active': dataid=='Pembayaran',
            }" 
            id="Pembayaran" 
            data-toggle="tab" 
            role="tab" 
            aria-controls="Pembayaran" 
            aria-selected="false">Pembayaran</a>
        </li>
        <li v-if="toLowerCase(status) == 'paid'" class="nav-item">
            <a class="nav-link" 
            @click.prevent="tabclick('komplain')" 
            :class="{
                'active': dataid=='komplain',
            }" 
            id="komplain" 
            data-toggle="tab"
            role="tab" 
            aria-controls="komplain" 
            aria-selected="false">Komplain</a>
        </li>
        <li v-if="toLowerCase(status) == 'paid'" class="nav-item">
            <a class="nav-link" 
            @click.prevent="tabclick('ulasan')" 
            :class="{
                'active': dataid=='ulasan',
            }" 
            id="ulasan" 
            data-toggle="tab"
            role="tab" 
            aria-controls="ulasan" 
            aria-selected="false">Ulasan</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" 
            @click.prevent="tabclick('Timeline')" 
            :class="{
                'active': dataid=='Timeline',
            }" 
            id="Timeline" 
            data-toggle="tab"
            role="tab" 
            aria-controls="Timeline" 
            aria-selected="false">Log Transaksi</a>
        </li>
        </ul>
        <div class="tab-content container-fluid">
        
        </div>
    </div>
    <div>
        <div class="w-100 background-white pb-md-1">
            <detail-pesanan class="pb-5" v-if="dataid == 'detail_pesanan'"></detail-pesanan>
            <nego-detail v-else-if="dataid == 'negodetail'"></nego-detail>
            <upload-file class="pb-5" v-else-if="dataid == 'penerimaan'"></upload-file>
            <pembayaran class="pb-5" v-else-if="dataid == 'Pembayaran'"></pembayaran>
            <data-ulasan class="pb-5" v-else-if="dataid == 'ulasan' && toLowerCase(status) == 'paid'"></data-ulasan>
            <data-complain class="pb-5" v-else-if="dataid == 'komplain' && toLowerCase(status) == 'paid'"></data-complain>
            <timeline class="pb-5" v-else-if="dataid == 'Timeline'"></timeline>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import DetailPesanan from '../../components/TransaksiUser/DetailPesanan.vue'
import NegoDetail from '../../components/TransaksiUser/DetailNego.vue'
import UploadFile from '../../components/TransaksiUser/DetailPenerimaan.vue'
import Pembayaran from '../../components/TransaksiUser/DetailPembayaran.vue'
import Timeline from '../../components/TransaksiUser/DetailTimeline.vue'
import DataUlasan from '../../components/TransaksiUser/UlasanData.vue'
import DataComplain from '../../components/TransaksiUser/ComplainData.vue'
export default {
    data() {
    return {
      dataid:'detail_pesanan',
      transaksi_id: '',
      datanego: '',
      status: ''
    };
  },
  components : {
        DetailPesanan,
        UploadFile,
        NegoDetail,
        Pembayaran,
        Timeline,
        DataUlasan,
        DataComplain
    },
    created(){

        this.transaksi_id = this.$router.currentRoute._value.query.id_transaksi
        axios.get('/transaction/'+this.transaksi_id).then(res => {
            this.datanego = res.data.data.negotiations
            let datatransaksi = res.data.data.data
            this.status = datatransaksi.status
            //  this.loading=false
        })
        .catch(() =>{
            // this.loading=false
        })
        
    },
  methods:{
      tabclick(data){
          this.dataid = data
      },
      toLowerCase(value){
      return value.toLowerCase()
    },
  }
}
</script>
<style scoped>
.nav-link{
    color: rgba(0, 0, 0, 0.6);
}
.active{
    color:var(--primary-color) !important;
}
.nav-tabs{
    position: relative !important;
}
.bg-red{
    background-color: var(--primary-color) !important;
}
</style>