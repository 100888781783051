<template>
    <div class="loading-skeleton">
        <div class="container-fluid pt-4">
            <div class="float-right loading-skeleton">
                <p class="w-75 tinggi-p" style="width:300px;">Tanggal 02 January 2022</p>
            </div>
        </div>
        <div class="container-fluid mt-5">
            <h2>ID PESANAN:</h2>
            <div class="row card-group">
                <div class="col-md-6 card p-2 p-md-4 mx-1">
                    <h5 class="mb-3 mb-md-4">Informasi User</h5>
                    <p>Nama Instansi: <b></b></p>
                    <p>No Telepon: <b></b></p>
                    <p>Email: </p>
                    <p>Alamat: </p>
                    <p>NIP: </p>
                    <p>Nama PP: </p>
                    <p>Nama PPK: </p>
                </div>
                <div class="col-md-6 card p-2 p-md-4 mx-1">
                    <h5 class="mb-3 mb-md-4">Informasi User</h5>
                    <p>Ekspedisi: </p>
                    <p>Alamat Pengirim:</p>
                        <br>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,   
                        
                    </p>
                    <div class="transaksi-footer-bottom">
                        <!-- <router-link to="/oke" class="btn btn-danger float-right">Nego Ongkir</router-link> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid pb-5 pb-md-1 mt-3" >
            <h3>Daftar Produk</h3>
            <div class="background-white p-md-3"  >
                <div>
                    
                </div>
                <div class="mt-3 p-3 data-ongkir" style="overflow-x:scroll;">
                    <h5 class="mt-3">Pengiriman Partial</h5>
                    <!-- <div v-for="(partialongkir,dataindex) in produk.shipping" :key="dataindex"> -->
                        <table class="mt-2 data-tabel" >
                            <thead>
                                <tr style="border:0px !important;">
                                    <th style="min-width:80px;"><p>jumlah</p></th>
                                    <th style="min-width:130px;"><p>Harga Ongkir</p></th>
                                    <!-- <th style="min-width:180px;" colspan="2">Nego Harga Ongkir</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="border:0px !important;" >
                                    <td> <p>Unit</p></td>
                                    <td><p>Rp.</p> </td>
                                    <!-- <td>
                                        <input class="form-control" type="text" v-model="negoongkir" name="nego-harga">
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                        <div class="w-100 mt-3" style="display: flow-root;">
                            <a class="btn btn-danger float-right">Ajukan Negosiasi Harga Ongkir</a>
                        </div>
                    <!-- </div> -->
                </div>
                <div class="mt-3 p-3 data-ongkir" style="overflow-x:scroll;">
                    <h5 class="mt-3">Pengiriman Partial</h5>
                    <!-- <div v-for="(partialongkir,dataindex) in produk.shipping" :key="dataindex"> -->
                        <table class="mt-2 data-tabel" >
                            <thead>
                                <tr style="border:0px !important;">
                                    <th style="min-width:80px;"><p>jumlah</p></th>
                                    <th style="min-width:130px;"><p>Harga Ongkir</p></th>
                                    <!-- <th style="min-width:180px;" colspan="2">Nego Harga Ongkir</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="border:0px !important;" >
                                    <td> <p>Unit</p></td>
                                    <td><p>Rp.</p> </td>
                                    <!-- <td>
                                        <input class="form-control" type="text" v-model="negoongkir" name="nego-harga">
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                        <div class="w-100 mt-3" style="display: flow-root;">
                            <a class="btn btn-danger float-right">Ajukan Negosiasi Harga Ongkir</a>
                        </div>
                    <!-- </div> -->
                </div>
            </div>
            <h3 class="mt-4">Ringkasan Belanja</h3>
            <div class="background-white p-3 mt-3">
                <div class="ringkasan w-100">
                    <p>Total Nego Harga</p>
                    <p><b>Rp. </b></p>
                </div>
                <div class="ringkasan w-100">
                    <p>Total Nego Ongkir</p>
                    <p><b>Rp. </b></p>
                </div>
                <div class="ringkasan w-100">
                    <p>Total PPN</p>
                    <p><b>Rp. 30.000</b></p>
                </div>
                <div class="total-ringkasan w-100">
                    <h3>Total Nego Tagihan</h3>
                    <h3>Rp. </h3>
                </div>
            </div>
            <div class="form-group">
                <label for="status">Status</label>
                <input type="text" class="form-control mt-2" id="status" aria-describedby="status" placeholder="Status" disabled>
            </div>
            <div class="form-group mt-3">
                <label for="respon">Respon Admin</label>
                <textarea class="form-control mt-2" id="respon" rows="3" disabled>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                </textarea>
            </div>
            <div class="form-group mt-3">
                <label for="respon">Catatan</label>
                <textarea v-model="catatan" class="form-control mt-2" id="respon" rows="3">
                </textarea>
            </div>
            <div class="w-100 mt-4 pb-3">
                <div class="float-right">
                    <button class="btn btn-danger mx-1" data-bs-toggle="modal" data-bs-target="#modalform">
                        Lanjutkan Transaksi
                    </button>
                    <button class="btn btn-danger mx-1">
                        Setuju Negoisasi
                    </button>
                    <button class="btn btn-danger mx-1" >
                        Lanjutkan Negosiasi
                    </button>
                </div>
            </div>
        </div>
        

    </div>
</template>
<script>
export default {
  name: 'DetailPesanan',
  
}
</script>
<style scoped>
.data-tabel, tr, td, th{
    border:0px !important;
}
.data-ongkir{
    box-shadow: 10px 10px 19px #f5f5f5, -10px -10px 19px #efefef;
    border-radius: 15px;
}
.transaksi-footer-bottom{
    display: block;
    position: initial;
    bottom: 1.5rem;
    right: 2rem;
}
.img-profile{
    max-height: 150px !important;
    max-width: 150px !important;
}
.img-profile img{
    width: 100%;
}
.td-nama-produk{
    max-width: 200px !important;
}
.nama-produk{
    display: flex;
    margin: 0px;
    margin-right: 0px !important;
}
.discount-harga{
    text-align: right;
    margin-bottom: 5px;
    /* max-width: 40px; */
}
.discount-harga span{
    background-color: #dc3545;
    color:#ffffff;
    border-radius: 5px;
    /* max-width: 40px; */
}
.ringkasan{
    display: flex;
    justify-content: space-between;
}
.total-ringkasan{
    border-top: solid 1px grey;
    display: flex;
    justify-content: space-between;
}
.form-group label{
    font-weight: 600;
    font-size: 1.3rem;
}
.header-form{
    border-bottom: solid 1px grey;
}
.tinggi-p{
    height: 30px;
}
.loading-skeleton h1,
.loading-skeleton h2,
.loading-skeleton h3,
.loading-skeleton h4,
.loading-skeleton h5,
.loading-skeleton h6,
.loading-skeleton p,
.loading-skeleton li,
.loading-skeleton .btn,
.loading-skeleton label,
.loading-skeleton .image-user,
.loading-skeleton .datatable,
.loading-skeleton .form-control {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee;
  border-color: #eee;
}
.loading-skeleton h1::placeholder,
.loading-skeleton h2::placeholder,
.loading-skeleton h3::placeholder,
.loading-skeleton h4::placeholder,
.loading-skeleton h5::placeholder,
.loading-skeleton h6::placeholder,
.loading-skeleton p::placeholder,
.loading-skeleton li::placeholder,
.loading-skeleton .btn::placeholder,
.loading-skeleton .datatable::placeholder,
.loading-skeleton .image-user::placeholder,
.loading-skeleton label::placeholder,
.loading-skeleton .form-control::placeholder {
  color: transparent;
}

@keyframes loading-skeleton {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;
}
.loading-skeleton img {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
.loading-skeleton .datatable {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
    .transaksi-footer-bottom{
        position: absolute;
    }
}

@media (min-width: 1023px) {
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>