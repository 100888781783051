<template>
      
      <div class="confirmation-payment section">

        <div class="confirmation-payment-container container">

          <div class="d-flex justify-content-end">
            <nav aria-label="breadcrumb" class="">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Beranda</a></li>
                <li class="breadcrumb-item active" aria-current="page">Konfirmasi Pembelian</li>
              </ol>
            </nav>
          </div>

          <div>
            <div class="p-3 w-100 d-flex align-items-center bg-red justify-content-center background-primary">
              <i class='bx bx-info-circle '></i>
              <p class="my-0 mx-2">*Harap selesaikan pembayaran sebelum 02 Februari 2022 19:32 WIB</p>
            </div>

            <div class="p-3 my-3 background-white">
              <div class=" d-flex justify-content-center align-items-center">
                <div class="text-center py-2 ">
                  <p class="font-large">Metode Pembayaran: <b>BCA Virtual Account</b></p>
                  <img :src="require(`../assets/img/bca.png`)" class="logo-pembayaran my-5" alt="">
                  <p class="font-large m-0">No Virtual Account: <b>123456xxxx</b></p>
                  <p class="font-large m-0">Jumlah yang harus dibayar</p>
                  <h1 class="m-0">Rp. 3.000.000</h1>
                  <p class="font-large m-0">ID Pesanan: <b>#123xxxxxxx</b></p>
                </div>
              </div>

              <h3 class="title mt-5">Cara Pembayaran</h3>
                <ul class="accordion-custom sidebar-accordion border-0 p-0">
                  <li>
                      <a class="accordion-custom-btn" @click="openAccordion($event)">
                        <h5>ATM BCA</h5>
                        <span class="icon"><i class='bx bx-chevron-down'></i></span>  
                      </a>
                      <div class="accordion-custom-content">
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum quia corrupti deleniti. Non doloremque culpa laudantium quod quibusdam cum, nesciunt eos perspiciatis maiores expedita? Sapiente, corporis. Magni tenetur quo saepe.
                        </p>
                      </div>
                  </li>
                </ul>
            </div>
          </div>

        </div>

        <Product>
          <div class="product-header">
            <h3 class="product-title">Rekomendasi untuk Anda</h3>
            <a href="#" class="product-link">
              Lihat semua <i class='bx bx-right-arrow-alt' ></i>
            </a>
          </div>

          <div class="product-content" v-if="productRekomendasi">
            <product-list 
              v-for="(rekomendasi, index) in productRekomendasi" 
              :key="index" 
              :img="rekomendasi.image"
              :title="rekomendasi.name"
              :desc="rekomendasi.descriptions"
              :price="rekomendasi.price"
              :promo="rekomendasi.promo"
              :rating="rekomendasi.star_review"
              :status="rekomendasi.status"
              >
              </product-list>
          </div>
          <div v-else class="product-content-null">
            <img :src="require(`../assets/img/empty-illustration.svg`)" alt="">
          </div>
        </Product>

      </div>

</template>

<script>

import Product from '../components/Product.vue'
import ProductList from '../components/ProductList.vue'

export default {
  name: 'keranjang-belanja',
  props:['dataAllProducts'],
  data(){
    return{
      productRekomendasi: [],
    }
  },
  components: {
    Product,
    ProductList
  },
  methods: {
    setProductRekomendasi(){
      let data_products = this.dataAllProducts
      this.productRekomendasi = data_products.slice(0,5)
    },
    openAccordion(event){
        const accordion = document.querySelectorAll('.accordion-custom-btn');

        if(event.currentTarget.classList.contains("active")){
            event.currentTarget.classList.remove("active")
        }else{
            accordion.forEach(n => {
                n.classList.remove('active')
            });
            event.currentTarget.classList.add("active")
        }

    },

  },

  mounted(){
    if(this.dataAllProducts != ''){
      this.setProductRekomendasi()
    }
  },
  watch: {
    dataAllProducts:{
      deep: true,
      handler: function(){
        console.log(this.dataAllProducts)
        if(this.dataAllProducts != ''){
          this.setProductRekomendasi()
        }
      }
    },
  }
}
</script>

