<template>
    <div class="background-white loading-skeleton">
        <div class="card p-3 mb-3">
            <div style="display:flex; justify-content: space-between;">
                <div style="display:flex;vertical-align: baseline; ">
                    <firgure style="max-width:150px;max-height:150px;">
                        <img alt="">
                    </firgure>
                    <div class="py-5">
                        <h6>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</h6>
                        <p>lorem X Rp.loremipsumadadad</p>
                    </div>
                </div>
                <div class="py-5">
                    <h1 style="display:flex;"><i class='bx bxs-star align-middle'  style="color:yellow;"></i> 5.0</h1>
                </div>
            </div>
            <div>
                <div>
                    <h6>Komentar</h6>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatum fugiat excepturi vero mollitia id necessitatibus nesciunt odio corrupti quaerat totam quibusdam tenetur itaque dolorum, explicabo, facilis rem temporibus doloribus labore!</p>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

.comment-star {
    position: relative;
    width: 400px;
    padding: 0px;
    border-radius: 5px;
    display: flex;
    text-align: left !important;
    /* align-items: right; */
}

.star-widget input {
    display: none;
}

.comment-star label {
    font-size: 1.5rem;
    color: #444;
    padding: 5px;
    float: right;
    transition: all 0.2s ease;
}

input:not(:checked)~label:hover,
input:not(:checked)~label:hover~label {
    color: #fd4;
}

input:checked~label {
    color: #fd4;
}

input#rate-5:checked~label {
    color: #fe7;
}
.bintang{
    color: #fd4;
    font-size: 3rem;
}

.loading-skeleton h1,
.loading-skeleton h2,
.loading-skeleton h3,
.loading-skeleton h4,
.loading-skeleton h5,
.loading-skeleton h6,
.loading-skeleton p,
.loading-skeleton li,
.loading-skeleton .btn,
.loading-skeleton v-select,
.loading-skeleton label,
.loading-skeleton .image-user,
.loading-skeleton .datatable,
.loading-skeleton .form-control {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee;
  border-color: #eee;
}
.loading-skeleton h1::placeholder,
.loading-skeleton h2::placeholder,
.loading-skeleton h3::placeholder,
.loading-skeleton h4::placeholder,
.loading-skeleton h5::placeholder,
.loading-skeleton h6::placeholder,
.loading-skeleton p::placeholder,
.loading-skeleton li::placeholder, 
.loading-skeleton v-select::placeholder, 
.loading-skeleton .btn::placeholder,
.loading-skeleton .datatable::placeholder,
.loading-skeleton .image-user::placeholder,
.loading-skeleton label::placeholder,
.loading-skeleton .form-control::placeholder {
  color: transparent;
}

@keyframes loading-skeleton {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;
}
.loading-skeleton img {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
.loading-skeleton .datatable {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
</style>
