<template>
    <div>
        <form @submit.prevent="updatepenerimaan">
            <div class="container-fluid">
                <div class="row">
                    <div class="form-group col-12">
                        <label for="namaproduk">Nama Produk</label>
                        <input type="text" disabled class="form-control mt-2" :value="nama_produk" id="namaproduk" aria-describedby="namaproduk" placeholder="Nama Produk">
                    </div>
                    <div class="form-group col-md-6 mt-md-3">
                        <label for="resi">No Resi</label>
                        <input type="text" disabled class="form-control mt-2" :value="resi" id="resi" aria-describedby="resi" placeholder="No Resi">
                    </div>
                    <div class="form-group col-md-6 mt-md-3">
                        <label for="Ekspedisi">Ekspedisi</label>
                        <input type="text" disabled class="form-control mt-2" :value="ekspedisi" id="Ekspedisi" aria-describedby="Ekspedisi" placeholder="Ekspedisi">
                    </div>
                    <div class="form-group col-md-6 mt-md-3">
                        <label for="dikirim">Tanggal Dikirim</label>
                        <input type="date" disabled class="form-control mt-2" :value="tgl_dikirim" id="dikirim" aria-describedby="dikirim" placeholder="Ekspedisi">
                    </div>
                    <div class="form-group col-md-6 mt-md-3">
                        <label for="unitdikirim">Unit Dikirim</label>
                        <input type="text" disabled class="form-control mt-2" :value="unit_dikirim" id="unitdikirim" aria-describedby="unitdikirim" placeholder="Unit Dikirim">
                    </div>
                    <div class="form-group col-12 mt-md-3">
                        <label for="penerima">Catatan</label>
                        <textarea class="form-control mt-2" id="catatan" disabled rows="3" :value="descriptions"></textarea>
                    </div>
                    <div class="form-group col-md-6 mt-md-3">
                        <label for="diterima">Tanggal Diterima</label>
                        <input type="date" class="form-control mt-2" id="diterima" :class="{ 'border-danger': v$.tglditerima.$error }" v-model="tglditerima" aria-describedby="diterima" placeholder="Ekspedisi">
                        <div class="text-danger" v-if="v$.tglditerima.$error">
                            <small v-if="v$.tglditerima.required">Tanggal Diterima Wajib Diisi</small>
                        </div>
                    </div>
                    <div class="form-group col-md-6 mt-md-3">
                        <label for="unitditerima">Unit Diterima</label>
                        <input type="text" class="form-control mt-2" :class="{ 'border-danger': v$.unitditerima.$error }" id="unitditerima" v-model="unitditerima" aria-describedby="unitditerima" placeholder="Unit Diterima">
                        <div class="text-danger" v-if="v$.unitditerima.$error">
                            <small v-if="v$.unitditerima.required">Unit Diterima Wajib Diisi</small>
                        </div>
                    </div>
                    <div class="form-group col-12 mt-md-3">
                        <label for="penerima">Nama Penerima</label>
                        <input type="text" class="form-control mt-2" id="penerima" :class="{ 'border-danger': v$.nama_penerima.$error }" v-model="nama_penerima" aria-describedby="penerima" placeholder="Nama Penerima">
                        <div class="text-danger" v-if="v$.nama_penerima.$error">
                            <small v-if="v$.nama_penerima.required">Nama Penerima Wajib Diisi</small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-5 p-2">
                    <!-- <pre>{{filteredData}}</pre>
                                    <pre>{{datalist}}</pre> -->
                    <h5>Dokumen Pendukung</h5>
                    <div style="overflow-x:scroll;">
                        <table>
                        <tr>
                            <th>No</th>
                            <th>Nama File</th>
                            <th>Download Template File</th>
                            <th>Upload</th>
                            <th>File</th>
                        </tr>
                        <tbody>
                            <tr v-for="(FileData, index) in dataFile" :key="FileData.id">
                            <!-- <tr> -->
                              <td class="">
                                  {{index+1}}
                              </td>
                              <td class="">
                                  {{FileData.nama_file}}
                              </td>
                              <td>
                                  <div v-if="FileData.FileTemplate == 'receipt'">
                                    <a class="text-link" @click="datareceipt">Klik Disini</a>
                                  </div>
                                  <div v-else-if="FileData.FileTemplate == 'spk'">
                                    <a class="text-link" @click="dataspk">Klik Disini</a>
                                  </div>
                                  <div v-else-if="FileData.FileTemplate == 'invoice'">
                                    <a class="text-link" @click="datainvoice">Klik Disini</a>
                                  </div>
                                  <div v-else-if="FileData.FileTemplate == 'bast'">
                                    <a class="text-link" @click="databast">Klik Disini</a>
                                  </div>
                              </td>
                              <td>
                                <div v-if="FileData.datanama">
                                  <p>{{FileData.datanama}}</p>
                                </div>
                                <div v-else-if="!FileData.datanama">
                                  <!-- <label for="input_file" class="btn btn-danger btn-sm" @click="setindex(index,FileData.nama_file)"><i class='bx bx-upload' ></i> Upload File</label> -->
                                  <input type="file" accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf" :id="'input_file'+pengiriman+indexdata+index" @change="inputfile($event,index, FileData.nama_file)" >
                                </div>
                              </td>
                              <td>
                                  <a v-if="FileData.download" :href="FileData.download">Download</a>
                              </td>
                            </tr>
                            <!-- <tr>
                              <td class="">
                                  2
                              </td>
                              <td class="">
                                  Receipt Kwitansi Pembayaran
                              </td>
                              <td>
                                  <a href="https://admin-armed.bithouse.id/public/documents/receipt.docx">Klik Disini</a>
                              </td>
                              <td>
                                  <label for="input_file" class="btn btn-danger btn-sm" @click="setindex(index,FileData.nama_file)"><i class='bx bx-upload' ></i> Upload File</label>
                                  <input type="file" id="input_file" @change="inputfile($event)" style="display:none">
                              </td>
                            </tr>
                            <tr>
                              <td class="">
                                  3
                              </td>
                              <td class="">
                                  Invoice Surat Tagihan
                              </td>
                              <td>
                                  <a href="https://admin-armed.bithouse.id/public/documents/invoice.docx">Klik Disini</a>
                              </td>
                              <td>
                                  <label for="input_file" class="btn btn-danger btn-sm" @click="setindex(index,FileData.nama_file)"><i class='bx bx-upload' ></i> Upload File</label>
                                  <input type="file" id="input_file" @change="inputfile($event)" style="display:none">
                              </td>
                            </tr>
                            <tr>
                              <td class="">
                                  4
                              </td>
                              <td class="">
                                  Bast
                              </td>
                              <td>
                                  <a href="https://admin-armed.bithouse.id/public/documents/bast.docx">Klik Disini</a>
                              </td>
                              <td>
                                  <label for="input_file" class="btn btn-danger btn-sm" @click="setindex(index,FileData.nama_file)"><i class='bx bx-upload' ></i> Upload File</label>
                                  <input type="file" id="input_file" @change="inputfile($event)" style="display:none">
                              </td>
                            </tr> -->
                        </tbody>
                        </table>
                    </div>
            </div>
                <div class="container-fluid pb-3">
                    <div class="float-right">
                        <button v-if="isidata.length > 0" type="submit" id="btnsimpan" class="btn btn-danger">Simpan</button>
                    </div>
                </div>
        </form>
    </div>
    
    <div v-if="loadingSubmit" class="loading-submit-animation-container" >
        <div class="loading-submit-animation-content">
            <div>
                <div class="lds-facebook"><div></div><div></div><div></div></div>
                <p>Mohon tunggu, data sedang diproses</p>
            </div>
        </div>
    </div>

    <Modal
      :show="modal.showModal"
      :header="modal.header"
      :headerBorder="modal.headerBorder"
      :title="modal.title"
      :titleText="modal.titleText"
      :footer="modal.footer"
      :footerBtnPosition="modal.footerBtnPosition"
      :footerBorder="modal.footerBorder"
      :btnCancel="modal.btnCancel"
      :btnSubmit="modal.btnSubmit"
      :btnSubmitText="modal.btnSubmitText"
      :btnCancelText="modal.btnCancelText"
      :btnSubmitWidth="modal.btnSubmitWidth"
      :btnCancelWidth="modal.btnCancelWidth"
      @closeModal="closeModal"
      @submitModal="submitModal"
    >
      <!-- Notif add cart -->
      <div v-if="modalTipe === 'success'">
        <div class="text-center mt-2 border-2 border-light border-bottom">
          <i class='bx bxs-check-circle ' style="font-size:115px; color:var(--primary-color)"></i>
          <div class="notif-text">
            <h5>Data anda telah berhasil diperbaharui</h5>
          </div>
        </div>
        <div class="text-right mt-2">
            <button @click="datarefresh" class="btn btn-red">Oke</button>
        </div>
      </div>

      <!-- Info Negosiasi -->
      <div v-else-if="modalTipe === 'errorsize'" class="d-flex align-items-center justify-content-center text-center mt-2">
        <div>
          <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
          <h5>Ooppsss</h5>
          <p>Ukuran format File anda lebih dari 2mb</p>
        </div>
      </div>
      <div v-else-if="modalTipe === 'errorformat'" class="d-flex align-items-center justify-content-center text-center mt-2">
        <div>
          <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
          <h5>Ooppsss</h5>
          <p>Format File Hanya berupa(.docx, .doc, .pdf)</p>
        </div>
      </div>
      <div v-else-if="modalTipe === 'errorinput'" class="d-flex align-items-center justify-content-center text-center mt-2">
        <div>
          <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
          <h5>Ooppsss</h5>
          <p>{{massageadmin}}</p>
        </div>
      </div>
    </Modal>
</template>
<script>

import axios from 'axios'
import Modal from '../Modal.vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
// import DashboardUser from '../../components/DashboardUser.vue'
// import FormProfile from '../../components/FormProfile.vue'

export default {
  props:['pengiriman','indexdata','descriptions','idtrasnsaksi','nama_produk','resi','ekspedisi','tgl_dikirim','tgl_diterima','unit_dikirim','unit_diterima','penerima','document'],
  name: "profile-data-component",
  components:{
    Modal
  },
  data() {
    return {
       dataFile: [
        {
          id: 1,
          nama_file: "SPK Surat Pesanan",
          datanama:'',
          FileTemplate:'spk',
          download: "",
        },
        {
          id: 2,
          nama_file: "Receipt Kwitansi Pembayaran",
          datanama:'',
          FileTemplate:'receipt',
          download: "",
        },
        {
          id: 3,
          nama_file: "Invoice Surat Tagihan",
          datanama:'',
          FileTemplate:'invoice',
          download: "",
        },
        {
          id: 4,
          nama_file: "Bast",
          datanama:'',
          FileTemplate:'bast',
          download: "",
        },
        // {
        //   id: 5,
        //   nama_file: "Berita Acara Serah Terima",
        //   download: "",
        // },
        // {
        //   id: 6,
        //   nama_file: "Surat Perintah Kerja",
        //   download: "",
        // },
        // {
        //   id: 7,
        //   nama_file: "Surat Serah Terima",
        //   download: "",
        // },
        
      ],
      donloddata:'',
      isidata:[],
      dataindex:'',
      transaksi_id:'',
      datanama:'',
      tglditerima:'',
      unitditerima:'',
      nama_penerima:'',
      cekdata:'',
      loadingSubmit:false,
      massageadmin:'',
      modalTipe:'',
      modal:{
          showModal: false,
          header:false,
          headerBorder: false,
          title: false,
          titleText: '',
          footer: false,
          footerBtnPosition:'',
          footerBorder: false,
          btnCancel: false,
          btnSubmit: false,
          btnSubmitText: '',
          btnCancelText: '',
          btnSubmitWidth: '',
          btnCancelWidth: '',
      },
    };
  },
  setup () {
      return { v$: useVuelidate() }
  },
  
  validations() {
      return {
          tglditerima: {
              required
          },
          unitditerima: {
              required
          },
          nama_penerima: {
              required
          }
      }
  },
  computed: {
  },
  mounted() {
    this.transaksi_id = this.$router.currentRoute._value.query.id_transaksi
    this.tglditerima = this.tgl_diterima
    this.unitditerima = this.unit_diterima
    this.nama_penerima = this.penerima
    // let k = 0
    this.dataFile.forEach((file) =>  {
      this.document.forEach((document_file) => {
        if (file.nama_file == document_file.file.replace(/(Support Document|\/)+/g, '')) {
          file.download = this.$apiDoc + document_file.file + document_file.name
        }
      })
    })
  },
  watch:{
    document(){
      this.dataFile.forEach((file) =>  {
      this.document.forEach((document_file) => {
        if (file.nama_file == document_file.file.replace(/(Support Document|\/)+/g, '')) {
          file.download = this.$apiDoc + document_file.file + document_file.name
        }
      })
    })
    }
  },
  methods: {
    dataspk(){
      this.loadingSubmit=true
      axios.get(`/transaction-support-document/export-spk?transaction_id=`+this.transaksi_id+`&outgoing_detail_id=`+this.idtrasnsaksi, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/msword'
            }
        }).then((response)=>{
      this.loadingSubmit=false
       const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Template_spk.doc'); //or any other extension
            document.body.appendChild(link);
            link.click();
      })
    },
    datainvoice(){
      this.loadingSubmit=true
      axios.get(`/transaction-support-document/export-invoice?transaction_id=`+this.transaksi_id+`&outgoing_detail_id=`+this.idtrasnsaksi, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/msword'
            }
        }).then((response)=>{
      this.loadingSubmit=false
       const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Template_invoice.doc'); //or any other extension
            document.body.appendChild(link);
            link.click();
      })
    },
    databast(){
      this.loadingSubmit=true
      axios.get(`/transaction-support-document/export-bast?transaction_id=`+this.transaksi_id+`&outgoing_detail_id=`+this.idtrasnsaksi, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/msword'
            }
        }).then((response)=>{
      this.loadingSubmit=false
       const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Template_bast.doc'); //or any other extension
            document.body.appendChild(link);
            link.click();
      })
    },
    datareceipt(){
      this.loadingSubmit=true
      axios.get(`/transaction-support-document/export-receipt?transaction_id=`+this.transaksi_id+`&outgoing_detail_id=`+this.idtrasnsaksi, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/msword'
            }
        }).then((response)=>{
      this.loadingSubmit=false
       const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Template_receipt.doc'); //or any other extension
            document.body.appendChild(link);
            link.click();
      })
    },
    setModal(condition, tipe){
        this.resetDataModal()
        this.modal.showModal= condition
        condition == true ? this.modalTipe = tipe : ''
        if(tipe == 'success' && condition){
            this.modal.header=true
        }else if(tipe == 'errorsize' && condition){
            this.modal.header=true
            this.modal.footer= true
            this.modal.footerBtnPosition='right'
            this.modal.btnSubmit= true
            this.modal.btnSubmitText= 'Oke'
            this.modal.btnSubmitWidth='auto'
        }else if(tipe == 'errorformat' && condition){
            this.modal.header=true
            this.modal.footer= true
            this.modal.footerBtnPosition='right'
            this.modal.btnSubmit= true
            this.modal.btnSubmitText= 'Oke'
            this.modal.btnSubmitWidth='auto'
        }else if(tipe == 'errorinput' && condition){
            this.modal.header=true
            this.modal.footer= true
            this.modal.footerBtnPosition='right'
            this.modal.btnSubmit= true
            this.modal.btnSubmitText= 'Oke'
            this.modal.btnSubmitWidth='auto'
        }
    },
    resetDataModal(){
        this.modal.showModal = false
        this.modal.header = false,
        this.modal.headerBorder = false
        this.modal.title = false
        this.modal.titleText = '' 
        this.modal.footer = false
        this.modal.footerBtnPosition =''
        this.modal.footerBorder = false
        this.modal.btnCancel = false
        this.modal.btnSubmit = false
        this.modal.btnSubmitText = ''
        this.modal.btnCancelText = ''
        this.modal.btnSubmitWidth = ''
        this.modal.btnCancelWidth = ''
    },
    closeModal(){
        this.setModal(false, this.modalTipe)
    },
    datarefresh(){
        this.setModal(false, this.modalTipe)
    },
    submitModal(){
        this.setModal(false, this.modalTipe)
    },
    setindex(value,name){
      this.dataindex = value
      this.datanama = name
    },
    inputfile(event,index,name){
      let file = event.target.files[0];
      if (
      file["type"] === "application/msword" ||
      file["type"] === "application/pdf" ||
      file["type"] === ".docx" ||
      file["type"] === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      file["type"] === ".doc" 
      ) {
          if(file["size"] <= 2048000){
            let dataarray ={
              name: name,
              file: event.target.files[0]
            }
            this.isidata[index] = dataarray
            document.getElementById('btnsimpan').style.display = "block"
          }else{
            
            this.setModal(true, 'errorsize')
          document.getElementById(`input_file`+this.pengiriman+this.indexdata+index).value= null
          }
      } else {
            this.setModal(true, 'errorformat')
          document.getElementById(`input_file`+this.pengiriman+this.indexdata+index).value= null
      }
      
    },
    updatepenerimaan(){
            this.v$.$touch()
            
            if (!this.v$.$invalid) {
                let data = new FormData();
                data.append("_method", "PUT");
                data.append("name_receipt", this.nama_penerima);
                data.append("unit_receipt", this.unitditerima);
                data.append("date_receipt", this.tglditerima);
                data.append("customer_id", this.$store.state.user.id);
                if(this.isidata[0] != undefined){
                    data.append("files[0][name]", this.isidata[0].name);
                    data.append("files[0][file]", this.isidata[0].file);
                }
                else if(this.isidata[1] != undefined){
                    data.append("files[1][name]", this.isidata[1].name);
                    data.append("files[1][file]", this.isidata[1].file);
                }
                else if(this.isidata[2] != undefined){
                    data.append("files[2][name]", this.isidata[2].name);
                    data.append("files[2][file]", this.isidata[2].file);
                }
                else if(this.isidata[3] != undefined){
                    data.append("files[3][name]", this.isidata[3].name);
                    data.append("files[3][file]", this.isidata[3].file);
                }
                else if(this.isidata[4] != undefined){
                    data.append("files[4][name]", this.isidata[4].name);
                    data.append("files[4][file]", this.isidata[4].file);
                }
                else if(this.isidata[5] != undefined){
                    data.append("files[5][name]", this.isidata[5].name);
                    data.append("files[5][file]", this.isidata[5].file);
                }
                else if(this.isidata[6] != undefined){
                    data.append("files[6][name]", this.isidata[6].name);
                    data.append("files[6][file]", this.isidata[6].file);
                }
                this.loadingSubmit = true
                axios.post(`/transaction-support-document/update/`+this.idtrasnsaksi, data)
                  .then(() => {
                      this.loadingSubmit = false
                      this.setModal(true, 'success')
                      let k=0
                      for(let i = 0;i<4;i++){
                        document.getElementById(`input_file`+this.pengiriman+this.indexdata+k).value= null
                        k++
                      }
                      document.getElementById('btnsimpan').style.display = "none"
                      this.transaksi_id = this.$router.currentRoute._value.query.id_transaksi
                      axios.get('/transaction-support-document/'+this.transaksi_id).then(res => {
                          let datasupport = res.data.data
                          this.$emit('datareload', datasupport)
                          this.dataFile.forEach((file) =>  {
                          this.document.forEach((document_file) => {
                              if (file.nama_file == document_file.file.replace(/(Support Document|\/)+/g, '')) {
                                file.download = this.$apiDoc + document_file.file + document_file.name
                              }
                            })
                          })
                      })
                      .catch(() =>{
                      })
                    
                  })
                  .catch((e) => {
                    this.loadingSubmit = false
                    this.massageadmin = e.response.data.message
                      this.setModal(true, 'errorinput')
                  });
            }

        }
  },
};
</script>

<style scoped>
.text-link{
  color: var(--primary-color);
}
.search-group {
  border: solid 1px #ced4da;
  background-color: #fff;
  border-radius: 20px;
  max-width: 15rem;
}
.search-group input {
  background-color: transparent;
  border: 0px;
}
.tombol-search span {
  background-color: transparent;
  border: 0px;
}
.header-investor{
  justify-content: center;
  display: flex;
}
.content-wrapper .content {
  padding: 0 0.5rem;
}
.head_subs{
    display: flex;
}
.collapse .card{
  background-color: transparent !important;
  box-shadow: none !important;
}
.collapsing .card{
  background-color: transparent !important;
  box-shadow: none !important;
}
.table-data{
    overflow: auto;
}
.table > tbody > tr > td {
  vertical-align: center !important;
  background-color: transparent !important;
  --bs-table-accent-bg: transparent !important;
  vertical-align: center;
  line-height: 50px;
  justify-content: center;
  padding: 15px 15px;
}
.table > tbody > tr > td h6{
    padding-top: 10px;
}
.table > tbody > tr > td h5{
    padding-top: 5px;
}
.panjang-td-table figure{
  width: 70px;
  height: 70px;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  margin: 0px;
}
.panjang-td-table img{
  height: 100%;
}
.dalam-td{
    padding-left: 20px;
}
.dalam-td p{
    margin-bottom: 0px;
    color: var(--color-light);
}
.td-installed{
    border: solid 1px #2980B9;
    color: #2980B9;
    background-color: transparent;
    border-radius: 25px;
    line-height: 30px;
    padding: 0px 15px;
    cursor: context-menu;
}
.td-expired{
    border: solid 1px #F1484F;
    color: #F1484F;
    background-color: transparent;
    border-radius: 25px;
    line-height: 30px;
    padding: 0px 15px;
    cursor: context-menu;
}
.td-pending{
    border: solid 1px #F1C40F;
    color: #F1C40F;
    background-color: transparent;
    border-radius: 25px;
    line-height: 30px;
    padding: 0px 15px;
    cursor: context-menu;
}

@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
  .content-wrapper .content {
    padding: 0 0.5rem;
  }
  .nav-subcription {
    display: flex;
    justify-content: space-between;
  }
  .header-subcription{
  display: flex;
  justify-content: space-between;
  }
  .user-investasi::before {
    content: " ";
    position: absolute;
    width: 150px;
    right: 10%;
    top: 40px;
    height: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    /* background-image: url("../assets/image/team.svg"); */
  }
  .data-subcription-user{
  padding-left: 20px;
  }
  .paginate_button{
      padding: 2px !important;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button{
      padding: 2px !important;
  }
    .header-investor{
  display: flex;
  justify-content: space-between;
  }
}

@media (min-width: 1023px) {
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>
