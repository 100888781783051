<template>
      
    <div>
        404
    </div>

</template>

<script>

export default {
  name: '404-component',
  mounted(){
    this.$emit('view', "PageNotFound");
  },
}
</script>

