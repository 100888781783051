<template>
      
      <div class="checkout section section-modif">

        <div class="checkout-container container">

          <div class="d-flex justify-content-end">
            <nav aria-label="breadcrumb" class="">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Beranda</a></li>
                <li class="breadcrumb-item active" aria-current="page">Checkout</li>
              </ol>
            </nav>
          </div>

          <div class="checkout-content-container">
            <h3 class="title">Checkout</h3>
            <div class="checkout-content">
              <div class="checkout-alamat-container">
                <div class="checkout-alamat-header">
                  <h5>Alamat Pengiriman</h5>
                </div>
                <div class="checkout-alamat-body" v-for="(data, index) in defaultAddress" :key="index">
                  <h5>{{data?.receipt_name}}</h5>
                  <p >{{data?.address}}, {{data?.sub_district.subdistrict_name}}, {{data?.district.city_name}}, {{data?.province.province_name}} {{data?.receipt_phone_number}}</p>
                </div>
                <div class="checkout-alamat-footer">
                  <button class="btn btn-red" v-if="defaultAddress.length == 0" @click="setModal(true, '','addAddress')"><i class='bx bx-plus-circle' ></i> Tambah alamat</button>
                  <button class="btn btn-red" v-else @click="setModal(true, '','listAddress')"><i class='bx bxs-home' ></i> Pilih alamat</button>
                </div>
              </div>
              <div class="checkout-ekspedisi-container">
                <div class="checkout-ekspedisi-header">
                  <h5>Ekspedisi Pengiriman</h5>
                </div>
                <div class="checkout-ekspedisi-body">
                  <select id="province_id" class="input" v-model="courier" @change.prevent="getDetailExpedisi">
                      <option value="" disabled selected v-if="loading">Loading..</option>
                      <option value="" disabled selected v-else>Pilih Ekspedisi</option>
                      <template v-for="(data,index) in listExpedisi" :key="index">
                      <option v-if="data.status == 'enabled'" :selected="data.courier_name" :value="data.courier_code">{{data.courier_name}}</option>
                      </template>
                  </select>
                  <select class="input" v-model="service" @change.prevent="getOngkirPrice">
                    <option value="" disabled v-if="loading == false">Pilih Layanan</option>
                    <option value="" v-if="loading" disabled>Loading..</option>
                    <option value="" v-if="notFound && listExpedisiService.length === 0" disabled>Layanan Tidak Tersedia</option>

                      <template v-if="!loading">
                      <option :value="data.service" v-for="(data,index) in listExpedisiService" :key="index">{{data.service}}</option>
                      </template>
                  </select>
                  <p class="text-grey">Estimasi Barang Diterima :
                    <span v-if="estimate !== ''">{{estimate}} hari</span>
                    <span v-else>-</span>
                  </p>
                  <table>
                    <tr>
                      <td>Biaya Ongkir</td>
                      <td class="text-right">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(biayaOngkir).toFixed())}}</td>
                    </tr>
                    <tr :class="{ hideNegoOngkir: !dataNegoOngkirx || shipping_cost_nego == null}" v-if="shipping_cost_nego !== null">
                      <td>Nego Ongkir</td>
                      <td class="text-right">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(shipping_cost_nego).toFixed())}}</td>
                    </tr>
                  </table>
                </div>
                <div class="checkout-ekspedisi-footer">
                  <button class="btn btn-light mr-2" :class="{ hideNegoOngkir: !dataNegoOngkirx || shipping_cost_nego == null}" v-if="shipping_cost_nego !== null" @click="setModal(true, '','batalNegosiasiOngkir')">Batal Nego</button>
                  <button class="btn btn-red" @click="setModal(true, '','negosiasiOngkir')"><i class='bx bx-book-open'></i> Nego ongkir</button>
                </div>
              </div>
            </div>
          </div>

          <div class="keranjang-content-container">
            <div class="product-content d-flex justify-content-center align-items-center" v-if="loadingCart" >
                <div class="lds-facebook"><div></div><div></div><div></div></div>
            </div> 
            <div class="bg-white p-3 mb-4" v-if="!loadingCart && listCheckOutDetail.length > 0">
              <div class="keranjang-table-container">
                <table class="mt-5 checkout" v-for="(cart,index) in listCheckOutDetail" :key="index">
                  <tr >
                    <th class="nama">Nama Produk</th>
                    <th class="jumlah">Jumlah</th>
                    <th class="harga">Harga </th>
                    <th class="harga" v-if="ringkasan.isNegoPrice == true && cart.price_nego !==null">Nego Harga </th>
                    <th class="total">Sub Total</th>
                  </tr>
                  <tbody>
                    <tr>
                      <td class="nama">
                        <div class="d-flex">
                          <!-- Image Cart -->
                          <div v-if="supportWebp">
                              <div :style="[cart.product.image.webp === null ? {'background-image':'url('+baseUrl+`/`+cart.product.image.image+')'} : {'background-image':'url('+baseUrl+`/`+cart.product.image.webp+')'}]" class="keranjang-produk-img"></div>
                          </div>
                          <div v-else-if="!supportWebp">
                              <div :style="'background-image:url('+baseUrl+`/`+cart.product.image.image+')'" class="keranjang-produk-img"></div>
                          </div>
                          <div v-else>
                            <div class="keranjang-produk-img  d-flex align-items-center justify-content-center">
                                <i class='bx bxs-image' style="color:#212121" ></i>
                            </div>
                          </div>
                          <div>
                            <p>{{cart.product.name}}</p>
                          </div>
                        </div>
                      </td>
                      <td class="jumlah text-center">
                        {{cart.qty}} {{cart.product.unit.name}}
                      </td>
                      <td class="harga text-right" v-if="cart.product.promo.length > 0">
                        <span class="badge-custom-red mb-2" >{{Number(cart.product.promo[0].discount)}}%</span>
                        <p class="m-0">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(cart.product.promo[0].price_promo).toFixed()) }}</p>
                        <p class="line-through m-0 font-small">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(cart.product.price).toFixed())}}</p>
                      </td>
                      <td class="harga text-right" v-else>
                        <p class="m-0">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(cart.product.price).toFixed())}}</p>
                      </td>
                      <td class="harga text-right" v-if="ringkasan.isNegoPrice == true && cart.price_nego !==null">
                        <p class="m-0">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(cart.price_nego).toFixed())}}</p>
                      </td>
                      <td class="total">
                        <b v-if="cart.product.promo.length > 0">Rp.{{ getNegoPrice(cart.id) == 0 ? new Intl.NumberFormat('id-ID').format(Number(cart.product.promo[0].price_promo * cart.qty).toFixed()) : new Intl.NumberFormat('id-ID').format(getNegoPrice(cart.id) * cart.qty)}}</b>
                        <b v-else>Rp.  {{ getNegoPrice(cart.id) == 0 ? new Intl.NumberFormat('id-ID').format(Number(cart.product.price * cart.qty).toFixed()) : new Intl.NumberFormat('id-ID').format(getNegoPrice(cart.id) * cart.qty)}}</b> <br>
                        <small class="text-danger">(Harga belum termasuk PPN)</small>
                      </td>
                    </tr>
                    <tr v-if="cart.notes != null">
                      <td colspan="5">
                        <div class="d-flex align-items-center mb-2">
                          <i class='bx bx-notepad'></i>
                          <h5 class="my-0 mx-2">Catatan</h5>
                        </div>
                        <p>{{cart.notes}}</p>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5">
                        <!-- :class="[cart.qty > 1 ? '':'qty-satu']" -->
                        <div class="checkout-cart-negosiasi-btn-container">
                          <div v-if="cart.qty > 1" >
                            <label :for="'partial-'+cart.id">
                              <input type="checkbox" :name="'partial-'+cart.id" :key="'partial-'+cart.id" :id="'partial-'+cart.id" @change="showFormPartial(cart.id, $event.target.checked, cart.qty)" :checked="dataFormPartial(cart.id).length > 0">
                              Pengiriman partial
                            </label>
                          </div>
                          <div v-else></div>
                          <div>
                            <button class="btn btn-light mx-3" v-if="ringkasan.isNegoPrice == true && cart.price_nego !==null" @click="setModal(true, cart.id,'modalBatalNegosiasiHarga')">Batal Nego</button>
                            <button class="btn btn-light ml-2" @click="setModal(true, cart.id, 'modalNegosiasiHarga')">
                              <i class='bx bx-book-open'></i> Ajukan negosiasi harga
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr :class="['form-partial-'+cart.id, dataFormPartial(cart.id).length == 0 ? 'd-none' : '' ]">
                      <td colspan="5">
                        <div class="d-flex justify-content-start mb-2">
                          <button class="btn btn-red" @click="addFormPartial(cart.id, cart.qty)">
                            <i class='bx bx-plus'></i>
                            Tambah partial
                          </button>
                        </div>
                        <div class="form-input mb-2" v-for="(data, int) in formPartial" :key="int">
                          <div v-if="data.id == cart.id">
                            <label for="" class="d-block">Jumlah Produk</label>

                            <input type="text" class="input" id="" :value="data.value" min="0" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                            @keyup="setInputPartialValue(cart.id, data.index, $event.target.value, data.qty)"
                            >
                            <button class="btn btn-light mx-2" @click="removeInputPartial(data.id, data.index)">
                              <i class='bx bx-trash-alt'></i>
                            </button>
                          </div>
                        </div>
                        <div v-if="errorPartialId.includes(cart.id)" class="alert alert-warning alert-dismissible fadeshow mt-4"  role="alert">
                            <strong>Peringatan !</strong> 
                            <span> Jumlah partial kurang / melebihi atau sama dengan jumlah produk yang Anda pesan</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div v-if="!loadingCart && listCheckOutDetail.length == 0" class="product-content-null">
              <div>
                <img :src="require(`../assets/img/empty-illustration.svg`)" alt="">
                <p class="text-center"><b>Wah keranjang belanjamu masih kosong ..</b></p>
                <div class="d-flex justify-content-center">
                  <router-link to="/produk?page=1" class="text-center btn btn-red">Yuk belanja sekarang</router-link>
                </div>
              </div>
            </div>
            <h3 class="title">Ringkasan Belanja</h3>
            <div class="ringkasan-belanja-container">
              <table class="table-ringkasan-belanja border-0">
                <tr>
                  <th class="border-0 p-1">Total {{ringkasan.isNegoPrice ? 'Nego' : ''}} Harga {{ ringkasan.jumlahBarang > 0 ? '('+ringkasan.jumlahBarang+' '+ 'Barang )' : '' }}</th>
                  <td class="border-0 p-1">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(ringkasan.totalNegoHarga).toFixed())  }}</td>
                </tr>
                <tr>
                  <th class="border-0 p-1">Total {{ringkasan.isNegoOngkir ? 'Nego' : ''}} Ongkir</th>
                  <td class="border-0 p-1">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(ringkasan.totalNegoOngkir).toFixed()) }}</td>
                </tr>
                <tr>
                  <th class="border-0 p-1">Total PPN</th>
                  <td class="border-0 p-1">Rp. {{  new Intl.NumberFormat('id-ID').format(Number(ringkasan.totalPPN).toFixed()) }}</td>
                </tr>
                <tr class="total-ringkasan-belanja border-top">
                  <th class="border-0 p-1">Total {{ringkasan.isNegoPrice || ringkasan.isNegoOngkir ? 'Nego' : ''}} Tagihan</th>
                  <th class="border-0 p-1">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(ringkasan.totalNegoTagihan).toFixed())  }}</th>
                </tr>
              </table>
            </div>


            <div v-if="messageErrorPengajuan != ''" class="alert alert-warning alert-dismissible fade show mt-4"  role="alert">
                <strong>Peringatan !</strong> 
                <span class="mx-2"> {{messageErrorPengajuan}}</span>
            </div>
            <div class="d-flex justify-content-end py-3">
              <button @click="pengajuanPembelian" id="pengajuanBtn" class="btn btn-red"><i class='bx bx-cart-download' ></i> Pengajuan pembelian</button>
            </div>
          </div>
          
        </div>

        <Modal
          :show="modal.showModal"
          :header="modal.header"
          :headerBorder="modal.headerBorder"
          :title="modal.title"
          :titleText="modal.titleText"
          :footer="modal.footer"
          :footerBtnPosition="modal.footerBtnPosition"
          :footerBorder="modal.footerBorder"
          :btnCancel="modal.btnCancel"
          :btnSubmit="modal.btnSubmit"
          :btnSubmitText="modal.btnSubmitText"
          :btnCancelText="modal.btnCancelText"
          :btnSubmitWidth="modal.btnSubmitWidth"
          :btnCancelWidth="modal.btnCancelWidth"
          @closeModal="closeModal"
          @submitModal="submitModal"
        >
          <div v-if="modalTipe === 'listAddress'">
              <ul>
                <li v-for="(address, index) in dataAddress" :key="index">
                  <label :for="address.id" class="d-block">
                      <table class="border-0">
                        <tr>
                          <td style="width:40px;" rowspan="2" class="border-0 pt-2 pb-0">
                            <input type="radio" name="address" :id="address.id" :value="address.id" v-model="defaultAddressIdCadangan" 
                            >
                          </td>
                          <td class="border-0 pt-2 pb-0">
                            <h5 class="m-0">{{address.customer_address.receipt_name}}</h5>
                          </td>
                        </tr>
                        <tr>
                          <td class="border-0 pt-0 pb-2">
                            <p class="m-0">{{address.customer_address.address}}, {{address.customer_address.sub_district.subdistrict_name}}, {{address.customer_address.district.city_name}}, {{address.customer_address.province.province_name}} {{address.customer_address.receipt_phone_number}}</p>
                          </td>
                        </tr>
                      </table>
                      <hr class="m-0 border border-secondary">
                  </label>
                </li>
              </ul>
          </div>
          <div v-if="modalTipe === 'addAddress'">
              <form class="mt-3" @submit.prevent="simpanAlamat()" style="overflow: hidden;">
                  <div class="alert alert-success" v-if="msgSuccess">{{msgSuccess}}</div>
                  <div class="alert alert-danger" v-if="message">{{message}}</div>
                      <div class="form-profile-info row">
                          <input type="hidden" name="" v-model="customer_id">
                          <div class="form-group col-sm-12 col-md-6 mb-3">
                              <label class="form-label">Nama Penerima <span class="text-danger">*</span></label>
                              <input type="text" class=" form-control input w-100" id="receipt_name" v-model="receipt_name"
                              :class="{'is-invalid': v$.receipt_name.$error}">
                              <span  v-if="v$.receipt_name.$error" class="text-danger font-small"> {{ v$.receipt_name.$errors[0].$message }}</span>
                          </div>
                          <div class="form-group col-sm-12 col-md-6 mb-3">
                              <label class="form-label">No Telepon <span class="text-danger">*</span></label>
                              <input type="text" id="receipt_phone_number" class="form-control input w-100" v-model="receipt_phone_number"
                              :class="{'is-invalid': v$.receipt_phone_number.$error}">
                              <span  v-if="v$.receipt_phone_number.$error" class="text-danger font-small"> {{ v$.receipt_phone_number.$errors[0].$message }}</span>
                          </div>
                          <div class="form-group col-12 mb-3">
                              <label class="form-label">Alamat <span class="text-danger">*</span></label>
                              <textarea class="form-control input w-100" id="address" v-model="address"
                              :class="{'is-invalid': v$.address.$error}"></textarea>
                              <span  v-if="v$.address.$error" class="text-danger font-small"> {{ v$.address.$errors[0].$message }}</span>

                          </div>
                          <div class="form-group col-12 col-md-6 mb-3">
                              <label class="form-label">Satker <span class="text-danger">*</span></label>
                              <input type="text" class="form-control input w-100" id="name" v-model="name"
                              :class="{'is-invalid': v$.name.$error}">
                              <span  v-if="v$.name.$error" class="text-danger font-small"> {{ v$.name.$errors[0].$message }}</span>
                          </div>
                          <div class="form-group col-sm-12 col-md-6 mb-3">
                              <label for="province_id">Provinsi <span class="text-danger">*</span></label>
                              <select id="province_id" class="form-control mt-1 input" v-model="province_id" @change="getWilayah('district',$event.target.value)"
                              :class="{'is-invalid': v$.province_id.$error}">
                                  <option value="" disabled>Pilih Provinsi</option>
                                  <option :value="provinsi.province_id" v-for="provinsi in provinces" :key="provinsi.province_id">{{provinsi.province_name}}</option>
                              </select>
                              <span  v-if="v$.province_id.$error" class="text-danger font-small"> {{ v$.province_id.$errors[0].$message }}</span>
                          </div>
                          <div class="form-group col-sm-12 col-md-6 mb-3">
                              <label for="district_id">Kabupaten <span class="text-danger">*</span></label>
                              <select id="district_id" class="form-control mt-1 input" v-model="district_id" @change="getWilayah('sub-district',$event.target.value)"
                              :class="{'is-invalid': v$.district_id.$error}">
                                  <option value="" disabled>Pilih Kabupaten</option>
                                  <option :value="district.city_id" v-for="district in districts" :key="district.city_id">{{district.city_name}}</option>
                              </select>
                              <span  v-if="v$.district_id.$error" class="text-danger font-small"> {{ v$.district_id.$errors[0].$message }}</span>
                          </div>

                          <div class="form-group col-sm-12 col-md-6 mb-3">
                              <label for="sub_district_id">Kecamatan <span class="text-danger">*</span></label>
                              <select id="sub_district_id" class="form-control mt-1 input" v-model="sub_district_id"
                              :class="{'is-invalid': v$.sub_district_id.$error}">
                                  <option value="" disabled>Pilih Kecamatan</option>
                                  <option :value="sub_district.subdistrict_id" v-for="sub_district in sub_districts" :key="sub_district.sub_districtid">{{sub_district.subdistrict_name}}</option>
                              </select>
                              <span  v-if="v$.sub_district_id.$error" class="text-danger font-small"> {{ v$.sub_district_id.$errors[0].$message }}</span>
                          </div>
                      </div>
                      <!-- <div class="text-right">
                        <button type="submit" class="btn btn-red" v-if="sub_district.subdistrict_id !== null"><i class='bx bxs-check-square'></i> Simpan</button>
                      </div> -->
                  </form>
          </div>

          <div v-if="modalTipe === 'negosiasiOngkir'">
            <table>
              <tr>
                <th>Biaya Ongkir</th>
                <th>Nego Ongkir</th>
              </tr>
              <tr>
                <td>
                  <h5>Rp. {{ new Intl.NumberFormat('id-ID').format(Number(biayaOngkir).toFixed())}}</h5>
                </td>
                <td>
                  <!-- <input type="number" class="input" min="0" placeholder="0" v-model="shipping_cost_nego_cadangan"> -->
                  <input class="input"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      type = "number"
                      maxlength = "8"
                      v-model="shipping_cost_nego_cadangan"
                  />
                  <p v-if="shipping_cost_nego_cadangan.length > 7" class="text-danger">Data terlalu panjang!</p>
                </td>
              </tr>
            </table>
          </div>

          <div v-if="modalTipe === 'batalNegosiasiOngkir'">
            <div class="d-flex align-items-center justify-content-center text-center">
              <div>
                <i class='bx bxs-error-circle' style="font-size:72px; color: #E40C0C;"></i>
                <p>Anda yakin ingin membatalkan negosiasi ongkir?</p>
              </div>
            </div>
          </div>

          <div v-if="modalTipe === 'modalBatalNegosiasiHarga'">
            <div class="d-flex align-items-center justify-content-center text-center">
              <div>
                <i class='bx bxs-error-circle' style="font-size:72px; color: #E40C0C;"></i>
                <p>Anda yakin ingin membatalkan negosiasi harga?</p>
              </div>
            </div>
            <div v-for="(dataDetail, index) in productDetail" :key="index">
              <div class="d-flex mb-3 justify-content-center">
                <div v-if="supportWebp">
                    <div :style="[dataDetail.product.image.webp === null ? {'background-image':'url('+baseUrl+`/`+dataDetail.product.image.image+')'} : {'background-image':'url('+baseUrl+`/`+dataDetail.product.image.webp+')'}]" class="keranjang-produk-img"></div>
                </div>
                <div v-else-if="!supportWebp">
                    <div :style="'background-image:url('+baseUrl+`/`+dataDetail.product.image.image+')'" class="keranjang-produk-img"></div>
                </div>
                <div v-else>
                  <div class="keranjang-produk-img  d-flex align-items-center justify-content-center">
                      <i class='bx bxs-image' style="color:#212121" ></i>
                  </div>
                </div>
                <p>{{dataDetail.product.name}}</p>
              </div>
              <div class="text-center">
                <p class="m-0">Harga Nego: </p>
                <h5 class="text-red">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(negoHargaPrice).toFixed())}}</h5>
              </div>

              <!-- <input type="number" class="input w-100" min="0" id="negoPrice" v-model="negoHargaPrice" placeholder="0"> -->
              <input type="hidden" id="id_nego_price" v-model="id_nego_price">
            </div>
          </div>

          <div v-if="modalTipe === 'modalNegosiasiHarga'" class="modal-body">
            <div v-for="(dataDetail, index) in productDetail" :key="index">
              <div class="d-flex mb-3">
                <div v-if="supportWebp">
                    <div :style="[dataDetail.product.image.webp === null ? {'background-image':'url('+baseUrl+`/`+dataDetail.product.image.image+')'} : {'background-image':'url('+baseUrl+`/`+dataDetail.product.image.webp+')'}]" class="keranjang-produk-img"></div>
                </div>
                <div v-else-if="!supportWebp">
                    <div :style="'background-image:url('+baseUrl+`/`+dataDetail.product.image.image+')'" class="keranjang-produk-img"></div>
                </div>
                <div v-else>
                  <div class="keranjang-produk-img  d-flex align-items-center justify-content-center">
                      <i class='bx bxs-image' style="color:#212121" ></i>
                  </div>
                </div>
                <p>{{dataDetail.product.name}}</p>
              </div>

              <table class="mt-3">
                <tr>
                  <th>Harga</th>
                  <th>Nego Harga</th>
                </tr>
                <tr>
                  <td class="text-right" v-if="dataDetail.product.promo.length > 0">
                    <span class="badge-custom-red mb-2" >{{Number(dataDetail.product.promo[0].discount)}}%</span>
                    <p class="m-0">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(dataDetail.product.promo[0].price_promo).toFixed()) }}</p>
                    <p class="line-through m-0 font-small">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(dataDetail.product.price).toFixed())}}</p>
                  </td>
                  <td class="text-right" v-else>
                    <p class="m-0">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(dataDetail.product.price).toFixed())}}</p>
                  </td>
                  <td>
                    <input type="number" class="input w-100" min="0" id="negoPrice"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      maxlength = "10" v-model="negoHargaPrice" placeholder="0">
                    <input type="hidden" id="id_nego_price" v-model="id_nego_price">
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div v-if="modalTipe === 'notifSukses'" class="modal-body">
            <div  class="text-center">
              <i class='bx bxs-check-circle ' style="font-size:72px; color:var(--primary-color)"></i>
              <div class="notif-text">
                <h3>Data berhasil disimpan</h3>
              </div>
            </div>
          </div>

          <div v-if="modalTipe === 'modalErrorSubmit'" class="modal-body">
            <div  class="text-center">
              <i class='bx bxs-error-circle ' style="font-size:72px; color:var(--primary-color)"></i>
              <div class="notif-text">
                <h3>{{messageErrorModal}}</h3>
              </div>
            </div>
          </div>

        </Modal>

        <div v-if="loadingPengajuan" class="loading-submit-animation-container" >
            <div class="loading-submit-animation-content">
                <div>
                    <div class="lds-facebook"><div></div><div></div><div></div></div>
                    <p>Mohon tunggu, data sedang diproses</p>
                </div>
            </div>
        </div>

      </div>

</template>

<script>
import { debuggerStatement } from '@babel/types'
import axios from 'axios'
import Modal from '../components/Modal.vue'
import useVuelidate from '@vuelidate/core'
import { required, helpers, numeric} from '@vuelidate/validators'

export default {
  name: 'checkout-component',
  props: ['supportWebp'],
  data(){
    return{
      baseUrl: process.env.VUE_APP_NAME_AXIOS,
      modalTipe: '',
      dataAddress:[],
      defaultAddress:[],
      defaultAddressId:'',
      defaultAddressIdCadangan:'',
      loading: false,
      notFound: false,
      modal:{
        showModal: false,
        header:false,
        headerBorder: false,
        title: false,
        titleText: '',
        footer: false,
        footerBtnPosition:'',
        footerBorder: false,
        btnCancel: false,
        btnSubmit: false,
        btnSubmitText: '',
        btnCancelText: '',
        btnSubmitWidth: '',
        btnCancelWidth: '',
      },
      dataCarts:[],
      loadingCart: true,
      formPartial:[],
      price_nego:'',
      id_nego_price:'',
      productDetail:[],
      listCheckOutDetail:[],
      listExpedisi:[],
      listExpedisiService:[],
      detailEkspedisi: [],
      ringkasan:{
        jumlahBarang:0,
        totalNegoHarga:0,
        totalNegoOngkir:0,
        totalPPN:0,
        totalNegoTagihan:0,
        isNegoPrice:false,
        isNegoOngkir:false
      },
      courier:'',
      service:'',
      shipping_cost_nego: '',
      shipping_cost_nego_cadangan: '',
      biayaOngkir:0,
      loadingLayanan:false,
      messageErrorPengajuan: '',
      loadingPengajuan:false,
      messageErrorModal:'',
      negoHargaPrice:0,
      errorPartialId:[],
      data_partial:'',
      dataNegoOngkirx:false,
      estimate: '',
      checked: false,
      loadingadx: false,
      message: '',
      msgSuccess: '',
      'customer_id': this.$cookies.get('user_id'),
      'receipt_name': '',
      'address': '',
      'name': '',
      'province_id': '',
      'district_id': '',
      'sub_district_id': '',
      'receipt_phone_number': '',
      'provinces':"",
      'districts':"",
      'sub_districts':"",
      'address_id':'',
    }
  },
  components: {
    Modal
  },
  setup () {
      return { v$: useVuelidate() }
  },
  validations() {
    return {
      receipt_name: {
          required: helpers.withMessage("Nama penerima tidak boleh kosong", required), 
      },
      address: {
          required: helpers.withMessage("Alamat tidak boleh kosong", required), 
      },
      name: {
          required: helpers.withMessage("Satuan kerja tidak boleh kosong", required), 
      },
      province_id: {
          required: helpers.withMessage("Silahkan pilih provinsi", required), 
      },
      district_id: {
          required: helpers.withMessage("Silahkan pilih kabupaten", required), 
      },
      sub_district_id: {
          required: helpers.withMessage("Silahkan pilih kecamatan", required), 
      },
      receipt_phone_number: {
          required: helpers.withMessage("No telepon tidak boleh kosong", required), 
          numeric: helpers.withMessage("Pastikan data yang diinput berupa angka", numeric)
      },
    }
  },
  beforeRouteEnter(to, from, next) {
    console.log(to, from, next)
    if(!window.$cookies.isKey('id_checkout')){
      next({ name: 'KeranjangBelanja' })
    }else{
      next()
    }
  },
  created(){
    this.getExpedisi()
    this.loadingadx = true
    
    setTimeout(() => {
      this.loadingadx = false
    }, 2000)
  },
  mounted(){
    this.loading = true
    if(this.$cookies.get('token')){
      this.getUser()
    }
    this.autoLogout()
    debuggerStatement
    this.getWilayah('province');
    // console.log('User id: ', this.$store.dispatch('carts'));

    this.loading = true
    debuggerStatement
    this.getWilayah('province');
    // console.log('User id: ', this.user?.id);
    if(this.user){
      this.loading = false
        this.getAddress(this.user?.id)
        if(this.$cookies.isKey('id_checkout')){
          this.getDetailCheckout(this.$cookies.get('id_checkout'))
        }
    }
    // console.log('address: ', this.defaultAddressIdCadangan);
  },
  computed:{
    user(){
      return this.$store.state.user
    }
  },
  methods: {
    // set modal
    setModal(condition, id=null, tipeModal){
      this.resetDataModal()
      this.modal.showModal = condition
      this.modalTipe = tipeModal
      if(tipeModal == 'listAddress' && condition){
        this.modal.header = true,
        this.modal.headerBorder = true,
        this.modal.title = true,
        this.modal.titleText = 'Daftar Alamat',
        this.modal.footer = true,
        this.modal.footerBorder = true,
        this.modal.footerBtnPosition ='center'
        this.modal.btnCancel = true
        this.modal.btnSubmit = true
        this.modal.btnSubmitText = 'Pilih'
        this.modal.btnCancelText = 'Batal'
        this.modal.btnSubmitWidth = 'auto'
        this.modal.btnCancelWidth = 'auto'
      } else if(tipeModal == 'addAddress' && condition){
        this.modal.header = true,
        this.modal.headerBorder = true,
        this.modal.title = true,
        this.modal.titleText = 'Tambah Alamat',
        this.modal.footer = true,
        this.modal.footerBorder = true,
        this.modal.footerBtnPosition ='center'
        this.modal.btnCancel = true
        this.modal.btnSubmit = true
        this.modal.btnSubmitText = 'Simpan'
        this.modal.btnCancelText = 'Batal'
        this.modal.btnSubmitWidth = 'auto'
        this.modal.btnCancelWidth = 'auto'
      } else if(tipeModal == 'negosiasiOngkir' && condition){
        this.modal.header = true,
        this.modal.headerBorder = true,
        this.modal.title = true,
        this.modal.titleText = 'Form Negosiasi Ongkir',
        this.modal.footer = true,
        this.modal.footerBorder = true,
        this.modal.footerBtnPosition ='center'
        this.modal.btnCancel = true
        this.modal.btnSubmit = true
        this.modal.btnSubmitText = 'Simpan'
        this.modal.btnCancelText = 'Batal'
        this.modal.btnSubmitWidth = 'auto'
        this.modal.btnCancelWidth = 'auto'
        this.shipping_cost_nego_cadangan = Number(this.shipping_cost_nego).toFixed() 

      }else if(tipeModal == 'batalNegosiasiOngkir' && condition){
        this.modal.header = true,
        this.modal.headerBorder = true,
        this.modal.title = true,
        this.modal.titleText = 'Batal Negosiasi Ongkir',
        this.modal.footer = true,
        this.modal.footerBorder = true,
        this.modal.footerBtnPosition ='center'
        this.modal.btnCancel = true
        this.modal.btnSubmit = true
        this.modal.btnSubmitText = 'Iya, Batal Nego'
        this.modal.btnCancelText = 'Tidak'
        this.modal.btnSubmitWidth = 'auto'
        this.modal.btnCancelWidth = 'auto'

      }else if(tipeModal == 'modalNegosiasiHarga' && condition){
        this.modal.header = true,
        this.modal.headerBorder = true,
        this.modal.title = true,
        this.modal.titleText = 'Form Negosiasi Harga',
        this.modal.footer = true,
        this.modal.footerBorder = true,
        this.modal.footerBtnPosition ='center'
        this.modal.btnCancel = true
        this.modal.btnSubmit = true
        this.modal.btnSubmitText = 'Simpan'
        this.modal.btnCancelText = 'Batal'
        this.modal.btnSubmitWidth = 'auto'
        this.modal.btnCancelWidth = 'auto'
        this.getDetailProduct(id)
        this.nego_price = 
        this.id_nego_price = id
      }else if(tipeModal == 'modalBatalNegosiasiHarga' && condition){
        this.modal.header = true,
        this.modal.headerBorder = true,
        this.modal.title = true,
        this.modal.titleText = 'Batal Negosiasi Harga',
        this.modal.footer = true,
        this.modal.footerBorder = true,
        this.modal.footerBtnPosition ='center'
        this.modal.btnCancel = true
        this.modal.btnSubmit = true
        this.modal.btnSubmitText = 'Iya, Batal'
        this.modal.btnCancelText = 'Tidak'
        this.modal.btnSubmitWidth = 'auto'
        this.modal.btnCancelWidth = 'auto'
        this.getDetailProduct(id)
        this.nego_price = ''
        this.id_nego_price = id
      }else if(tipeModal == 'notifSukses' && condition){
        this.modal.footer = true,
        this.modal.footerBtnPosition ='center'
        this.modal.btnSubmit = true
        this.modal.btnSubmitText = 'Oke'
        this.modal.btnSubmitWidth = 'auto'
      }else if(tipeModal == 'modalErrorSubmit' && condition){
        this.modal.footer = true,
        this.modal.footerBtnPosition ='center'
        this.modal.btnSubmit = true
        this.modal.btnSubmitText = 'Oke'
        this.modal.btnSubmitWidth = 'auto'
      }
    },
    resetDataModal(){
      this.modal.showModal = false
      this.modal.header = false,
      this.modal.headerBorder = false
      this.modal.title = false
      this.modal.titleText = '' 
      this.modal.footer = false
      this.modal.footerBtnPosition =''
      this.modal.footerBorder = false
      this.modal.btnCancel = false
      this.modal.btnSubmit = false
      this.modal.btnSubmitText = ''
      this.modal.btnCancelText = ''
      this.modal.btnSubmitWidth = ''
      this.modal.btnCancelWidth = ''
    },
    closeModal(){
      this.setModal(false, '' , this.modalTipe)
      this.messageErrorModal = ''

      if(this.modalTipe == 'listAddress'){
        this.defaultAddressIdCadangan = this.defaultAddressId
      } else if(this.modalTipe == 'addAddress'){
        this.resetForm()
      } else if(this.modalTipe == 'modalNegosiasiHarga'){
        this.price_nego = ''
        this.negoHargaPrice = ''
      }else if(this.modalTipe == 'negosiasiOngkir'){
        this.shipping_cost_nego_cadangan = ''
        // this.shipping_cost_nego_cadangan = this.shipping_cost_nego
      }
      
    },
    submitModal(){
      this.setModal(false, '' , this.modalTipe)
      this.messageErrorModal = ''
      if(this.modalTipe == 'listAddress'){
        this.simpanPilihAlamat()
      } else if(this.modalTipe == 'addAddress'){
        this.simpanAlamat()
      } else if(this.modalTipe == 'modalNegosiasiHarga'){
        this.negosiasiHarga()
        // this.negosiasiHarga(document.getElementById('negoPrice').value)
      }else if(this.modalTipe == 'notifSukses'){
        // console.log('berhasil nego!');
        // this.getDetailCheckout(this.$cookies.get('id_checkout'))
      } else if(this.modalTipe == 'modalErrorSubmit'){
        // console.log('Gagal!');
        // this.getDetailCheckout(this.$cookies.get('id_checkout'))
      }else if(this.modalTipe == 'negosiasiOngkir'){
        this.negoOngkir()
      }else if(this.modalTipe == 'batalNegosiasiOngkir'){
        this.batalNego()
      }else if(this.modalTipe == 'modalBatalNegosiasiHarga'){
        this.batalNegosiasiHarga()
      }

    },

    getWilayah(wilayah, value){
      wilayah = 'customer/' +wilayah
      let cek_wilayah = wilayah;
      value ? wilayah += '/'+value : wilayah;
      axios.get(wilayah)
      .then(response => {
          if(cek_wilayah == 'customer/province'){
              this.provinces = response.data.data
          }else if(cek_wilayah == 'customer/district'){
              this.districts = response.data.data
          }else if(cek_wilayah == 'customer/sub-district'){
              this.sub_districts = response.data.data
          }
      })
      .catch(() => {
          // console.error("There was an error!", error);
      }); 
    },

    async simpanAlamat(){
      this.loading = true
      this.v$.$touch()
      if (!this.v$.$invalid) {
        let form = new FormData();
        form.append('customer_id', this.customer_id)
        form.append('receipt_name', this.receipt_name)
        form.append('address', this.address)
        form.append('name', this.name)
        form.append('province_id', this.province_id)
        form.append('district_id', this.district_id)
        form.append('sub_district_id', this.sub_district_id)
        form.append('receipt_phone_number', this.receipt_phone_number)

        let url = ''
        let method = ''
          url = 'customer/address/create'
          method = 'post'
        this.loading =  true

        try {
          await axios({
            method: method,
            url: url,
            data: form
          });

          this.msgSuccess = 'Alamat berhasil ditambahkan'
          // this.loadingSubmit =  false
          // this.setToast()
          this.resetForm()
          this.setModal(true, '', 'notifSukses')
          // console.log('Submited Adddress',data)
          this.loading = false
          this.getAddress(this.$cookies.get('user_id'))
        } catch (error) {
          this.loading =  false
          this.setModal(true, '', 'addAddress')
          if (error.response.status === 404) {
            // console.log('Resource could not be found!');
          } else {
            this.message = error.message
            // console.log(error.message);
          }
        }

      } else{
        this.setModal(true, '', 'addAddress')
      }
      
    },
    resetForm(){
      this.receipt_name = "";
      this.receipt_phone_number ="";
      this.address = "";
      this.name = "";
      this.province_id = "";
      this.district_id =  "";
      this.sub_district_id =  "";
    },
   // address
    simpanPilihAlamat(){
      this.defaultAddressId = this.defaultAddressIdCadangan
      let default_id = this.defaultAddressIdCadangan
      this.defaultAddress = []
      let dataAddress = this.dataAddress.filter(function(value){ 
        return value.id == Number(default_id);
      });
      this.defaultAddress.push(dataAddress[0].customer_address)
      this.courier = ''
      this.service = ''
      this.biayaOngkir = 0
      this.shipping_cost_nego = ''
      // this.ringkasan.totalNegoOngkir =  0
      this.resetNego()
    },
    storeCatatan(id){
      let data = new FormData();
      data.append('note', this.catatan)
      data.append('_method', 'PUT')

      axios.post('/transaction/cart/update-note/'+id, data)
      .then(() => {
        // console.log(res)
        this.catatan = ''
        this.$store.dispatch('carts')
        this.setModal(false, '', this.modalTipe)

      }).catch(() => {
        // console.log(err)
      })
    },
    getAddress(user_id){
      axios.get('/customer/address/list/'+user_id)
      .then(res=>{

        this.dataAddress = res.data.data
        this.defaultAddress = []
        this.defaultAddress.push(this.dataAddress[0].customer_address)
        this.defaultAddressId = this.dataAddress[0].id
        this.defaultAddressIdCadangan = this.dataAddress[0].id
      }).catch(() => {
        // console.log(err)        
      })
    },

    //ongkir
    getExpedisi(){
      axios.get('expedition')
      .then(res => {
        this.loading = true
        this.listExpedisi = res.data.data
        setTimeout(() => {
          this.loading = false
        }, 2000)
      })
      .catch(() => {
        // console.log(err)
      })
    },
    // test(){
    //   console.log('Clicked!');
    // },
    batalNego(){
      // if(this.service != '' && this.courier != ''){
        this.shipping_cost_nego = ''
        let data = new FormData()
        data.append('checkout_id', this.$cookies.get('id_checkout'))
        data.append('shipping_cost_nego', '')
        axios.post('transaction/checkout/shipping-negotiation', data)
        .then(() => {
          // console.log('reset:', res)
          this.dataNegoOngkirx = false
          this.ringkasan.totalNegoOngkir = this.biayaOngkir
          // console.log('Nego Ongkir: ', this.biayaOngkir);
          this.shipping_cost_nego_cadangan = ''
          this.ringkasan.isNegoOngkir = false
        }).catch(err => {
          this.setModal(true, '', 'modalErrorSubmit')
          this.messageErrorModal = err.response.data.message
          // console.log(err)
        })
      // }else{
      //     this.setModal(true, '', 'modalErrorSubmit')
      //     this.messageErrorModal = 'Silahkan pilih kurir terlebih dahulu'
      // }
      // console.log('Ongkir: ', this.biayaOngkir, this.shipping_cost_nego);
    },
    resetNego(){
      // if(this.service != '' && this.courier != ''){
        this.shipping_cost_nego = ''
        let data = new FormData()
        data.append('checkout_id', this.$cookies.get('id_checkout'))
        data.append('shipping_cost_nego', '')
        axios.post('transaction/checkout/shipping-negotiation', data)
        .then(() => {
          // console.log('reset:', res)
          this.dataNegoOngkirx = false
          this.ringkasan.totalNegoOngkir = this.biayaOngkir
          // console.log('Total Ongkir Reset: ', this.biayaOngkir);
          this.ringkasan.isNegoOngkir = false
        }).catch(() => {
          // this.setModal(true, '', 'modalErrorSubmit')
          // this.messageErrorModal = err.response.data.message
          // console.log(err)
        })
      // }else{
      //     this.setModal(true, '', 'modalErrorSubmit')
      //     this.messageErrorModal = 'Silahkan pilih kurir terlebih dahulu'
      // }
      // console.log('Ongkir: ', this.biayaOngkir, this.shipping_cost_nego);
    },
    getDetailExpedisi(){
      let expedisi = this.courier
      this.loadingLayanan = true
      this.loading = true
      this.notFound = false
      let data = new FormData()
      data.append('satker_id', this.defaultAddress[0].satker_id)
      // console.log(this.defaultAddress[0].satker_id)
      data.append('courier', this.courier)
      axios.post('transaction/checkout/get-shipping-cost/'+this.$cookies.get('id_checkout'), data)
      .then(res => {
        
        this.listExpedisiService = res.data.data[0].rajaongkir.results[0].costs
        this.detailEkspedisi = res.data.data
        this.estimate = res.data.data[0].rajaongkir.results[0].costs[0].cost[0].etd
        // console.log('Estimasi Diterima: ', this.estimate)
        if (expedisi) {
          this.resetNego()
          // console.log('nego reset successfuly');
        }
        // console.log('Ekspedisi: ', this.listExpedisiService)
        setTimeout(() => {
          this.loading = false
          this.loadingLayanan = false
        }, 2000)
        // console.log('clicked!')
      }).catch(() => {
        // console.log(err)
        this.loading = false
        this.notFound = true
      })
    },
    negoOngkir(){
      if(this.service != '' && this.courier != ''){
        this.shipping_cost_nego = this.shipping_cost_nego_cadangan
        let data = new FormData()
        data.append('checkout_id', this.$cookies.get('id_checkout'))
        data.append('shipping_cost_nego', this.shipping_cost_nego == '' ? '' : this.shipping_cost_nego)
        axios.post('transaction/checkout/shipping-negotiation', data)
        .then(() => {
          // console.log(res)
          this.dataNegoOngkirx = true
          this.setModal(true, '', 'notifSukses')
          this.ringkasan.totalNegoOngkir = this.shipping_cost_nego
          this.ringkasan.isNegoOngkir = true
        }).catch(err => {
          this.setModal(true, '', 'modalErrorSubmit')
          this.messageErrorModal = err.response.data.message
          // console.log(err)
        })
      }else{
          this.setModal(true, '', 'modalErrorSubmit')
          this.messageErrorModal = 'Silahkan pilih kurir terlebih dahulu'
      }
      // console.log('Ongkir: ', this.biayaOngkir, this.shipping_cost_nego);
    },
    getOngkirPrice(){
      let layanan = this.service
      
      this.loadingLayanan = true
      // console.log('Layanan',layanan)
      let totalOngkir = 0
      let dataShippingCost = []
      for(let i = 0; i< Object.keys(this.detailEkspedisi).length; i++){
        let dataService = this.detailEkspedisi[i].rajaongkir.results[0].costs
        for(let j = 0; j< dataService.length; j++){
          if(dataService[j].service == layanan){
            totalOngkir += Number(dataService[j].cost[0].value)

            dataShippingCost.push({
              partial_id : Number(this.detailEkspedisi[i].partial_id),
              shipping_cost : Number(dataService[j].cost[0].value),
              etd: dataService[j].cost[0].etd
            })
          }
        }
        this.resetNego()
        
      }
      this.storeOngkir(dataShippingCost)
      this.biayaOngkir = totalOngkir
      this.ringkasan.totalNegoOngkir = this.biayaOngkir
      // console.log('Ongkir telah di set', this.biayaOngkir);
      setTimeout(() => {
        this.loadingLayanan = false
        // console.log('Loading Layanan FALSE');
      }, 2000)
    },

    storeOngkir(dataShippingCost){
      this.shipping_cost_nego = this.shipping_cost_nego_cadangan

      let data = {
        satker_id : this.defaultAddress[0].satker_id,
        shipping_courier : this.courier,
        shipping_service : this.service,
        shipping_costs : dataShippingCost
      }

      axios.post('transaction/checkout/store-shipping-cost/'+this.$cookies.get('id_checkout'), data)
      .then(() => {
        // console.log('Store Ongkir', res)
        //this.getDetailCheckout(this.$cookies.get('id_checkout'))
      }).catch(() => {
        // console.log(err)
      })
    },


    
    // Partial
    showFormPartial(id, condition, qty){
      
      let element = document.querySelector('.form-partial-'+id)
      if(condition){
        // get data partial dari data formPartial sesuai dengan id untuk mengecek apakah ada atau tidak
        let data = this.formPartial.filter(function (e) {
            return e.id === id
        })

        // cek apakah cart memiliki partial
        let isPartial = false
        this.listCheckOutDetail.forEach(e => {
          if(e.is_partial && e.id == id){
            isPartial = true
          }
        });
        
        let jumlahData = data.length
        if(jumlahData == 0 && !isPartial){
          this.addFormPartial(id, qty)
        }
        element.classList.contains('d-none') ? element.classList.remove('d-none') : '' 
      }else{
        // this.addFormPartial(id, qty)
        // this.errorPartialId = []
        // this.formPartial = []
        for( let i = 0; i < this.formPartial.length; i++){ 
          if(this.formPartial[i].id === id){
            this.formPartial.splice(i)
            // this.formPartial.splice(i, i+1)
            this.errorPartialId = []
          }
        }
        element.classList.contains('d-none') ? '' : element.classList.add('d-none')
      }
    },
    addFormPartial(id, qty){
      let jumlahData = this.formPartial.length

      if(jumlahData > 0){
        const index = this.formPartial.map(object => {
          return object.index;
        });
        jumlahData = Math.max(...index);
      }

      let newData = {
        id:id,
        index: jumlahData + 1,
        value:0,
        qty:qty
      }
      this.formPartial.push(newData) 

      this.validatePartial(id, qty)

    },
    removeInputPartial(id, index){
      let qty = ''
      let data = this.formPartial.filter(function (e) {
        qty = e.qty
        return e.index != index
      })
      this.formPartial = data

      this.validatePartial(id, qty)

    },
    setInputPartialValue(id, index, value, qty){
      // hapus data di formPartial dengan index yang sama, untuk menghindari data yang duplikat
      for( let i = 0; i < this.formPartial.length; i++){ 
        if(this.formPartial[i].index === index){
          this.formPartial.splice(i, 1)
        }
      }
      
      let newData = {
        id:id,
        index: index,
        value:value,
        qty:qty
      }
      this.formPartial.push(newData)
      this.validatePartial(id, qty)
      let result_data = this.formPartial.sort(function(a, b) {
          return a.index - b.index;
      });

      this.formPartial = result_data


    },
    validatePartial(id, qty){
      // cek total value not higher than qty
      let dataPerId = this.formPartial.filter(function (e) {
          return e.id == id
      })
      let totalQtyPartial = 0
      dataPerId.forEach(el => {
        if(el.value === el.qty.toString()){
          if(!this.errorPartialId.includes(id)) this.errorPartialId.push(id)
        } else{
          totalQtyPartial += Number(el.value)
        }
      })
      if(totalQtyPartial != qty){
        // push id checkout detail ke error partial
        if(!this.errorPartialId.includes(id)) this.errorPartialId.push(id)
      }else{
        // remove data id yang sama dengan id checkout detail di error partial
        for( let i = 0; i < this.errorPartialId.length; i++){ 
          if(this.errorPartialId[i] === id) {
            this.errorPartialId.splice(i, 1)
          }
        }

      }
    },
    dataFormPartial(id){
      return this.formPartial.filter(e => e.id == id)
    },
    getListProduct(){
      axios.get('transaction/checkout/detail-checkout/'+this.$cookies.get('id_checkout'))
      .then(response => {
        this.listCheckOutDetail = response.data.data.checkout_detail
        this.checkOutDetail = response.data.data
        this.setRingkasan(this.checkOutDetail)
        this.shipping_cost_nego = response.data.data.shipping_cost_nego
      }).catch(() => {
        // console.log(err)
      })
    },
    // cart
    negosiasiHarga(){
      let data = new FormData()
      data.append('price_nego', this.negoHargaPrice)
      data.append('checkout_detail_id', this.id_nego_price)

      axios.post('/transaction/checkout/price-negotiation', data)
      .then(() => {
        // console.log(res)
        this.price_nego = this.negoHargaPrice
        this.ringkasan.isNegoPrice = true
        this.setModal(true, '', 'notifSukses')
        // this.getDetailCheckout(this.$cookies.get('id_checkout'))
        this.getListProduct()
      }).catch(() => {
        // console.log(err)
      })
    },
    batalNegosiasiHarga(){
      let data = new FormData()
      data.append('price_nego', '')
      data.append('checkout_detail_id', this.id_nego_price)

      axios.post('/transaction/checkout/price-negotiation', data)
      .then(res => {
        console.log(res)
        this.price_nego = ''
        this.negoHargaPrice = ''
        this.ringkasan.isNegoPrice = false
        this.setModal(true, '', 'notifSukses')
        this.getListProduct()
      }).catch(() => {
        // console.log(err)
      })
    },
    getDetailProduct(id){
      let data = this.listCheckOutDetail.filter(function(value){ 
          return value.id == id;
      });
      this.productDetail = data
      this.negoHargaPrice = Number(data[0].price_nego).toFixed()
    },
    getNegoPrice(id){
      let data = this.listCheckOutDetail.filter(function(value){ 
          return value.id == id;
      });
      return Number(data[0].price_nego).toFixed()
    },
    getDetailCheckout(id){
      this.loading = true
      this.loadingLayanan = true
      axios.get('transaction/checkout/detail-checkout/'+id)
      .then(response => {
        if(response.data.data.shipping_courier != null && response.data.data.shipping_service != null){
          this.courier = response.data.data.shipping_courier 
          this.service = response.data.data.shipping_service
          this.getDetailExpedisi()
        }

        this.listCheckOutDetail = response.data.data.checkout_detail
        // console.log('list : ', this.listCheckOutDetail)
        this.loadingCart = false
        this.checkOutDetail = response.data.data
        this.setRingkasan(this.checkOutDetail)
        this.shipping_cost_nego = response.data.data.shipping_cost_nego
        this.biayaOngkir = response.data.data.shipping_cost
        
        this.loading = false
        setTimeout(() => {
          this.loadingLayanan = false
        }, 2000)
      })
      .catch(() => {
          // console.log(error)
          this.loadingCart = false
          this.loading = false
      })
    },

    //ringkasan
    setRingkasan(data){
      if(data.shipping_cost_nego == null || Number(data.shipping_cost_nego) == 0){
        // this.ringkasan.totalNegoOngkir =  Number(data.shipping_cost)
        this.ringkasan.totalNegoOngkir =  Number(this.biayaOngkir)
        this.ringkasan.isNegoOngkir = false
        // console.log('Total Ongkir Set Ringkasan', this.ringkasan.totalNegoOngkir);
      }else{
        this.ringkasan.totalNegoOngkir =  Number(data.shipping_cost_nego)
        this.ringkasan.isNegoOngkir = true
      }
      this.ringkasan.jumlahBarang = 0
      this.ringkasan.totalNegoHarga = 0
      this.ringkasan.totalPPN = 0
      this.ringkasan.totalNegoTagihan = 0
      this.ringkasan.isNegoPrice = false
      data.checkout_detail.forEach(element => {
        if(element.is_nego && element.price_nego > 0){
          this.ringkasan.jumlahBarang = Number(this.ringkasan.jumlahBarang) + 1 
          this.ringkasan.totalNegoHarga = Number(this.ringkasan.totalNegoHarga) + Number(element.subtotal_nego)
          this.ringkasan.isNegoPrice = true
          this.ringkasan.totalPPN = Number(this.ringkasan.totalPPN) + (Number(element.subtotal_nego) * (11/100))
        }else{
          this.ringkasan.jumlahBarang =  Number(this.ringkasan.jumlahBarang) + 0
          this.ringkasan.totalNegoHarga = Number(this.ringkasan.totalNegoHarga) + Number(element.subtotal)
          this.ringkasan.totalPPN = Number(this.ringkasan.totalPPN) + (Number(element.subtotal) * (11/100))
        }
        this.ringkasan.totalNegoTagihan =  Number(this.ringkasan.totalNegoHarga) + Number(this.ringkasan.totalPPN)
      })
      this.ringkasan.totalNegoTagihan +=  this.ringkasan.totalNegoOngkir
    },

    //pengajuan pemebelian
    pengajuanPembelian(){
      let partial = []

      this.listCheckOutDetail.forEach(data => {
        let formPartial = this.formPartial.filter(e => e.id === data.id)
        if(formPartial.length > 0){
          let partial_qty = []
          formPartial.forEach(i => {
            if(i.value != '' && parseInt(i.value) > 0) partial_qty.push(i.value)
          })
          partial.push({
            id:data.id,
            partial_qty: partial_qty
          })
        }else{
          let partial_qty = []
          partial_qty.push(data.has_partial[0].qty)
          partial.push({
            id:data.id,
            partial_qty: partial_qty
          })
        }
      })
      if(this.service == ''){
        this.messageErrorPengajuan = 'Silahkan pilih layanan ekspedisi terlebih dahulu'
      }else if(this.errorPartialId.length > 0){
        this.messageErrorPengajuan = 'Pastikan jumlah partial sesuai dengan jumlah produk'
      }else{
        document.getElementById('pengajuanBtn').disabled = true;
        this.loadingPengajuan =  true

        // console.log('partial : ', partial)

        let data = {
          checkout_id : this.$cookies.get('id_checkout'),
          total_ppn : this.ringkasan.totalPPN,
          partial: partial
        }
        axios.post('transaction/create', data)
        .then(res => {
          this.messageErrorPengajuan = ''
          // console.log(res)
          this.$router.push('/confirmation-order')
          this.loadingPengajuan =  false
          this.$cookies.set('id_transaction', res.data.data.id);
          this.$cookies.remove('id_checkout')
          this.$store.dispatch('carts')
        }).catch(err => {
          this.loadingPengajuan =  false
          this.messageErrorPengajuan = err.response.data.message
          // console.log(err.response.data.message)
        })
      }
    },
    getUser(){
      axios.get(`user`)
      .then(() => {
        // console.log('user: ', res);
          // this.user = res.data.data
      }).catch(() => {
        // console.log(err.response);
        // if (err.response.data.message == 'Unauthenticated.'){
        //   this.$cookies.remove('token')
        //   // this.$router.push('/login')
        // }
      })
    },
    autoLogout(){
      axios.interceptors.response.use( (response) => {
            // Return a successful response back to the calling service
            return response;
        }, (error) => {
            // console.log('response error', error.response)
            if (error.response.status == 401) {
              if (error.response.data.message == "Unauthenticated") {
                  // Hapus token di cookie
                  this.$cookies.remove('token')
                  this.$store.dispatch('cart')
                  // Redirect Home atau login
                  this.$router.push('/login')
              }
          }

          return Promise.reject(error.response);
        })
    }
  },
  watch: {
    user:{
      deep: true,
      handler: function(){
        if( typeof this.user?.id !== 'undefined' ){
          this.getAddress(this.user?.id)
        }
        
        if(this.$cookies.isKey('id_checkout')){
          this.getDetailCheckout(this.$cookies.get('id_checkout'))
        }
      }
    },
  },
}
</script>

<style scoped>
.hideNegoOngkir {
    display: none;
}
.loader {
  border: 4px solid transparent;
  border-radius: 50%;
  border-top: 4px solid white;
  width: 14px;
  height: 14px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

table.checkout tr th.nama{
  width: 40%;
}
table.checkout tr th.jumlah{
  width: 10%;
}
table.checkout tr th.total{
  width: 30%;
}

@media(max-width: 767.98px){
  table.checkout{
    width: 200%;
  }
  table.checkout tr th.nama, table.checkout tr td.nama{
    width: 30%;
  }
  table.checkout tr th.jumlah{
    width: 10%;
  }
  /* table.checkout tr th.harga{
    width: 100px;
  } */
  table.checkout tr th.total{
    width: 30%;
  }
}
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>

