<template>
    <div class="container-fluid">
        <div class="w-100">
            <div class="float-right loading-skeleton">
                <button class="btn btn-outline-danger">
                    Ubah Password
                </button>
            </div>
        </div>
        <div class="w-100">
            <form @submit.prevent="editrofile"  class="loading-skeleton">
                <div class="form-profile-picture">
                    <div class="profile-piture-container">
                        <div class="profile-picture d-flex align-items-center justify-content-center image-user">A</div>

                        <div class="input-group mb-3 loading-skeleton">
                            <input type="file" class="form-control" id="inputGroupFile02">
                            <label class="input-group-text" for="inputGroupFile02">Upload</label>
                        </div>
                    </div>
                </div>

                <div class="form-profile-info row loading-skeleton">
                    <div class="form-group col-sm-12 col-md-6 mb-3">
                        <label class="form-label">Nama Instansi</label>
                        <input type="text" required class="input w-100">
                    </div>
                    <div class="form-group col-sm-12 col-md-6 mb-3">
                        <label class="form-label">Email</label>
                        <input type="email" required class="input w-100">
                    </div>
                    <!-- <div class="form-group col-sm-12 col-md-6 mb-3">
                        <label class="form-label">Kata Sandi</label>
                        <input type="password" class="input w-100">
                    </div>
                    <div class="form-group col-sm-12 col-md-6 mb-3">
                        <label class="form-label">Konfirmasi Kata Sandi</label>
                        <input type="password"  class="input w-100">
                    </div> -->
                    <div class="form-group col-sm-12 col-md-6 mb-3">
                        <label class="form-label">No Telepon</label>
                        <input type="text" required class="input w-100">
                    </div>
                    <div class="form-group col-sm-12 col-md-6 mb-3">
                        <label class="form-label">NIP</label>
                        <input type="text" required class="input w-100">
                    </div>
                    <div class="form-group col-sm-12 col-md-6 mb-3">
                        <label class="form-label">Nama PP</label>
                        <input type="text" required class="input w-100">
                    </div>
                    <div class="form-group col-sm-12 col-md-6 mb-3">
                        <label class="form-label">Nama PPK</label>
                        <input type="text" required class="input w-100">
                    </div>

                    <div class="col-12 d-flex justify-content-end">
                        <button type="submit" class="btn btn-red">Simpan</button>
                    </div>
                </div>
            </form>
        </div>
    </div>

</template>

<style scoped>
.container {
  margin-top: 4em;
  margin-bottom: 4em;
}

.loading-skeleton h1,
.loading-skeleton h2,
.loading-skeleton h3,
.loading-skeleton h4,
.loading-skeleton h5,
.loading-skeleton h6,
.loading-skeleton p,
.loading-skeleton li,
.loading-skeleton .btn,
.loading-skeleton label,
.loading-skeleton .image-user,
.loading-skeleton .form-control {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee;
  border-color: #eee;
}
.loading-skeleton h1::placeholder,
.loading-skeleton h2::placeholder,
.loading-skeleton h3::placeholder,
.loading-skeleton h4::placeholder,
.loading-skeleton h5::placeholder,
.loading-skeleton h6::placeholder,
.loading-skeleton p::placeholder,
.loading-skeleton li::placeholder,
.loading-skeleton .btn::placeholder,
.loading-skeleton label::placeholder,
.loading-skeleton .form-control::placeholder {
  color: transparent;
}

@keyframes loading-skeleton {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;
}
.loading-skeleton img {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
</style>
