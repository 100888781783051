<template>
    <div class="section loading-skeleton">
        <div class="container loading-skeleton">
            <div class="row row-dokupayment">
                <div class="d-flex justify-content-end">
                </div>
                <div class="col-xs-12 col-sm-5 col-md-4 left-content-bpdpaymet">
                    <div class="border-bottom border-white pb-2">
                        <span class="title-dokupayment">Nama Pembeli</span><br>
                        <span class="subtitle-dokupayment"><b>Lorem ipsum, dolor sit amet consectetur</b></span>
                    </div>
                    <div class="border-bottom border-white pt-3 pb-2">
                        <span class="title-dokupayment">Tanggal Pembelian</span><br>
                        <span class="subtitle-dokupayment"><b>Lorem ipsum, dolor sit amet consectetur</b></span>
                    </div>
                    <div class="border-bottom border-white pt-3 pb-2">
                        <span class="title-dokupayment">No Invoice</span><br>
                        <span class="subtitle-dokupayment"><b>Lorem ipsum, dolor sit amet consectetur</b></span>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-7 col-md-8 right-content-bpdpaymet p-3">
                    <div class="row">
                        <div class="col-sm-12 col-md-2 pt-3 text-center">
                            <img class="logo-dokupayment" src="../../../assets/img/doku.png">
                        </div>
                        <div class="col-sm-12 col-md-10 title-logo-dokupayment">
                            <b></b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <hr class="border border-2 border-secondary">
                        </div>
                        <span class="col-md-12 text-center mb-3">
                            <b>Metode Pembayaran :</b> Lorem ipsum, dolor sit amet consectetur<br>
                        </span>
                        <span class="col-md-12 text-center mb-3">
                            <b>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero fuga quasi praesentium quisquam libero consequuntur dolore eaque repellat quidem? Dolore omnis exercitationem voluptatem veniam, esse dicta ratione vitae doloribus ullam?</b><br>
                        </span>
                        <span class="col-md-12 text-center mb-3">
                            <b>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero fuga quasi praesentium quisquam libero consequuntur dolore eaque repellat quidem? Dolore omnis exercitationem voluptatem veniam, esse dicta ratione vitae doloribus ullam?</b><br>
                        </span>
                        <div class="col-md-12 text-center">
                            <div class="alert alert-secondary d-flex flex-row" role="alert">
                                <div>
                                    <img class="logo-checked-dokupayment" src="../../../assets/checked.png">
                                </div>
                                <span class="text-left p-4">
                                    Terima kasih atas pembayaran yang telah dilakukan, kami akan segera proses pesanan anda
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="view-mobile">
                            <div class="w-100">
                                <p><b>Layanan Pembayaran :</b></p>
                                <p> -</p>
                            </div>
                            <div class="w-100">
                                <p><b>Tipe Pembayaran :</b></p>
                                <p> -</p>
                            </div>
                            <div class="w-100">
                                <p><b>Nama Bank :</b></p>
                                <p> -</p>
                            </div>
                            <div class="w-100">
                                <p><b>No Rekening :</b></p>
                                <p> -</p>
                            </div>
                            <div class="w-100">
                                <p><b>Tanggal :</b></p>
                                <p> -</p>
                            </div>
                            <div class="w-100">
                                <p><b>Jumlah :</b></p>
                                <!-- <p> Rp.  {{datatransaksi.nominal}}</p> -->
                                <p> Rp.  Lorem ipsum, dolor sit amet consectetur</p>
                            </div>
                            <div class="w-100">
                                <p><b>Status :</b></p>
                                <p>Lorem ipsum, dolor sit amet consectetur</p>
                            </div>
                            <div class="w-100">
                                <p><b>Notes :</b></p>
                                <p> Lorem ipsum, dolor sit amet consectetur</p>
                            </div>
                        </div>
                    <div class="row mt-3">
                        <div class="col-md-12 text-right">
                             <button type="button" class="btn btn-back-dokupayment"><i class='bx bx-arrow-back' ></i> Kembali</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.loading-skeleton h1,
.loading-skeleton h2,
.loading-skeleton h3,
.loading-skeleton h4,
.loading-skeleton h5,
.loading-skeleton h6,
.loading-skeleton p,
.loading-skeleton li,
.loading-skeleton .btn,
.loading-skeleton label,
.loading-skeleton .image-user,
.loading-skeleton .datatable,
.loading-skeleton .form-control {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee;
  border-color: #eee;
}
.loading-skeleton h1::placeholder,
.loading-skeleton h2::placeholder,
.loading-skeleton h3::placeholder,
.loading-skeleton h4::placeholder,
.loading-skeleton h5::placeholder,
.loading-skeleton h6::placeholder,
.loading-skeleton p::placeholder,
.loading-skeleton li::placeholder,
.loading-skeleton .btn::placeholder,
.loading-skeleton .datatable::placeholder,
.loading-skeleton .image-user::placeholder,
.loading-skeleton label::placeholder,
.loading-skeleton .form-control::placeholder {
  color: transparent;
}

@keyframes loading-skeleton {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;
}
.loading-skeleton img {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
.loading-skeleton .datatable {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
    .btn-upload{
        display: block;
        margin: 0 auto;
    }
    .left-content-bpdpaymet {
        background-color: #e40c0c;
        border-radius: 10px 0px 0px 10px;
        color: #ffffff;
        padding: 30px;
    }
    .title-bpdpayment {
        font-size: 12px;
    }
    .subtitle-bpdpayment {
        font-size: 24px;
    }
    .right-content-bpdpaymet {
        background-color: #ffffff;
        border-radius: 0px 10px 10px 0px;
    }
    .logo-bpdpayment {
        width: 60px;
        height: auto;
    }
    .title-logo-bpdpayment {
        font-size: 26px;
        margin-top: 10px;
        margin-left: -20px;
    }
    .border-2 {
        border-width:2px !important;
    }
    .rounded-15 {
        border-radius: 15px;
    }
    .title-price-bpdpayment {
        font-size: 40px;
        color: #e40c0c;
        margin-top: -10px;
    }
    .barcode-bpdpayment {
        width: 200px;
        height: auto;
    }
    .list-method-bpdpayment {
        list-style-type: decimal; 
        margin-left:15px;
    }
    .btn-back-bpdpayment {
        background-color: #e40c0c;
        color: #FFFFFF;
    }
    @media (max-width: 1200px) { 
        .row-bpdpayment {
            margin: 0px 3px;
        }
        .subtitle-bpdpayment {
            font-size: 20px;
        }
    }
    @media (max-width: 992px) { 
        .title-logo-bpdpayment {
            font-size: 24px;
            margin-top: 10px;
        }
        .subtitle-bpdpayment {
            font-size: 16px;
        }
    }
    @media (max-width: 768px) { 
        .title-logo-bpdpayment {
            margin-top: 0px;
            margin-left: 0px;
            text-align: center;
        }
        .left-content-bpdpaymet {
            padding: 20px;
        }
        .subtitle-bpdpayment {
            font-size: 18px;
        }
    }
    @media (max-width: 576px) { 
        .left-content-bpdpaymet {
            border-radius: 10px 10px 0px 0px;
        }
        .right-content-bpdpaymet {
            border-radius: 0px 0px 10px 10px;
        }
    }
    .left-content-bpdpaymet {
        background-color: #e40c0c;
        border-radius: 10px 0px 0px 10px;
        color: #ffffff;
        padding: 30px;
    }
    .title-dokupayment {
        font-size: 12px;
    }
    .subtitle-dokupayment {
        font-size: 24px;
    }
    .right-content-bpdpaymet {
        background-color: #ffffff;
        border-radius: 0px 10px 10px 0px;
    }
    .logo-dokupayment {
        width: 35px;
        height: auto;
    }
    .logo-checked-dokupayment {
        width: 100px;
    }
    .title-logo-dokupayment {
        font-size: 26px;
        margin-top: 10px;
        margin-left: -20px;
    }
    .border-2 {
        border-width:2px !important;
    }
    .rounded-15 {
        border-radius: 15px;
    }
    .title-price-dokupayment {
        font-size: 40px;
        color: #e40c0c;
        margin-top: -10px;
    }
    .barcode-dokupayment {
        width: 200px;
        height: auto;
    }
    .list-method-dokupayment {
        list-style-type: decimal; 
        margin-left:15px;
    }
    .btn-back-dokupayment {
        background-color: #e40c0c;
        color: #FFFFFF;
    }
    .view-mobile{
        display: block;
    }
    .view-web{
        display: none;
    }
    @media (max-width: 1200px) { 
        .row-dokupayment {
            margin: 0px 3px;
        }
        .subtitle-dokupayment {
            font-size: 20px;
        }
    }
    @media (max-width: 992px) { 
        .title-logo-dokupayment {
            font-size: 24px;
            margin-top: 10px;
        }
        .subtitle-dokupayment {
            font-size: 16px;
        }
    }
    @media (max-width: 768px) { 
        .title-logo-dokupayment {
            margin-top: 0px;
            margin-left: 0px;
            text-align: center;
        }
        .left-content-bpdpaymet {
            padding: 20px;
        }
        .subtitle-dokupayment {
            font-size: 18px;
        }
    }
    @media (max-width: 576px) { 
        .left-content-bpdpaymet {
            border-radius: 10px 10px 0px 0px;
        }
        .right-content-bpdpaymet {
            border-radius: 0px 0px 10px 10px;
        }
    }
    @media (min-width: 767px) {
    .img-none{
        width:50%;
        display:block; 
        margin:0 auto;
    }
    .view-mobile{
        display: none;
    }
    .view-web{
        display: table;
    }
}
</style>