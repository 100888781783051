<template>
  <div class="footer">
      <div class="footer-top">
          <div class="footer-top-container container">
              <p>Temukan kami di sosial media :</p>
              <ul class="footer-sosial" :class="{ 'loading-skeleton' : loading }">
                <li class="footer-sosial-link">
                    <a class="text-light" :href="settings.facebook" target="_blank" rel="noopener noreferrer"><i class='bx bxl-facebook'></i></a>
                </li>
                <li class="footer-sosial-link">
                    <a class="text-light" :href="settings.twitter" target="_blank" rel="noopener noreferrer"><i class='bx bxl-twitter' ></i></a>
                </li>
                <li class="footer-sosial-link">
                    <a class="text-light" :href="settings.instagram" target="_blank" rel="noopener noreferrer"><i class='bx bxl-instagram-alt'></i></a>
                </li>
                <li class="footer-sosial-link">
                    <a class="text-light" :href="settings.linkedin" target="_blank" rel="noopener noreferrer"><i class='bx bxl-linkedin'></i></a>
                </li>
              </ul>
          </div>
      </div>
      <div class="footer-middle">
          <div class="footer-middle-container container">
              <div class="footer-contact">
                  <h3 class="footer-contact-title footer-title">Kantor Pusat</h3>
                  <table class="table-no-border">
                    <tr class="footer-contact-item">
                        <td :class="{ 'loading-skeleton' : loading }">
                            <i class='bx bxs-map'></i>
                        </td>
                        <td :class="{ 'loading-skeleton' : loading }">
                            <span>{{settings.address ? settings.address+',' : '-'}}</span><br class="d-none d-sm-block">
                            {{districts.city_name ? districts.city_name+',' : ''}}
                            {{provinces.province_name ? provinces.province_name+',' : ''}}
                            {{districts.postal_code ? districts.postal_code : ''}}
                        </td>
                    </tr>
                    <tr class="footer-contact-item">
                        <td></td>
                        <td :class="{ 'loading-skeleton' : loading }">
                            <span class="footer-telp">{{settings.phone ? settings.phone : '-'}}</span>
                            <span class="footer-mail">{{settings.email ? settings.email : '-'}}</span>
                        </td>
                    </tr>
                  </table>
              </div>
              <div class="footer-help">
                  <h3 class="footer-help-title footer-title">Bantuan & Panduan</h3>
                  <ul class="footer-help-list" :class="{ 'loading-skeleton' : loading }">
                      <li class="footer-help-item">
                          <router-link to="/tentang-kami">Tentang Kami</router-link>
                        </li>
                      <li class="footer-help-item">
                          <router-link to="/hubungi-kami">Hubungi Kami</router-link>
                        </li>
                      <li class="footer-help-item">
                          <router-link to="/term-condition">Syarat & Ketentuan</router-link>
                        </li>
                      <li class="footer-help-item">
                          <router-link to="/privacy-police">Kebijakan & Keamanan</router-link>
                        </li>
                      <li class="footer-help-item"><router-link to="/faq">Pusat Bantuan</router-link></li>
                  </ul>
              </div>
              <div class="footer-metode">
                  <div class="footer-metode-top">
                    <h3 class="footer-title">Metode Pembayaran</h3>
                    <img :src="require(`../assets/logo_pembayaran.png`)" alt="">
                  </div>
                  <div class="footer-metode-bottom">
                    <h3 class="footer-title">Jasa Pengiriman</h3>
                    <img :src="require(`../assets/logo_pengiriman.png`)" alt="">
                  </div>
              </div>
          </div>
      </div>
      <div class="footer-bottom">
          <div class="footer-bottom-container container">
              <i class='bx bx-copyright' ></i>
              Kantorqu 2022. Hak cipta dilindungi
          </div>
      </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
    name: 'footer-component',
    // props: ['settings']
    data(){
        return{
            settings: [],
            loading: false,
            provinces: '',
            districts: ''
        }
    },
    async mounted(){
        this.loading = true
        await axios.get(`setting`)
        .then((result) => {
            // console.log('Settings : ', result.data.data)
            this.settings = result.data.data
            this.loading = false
        }).catch(() => {
            // console.log(err.response);
            this.loading = false
        })
        await this.getDistrict()
        await this.getProvince()
    },
    methods: {
      getDistrict(){
        axios.get(`customer/district/${this.settings.province}`)
        .then(res => {
          let data = res.data.data
          data.forEach(item => {
            if (item.city_id === this.settings.district) {
              // console.log('district: ', item)
              // console.log('district: ', item)
              this.districts = item
            }
          });
          // console.log('Provinsi: ', this.sub_districts)
        })
      },
      getProvince(){
        axios.get('customer/province')
        .then(res => {
          let data = res.data.data
          data.forEach(item => {
            if (item.province_id === this.settings.province) {
              // console.log('data: ', item)
              this.provinces = item
            }
          });
            // console.log('Provinsi: ', this.provinces)
        })
      },
    }
}
</script>

<style scoped>
.loading-skeleton h1,
.loading-skeleton h2,
.loading-skeleton h3,
.loading-skeleton h4,
.loading-skeleton h5,
.loading-skeleton h6,
.loading-skeleton p,
.loading-skeleton li,
.loading-skeleton td,
.loading-skeleton span,
.loading-skeleton a,
.loading-skeleton .btn,
.loading-skeleton label,
.loading-skeleton .image-user,
.loading-skeleton .datatable,
.loading-skeleton .form-control {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee;
  border-color: #eee;
}
.loading-skeleton h1::placeholder,
.loading-skeleton h2::placeholder,
.loading-skeleton h3::placeholder,
.loading-skeleton h4::placeholder,
.loading-skeleton h5::placeholder,
.loading-skeleton h6::placeholder,
.loading-skeleton p::placeholder,
.loading-skeleton li::placeholder,
.loading-skeleton td::placeholder,
.loading-skeleton span::placeholder,
.loading-skeleton a::placeholder,
.loading-skeleton .btn::placeholder,
.loading-skeleton .datatable::placeholder,
.loading-skeleton .image-user::placeholder,
.loading-skeleton label::placeholder,
.loading-skeleton .form-control::placeholder {
  color: transparent;
}

@keyframes loading-skeleton {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;
}
.loading-skeleton img {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
.loading-skeleton .datatable {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
</style>

