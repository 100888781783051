<template>
    <div id="modalCustom">
        <div class="modal-custom" :class="[show ? 'show' : '']">
            <div v-if="show" class="modal-custom-background" @click="closeModal()"></div>
            <div class="modal-custom-content">
                <form @submit.prevent="submitModal()">
                    <div class="modal-custom-dialog">
                        <div v-if="header" class="modal-custom-header border-2 border-light border-bottom"
                        :class="[headerBorder ? 'modal-custom-header-border' : '', title ? 'between' : 'end']"
                        >
                            <h5 v-if="title" class="m-0">{{titleText}}</h5>
                            <button class="btn btn-light btn-sm" @click="closeModal()">
                                <i class='bx bx-x'></i>
                            </button>
                        </div>
                        <div class="modal-custom-body"
                        :class="[footerBorder ? '' : 'pb-0']">
                            <slot></slot>
                        </div>
                        <div v-if="footer" class="modal-custom-footer border-2 border-light border-top text-right"
                        :class="[footerBorder ? 'modal-custom-footer-border' : '', footerBtnPosition, footerBorder ? '' : 'pt-0']"
                        >
                            <button type="submit" class="btn btn-red mt-2 px-4" v-if="btnSubmit"
                            :class="[btnSubmitWidth == 'full' ? 'btn-block' : '']"
                            ><i class='bx bxs-check-square'></i> {{btnSubmitText != '' ? btnSubmitText : 'Simpan' }}</button>
                            <button class="btn btn-light py-2 mt-2" v-if="btnCancel"
                            :class="[btnCancelWidth == 'full' ? 'btn-block' : '']"
                            @click="closeModal()"
                            ><i class='bx bxs-minus-circle'></i> {{btnCancelText != '' ? btnCancelText : 'Batal' }}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    /** props 
     *  (show, header, headerBorder, title, footer, footerBorder, btnCancel, btnSubmit) = boolean (true or false)
     *  (titleText, btnSubmitText, btnCancelText) = string
     *  (footerBtnPosition) = left, center, right
     *  (btnSubmitWidth, btnCancelWidth) = full, auto
    */
    
    name: 'modal-component',
    props:['show','header','headerBorder','title', 'titleText', 'footer','footerBtnPosition','footerBorder','btnCancel','btnSubmit','btnSubmitText','btnCancelText','btnSubmitWidth','btnCancelWidth'],
    data(){
        return{
            style: {
                maxHeight: 65 / 100 * innerHeight,
            }
        }
    },
    methods: {
        closeModal(){
            document.body.classList.remove('no-scroll')
            let modal = document.querySelector('.modal-custom')
            modal.classList.remove('show')
            this.$emit('closeModal')
        },
        submitModal(){
            document.body.classList.remove('no-scroll')
            let modal = document.querySelector('.modal-custom')
            modal.classList.remove('show')
            this.$emit('submitModal')
        },

    },

    watch:{
        show: {
            deep: true,
            handler: function () {
                if(this.show == true){
                    if(!document.body.classList.contains('no-scroll')){
                        document.body.classList.add('no-scroll')
                    }
                }else{
                    document.body.classList.remove('no-scroll')
                }
            }
        },
    },
}
</script>
<style scoped>
.modal-custom-body {
  padding: 0px !important;
}
</style>
