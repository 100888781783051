<template>
    <div class="background-white py-3">
        <div class="form-complain-header border-0 mb-3">
            <router-link to="/profil/addkomplain" class="btn btn-red text-white"><i class='bx bx-plus-circle'></i> Komplain</router-link>
        </div>
        <div class="form-profile-body">
            <table-complain></table-complain>
        </div>
    </div>
</template>

<script>

import axios from 'axios'

import TableComplain from '../../components/ComplainUser/TableComplain.vue'
export default {
    name: 'form-profil',
    data(){
        return {
        }
    },

   components:{
        TableComplain
    },
    created(){
        this.$store.dispatch('id')
        .then(() => {
            axios.get('/transaction/complaint/list').then(() => {
            })
            .catch(() =>{
            })
        })
        .catch(() => {
        })
    },

    methods: {
    }
}
</script>

<style>
.form-complain-header{
    text-align: left;
}

@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
  .form-complain-header{
        text-align: right;
    }
}

@media (min-width: 1023px) {
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>
