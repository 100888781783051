<template>
    <div class="faq section section-modif">
        <div class="container">
            <h1 class="text-center">Pusat Bantuan</h1>

            <div class="row" v-if="loading">
                <div class="col-md-8 mx-auto" :class="{ 'loading-skeleton' : loading }">
                    <p>loading..</p>
                    <p>loading..</p>
                    <p>loading..</p>
                </div>
            </div>

            <div class="row">
                <div class="col-md-8 mx-auto">
                    <ul class="faq_group">
                        <li class="faq_item" v-for="list in faq" :key="list.id">
                            <p class="faq_quest">
                                <span>Q.</span>
                                {{list.question}}
                            </p>
                            <p class="faq_ans">
                                <span>A.</span>
                                {{list.answer}}
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'faq-view',
    data(){
        return{
            faq: [],
            loading: false
        }
    },
    mounted(){
        this.loading = true
        axios.get(`setting`)
        .then((result) => {
            // console.log('faq : ', result.data.data.faq)
            this.faq = result.data.data.faq
            this.loading = false
        }).catch(() => {
            // console.log(err.response);
            this.loading = false
        })
    }
}
</script>

<style scoped>
.faq_group{
    margin: 20px 0;
}
.faq_group .faq_item{
    border-bottom:1px solid #ececec;
    margin-bottom: 20px;
}
.faq_group .faq_item .faq_quest{
    background: #ececec;
    border-left: 3px solid red;
    padding: 15px;
    font-weight: 600;
    margin: 0;
    display: flex;
}
.faq_group .faq_item .faq_quest span{
    color: red;
    font-size: 28pt;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    vertical-align: middle;
    margin: 0 15px 15px 0;
    position: relative;
    top: -5px;
}
.faq_group .faq_item .faq_ans{
    border-left: 3px solid #98989A;
    padding: 15px;
    font-weight: 400;
    margin: 0 0 20px 0;
    color: #98989A;
    display: flex;
}
.faq_group .faq_item .faq_ans span{
    color: #98989A;
    font-size: 28pt;
    font-weight: 600;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    vertical-align: middle;
    margin: 0 15px 15px 0;
    position: relative;
    top: -5px;
}
</style>