<template>
    <skeleton-ulasan v-if="loading"></skeleton-ulasan>
    <div v-if="!loading" class="background-white">
        <div class="w-100">
            <div class=" pt-3">
                <router-link class="btn btn-red" to="/profil/addulasan"><i class='bx bx-edit'></i> Tulis Ulasan</router-link>
            </div>
        </div>
        <div class="w-100 p-4" v-if="!filteredData" >
                <div class="p-md-5">
                <img src="../../assets/img/undraw_reviews_lp8w.png" class="img-none" alt="">
            </div>
            <p class="w-100 mt-3" style="text-align:center;">Maaf Anda belum memilliki Ulasan</p>
        </div>
        <div class="w-100 p-4" v-else-if="filteredData.length == 0" >
                <div class="p-md-5">
                <img src="../../assets/img/undraw_reviews_lp8w.png" class="img-none" alt="">
            </div>
            <p class="w-100 mt-3" style="text-align:center;">Maaf Anda belum memilliki Ulasan</p>
        </div>
        <div v-else-if="filteredData" class="form-profile-body mt-3 mb-4">
            <div >
                <div class="card p-3" v-for="(ulasan, index) in filteredData" :key=index>
                    <div style="display:flex;">
                        <div style="display:flex;vertical-align: baseline; ">
                            <firgure class="figure_produk">
                                <img :src="$imgProductUrl+ulasan.transaction_detail?.image" alt="">
                            </firgure>
                            <div class="px-1 py-2 py-md-4 py-xl-5 px-md-3">
                                <h6 class="text-ulasan">{{ulasan.transaction_detail.name_product}}</h6>
                                <p class="text-ulasan">{{ulasan.transaction_detail.qty}} X Rp.{{ulasan.transaction_detail.price}}</p>
                            </div>
                        </div>
                        <!-- <div class="py-5">
                            <h1 style="display:flex;"><i class='bx bxs-star align-middle'  style="color:yellow;"></i> 4.0</h1>
                        </div> -->
                    </div>
                    <div>
                        <h6 class="mt-3">Penilaian</h6>
                        <h3 v-for="(n,index) in ulasan.star" :key="index" class="bx bxs-star bintang"></h3>
                    </div>
                    <div>
                        <div>
                            <h6>Komentar</h6>
                            <p class="text-ulasan">{{ulasan.notes}}</p>
                        </div>
                    </div>
                    
                    <div class="mt-2 mb-3 w-100">
                        <button @click="detailulasan(ulasan.id)" class="btn btn-red float-right" @click.prevent="tipeModal()" data-bs-toggle="modal" data-bs-target="#modalform">Detail</button>
                    </div>
                </div>
            </div>
            <div class="mt-3"></div>
            <div style="display-flex mt-3">
                <button class="btn btn-outline-secondary p-1 px-2 rounded-0" @click="btnpage(-1)">
                    <i class="bx bx-chevron-left"></i>
                </button>
                <button v-if="(curentpage+1) ==initPagination && (curentpage-1) > 0" class="btn btn-outline-secondary p-1 px-2 rounded-0" @click="btnpage(-2)">
                    {{curentpage-1}}
                </button>
                <button v-if="curentpage >0" class="btn btn-outline-secondary p-1 px-2 rounded-0" @click="btnpage(-1)">
                    {{curentpage}}
                </button>
                <button 
                    class="btn btn-outline-secondary p-1 px-3 rounded-0"
                >
                    {{ curentpage+1 }}
                </button>
                <button v-if="initPagination>1 && (curentpage+1) < initPagination" class="btn btn-outline-secondary p-1 px-2 rounded-0" @click="btnpage(+1)">
                    {{curentpage+2}}
                </button>
                <button class="btn btn-outline-secondary p-1 px-2 rounded-0" @click="btnpage(+1)">
                    <i class="bx bx-chevron-right"></i>
                </button>
            </div>
        </div>


        <!-- modal -->
            <div class="modal bd-example-modal-lg" tabindex="-1" role="dialog" id="modalform" >
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="container-fluid">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Detail Ulasan</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <skeletondetail v-if="loadingdetail"></skeletondetail>
                        <div v-if="!loadingdetail" class="card p-3 mb-3">
                            <div style="display:flex; justify-content: space-between;">
                                <div style="display:flex;vertical-align: baseline; ">
                                    <firgure class="figure_produk">
                                        <img :src="$imgProductUrl+dataulasan.transaction_detail?.image" alt="">
                                    </firgure>
                                    <div class="px-1 py-2 py-md-4 py-xl-5 px-md-3">
                                        <h6 class="text-ulasan"> {{dataulasan.transaction_detail?.name_product}}</h6>
                                        <p class="text-ulasan"> {{dataulasan.transaction_detail?.qty}} X Rp.{{dataulasan.transaction_detail?.price}}</p>
                                    </div>
                                </div>
                                <div class="py-5 nilai_web">
                                    <h1 style="display:flex;"><i class='bx bxs-star align-middle'  style="color:yellow;"></i> {{dataulasan.star}}.0</h1>
                                </div>
                            </div>
                            <div>
                                <div class="nilai_mobile mt-3">
                                    <h6>Nilai</h6>
                                    <h1 style="display:flex;"><i class='bx bxs-star align-middle'  style="color:yellow;"></i> {{dataulasan.star}}.0</h1>
                                </div>
                                <div>
                                    <h6>Komentar</h6>
                                    <p class="text-ulasan">{{dataulasan.notes}}</p>
                                </div>
                            </div>
                            <div class="w-100 list-data-bukti mt-2" >
                                <div class="card p-3 ml-2" v-for="(dataimg,index) in dataulasan.image" :key="index">
                                    <div v-if="cekextensi(dataimg.image) == 'jpg' || cekextensi(dataimg.image) == 'png' || cekextensi(dataimg.image) == 'jpeg' || cekextensi(dataimg.image) == 'webp'">
                                        <img :src="$imgProductUrl+dataimg.image" alt="" style="width:150px;">
                                    </div>
                                    <div v-else-if="cekextensi(dataimg.image) == 'mp4' || cekextensi(dataimg.image) == 'mpeg' || cekextensi(dataimg.image) == 'mov' || cekextensi(dataimg.image) == '3gp' || cekextensi(dataimg.image) == 'mkv' || cekextensi(dataimg.image) == 'avi'">
                                        <video width="320" height="240" controls>
                                            <source :src="$imgProductUrl+dataimg.image" :type="'video/'+cekextensi(dataimg.image)">
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- modal -->
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import SkeletonUlasan from '../SkeletonUser/ulasan/UlasanSkeleton.vue'
import Skeletondetail from '../SkeletonUser/ulasan/DetailSkeleton.vue'
export default {
    name: 'form-profil',
    data(){
        return {
            loading:true,
            loadingdetail:true,
            datalist: [],
            dataulasan: [],
            jmlh_value: 1,
            curentpage: 0,
            datapage: 0,
        }
    },
    components:{
        SkeletonUlasan,
        Skeletondetail
    },
watch: {
  },
  computed: {
    initPagination() {
      return Math.ceil(this.datalist.length / this.jmlh_value);
    },
    Datasort: function(){
      return this.datalist.slice().sort( ( a, b) => {
            return new Date(b.created_at)- new Date(a.created_at);
        });
    },
    filteredData: function () {
      let pageStart = this.curentpage * this.jmlh_value;
      let pageEnd = this.jmlh_value * (this.curentpage * 1 + 1);

      return this.Datasort.filter((item, index) => {
        return index >= pageStart && index < pageEnd;
      });
    },
  },
  created(){
    axios.get('/reviews/list/user').then(res => {
        this.datalist = res.data.data
        this.loading=false
    })
    .catch(() =>{
    })
},
  mounted() {
      
  },
  methods: {
    detailulasan(value){
        this.loadingdetail=true
        axios.get('/review/'+value).then(res => {
            this.dataulasan = res.data.data
            this.loadingdetail=false
            //  this.loading=false
        })
        .catch(err =>{
            // this.loading=false
            console.log(err)
        })
    },
    dateTime(value) {
        return moment(value).format("DD MMMM YYYY");
    },
    async entries_data(value) {
      this.curentpage = value - 1;
    },
    cekextensi(value){
        return value.split('.').pop()
    },
    addInput(){
        this.inputs.push(this.inputs.length + 1)
    },
    btnpage(value) {
        let datapage = this.initPagination
            
        if(this.curentpage <= 0 && value == -1){
            this.curentpage = 0
        } else if (this.curentpage <= 0 && value == -2) {
            this.curentpage = 0
        } else if (this.curentpage >= 0 && this.curentpage < (datapage - 1)) {
            this.curentpage += value  
        } else if (this.curentpage >= 0 && this.curentpage <= datapage - 1 && value == -1) {
            this.curentpage += value  
        } else if (this.curentpage >= 0 && this.curentpage <= datapage - 1 && value == -2) {
            this.curentpage += value  
        }  

    },
    firstData(data) {
      this.datalist = [];
      for (let i = 0; i < 10; i++) {
        if (data.length == i) {
          break;
        }
        let dataval = data[i];
        this.datalist.push(dataval);
      }

    },
  },
}
</script>

<style scoped>

.comment-star {
    position: relative;
    width: 400px;
    padding: 0px;
    border-radius: 5px;
    display: flex;
    text-align: left !important;
    /* align-items: right; */
}

.star-widget input {
    display: none;
}

.comment-star label {
    font-size: 1.5rem;
    color: #444;
    padding: 5px;
    float: right;
    transition: all 0.2s ease;
}

.header-content{
    color: rgba(0, 0, 0, 0.4);
}
.list-data-bukti{
    display: flex;
}
.list-data-bukti i{
    text-align: center;
    font-size: 5rem;
}
.list-data-bukti p{
    margin-bottom: 0px !important;
}
.list-data-bukti .card{
    margin-left: 0.5rem !important;
}
.data-user h5{
    margin-bottom: 5px !important;
    color: #000;
    font-weight: 600;
}
.img-profile{
    width: 100px;
    height: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.img-profile img{
    width: 100%;
}

input:not(:checked)~label:hover,
input:not(:checked)~label:hover~label {
    color: #fd4;
}

input:checked~label {
    color: #fd4;
}

input#rate-5:checked~label {
    color: #fe7;
}
.bintang{
    color: #fd4;
    font-size: 2rem;
}
.text-ulasan{
    font-size: 0.8rem;
}
.figure_produk{
    max-width: 80px;
    max-height: 80px;
}
.nilai_web{
    display: none;
}
.nilai_mobile{
    display: block;
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
  .bintang{
    font-size: 3rem;
    }
    .text-ulasan{
        font-size: 1rem;
    }
    .figure_produk{
        max-width: 150px;
        max-height: 150px;
    }
    .nilai_web{
    display: block;
    }
    .nilai_mobile{
        display: none;
    }
}

@media (min-width: 1023px) {
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}

</style>
