<template>
    <div class="background-white loading-skeleton p-md-3">
        <div class="container-fluid loading-skeleton row card-data-user">
            <div class="col-lg-5">
                <!-- <h3 class="p-3">Beranda</h3> -->
                <figure class="img-profile">
                    <img src="" alt="">
                </figure>
            </div>
            <div class="col-lg-7 pt-lg-4">
                <div class="data-user">
                <p>Nama Instansi :</p>
                <h5 class="w-75"></h5>
                <p>Email :</p>
                <h5 class="w-75"></h5>
                <p>No. Telephone :</p>
                <h5 class="w-75"></h5>
                <p>Alamat :</p>
                <h5 class="w-75"></h5>
                <p>NIP :</p>
                <h5 class="w-75"></h5>
                <p>Nama PP :</p>
                <h5 class="w-75"></h5>
                <p>Nama PPK :</p>
                <h5 class="w-75"></h5>
              </div>
            </div>
        </div>
        
        <data-table class="datatable"></data-table>
    </div>
</template>
<script>
import DataTable from '../../components/BerandaUser/TableUser.vue'
export default {
    name:'DashMobile',
    components:{
        DataTable
    },
}
</script>

<style scoped>
.card-data-user{
    margin:0 !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
}
.nav-desk{
    display: none;
}
.navmobile{
    display: block;
}
.data-user p{
    margin-bottom: 5px !important;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 600;
}
.data-user h5{
    padding-left: 10px;
    height: 40px;
}
.img-profile{
    width: 200px;
    height: 200px;
    overflow: hidden;
    align-items: center;
    margin: 0 auto;
}
.img-profile img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
}
.loading-skeleton h1,
.loading-skeleton h2,
.loading-skeleton h3,
.loading-skeleton h4,
.loading-skeleton h5,
.loading-skeleton h6,
.loading-skeleton p,
.loading-skeleton li,
.loading-skeleton .btn,
.loading-skeleton label,
.loading-skeleton .image-user,
.loading-skeleton .datatable,
.loading-skeleton .form-control {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee;
  border-color: #eee;
}
.loading-skeleton h1::placeholder,
.loading-skeleton h2::placeholder,
.loading-skeleton h3::placeholder,
.loading-skeleton h4::placeholder,
.loading-skeleton h5::placeholder,
.loading-skeleton h6::placeholder,
.loading-skeleton p::placeholder,
.loading-skeleton li::placeholder,
.loading-skeleton .btn::placeholder,
.loading-skeleton .datatable::placeholder,
.loading-skeleton .image-user::placeholder,
.loading-skeleton label::placeholder,
.loading-skeleton .form-control::placeholder {
  color: transparent;
}

@keyframes loading-skeleton {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;
}
.loading-skeleton img {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
.loading-skeleton .datatable {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
    .card-data-user{
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
}
}

@media (min-width: 1023px) {
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>