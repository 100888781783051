<template>
    <div class="background-white py-3">
        <div class="form-profile-body">
            <table-transaksi></table-transaksi>
        </div>
    </div>
</template>

<script>
import TableTransaksi from '../../components/BerandaUser/TableUser.vue'

export default {
    name: 'form-profil',
    data(){
        return {
        }
    },

    components : {
        TableTransaksi
    },

    methods: {
    }
}
</script>

<style>

</style>
