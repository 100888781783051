<template>
      
      <div class="confirmation-order section section-modif">

        <div class="confirmation-order-container container">

          <div class="d-flex justify-content-end">
            <nav aria-label="breadcrumb" class="">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Beranda</a></li>
                <li class="breadcrumb-item active" aria-current="page">Konfirmasi Pembelian</li>
              </ol>
            </nav>
          </div>

          <div class="product-content d-flex justify-content-center align-items-center" v-if="loadingTransaction" >
              <div class="lds-facebook"><div></div><div></div><div></div></div>
          </div> 
          <div v-else>
            <div class="confirmation-order-top">
              <i class='bx bxs-basket text-red' style="font-size:115px;"></i>
              <h3>Terima Kasih</h3>
              <p>Pengajuan pembelian anda dengan no <b>{{dataTransactions.invoice}}</b> telah kami terima.<br> Kami akan mengirimkan detail pengajuan & informasi pembelian selanjutnya melalui email <b>{{user?.email}}</b>. Atau anda bisa melihat informasi tersebut pada halaman dashboard profil anda.</p>
              <div class="confirmation-order-info-container">
                <div class="confirmation-order-user border-4 border-light rounded-3">
                  <h5>Informasi User</h5>
                  <hr class="border-2 border-secondary">
                  <table class="table-no-border informationTable">
                    <tr>
                      <td><b>Nama Instansi</b></td>
                      <td>: {{user?.instance_name}}</td>
                    </tr>
                    <tr>
                      <td><b>Email</b></td>
                      <td>: {{user?.email}}</td>
                    </tr>
                    <tr>
                      <td><b>No Telepon</b></td>
                      <td>: {{user?.phone}}</td>
                    </tr>
                    <tr>
                      <td><b>Alamat</b></td>
                      <td>: {{listAddress?.address}}</td>
                      <!-- <td>: {{dataEkspedisi?.address}}, {{dataEkspedisi?.sub_district}}, {{dataEkspedisi?.district}}, {{dataEkspedisi?.province}} {{dataEkspedisi?.postal_code}}</td> -->
                    </tr>
                    <tr>
                      <td><b>NIP</b></td>
                      <td>: {{user?.nip}}</td>
                    </tr>
                    <tr>
                      <td><b>Nama PP</b></td>
                      <td>: {{user?.name_pp}}</td>
                    </tr>
                  </table>
                </div>
                <div class="confirmation-order-ekspedisi border-4 border-light rounded-3">
                  <h5>Informasi Ekspedisi Pengiriman</h5>
                  <hr class="border-2 border-secondary">
                  <table class="table-no-border informationTable">
                    <tr>
                      <td><b>Ekspedisi</b></td>
                      <td>: {{ getExpedisi(dataEkspedisi?.shipping_courier) }}{{courier}}</td>
                    </tr>
                    <tr>
                      <td><b>Layanan</b></td>
                      <td>: {{dataEkspedisi?.shipping_service}}</td>
                    </tr>
                    <tr>
                      <td><b>Nama</b></td>
                      <td>: {{dataEkspedisi?.name_receipt}}</td>
                    </tr>
                    <tr>
                      <td><b>No Telepon</b></td>
                      <td>: {{dataEkspedisi?.phone_receipt}}</td>
                    </tr>
                    <tr>
                      <td><b>Alamat Pengiriman</b></td>
                      <td>: {{dataEkspedisi?.address}}, {{dataEkspedisi?.sub_district}}, {{dataEkspedisi?.district}}, {{dataEkspedisi?.province}} {{dataEkspedisi?.postal_code}}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <div class="confirmation-order-bottom mt-3">
              <div class="keranjang-content-container">
                <div class="bg-white p-3 mb-4">

                  <div class="keranjang-table-container">
                    <table class="checkout mt-5" v-for="(cart,index) in listTrasactionsDetail" :key="index">
                      <tr >
                        <th class="nama">Nama Produk</th>
                        <th class="jumlah">Jumlah</th>
                        <th>Harga </th>
                        <th v-if="cart.price_nego != null">Nego Harga </th>
                        <th class="total">Sub Total</th>
                      </tr>
                      <tbody>
                        <tr>
                          <td class="nama">
                            <div class="d-flex">
                              <!-- Image Cart -->
                              <div v-if="supportWebp">
                                  <div :style="[cart.webp === null ? {'background-image':'url('+baseUrl+`/`+cart.image+')'} : {'background-image':'url('+baseUrl+`/`+cart.webp+')'}]" class="keranjang-produk-img"></div>
                              </div>
                              <div v-else-if="!supportWebp">
                                  <div :style="'background-image:url('+baseUrl+`/`+cart.image+')'" class="keranjang-produk-img"></div>
                              </div>
                              <div v-else>
                                <div class="keranjang-produk-img  d-flex align-items-center justify-content-center">
                                    <i class='bx bxs-image' style="color:#212121" ></i>
                                </div>
                              </div>
                              <div>
                                <p>{{cart.name_product}}</p>
                              </div>
                            </div>
                          </td>
                          <td class="jumlah text-center">
                            {{cart.qty}} {{cart.unit}}
                          </td>
                          <td class="text-right" v-if="Number(cart.discount).toFixed() > 0">
                            <span class="badge-custom-red mb-2" >{{Number(cart.discount)}}%</span>
                            <p class="m-0">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(cart.price_after_discount).toFixed()) }}</p>
                            <p class="line-through m-0 font-small">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(cart.price).toFixed())}}</p>
                          </td>
                          <td class="text-right" v-else>
                            <p class="m-0">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(cart.price).toFixed())}}</p>
                          </td>
                          <td class="text-right" v-if="cart.price_nego != null">
                            <p class="m-0">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(cart.price_nego).toFixed())}}</p>
                          </td>
                          <td class="total">
                            <b>Rp. {{ new Intl.NumberFormat('id-ID').format(Number(cart.subtotal_nego > 0 ? cart.subtotal_nego : cart.subtotal).toFixed())}}</b><br>
                            <small class="text-danger">(Harga belum termasuk PPN)</small>
                          </td>
                        </tr>
                        <tr v-if="cart.notes != null">
                          <td colspan="5">
                            <div class="d-flex align-items-center">
                              <i class='bx bx-notepad'></i>
                              <h5 class="my-0 mx-2">Catatan</h5>
                            </div>
                            <p>{{cart.notes}}</p>
                          </td>
                        </tr>
                        <tr v-if="cart.shipping.length > 1">
                          <td colspan="5">
                            Pengiriman partial
                            <ul class="spesifikasi-produk mt-3" >
                              <li v-for="(shipping, index) in cart.shipping" :key="index">
                                <b class="mx-3 ">Partial ke {{index+1}}</b>: {{shipping.qty}} {{cart.unit}}
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <h3 class="title">Ringkasan Belanja</h3>
                <div class="ringkasan-belanja-container">
                  <table class="table-ringkasan-belanja border-0">
                    <tr>
                      <th class="border-0 p-1">Total {{ringkasan.isNegoPrice ? 'Nego' : ''}} Harga</th>
                      <!-- <th class="border-0 p-1">Total {{ringkasan.isNegoPrice ? 'Nego' : ''}} Harga {{ ringkasan.jumlahBarang > 0 ? '('+ringkasan.jumlahBarang+' '+ 'Barang )' : '' }}</th> -->
                      <td class="border-0 p-1">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(ringkasan.totalNegoHarga).toFixed())  }}</td>
                    </tr>
                    <tr>
                      <th class="border-0 p-1">Total {{ringkasan.isNegoOngkir ? 'Nego' : ''}} Ongkir</th>
                      <td class="border-0 p-1">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(ringkasan.totalNegoOngkir).toFixed()) }}</td>
                    </tr>
                    <tr>
                      <th class="border-0 p-1">Total PPN</th>
                      <td class="border-0 p-1">Rp. {{  new Intl.NumberFormat('id-ID').format(Number(ringkasan.totalPPN).toFixed()) }}</td>
                    </tr>
                    <tr class="total-ringkasan-belanja border-top">
                      <th class="border-0 p-1">Total Harga</th>
                      <th class="border-0 p-1">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(ringkasan.totalNegoTagihan).toFixed())  }}</th>
                    </tr>
                  </table>
                </div>

                <div class="d-flex justify-content-center align-items-center mt-5">
                  <router-link class="btn btn-red px-5" :to="'/produk?page=1'"><i class='bx bx-cart'></i> Belanja Lagi</router-link>
                </div>

              </div>
            </div>
          </div>

        </div>

        <Product>
          <div class="product-header">
            <h3 class="title">Rekomendasi untuk Anda</h3>
            <router-link :to="'/produk?page=1'" class="product-link">
              Lihat semua <i class='bx bx-right-arrow-alt' ></i>
            </router-link>
          </div>

          <div class="product-content" v-if="productRekomendasi">
            <product-list 
              v-for="(rekomendasi, index) in productRekomendasi" 
              :key="index" 
              :img="rekomendasi.image"
              :id="rekomendasi.id"
              :title="rekomendasi.name"
              :desc="rekomendasi.descriptions"
              :price="rekomendasi.price"
              :promo="rekomendasi.promo"
              :rating="rekomendasi.star_review"
              :status="rekomendasi.status"
              >
              </product-list>
          </div>
          <div v-else class="product-content-null">
            <img :src="require(`../assets/img/empty-illustration.svg`)" alt="">
          </div>
        </Product>

      </div>

</template>

<script>
import axios from 'axios'

import Product from '../components/Product.vue'
import ProductList from '../components/ProductList.vue'

export default {
  name: 'keranjang-belanja',
  props:['dataAllProducts', 'user','supportWebp'],
  data(){
    return{
      baseUrl: process.env.VUE_APP_NAME_AXIOS,
      productRekomendasi: [],
      dataTransactions:{},
      dataEkspedisi:{},
      listAddress:{},
      dataAddress: [],
      listTrasactionsDetail:[],
      loadingTransaction: false,
      is_nego: false,
      ringkasan:{
        jumlahBarang:0,
        totalNegoHarga:0,
        totalNegoOngkir:0,
        totalPPN:0,
        totalNegoTagihan:0
      },
      courier:''
    }
  },
  components: {
    Product,
    ProductList
  },
  methods: {
    getExpedisi(courier){
      axios.get('expedition')
      .then(res => {
        let listExpedisi = res.data.data 
        for(let i = 0; i < Object.keys(listExpedisi).length; i++){
          // console.log(listExpedisi[i], courier)
          if(listExpedisi[i].courier_code == courier){
            this.courier = listExpedisi[i].courier_name
          }
        }
      })
      // .catch(err => {
      //   console.log(err)
      // })

    },

    setProductRekomendasi(){
      let data_products = this.dataAllProducts
      this.productRekomendasi = data_products.slice(0,6)
    },
    getDataTransaction(){
      this.loadingTransaction = true
      axios.get('transaction/'+this.$cookies.get('id_transaction'))
      .then(res => {
        this.loadingTransaction = false
        this.dataTransactions = res.data.data.data
        this.listTrasactionsDetail = res.data.data.data.transaction_detail
        this.setRingkasan(this.listTrasactionsDetail, this.dataTransactions)
        this.dataEkspedisi = res.data.data.address
        // console.log('Data: ', this.user);
      })
      // .catch(err => {
      //   console.log(err)
      // })
    },
    //ringkasan
    setRingkasan(data, dataTransactions){
      this.ringkasan.totalNegoOngkir =  Number(dataTransactions.shipping_cost_nego == null || dataTransactions.shipping_cost_nego == 0 ? dataTransactions.shipping_cost : dataTransactions.shipping_cost_nego ) 
      this.ringkasan.jumlahBarang = 0
      this.ringkasan.totalNegoHarga = Number(dataTransactions.total_price_nego == null || dataTransactions.total_price_nego == 0 ? dataTransactions.total_price : dataTransactions.total_price_nego)
      this.ringkasan.totalPPN = Number(dataTransactions.total_ppn)

      data.forEach(element => {
        this.ringkasan.jumlahBarang = element.is_nego ? Number(this.ringkasan.jumlahBarang) + 1 : Number(this.ringkasan.jumlahBarang) + 0
        this.is_nego = element.is_nego
      })

      this.ringkasan.totalNegoTagihan = Number(dataTransactions.grand_total)
    },
    getUser(){
      axios.get(`user`)
      .then(() => {
        // console.log('user: ', res);
          // this.user = res.data.data
      }).catch(() => {
        // console.log(err.response);
        // if (err.response.data.message == 'Unauthenticated.'){
        //   this.$cookies.remove('token')
        //   // this.$router.push('/login')
        // }
      })
    },
    autoLogout(){
      axios.interceptors.response.use( (response) => {
            // Return a successful response back to the calling service
            return response;
        }, (error) => {
            // console.log('response error', error.response)
            if (error.response.status == 401) {
              if (error.response.data.message == "Unauthenticated.") {
                  // Hapus token di cookie
                  this.$cookies.remove('token')
                  this.$store.dispatch('cart')
                  // Redirect Home atau login
                  this.$router.push('/login')
              }
          }

          return Promise.reject(error.response);
        })
    }
  },
  beforeRouteEnter(to, from, next) {
    console.log(to, from, next)
    if(!window.$cookies.isKey('id_transaction')){
      next({ name: 'KeranjangBelanja' })
    }else{
      next()
    }
  },
  mounted(){
    if(this.$cookies.get('token')){
      this.getUser()
    }
    this.autoLogout()
    if(this.dataAllProducts != ''){
      this.setProductRekomendasi()
    }
    this.getDataTransaction()
    // console.log('data transaction: ', this.dataTransactions);
    
    axios.get('/customer/address/list/'+this.user.id)
    .then(res=>{
      this.dataAddress = res.data.data
      // console.log('Data Address: ', this.dataAddress);
    }).catch(() => {
      // console.log(err)        
    })
  },
  created(){
    if(this.$cookies.isKey('token')){
            this.$store.dispatch('user')
            this.$store.dispatch('id')
            .then(() => {
                axios.get('/customer/address/list/'+this.$store.state.id).then(res => {
                    
                    this.loading =false
                    if(res.data.data[0].customer_address == null){
                        this.address = 'null'
                        // this.address = res.data.data[0].customer_address.address
                        // console.log(this.address)
                        this.loading =false
                        // console.log("ked dini")
                        // console.log(this.loading)
                        // console.log(this.listAddress.address)
                    }else{
                         this.address = res.data.data[0].customer_address?.address
                    }
                    this.listAddress = res.data.data[0].customer_address
                   
                    
                        // console.log(this.listAddress)
                        // console.log(this.address)
                        // console.log(res.data.data[0])
                    if(this.user.image){
                    this.imageuser = this.$imgUrl+this.user.image
                    }else{
                        // this.imageuser = Img_Default
                        this.imageuser = ''
                    }
                    
                    
                })
                .catch(() =>{
                        this.address = 'null'
                    // console.log(err)
                })
            })
            .catch(() => {
                // console.log(e)
            })
        }
  },
  watch: {
    dataAllProducts:{
      deep: true,
      handler: function(){
        if(this.dataAllProducts != ''){
          this.setProductRekomendasi()
        }
      }
    },
  }
}
</script>
<style scoped>
.informationTable td {
  padding: 2px;
}
.confirmation-order-top{
  padding: 3rem;
}
table.checkout tr th.nama{
  width: 40%;
}
table.checkout tr th.jumlah{
  width: 10%;
}
table.checkout tr th.total{
  width: 30%;
}

@media(max-width: 767.98px){
  .confirmation-order-top{
    padding: 0;
  }
  table.checkout{
    width: 200%;
  }
  table.checkout tr th.nama, table.checkout tr td.nama{
    width: 30%;
  }
  table.checkout tr th.jumlah{
    width: 10%;
  }
  /* table.checkout tr th.harga{
    width: 100px;
  } */
  table.checkout tr th.total{
    width: 30%;
  }
}
</style>

