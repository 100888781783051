<template>
    <div>
        <!-- <pre>{{chatData}}</pre> -->
        <div class="chat-header d-flex justify-content-between p-3">
            <div class="d-flex align-items-center">
                <div class="small-user-picture text-white d-flex justify-content-center align-items-center">A</div>
                <p class="mx-3 my-0">Admin</p>
            </div>
            <button type="button" @click="scrollBottom" class="btn btn-light">Scroll ke bawah</button>
        </div>
        <div class="message-container" id="messageContainer">
            <div class="d-flex my-2 w-100" :class="[data.is_user? 'justify-content-start' : 'justify-content-end']" v-for="data in chatData" :key="data.id">
                <div class="message-item" :class="[data.is_user? 'message-item-left' : 'message-item-right']">
                    <p class="m-0 data-massage">{{data.message}} </p>
                    <p class="font-smaller m-0 text-right text-secondary">{{ dateTime(data.created_at)}}</p>
                </div>
            </div>
        </div>
        <form @submit.prevent="kirimPesan">
            <textarea 
            placeholder="Tulis pesan di sini .."
            :class="{ 'border-danger': v$.message.$error }"  
            class="input w-100 mt-3" 
            v-model="message"></textarea>
            <div class="text-danger" v-if="v$.message.$error">
                <small v-if="v$.message.required">Pesan Wajib Diisi</small>
            </div>
            <div class="d-flex justify-content-end">
                <button type="submit" class="btn btn-red" id="btn-kirim"><i class='bx bxs-message-dots' ></i> Kirim pesan</button>
            </div>
        </form>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
// import {mapState} from 'vuex'


export default {
    name: 'form-profil',
    data(){
        return {
            message: '',
            jumlahPesan : 0,
            jumlahData : 0,
            dataPesan:[]
        }
    },
    setup () {
        return { v$: useVuelidate() }
    },
    validations() {
        return {
                message: {
                    required,
                }
            }
    },
    created(){
       
        let me = this
        // setInterval(this.updateChat, 5000)
        
        // let urlParams = new URLSearchParams(window.location.search);

        // let i = 0
        TimerHandler()
        function TimerHandler(){
            setTimeout(function(){
                if(window.location.pathname === '/profil/chat'){
                    me.updateChat().then(()=>{
                        TimerHandler()
                    })
                }
            }, 3000)
        }
        
    },
    components : {
    },
    mounted(){
        let invoice =  this.$router.currentRoute._value.query.invoice
       if(invoice){
        this.message = invoice
       }
        // if(this.$store.state.semuapesan != null){
        //     this.updateChat();
        //     this.$store.dispatch('resetchat',{
        //             datachat:0
        //     })
        //     setInterval(this.updateChat, 5000);
        //     this.dataPesan = this.$store.state.semuapesan
        // }else{
            this.$store.dispatch('semuapesan')
            .then(()=>{
                this.dataPesan = this.$store.state.semuapesan
                // this.updateChat();
                this.$store.dispatch('resetchat',{
                        datachat:0
                })
            }).catch(() => {
            })
        // }
        
        
    },
    computed:{
         chatData(){
            let tempArray = [];
            for (let item of this.dataPesan) {
                (tempItem => {
                if (!(tempItem.length > 0 && tempItem.find(x => x.id === item.id))) tempArray.push(item);
                })(tempArray.filter(x => x.message === item.message))
            }
            return tempArray;
        },
    },
    methods: {
        updateChat: async function () {
        await this.$store.dispatch('getpesan')
        this.dataPesan = [...this.$store.state.semuapesan]
        return
        },
        scrollBottom(){
            const element = document.getElementById('messageContainer');
            element.scrollTop = element.scrollHeight;
        },
        dateTime(value) {
            return moment(value).format("MMM DD, YYYY [|] HH:mm a ");
        },
        async kirimPesan(){
            this.v$.$touch()
            if (!this.v$.$invalid) {
                document.getElementById('btn-kirim').disabled = true
                let message = new FormData()
                message.append('message', this.message)
                axios.post('usermanagement/chat/reply',message)
                .then(()=>{
                    this.v$.$reset();
                    this.$store.dispatch('getpesan')
                    .then(()=>{
                        this.dataPesan = [...this.$store.state.semuapesan]
                    }).catch(() => {
                    })
                    document.getElementById('btn-kirim').disabled = false
                    this.message = ''
                }).catch(()=>{
                    this.v$.$reset();
                })
            }
        }
    },

    watch:{
        dataPesan: function(){
            this.$nextTick(() => {
                this.scrollBottom()
            })
        }
    }
}
</script>

<style scoped>
.data-massage{
    word-wrap: break-word;
}
.message-container{
    padding: 0px;
}
.message-item{
    max-width: 100%;
}

@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
    .message-container{
        padding: 1rem;
    }
    .message-item{
        max-width: 340px;
    }
}

@media (min-width: 1023px) {
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>
