<template>
    <div class="background-white p-3 loading-skeleton">
        <div class="form-profile-header">
        </div>
        <div class="form-profile-body">
            <form>
                <div class="form-group mt-3">
                    <label for="pilih_id_pesanan">Pilih ID Pesanan</label>
                    <v-select class="mt-2"  placeholder="Silahkan pilih Transaksi Anda" label="invoice" ></v-select>
                   
                </div>
                <label for="" class="mt-3">Produk</label>
                <div class="container-fluid row p-lg-3">
                    <div class="col-md-6 p-2" style="height:100px;">
                        <table class="data-tabel " >
                            <tr>
                                <td class="td-nama-produk">
                                        <div class="nama-produk">
                                            <figure class="img-profile">
                                                <!-- <img :src="image_produk" alt=""> -->
                                            </figure>
                                            <h6 class="px-2">Lorem ipsum dolor, sit amet consectetur </h6>
                                        </div>
                                    </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-md-6 p-2" style="height:100px;">
                        <table class="data-tabel " >
                            <tr>
                                <td class="td-nama-produk">
                                        <div class="nama-produk">
                                            <figure class="img-profile">
                                                <!-- <img :src="image_produk" alt=""> -->
                                            </figure>
                                            <h6 class="px-2">Lorem ipsum dolor, sit amet consectetur </h6>
                                        </div>
                                    </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <!-- <product
                :id="transaksi_id"></product> -->
                <div class="form-group mt-3">
                    <label for="satuan">Kasih Bintang</label>
                    <div class="comment-star mt-2">
                        <div class="star-widget">
                            <input type="radio" v-model="rate" name="rate" id="rate-5" value="5">
                            <label for="rate-5" class="bx bxs-star"></label>
                            <input type="radio" v-model="rate" name="rate" id="rate-4" value="4">
                            <label for="rate-4" class="bx bxs-star"></label>
                            <input type="radio" v-model="rate" name="rate" id="rate-3" value="3">
                            <label for="rate-3" class="bx bxs-star"></label>
                            <input type="radio" v-model="rate" name="rate" id="rate-2" value="2">
                            <label for="rate-2" class="bx bxs-star"></label>
                            <input type="radio" v-model="rate" name="rate" id="rate-1" value="1">
                            <label for="rate-1" class="bx bxs-star"></label>
                        </div>
                    </div>
                </div>
                <div class="form-group mt-5">
                    <label for="keterangan">Tulis Komentar</label>
                    <textarea class="form-control mt-2" name="keterangan" id="keterangan" rows="3"> Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum blanditiis nam a ipsam molestiae, soluta culpa deserunt </textarea>
                    
                </div>
                <div class="w-100">
                    <button type="submit" class="btn btn-primary mt-3">Submit</button>
                </div>
                </form>
            </div>

        
    </div>
</template>

<style scoped>

.img-profile{
    width: 150px;
    height: 110px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.img-profile img{
    width: 100%;
}
.td-nama-produk{
    max-width: 200px !important;
}
.nama-produk{
    display: flex;
    margin: 0px;
    margin-right: 0px !important;
}
.nama-produk h6{
    vertical-align: inherit;
    margin: auto 0;
}
.console_log{
    border: 2px solid red !important;
}

.loading-skeleton h1,
.loading-skeleton h2,
.loading-skeleton h3,
.loading-skeleton h4,
.loading-skeleton h5,
.loading-skeleton h6,
.loading-skeleton p,
.loading-skeleton li,
.loading-skeleton .btn,
.loading-skeleton v-select,
.loading-skeleton label,
.loading-skeleton .image-user,
.loading-skeleton .datatable,
.loading-skeleton .form-control {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee;
  border-color: #eee;
}
.loading-skeleton h1::placeholder,
.loading-skeleton h2::placeholder,
.loading-skeleton h3::placeholder,
.loading-skeleton h4::placeholder,
.loading-skeleton h5::placeholder,
.loading-skeleton h6::placeholder,
.loading-skeleton p::placeholder,
.loading-skeleton li::placeholder, 
.loading-skeleton v-select::placeholder, 
.loading-skeleton .btn::placeholder,
.loading-skeleton .datatable::placeholder,
.loading-skeleton .image-user::placeholder,
.loading-skeleton label::placeholder,
.loading-skeleton .form-control::placeholder {
  color: transparent;
}

@keyframes loading-skeleton {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;
}
.loading-skeleton img {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
.loading-skeleton .datatable {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
</style>
