<template>
    <div class="container-fluid pt-4 loading-skeleton">
        
        <div class="form-group w-100 mt-4">
            <label for="pembayaran">Pilih Metode Pembayaran</label><br>
            <input id="pembayaran" type="text" name="metode-bayar"  placeholder="Pilih Metode Pembayaran" class="form-control mt-2" >
        </div>
        <div class="w-100 mt-3">
            <button class="btn btn-danger float-right">Pilih</button>
        </div>
    </div>
    <div class="container-fluid loading-skeleton pb-5">
        <h3 >informasi Pembayaran</h3>
        <table>
            <tbody>
                <tr>
                    <td><p>Metode Pembayran</p></td>
                    <td><p>: Manual(transfer Bank)</p></td>
                </tr>
                <tr>
                    <td><p>Nama Bank</p></td>
                    <td><p>: BCA</p></td>
                </tr>
                <tr>
                    <td><p>Cabang</p></td>
                    <td><p>: Renon</p></td>
                </tr>
                <tr>
                    <td><p>Atas Nama</p></td>
                    <td><p>: Bayu ArtaYoga</p></td>
                </tr>
                <tr>
                    <td><p>No Rekening</p></td>
                    <td><p>: 123456789</p></td>
                </tr>
                <tr>
                    <td><p>Tanggal</p></td>
                    <td><p>: 02 Januari 2022</p></td>
                </tr>
                <tr>
                    <td><p>Jumlah</p></td>
                    <td><p>: Rp.2.000.000</p></td>
                </tr>
                <tr>
                    <td><p>Bukti Transfer</p></td>
                    <td><p>: Klik Disini</p></td>
                </tr>
            </tbody>
        </table>




        
    </div>
</template>

<style scoped>
.form-group label{
    font-size: 1.2rem;
    font-weight: 600;
}
.metode-pembayaran{
    border: solid 1px grey;
}
.dropdown-item{
    justify-content: space-between;
    display: flex !important;
    border-bottom: solid 1px grey;
}
.dropdown-item p{
    margin-bottom: 0px;
}
.dropdown-content-left{
    display: flex;
    vertical-align: middle;
    padding: 10px;
}
.dropdown-content-left p{
    padding: 10px;
}
.dropdown-content-right{
    vertical-align: middle;
}
.dropdown-content-right i{
    padding: 10px;
    font-size: 2rem;
}

.loading-skeleton h1,
.loading-skeleton h2,
.loading-skeleton h3,
.loading-skeleton h4,
.loading-skeleton h5,
.loading-skeleton h6,
.loading-skeleton p,
.loading-skeleton li,
.loading-skeleton .btn,
.loading-skeleton label,
.loading-skeleton .image-user,
.loading-skeleton .datatable,
.loading-skeleton .form-control {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee;
  border-color: #eee;
}
.loading-skeleton h1::placeholder,
.loading-skeleton h2::placeholder,
.loading-skeleton h3::placeholder,
.loading-skeleton h4::placeholder,
.loading-skeleton h5::placeholder,
.loading-skeleton h6::placeholder,
.loading-skeleton p::placeholder,
.loading-skeleton li::placeholder,
.loading-skeleton .btn::placeholder,
.loading-skeleton .datatable::placeholder,
.loading-skeleton .image-user::placeholder,
.loading-skeleton label::placeholder,
.loading-skeleton .form-control::placeholder {
  color: transparent;
}

@keyframes loading-skeleton {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;
}
.loading-skeleton img {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
.loading-skeleton .datatable {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
</style>