<template>
    <div>
        <skeleton-penerimaan v-if="loading"></skeleton-penerimaan>
        <div v-else-if="!loading" class="pb-3">
            <div v-if="!transaction_detail || cekdata" class="p-4 w-100">
                <div class="p-md-5">
                    <img src="../../assets/img/undraw_delivery_truck_vt6p.svg" class="img-none" alt="">
                </div>
                <p class="w-100 mt-3" style="text-align:center;">Pesanan Anda Belum memasuki proses pengiriman</p>
            </div>
            <div v-else-if="transaction_detail == undefined" class="p-4 w-100">
                <div class="p-md-5">
                    <img src="../../assets/img/undraw_delivery_truck_vt6p.svg" class="img-none" alt="">
                </div>
                <p class="w-100 mt-3" style="text-align:center;">Pesanan Anda Belum di Konfirmasi</p>
            </div>
            <!-- <div v-else-if="transaction_detail.length > 0" v-for="(produk, indexdata) in transaction_detail" :key="indexdata"> 
                <div class="data1" v-for="(datapembayaran, index) in produk.detail" :key="index">
                    <h5 class="mt-3">Informasi Penerimaan Barang {{indexdata+1}} h</h5>
                    <div class="container-fluid pt-4" v-if="datapembayaran.document.length >0">
                        <table-upload
                        @datareload="datareload"
                        :indexdata="index"
                        :idtrasnsaksi="datapembayaran.id"
                        :index="index"
                        :descriptions="produk.descriptions"
                        :nama_produk="datapembayaran.transaction_detail.name_product"
                        :resi="datapembayaran?.no_airwaybill"
                        :ekspedisi="produk.address.shipping_courier"
                        :tgl_dikirim="datapembayaran?.date_delivery"
                        :tgl_diterima="datapembayaran?.date_receipt"
                        :unit_dikirim="datapembayaran?.unit_delivery"
                        :unit_diterima="datapembayaran?.unit_receipt"
                        :penerima="datapembayaran?.name_receipt"
                        :document="datapembayaran?.document">
                        </table-upload>
                    </div>
                    <div class="container-fluid pt-4" v-if="datapembayaran.document.length <=0">
                        <table-created
                        @datareload="datareload"
                        :indexdata="index"
                        :idtrasnsaksi="datapembayaran.id"
                        :index="index"
                        :descriptions="produk.descriptions"
                        :nama_produk="datapembayaran.transaction_detail.name_product"
                        :resi="datapembayaran?.no_airwaybill"
                        :ekspedisi="produk.address.shipping_courier"
                        :tgl_dikirim="datapembayaran?.date_delivery"
                        :tgl_diterima="datapembayaran?.date_receipt"
                        :unit_dikirim="datapembayaran?.unit_delivery"
                        :unit_diterima="datapembayaran?.unit_receipt"
                        :penerima="datapembayaran?.name_receipt">

                        </table-created>
                    </div>
                </div> -->
                <!-- <div class="data2">
                    <div class="container-fluid pt-4">
                        <form action="">
                            <div class="row">
                                <div class="form-group col-12">
                                    <label for="namaproduk">Nama Produk</label>
                                    <input type="text" disabled class="form-control mt-2" id="namaproduk" aria-describedby="namaproduk" placeholder="Nama Produk">
                                </div>
                                <div class="form-group col-md-6 mt-md-3">
                                    <label for="resi">No Resi</label>
                                    <input type="text" disabled class="form-control mt-2" id="resi" aria-describedby="resi" placeholder="No Resi">
                                </div>
                                <div class="form-group col-md-6 mt-md-3">
                                    <label for="Ekspedisi">Ekspedisi</label>
                                    <input type="text" disabled class="form-control mt-2" id="Ekspedisi" aria-describedby="Ekspedisi" placeholder="Ekspedisi">
                                </div>
                                <div class="form-group col-md-6 mt-md-3">
                                    <label for="dikirim">Tanggal Dikirim</label>
                                    <input type="date" disabled class="form-control mt-2" id="dikirim" aria-describedby="dikirim" placeholder="Ekspedisi">
                                </div>
                                <div class="form-group col-md-6 mt-md-3">
                                    <label for="diterima">Tanggal Diterima</label>
                                    <input type="date" class="form-control mt-2" id="diterima" aria-describedby="diterima" placeholder="Ekspedisi">
                                </div>
                                <div class="form-group col-md-6 mt-md-3">
                                    <label for="unitdikirim">Unit Dikirim</label>
                                    <input type="text" disabled class="form-control mt-2" id="unitdikirim" aria-describedby="unitdikirim" placeholder="Unit Dikirim">
                                </div>
                                <div class="form-group col-md-6 mt-md-3">
                                    <label for="unitditerima">Unit Diterima</label>
                                    <input type="text" class="form-control mt-2" id="unitditerima" aria-describedby="unitditerima" placeholder="Unit Diterima">
                                </div>
                                <div class="form-group col-12 mt-md-3">
                                    <label for="penerima">Nama Penerima</label>
                                    <input type="text" class="form-control mt-2" id="penerima" aria-describedby="penerima" placeholder="Nama Penerima">
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="mt-5">
                        <table-upload></table-upload>
                    </div>
                    <div class="container-fluid pb-3">
                        <div class="float-right">
                            <button class="btn btn-danger">Simpan</button>
                        </div>
                    </div>
                </div> -->
            <!-- </div> -->
            <div class="pt-3 p-md-4" v-else-if="transaction_detail" v-for="(produk, datadataindex) in transaction_detail" :key="datadataindex"> 
                <h4>Pengiriman {{parseInt(datadataindex)+1}}</h4>
                <div class="data1" v-for="(datapembayaran, dataindex) in produk.detail" :key="dataindex">
                    <h5 class="mt-3">Informasi Penerimaan Barang {{parseInt(dataindex)+1}}</h5>
                    <div class="container-fluid pt-4" v-if="datapembayaran.document.length >0">
                        <table-upload
                        @datareload="datareload"
                        :indexdata="dataindex"
                        :pengiriman="datadataindex"
                        :idtrasnsaksi="datapembayaran.id"
                        :descriptions="produk.descriptions"
                        :nama_produk="datapembayaran.transaction_detail.name_product"
                        :resi="datapembayaran?.no_airwaybill"
                        :ekspedisi="produk.address.shipping_courier"
                        :tgl_dikirim="datapembayaran?.date_delivery"
                        :tgl_diterima="datapembayaran?.date_receipt"
                        :unit_dikirim="datapembayaran?.unit_delivery"
                        :unit_diterima="datapembayaran?.unit_receipt"
                        :penerima="datapembayaran?.name_receipt"
                        :document="datapembayaran?.document">
                        </table-upload>
                    </div>
                    <div class="container-fluid pt-4" v-if="datapembayaran.document.length <=0">
                        <table-created
                        @datareload="datareload"
                        :indexdata="dataindex"
                        :pengiriman="datadataindex"
                        :idtrasnsaksi="datapembayaran.id"
                        :descriptions="produk.descriptions"
                        :nama_produk="datapembayaran.transaction_detail.name_product"
                        :resi="datapembayaran?.no_airwaybill"
                        :ekspedisi="produk.address.shipping_courier"
                        :tgl_dikirim="datapembayaran?.date_delivery"
                        :tgl_diterima="datapembayaran?.date_receipt"
                        :unit_dikirim="datapembayaran?.unit_delivery"
                        :unit_diterima="datapembayaran?.unit_receipt"
                        :penerima="datapembayaran?.name_receipt">

                        </table-created>
                    </div>
                </div>
                <!-- <div class="data2">
                    <div class="container-fluid pt-4">
                        <form action="">
                            <div class="row">
                                <div class="form-group col-12">
                                    <label for="namaproduk">Nama Produk</label>
                                    <input type="text" disabled class="form-control mt-2" id="namaproduk" aria-describedby="namaproduk" placeholder="Nama Produk">
                                </div>
                                <div class="form-group col-md-6 mt-md-3">
                                    <label for="resi">No Resi</label>
                                    <input type="text" disabled class="form-control mt-2" id="resi" aria-describedby="resi" placeholder="No Resi">
                                </div>
                                <div class="form-group col-md-6 mt-md-3">
                                    <label for="Ekspedisi">Ekspedisi</label>
                                    <input type="text" disabled class="form-control mt-2" id="Ekspedisi" aria-describedby="Ekspedisi" placeholder="Ekspedisi">
                                </div>
                                <div class="form-group col-md-6 mt-md-3">
                                    <label for="dikirim">Tanggal Dikirim</label>
                                    <input type="date" disabled class="form-control mt-2" id="dikirim" aria-describedby="dikirim" placeholder="Ekspedisi">
                                </div>
                                <div class="form-group col-md-6 mt-md-3">
                                    <label for="diterima">Tanggal Diterima</label>
                                    <input type="date" class="form-control mt-2" id="diterima" aria-describedby="diterima" placeholder="Ekspedisi">
                                </div>
                                <div class="form-group col-md-6 mt-md-3">
                                    <label for="unitdikirim">Unit Dikirim</label>
                                    <input type="text" disabled class="form-control mt-2" id="unitdikirim" aria-describedby="unitdikirim" placeholder="Unit Dikirim">
                                </div>
                                <div class="form-group col-md-6 mt-md-3">
                                    <label for="unitditerima">Unit Diterima</label>
                                    <input type="text" class="form-control mt-2" id="unitditerima" aria-describedby="unitditerima" placeholder="Unit Diterima">
                                </div>
                                <div class="form-group col-12 mt-md-3">
                                    <label for="penerima">Nama Penerima</label>
                                    <input type="text" class="form-control mt-2" id="penerima" aria-describedby="penerima" placeholder="Nama Penerima">
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="mt-5">
                        <table-upload></table-upload>
                    </div>
                    <div class="container-fluid pb-3">
                        <div class="float-right">
                            <button class="btn btn-danger">Simpan</button>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import TableUpload from './TableUpdatePenerimaan.vue'
import TableCreated from './TableUploadFile.vue'
import axios from 'axios'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import SkeletonPenerimaan from '../../components/SkeletonUser/TransaksiUser/SkeletonPenerimaan.vue'
export default {
    data(){
        return{
            loading:true,
            imageuser:'',
            dataaddress:{},
            datatransaksi:{
            },
            transaction_detail:{},
            datapembayaran:{},
            listaddress:{},
            negoongkir:'',
            address:'',
            users:[],
            userdata:[],
            transaksi_id:'',
            modalTipe: '',
            tgl_diterima: '',
            unit_diterima: '',
            penerima: '',
            alldatafile:[],
            cekdata:true
        }
    },
    setup () {
        return { v$: useVuelidate() }
    },
    validations() {
        return {
            tgl_diterima: {
                required
            },
            unit_diterima: {
                required
            },
            penerima: {
                required
            }
        }
    },
    components:{
        TableUpload,
        TableCreated,
        SkeletonPenerimaan
    },
    async created(){
        // this.$refs.myChild.isiandata()
        this.transaksi_id = this.$router.currentRoute._value.query.id_transaksi
        await axios.get('/transaction-support-document/'+this.transaksi_id).then(res => {
            this.transaction_detail = res.data.data
            this.datapembayaran = this.transaction_detail?.detail
            this.cekdata=false
             this.loading=false
        })
        .catch(() =>{
            this.cekdata=true
            this.loading=false
        })
    },
    computed:{
        datapenerimaan(){
            return this.transaction_detail
        }
        
    },
    methods: {
        updatedatafile(value){
            this.alldatafile = value
        },
        datareload(value){
            this.cekdata=false
             this.loading=true
            this.transaction_detail = value
            this.datapembayaran = this.transaction_detail?.detail
             this.loading=false
        },
        updatepenerimaan(){
            this.v$.$touch()
            // for(let i = 0;i<7;i++){
            //     if(this.alldatafile[0] == undefined){
            //         this.$swal("Ooops", "Maaf File Surat Tagihan Belum Diisi", "error", {
            //              button: "OK"})
            //         break
            //     }
            //     else if(this.alldatafile[1] == undefined){
            //         this.$swal("Ooops", "Maaf File Kwitansi Belum Diisi", "error", {
            //              button: "OK"})
            //         break
            //     }
            //     else if(this.alldatafile[2] == undefined){
            //        this.$swal("Ooops", "Maaf File Surat Pesanan Belum Diisi", "error", {
            //              button: "OK"})
            //         break
            //     }
            //     else if(this.alldatafile[3] == undefined){
            //         this.$swal("Ooops", "Maaf File Tanda Terima Belum Diisi", "error", {
            //              button: "OK"})
            //         break
            //     }
            //     else if(this.alldatafile[4] == undefined){
            //         this.$swal("Ooops", "Maaf File Berita Acara Serah Terima Belum Diisi", "error", {
            //              button: "OK"})
            //         break
            //     }
            //     else if(this.alldatafile[5] == undefined){
            //         this.$swal("Ooops", "Maaf File Surat Perintah Kerja Belum Diisi", "error", {
            //              button: "OK"})
            //         break
            //     }
            //     else if(this.alldatafile[6] == undefined){
            //         this.$swal("Ooops", "Maaf File Surat Serah Terima Belum Diisi", "error", {
            //              button: "OK"})
            //         break
            //     }
            //     else{
            //         this.cekdata=true
            //     }

            // }
            
            if (!this.v$.$invalid) {
                let data = new FormData();
                data.append("name_receipt", this.penerima);
                data.append("unit_receipt", this.unit_diterima);
                data.append("date_receipt", this.tgl_diterima);
                data.append("customer_id", this.$store.state.user.id);
                data.append("files[0][name]", this.alldatafile[0].name);
                data.append("files[0][file]", this.alldatafile[0].file);
                data.append("files[1][name]", this.alldatafile[1].name);
                data.append("files[1][file]", this.alldatafile[1].file);
                data.append("files[2][name]", this.alldatafile[2].name);
                data.append("files[2][file]", this.alldatafile[2].file);
                data.append("files[3][name]", this.alldatafile[3].name);
                data.append("files[3][file]", this.alldatafile[3].file);
                data.append("files[4][name]", this.alldatafile[4].name);
                data.append("files[4][file]", this.alldatafile[4].file);
                data.append("files[5][name]", this.alldatafile[5].name);
                data.append("files[5][file]", this.alldatafile[5].file);
                data.append("files[6][name]", this.alldatafile[6].name);
                data.append("files[6][file]", this.alldatafile[6].file);
                // axios.post(`/transaction-support-document/create/`+value, data)
                //   .then((response) => {
                //       this.$swal("Selamat", "Data anda telah berhasil diperbaharui", "success", {
                //          button: "OK"})
                    
                //   })
                //   .catch((e) => {
                //         this.$swal("Ooops", e.response.data.message, "error", {
                //         button: "OK"})
                //   });
            }

        }
    },
}
</script>
<style scoped>
.img-none{
    width:100%;
    display:block; 
    margin:0 auto;
}

@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
    .img-none{
        width:50%;
        display:block; 
        margin:0 auto;
    }
    
}

@media (min-width: 1023px) {
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>