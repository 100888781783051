<template>
    <div class="faq section section-modif">
        <div class="container">
            <h1 class="text-center">Syarat & Ketentuan</h1>

            <div class="row" v-if="loading">
                <div class="col-md-8 mx-auto" :class="{ 'loading-skeleton' : loading }">
                    <p>loading..</p>
                    <p>loading..</p>
                    <p>loading..</p>
                </div>
            </div>

            <div class="row">
                <div class="col-md-8 mx-auto">
                    <p class="mb-3" v-html="term.tac ? term.tac : '-'"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'term-condition',
    data(){
        return{
            term: [],
            loading: false,
        }
    },
    mounted(){
        this.loading = true
        axios.get(`setting`)
        .then((result) => {
            // console.log('term : ', result.data.data)
            this.term = result.data.data
            this.loading = false
        }).catch(() => {
            // console.log(err.response);
            this.loading = false
        })
    }
}
</script>

<style scoped>

</style>