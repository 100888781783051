<template>
    <div v-if="loading">
        <skeleton-ulasan></skeleton-ulasan>
    </div>
    <div v-if="!loading" class="background-white p-md-3">
        <div class="form-profile-header">
        </div>
        <div class="form-profile-body">
            <form @submit.prevent="addComplain">
                <div class="form-group mt-3">
                    <label for="pilih_id_pesanan">Pilih ID Pesanan</label>
                    <v-select v-model="dataid" :on-change="datacheck" placeholder="Silahkan pilih Transaksi Anda" :class="{ 'border-danger': v$.dataid.$error }" label="invoice" :options="paidtransaksi">
                        <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                                Mohon Maaf tidak ada data yang berkaitan dengan <em>{{ search }}</em
                                >.
                            </template>
                            <em v-else>Mohon Maaf Belum Ada Data.</em>
                        </template>
                    </v-select>
                    <small
                        class="error text-danger"
                        v-for="(error, index) of v$.dataid.$errors"
                        :key="index"
                        >
                        {{ error.$message }}
                    </small>
                </div>
                <div v-if="transaksi_id">
                    <produk-skeleton v-if="LoadingSkeleton"></produk-skeleton>
                    <label v-if="!LoadingSkeleton" for="" class="mt-3">Produk</label>
                    <div v-if="!LoadingSkeleton" class="container-fluid p-lg-3">
                        <div v-if="datatransaksi.length <= 0" class="row">
                            <p style="width:100%; text-align:center;">Mohon Maaf tidak terdapat produk yang dapat di ulas pada ID pesanan diatas</p>
                        </div>
                        <div v-else-if="datatransaksi" class="row">
                            <div v-for="(produk, index) in datatransaksi" :key="index" class="col-md-6 p-md-3 mt-2">
                                <table class="data-tabel " :class="{ 'console_log': produk.id == detail_id }" @click="setId(produk.id)">
                                    <tr>
                                        <td class="td-nama-produk">
                                                <div class="nama-produk">
                                                    <figure v-bind:style="{ backgroundImage: 'url(' +$imgProductUrl+ produk.image + ')' }" class="img-profile">
                                                        <!-- <img :src="image_produk" alt=""> -->
                                                    </figure>
                                                    <h6 class="px-2">{{produk.name_product}}</h6>
                                                </div>
                                            </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div v-else-if="datatransaksi.length > 0" class="row">
                            <div v-for="(produk, index) in datatransaksi" :key="index" class="col-md-6 p-md-3 mt-2">
                                <table class="data-tabel " :class="{ 'console_log': produk.id == detail_id }" @click="setId(produk.id)">
                                    <tr>
                                        <td class="td-nama-produk">
                                                <div class="nama-produk">
                                                    <figure v-bind:style="{ backgroundImage: 'url(' +$imgProductUrl+ produk.image + ')' }" class="img-profile">
                                                        <!-- <img :src="image_produk" alt=""> -->
                                                    </figure>
                                                    <h6 class="px-2">{{produk.name_product}}</h6>
                                                </div>
                                            </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="w-100" v-else>
                            <p style="width:100%; text-align:center;">Mohon Maaf tidak terdapat produk yang dapat di ulas pada ID pesanan diatas</p>
                        </div>
                    </div>
                </div>
                <div class="mt-5"> 
                    <small class="error text-danger mt-5" v-if="!alertprodukid">Produk Wajib Dipilih</small>
                </div>
                <div class="container-fluid">
                    <div class="form-group mt-3">
                        <label class="mt-4" for="satuan">Kasih Bintang</label>
                        <div class="comment-star mt-2">
                            <div class="star-widget">
                                <input type="radio" v-model="rate" name="rate" id="rate-5" value="5">
                                <label for="rate-5" class="bx bxs-star"></label>
                                <input type="radio" v-model="rate" name="rate" id="rate-4" value="4">
                                <label for="rate-4" class="bx bxs-star"></label>
                                <input type="radio" v-model="rate" name="rate" id="rate-3" value="3">
                                <label for="rate-3" class="bx bxs-star"></label>
                                <input type="radio" v-model="rate" name="rate" id="rate-2" value="2">
                                <label for="rate-2" class="bx bxs-star"></label>
                                <input type="radio" v-model="rate" name="rate" id="rate-1" value="1">
                                <label for="rate-1" class="bx bxs-star"></label>
                            </div>
                        </div>
                        <small
                            class="error text-danger"
                            v-for="(error, index) of v$.rate.$errors"
                            :key="index"
                            >
                            {{ error.$message }}
                        </small>
                    </div>
                </div>
                <div class="form-group mt-3" v-if="rate">
                    <label for="catatan">Tulis Komentar</label>
                    <textarea class="form-control mt-2" v-model="komentar" id="catatan" rows="3"></textarea>
                    <small
                        class="error text-danger"
                        v-for="(error, index) of v$.komentar.$errors"
                        :key="index"
                        >
                        {{ error.$message }}
                    </small>
                </div>
                
                <div class="w-100 px-3 py-4 card mt-3" v-if="komentar && datafile.length < 3">
                    <div class="">
                        <div @click="addInput()" class="btn btn-danger float-right">
                            Tambah Media
                        </div>
                    </div>
                </div>
                <div class="w-100">
                    <div v-for="(bukti, index) in datafile" :key="index">
                        <label class="mt-3" for="bukti">Upload Media Ulasan {{index +1}}</label><br>
                        <div style="display:flex;">
                            <label :for="`file${index}`" class="input-group flex-nowrap"> 
                                <label style="background-color:#e9ecef;color:#000;" :for="`file${index}`" type="button" class="btn btn-outline-secondary label-file" >Choose File</label>
                                <div class="d-flex align-items-center border border-secondary p-2 w-100" style="overflow-x: hidden;">
                                    <p class="mb-0">{{ bukti.fileName }}</p>
                                </div>
                            </label>
                            <input :id="`file`+index" type="file" @change="inputfile($event,index)" class="d-none form-control mt-2" name="data" accept=".jpg,.jpeg,.png,.webp,.mp4,.mpeg,.mov,.3gp,.mkv,.avi" placeholder="Upload Bukti Komplain"  />
                            <div class="mt-2 view-web" style="margin-left: 10px;">
                                <p class="bg-danger btn-delete text-white bridge mt-3" @click="deletefile(index)"><i class='bx bx-trash'></i></p>
                            </div>
                        </div>
                        <div class="mt-2 view-mobile">
                            <p class="bg-danger btn-delete text-white bridge mt-3" @click="deletefile(index)"><i class='bx bx-trash'></i></p>
                        </div>
                    </div>
                </div>
                <!-- <small
                    class="error text-danger"
                    v-for="(error, index) of v$.datafile.$errors"
                    :key="index"
                    >
                    {{ error.$message }}
                </small> -->
                <div class="w-100">
                    <button type="submit" class="btn btn-success mt-3">Kirim Ulasan</button>
                </div>
            </form>
        </div>

        <div v-if="loadingSubmit" class="loading-submit-animation-container" >
            <div class="loading-submit-animation-content">
                <div>
                    <div class="lds-facebook"><div></div><div></div><div></div></div>
                    <p>Mohon tunggu, data sedang diproses</p>
                </div>
            </div>
        </div>

        <Modal
          :show="modal.showModal"
          :header="modal.header"
          :headerBorder="modal.headerBorder"
          :title="modal.title"
          :titleText="modal.titleText"
          :footer="modal.footer"
          :footerBtnPosition="modal.footerBtnPosition"
          :footerBorder="modal.footerBorder"
          :btnCancel="modal.btnCancel"
          :btnSubmit="modal.btnSubmit"
          :btnSubmitText="modal.btnSubmitText"
          :btnCancelText="modal.btnCancelText"
          :btnSubmitWidth="modal.btnSubmitWidth"
          :btnCancelWidth="modal.btnCancelWidth"
          @closeModal="closeModal"
          @submitModal="submitModal"
        >
          <!-- Notif add cart -->
          <div v-if="modalTipe === 'ulasansuccess'">
            <div class="text-center mt-2 border-2 border-light border-bottom">
              <i class='bx bxs-check-circle ' style="font-size:115px; color:var(--primary-color)"></i>
              <div class="notif-text">
                <h5>Ulasan Anda Berhasil dikirim</h5>
              </div>
            </div>
            <div class="text-right mt-2">
                <button @click="dataoke" class="btn btn-red">Oke</button>
            </div>
          </div>

          <!-- Info Negosiasi -->
          <div v-else-if="modalTipe === 'errorimagesize'" class="d-flex align-items-center justify-content-center text-center mt-2">
            <div>
              <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
              <h5>Ooppsss</h5>
              <p>Ukuran format Gambar anda lebih dari 2mb</p>
            </div>
          </div>
          <div v-else-if="modalTipe === 'errorvideosize'" class="d-flex align-items-center justify-content-center text-center mt-2">
            <div>
              <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
              <h5>Ooppsss</h5>
              <p>Ukuran format video anda lebih dari 50mb</p>
            </div>
          </div>
          <div v-else-if="modalTipe === 'errorformat'" class="d-flex align-items-center justify-content-center text-center mt-2">
            <div>
              <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
              <h5>Ooppsss</h5>
              <p>Format File Image Hanya berupa(.jpg, .png, .jpeg, .webp)</p>
            </div>
          </div>
          <div v-else-if="modalTipe === 'errorinput'" class="d-flex align-items-center justify-content-center text-center mt-2">
            <div>
              <i class='bx bxs-error-circle' style="font-size:115px; color: #E40C0C;"></i>
              <h5>Ooppsss</h5>
              <p>{{massageadmin}}</p>
            </div>
          </div>
        </Modal>
    </div>
</template>

<script>

import axios from 'axios'
import Modal from '../Modal.vue'
import useVuelidate from '@vuelidate/core'
import { required,helpers,maxLength, minLength} from '@vuelidate/validators'
import SkeletonUlasan from '../SkeletonUser/ulasan/SkeletonUlasan.vue'
import ProdukSkeleton from '../SkeletonUser/ulasan/ProductUlasan.vue'

export default {
    name: 'Add-Complain',
    data() {
        return {
        inputs: [],
        rate:'',
        dataid:'',
        komentar:'',
        transaksi_id:'',
        detail_id:'',
        alertprodukid:true,
        datalist:[],
        datafile:[],
        datatransaksi:[],
        loading:true,
        LoadingSkeleton:true,
        loadingSubmit:false,
        massageadmin:'',
        modalTipe:'',
        modal:{
            showModal: false,
            header:false,
            headerBorder: false,
            title: false,
            titleText: '',
            footer: false,
            footerBtnPosition:'',
            footerBorder: false,
            btnCancel: false,
            btnSubmit: false,
            btnSubmitText: '',
            btnCancelText: '',
            btnSubmitWidth: '',
            btnCancelWidth: '',
        },
        };
        
    },
    setup () {
        return { v$: useVuelidate() }
    },
    validations() {
        return {
                dataid: {
                    required: helpers.withMessage('ID Pesanan Wajib diisi', required),
                },
                rate: {
                    required: helpers.withMessage('Bintang wajib diisi', required),
                },
                komentar: {
                    // required: helpers.withMessage('Komentar Komplain wajib diisi', required),
                    maxLength: helpers.withMessage('Maksimal Komentar berisi sebanyak 255 huruf', maxLength(255)),
                    minLength: helpers.withMessage('Minimal Komentar berisi sebanyak 5 huruf', minLength(5)),
                },
                // datafile: {
                //     required: helpers.withMessage('Bukti wajib diisi', required),
                // },
        }
    },
    watch: {
        user(value){
            if(!(value instanceof Promise) && value !== null){
            axios.get('/transaction/list-transaction/'+this.user.id).then(res => {
                this.datalist = res.data.data
                this.loading=false
                })
                .catch(() =>{
                this.loading=false
                })
            
            }
        },
        dataid(value) {
            this.transaksi_id = value.id
            this.datacheck(value)
        },
        detail_id(){
            this.alertprodukid=true
        },
    },

   components:{
        SkeletonUlasan,
        ProdukSkeleton,
        Modal
    },
    created(){
            if(this.user){
                axios.get('/transaction/list-transaction/'+this.user.id).then(res => {
                this.datalist = res.data.data
                this.loading=false
                })
                .catch(() =>{
                this.loading=false
                })
            }
    },
    computed: {
        user: function() {
            return this.$store.state.user;
        },
        paidtransaksi: function () {
        return this.datalist
        .filter((data) => {
            return (
            data.status.toLowerCase().includes('paid')
            );
        })
        }
    },
    methods: {
        deletefile(value){
            this.datafile.splice(value, 1)
            this.inputs.splice(value, 1)
        },
        setId(value){
            this.detail_id = value
        },
        datacheck(data){
            this.LoadingSkeleton=true
            axios.get('/transaction/'+data.id).then(res => {
                this.datatransaksi = res.data.data.reviews
                 this.LoadingSkeleton=false
            })
            .catch(() =>{
                this.LoadingSkeleton=false
            })
        },
        setModal(condition, tipe){
            this.resetDataModal()
            this.modal.showModal= condition
            condition == true ? this.modalTipe = tipe : ''
            if(tipe == 'ulasansuccess' && condition){
                this.modal.header=true
            }else if(tipe == 'errorimagesize' && condition){
                this.modal.header=true
                this.modal.footer= true
                this.modal.footerBtnPosition='right'
                this.modal.btnSubmit= true
                this.modal.btnSubmitText= 'Oke'
                this.modal.btnSubmitWidth='auto'
            }else if(tipe == 'errorvideosize' && condition){
                this.modal.header=true
                this.modal.footer= true
                this.modal.footerBtnPosition='right'
                this.modal.btnSubmit= true
                this.modal.btnSubmitText= 'Oke'
                this.modal.btnSubmitWidth='auto'
            }else if(tipe == 'errorformat' && condition){
                this.modal.header=true
                this.modal.footer= true
                this.modal.footerBtnPosition='right'
                this.modal.btnSubmit= true
                this.modal.btnSubmitText= 'Oke'
                this.modal.btnSubmitWidth='auto'
            }else if(tipe == 'errorinput' && condition){
                this.modal.header=true
                this.modal.footer= true
                this.modal.footerBtnPosition='right'
                this.modal.btnSubmit= true
                this.modal.btnSubmitText= 'Oke'
                this.modal.btnSubmitWidth='auto'
            }
        },
        resetDataModal(){
            this.modal.showModal = false
            this.modal.header = false,
            this.modal.headerBorder = false
            this.modal.title = false
            this.modal.titleText = '' 
            this.modal.footer = false
            this.modal.footerBtnPosition =''
            this.modal.footerBorder = false
            this.modal.btnCancel = false
            this.modal.btnSubmit = false
            this.modal.btnSubmitText = ''
            this.modal.btnCancelText = ''
            this.modal.btnSubmitWidth = ''
            this.modal.btnCancelWidth = ''
        },
        closeModal(){
            this.setModal(false, this.modalTipe)
            this.$store.dispatch('carts')
        },
        dataoke(){
            this.setModal(false, this.modalTipe)
            this.$router.push('/profil/ulasan')
        },
        submitModal(){
            this.setModal(false, this.modalTipe)
        },
        addInput(){
            if(this.datafile.length < 3){
                let template = {
                    fileName: 'No file chosen',
                    file: null
                }
                if(this.datafile.length == 0 || this.datafile[this.datafile.length - 1].file != null ){
                    this.datafile.push(template)
                }
            }
            
        },
        inputfile(event,index){
            let file = event.target.files[0];
            if (
            file["type"] === "image/jpeg" ||
            file["type"] === "image/jpg" ||
            file["type"] === "image/png" ||
            file["type"] === "image/webp" 
            ) {
                if(file["size"] <= 2048000){
                    this.datafile[index].file = event.target.files[0]
                    this.datafile[index].fileName = event.target.files[0].name
                }else{
                    document.getElementById(`file`+index).value = "";
                    this.setModal(true, 'errorimagesize')
                }
                
            }else if(
            file["type"] === "video/mp4" ||
            file["type"] === "video/*" ||
            file["type"] === "video/mpeg" ||
            file["type"] === "video/mov" ||
            file["type"] === "video/3gp" ||
            file["type"] === "video/mkv" ||
            file["type"] === "video/avi" ){
                if(file["size"] <= 51200000){
                     this.datafile[index].file = event.target.files[0]
                     this.datafile[index].fileName = event.target.files[0].name
                }else{
                    document.getElementById(`file`+index).value = "";
                    this.setModal(true, 'errorvideosize')
                }

            } else {
                document.getElementById(`file`+index).value = "";
                this.setModal(true, 'errorformat')
            }
            
        },
        addComplain(){
            this.v$.$touch()
            if (!this.v$.$invalid && this.detail_id) {
                 let data = new FormData();
                data.append("transaction", this.detail_id);
                data.append("star", this.rate);
                data.append("notes", this.komentar);
                this.datafile.forEach((item)=>{
                    data.append("image[]", item.file)
                })
                this.loadingSubmit = true
                axios.post(`/product/review`, data)
                .then(() => {
                    this.loadingSubmit = false
                    this.setModal(true, 'ulasansuccess')

                })
                .catch((e) => {
                    this.loadingSubmit = false
                    if(e.response.data.message == 'You already make complaint with this transaction'){
                        this.massageadmin = 'Anda Sudah melakukan komplain pada produk didalam invoice yang anda pilih'
                    }else{
                        this.massageadmin = e.response.data.message
                    }
                    this.setModal(true, 'errorinput')
                    
                });
            }else if(!this.detail_id){
                this.alertprodukid = false
            }
        }
    }
}
</script>

<style scoped>

.img-profile{
    width: 150px;
    height: 110px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.img-profile img{
    width: 100%;
}
.td-nama-produk{
    max-width: 200px !important;
}
.nama-produk{
    display: flex;
    margin: 0px;
    margin-right: 0px !important;
}
.nama-produk h6{
    vertical-align: inherit;
    margin: auto 0;
}
.console_log{
    border: 2px solid red !important;
}

.comment-star {
    padding: 0px;
    border-radius: 5px;
    display: flex;
    text-align: left !important;
    /* align-items: right; */
}

.star-widget input {
    display: none;
}

.comment-star label {
    font-size: 1.5rem;
    color: #444;
    padding: 5px;
    float: right;
    transition: all 0.2s ease;
}
.btn-delete{
    padding: 0.385rem 0.5rem;
    border-radius: 5px;
    display: initial;
}

input:not(:checked)~label:hover,
input:not(:checked)~label:hover~label {
    color: #fd4;
}

input:checked~label {
    color: #fd4;
}

input#rate-5:checked~label {
    color: #fe7;
}

.label-file{
    width: 175px;
}
.view-web{
    display: none;
}
.view-mobile{
    display: block;
}

@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
    .label-file{
        width: 140px;
    }
    .view-web{
        display: block;
    }
    .view-mobile{
        display: none;
    }
  
}

@media (min-width: 1023px) {
    .label-file{
        width: 130px;
    }
}

@media (min-width: 1439px) {
    .label-file{
        width: 125px;
    }
}

@media (min-width: 2000px) {
}
</style>
