<template>
    <div class="section">
        <div class="container">
            <div class="row row-dokupayment">
                <div class="d-flex justify-content-end">
                    <!-- <nav aria-label="breadcrumb" class="">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a href="../profil/beranda" class="custom-link">Beranda</a>
                            </li>
                            <li class="breadcrumb-item">
                                <a aria-current="page" class="router-link-active router-link-exact-active text-secondary">Metode Pembayaran BCA Virtual Account</a>
                            </li>
                        </ol>
                    </nav> -->
                </div>
                <div class="col-xs-12 col-sm-5 col-md-4 left-content-bpdpaymet">
                    <div class="border-bottom border-white pb-2">
                        <span class="title-dokupayment">Nama Pembeli</span><br>
                        <span class="subtitle-dokupayment"><b>{{name}}</b></span>
                    </div>
                    <div class="border-bottom border-white pt-3 pb-2">
                        <span class="title-dokupayment">Tanggal Pembelian</span><br>
                        <span class="subtitle-dokupayment"><b>{{tanggal_beli}}</b></span>
                    </div>
                    <div class="border-bottom border-white pt-3 pb-2">
                        <span class="title-dokupayment">No Invoice</span><br>
                        <span class="subtitle-dokupayment"><b>{{invoice}}</b></span>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-7 col-md-8 right-content-bpdpaymet p-3">
                    <div class="row">
                        <div class="col-sm-12 col-md-2 pt-3 text-center">
                            <img class="logo-dokupayment" src="../../assets/img/doku.png">
                        </div>
                        <div class="col-sm-12 col-md-10 title-logo-dokupayment">
                            <b>{{payment}}</b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <hr class="border border-2 border-secondary">
                        </div>
                        <div class="col-md-12 text-center">
                            <b>Metode Pembayaran :</b>{{payment}}<br>
                            <!-- <b>Lakukan Pembayaran Sebelum :</b> 12 Mei 2022 19:00: WITA -->
                        </div>
                        <div class="col-md-12 text-center my-3">
                            <div>Total Yang Harus Dibayar :</div>
                            <div class="title-price-dokupayment"><b>Rp. {{new Intl.NumberFormat('id-ID').format(Number(nominal).toFixed())}}</b></div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-12 col-md-12 text-center mb-2">
                            <div class="border border-2 p-4 rounded-15">
                                <h6>Pembayaran Menggunakan Virtual Account</h6>
                                <div class="text-left">
                                    Tata cara pembayaran :
                                    <ul class="list-method-dokupayment">
                                        <li>Buka aplikasi m-banking di smartphone.</li>
                                        <li>Masukkan username dan password.</li>
                                        <li>Pilih menu Transfer > Bank Lain Dalam Negeri.</li>
                                        <li>Masukkan Kode Bank dan Nomor Virtual Account tujuan.</li>
                                        <li>Masukkan nominal yang harus dibayarkan.</li>
                                        <li>Layar akan menunjukkan konfirmasi. Apabila telah sesuai, pilih Lanjutkan / Kirim.</li>
                                        <li>Masukkan PIN transaksi.</li>
                                        <li>Selesai.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-12 text-right">
                            <router-link :to="'/profil/detailtransaksi?id_transaksi='+id" type="button" class="btn btn-back-dokupayment"><i class='bx bx-arrow-back' ></i> Kembali</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
     props:['name','id','invoice','tanggal_beli','nominal','payment'],
    setup() {
        
    },
}
</script>
<style>
    .left-content-bpdpaymet {
        background-color: var(--primary-color);
        border-radius: 10px 0px 0px 10px;
        color: #ffffff;
        padding: 30px;
    }
    .title-dokupayment {
        font-size: 12px;
    }
    .subtitle-dokupayment {
        font-size: 24px;
    }
    .right-content-bpdpaymet {
        background-color: #ffffff;
        border-radius: 0px 10px 10px 0px;
    }
    .logo-dokupayment {
        width: 35px;
        height: auto;
    }
    .title-logo-dokupayment {
        font-size: 26px;
        margin-top: 10px;
        margin-left: -20px;
    }
    .border-2 {
        border-width:2px !important;
    }
    .rounded-15 {
        border-radius: 15px;
    }
    .title-price-dokupayment {
        font-size: 40px;
        color: var(--primary-color);
        margin-top: -10px;
    }
    .barcode-dokupayment {
        width: 200px;
        height: auto;
    }
    .list-method-dokupayment {
        list-style-type: decimal; 
        margin-left:15px;
    }
    .btn-back-dokupayment {
        background-color: var(--primary-color);
        color: #FFFFFF;
    }
    @media (max-width: 1200px) { 
        .row-dokupayment {
            margin: 0px 3px;
        }
        .subtitle-dokupayment {
            font-size: 20px;
        }
    }
    @media (max-width: 992px) { 
        .title-logo-dokupayment {
            font-size: 24px;
            margin-top: 10px;
        }
        .subtitle-dokupayment {
            font-size: 16px;
        }
    }
    @media (max-width: 768px) { 
        .title-logo-dokupayment {
            margin-top: 0px;
            margin-left: 0px;
            text-align: center;
        }
        .left-content-bpdpaymet {
            padding: 20px;
        }
        .subtitle-dokupayment {
            font-size: 18px;
        }
    }
    @media (max-width: 576px) { 
        .left-content-bpdpaymet {
            border-radius: 10px 10px 0px 0px;
        }
        .right-content-bpdpaymet {
            border-radius: 0px 0px 10px 10px;
        }
    }
</style>