<template>
    <div>
        <skeleton-dashboard v-if="loading==true"></skeleton-dashboard>
        <div v-if="loading==false" class="background-white py-3">
            <div class="container-fluid row card-data-user">
                <div class="col-lg-4 align-middle">
                    <!-- <h3 class="p-3">Beranda</h3> -->
                    <figure class="img-profile">
                        <img :src="imageuser" alt="">
                    </figure>
                </div>
                <div class="col-lg-8 card-user">
                    <table class="table table-striped table-hover border-0">
                        <tbody>
                            <tr class="border-0">
                                <td class="border-0"><b>Nama Instansi</b></td>
                                <td class="border-0">: {{user?.name}}</td>
                            </tr>
                            <tr class="border-0">
                                <td class="border-0"><b>Email</b></td>
                                <td class="border-0">: {{user?.email}}</td>
                            </tr>
                            <tr class="border-0">
                                <td class="border-0"><b>No. Telepon</b></td>
                                <td class="border-0">: {{user?.phone}}</td>
                            </tr>
                            <tr v-if="address != 'null'" class="border-0">
                                <td class="border-0"><b>Alamat</b></td>
                                <td class="border-0">: {{address}}</td>
                            </tr>
                            <tr class="border-0">
                                <td class="border-0"><b>NIP</b></td>
                                <td class="border-0">: {{user?.nip}}</td>
                            </tr>
                            <tr class="border-0">
                                <td class="border-0"><b>Nama PP</b></td>
                                <td class="border-0">: {{user?.name_pp}}</td>
                            </tr>
                            <tr class="border-0">
                                <td class="border-0"><b>Nama PPK</b></td>
                                <td class="border-0">: {{user?.name_ppk}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                        <h5>Daftar Transaksi</h5>
                        <hr>
                        <data-table></data-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import axios from 'axios'
import Img_Default from '../assets/img/img-default.png'
import DataTable from '../components/BerandaUser/TableUser.vue'
import SkeletonDashboard from '../components/SkeletonUser/DashboardUser.vue'
export default {
    name:'DashMobile',
    components:{
        DataTable,
        SkeletonDashboard
    },
    data(){
        return{
            loading:true,
            imageuser:'',
            listaddress:{
            },
            address:'-',
            users:[
            ],
        }
    },
    created(){
        this.$store.dispatch('semuapesan')
        if(!this.$store.state.user){
            this.$store.dispatch('user')
        }
        if(this.$store.state.address.length >0){
                this.address = this.$store.state.address[0].customer_address?.address
                this.loading=false
        }else if(this.$store.state.address.length == []){
                this.loading=false
        }else if(!this.$store.state.address){
                this.loading=false
        }
        if(this.user.image){
            this.imageuser = this.$imgUrl+this.user.image
            }else{
                this.imageuser = Img_Default
            }

        
    },
    mounted(){
        
    },
    computed: {
        // loading:function(){
        //     return this.$store.state.user instanceof Promise
        // },
        user: function() {
            return this.$store.state.user;
        },
    },
    watch:{
        user(value){
            if(value.image){
            this.imageuser = this.$imgUrl+value.image
            }else{
                this.imageuser = Img_Default
            }
            if(this.$store.state.address > 0){
                this.address = this.$store.state.address[0].customer_address?.address
                this.loading=false
            }
            else if(!(value instanceof Promise) && value !== null && this.$store.state.address == null){
                // this.address = 'null'
            // this.$store.dispatch('user')
            let me = this
            this.$store.dispatch('address').then(()=>{
                this.address = this.$store.state.address[0].customer_address?.address
                this.loading=false
                me.cartsloaded = true
            })
            
            }
        }
    },
    methods: {
        dataaddress(){

        }
    },
}
</script>
<style scoped>
.card-data-user{
    margin:0 !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
}
.nav-desk{
    display: none;
}
.navmobile{
    display: block;
}
.card-user{
    margin-top: 3rem;
}
.data-user p{
    margin-bottom: 5px !important;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 600;
}
.data-user h5{
    font-weight: 600;
    display: block;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 32px;
}
.labeldata{
    min-width: 150px;
}
.isidatauser{
    padding-left: 10px !important;
    font-weight: 400;
}
.img-profile{
    width: 200px;
    height: 200px;
    overflow: hidden;
    align-items: center;
    margin: 0 auto;
}
.img-profile img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    border-radius: 50%;
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
    .card-data-user{
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
}
.data-user h5{
    display: flex;
}
}

@media (min-width: 1023px) {
    .card-user{
    margin-top: 0rem;
    }
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>