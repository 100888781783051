<template>
    <div class="contact section section-modif">
        <div class="container">
            <h1 class="text-center mb-3">Hubungi Kami</h1>

            <div class="row">
                <div class="col-md-10 mx-auto">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="box">
                                <h3 class="">Informasi</h3>
                                <hr class="red-line mb-4">
                                <div v-if="loading" :class="{ 'loading-skeleton' : loading }">
                                    <p>loading..</p>
                                    <p>loading..</p>
                                    <p>loading..</p>
                                </div>

                                <div class="list" v-else>
                                    <div class="item d-flex align-items-start">
                                        <i class='bx bxs-map' ></i>
                                        <div>
                                            {{setting.address+','}}
                                            {{districts.city_name ? districts.city_name+',' : ''}}
                                            {{provinces.province_name ? provinces.province_name+',' : ''}}
                                            {{districts.postal_code ? districts.postal_code : ''}}
                                        </div>
                                    </div>
                                    <a :href="'https://wa.me/'+setting.phone" target="_blank" class="item d-flex align-items-start">
                                        <i class='bx bxs-phone' ></i>
                                        {{setting.phone}}
                                    </a>
                                    <a :href="'mailto:'+setting.email" target="_blank" class="item d-flex align-items-start">
                                        <i class='bx bxs-envelope' ></i>
                                        {{setting.email}}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <iframe 
                                frameborder="0" 
                                scrolling="no"
                                marginheight="0" 
                                marginwidth="0" 
                                class="maps mb-3"
                                :src="'https://maps.google.com/maps?q='+setting.lat+','+setting.long+'&hl=en&z=14&amp;output=embed'"
                            >
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'faq-view',
    data(){
        return{
            setting: [],
            loading: false,
            baseUrl: process.env.VUE_APP_NAME_AXIOS,
            provinces: '',
            districts: '',
            sub_districts: ''
        }
    },
    async mounted(){
        this.loading = true
        await axios.get(`setting`)
        .then((result) => {
            // console.log('setting : ', result.data.data)
            this.setting = result.data.data
            this.loading = false
        }).catch(() => {
            // console.log(err.response);
            this.loading = false
        })
        // await this.getSubDistrict()
        await this.getDistrict()
        await this.getProvince()
    },
    methods: {
        // getSubDistrict(){
        //     axios.get(`customer/sub-district/${this.setting.district}`)
        //     .then(res => {
        //         let data = res.data.data
        //         console.log('sub district: ', data)
        //         data.forEach(item => {
        //             if (item.city_id === this.setting.district) {
        //                 console.log('sub district: ', item)
        //                 // console.log('district: ', item)
        //                 this.sub_districts = item
        //             }
        //         });
        //         console.log('Provinsi: ', this.sub_districts)
        //     })
        // },
        getDistrict(){
            axios.get(`customer/district/${this.setting.province}`)
            .then(res => {
                let data = res.data.data
                data.forEach(item => {
                    if (item.city_id === this.setting.district) {
                        // console.log('district: ', item)
                        // console.log('district: ', item)
                        this.districts = item
                    }
                });
                // console.log('Provinsi: ', this.sub_districts)
            })
        },
        getProvince(){
            axios.get('customer/province')
            .then(res => {
                let data = res.data.data
                data.forEach(item => {
                    if (item.province_id === this.setting.province) {
                        // console.log('data: ', item)
                        this.provinces = item
                    }
                });
                // console.log('Provinsi: ', this.provinces)
            })
        },
    }
}
</script>

<style scoped>
iframe.maps{
    width: 100%;
    height: 400px;
}

.box{
    background: white;
    padding: 20px;
}

</style>